import styled from 'styled-components';
import { StyledRichText as RichText } from '../RichText/RichText.jsx';
import { StyledResponsiveDwPicture as ResponsiveDwPicture } from '../ResponsiveDwPicture/ResponsiveDwPicture.jsx';
import { Bubble } from '../Bubble/Bubble.jsx';

export const VocabularyQuestion = ({ className, children, mainContentImage }) => {
  return (
    <div className={className}>
      {mainContentImage && (
        <ResponsiveDwPicture
          image={mainContentImage}
          alt={mainContentImage.name}
          key={mainContentImage.id}
        />
      )}
      <Bubble>
        <RichText content={children} noContainer />
      </Bubble>
    </div>
  );
};

export const StyledVocabularyQuestion = styled(VocabularyQuestion)`
  > ${Bubble} > ${RichText} p {
    margin: 0;
  }
`;
