import { memoizeWith } from 'ramda';
import gql from 'graphql-tag';
import { createContext, useContext } from 'react';

const fragmentName = 'NavigationRootFragment';
export const navigationRootFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on LgNavigationRoot {
        learningLevelNavigations(lang: $lang) {
          id
          name
          namedUrl
          language
        }
        allCoursesNavigation(lang: $lang) {
          id
          namedUrl
        }
      }
    `,
  ),
};

export const NavigationRootContext = createContext();

export const useNavigationRoot = () => useContext(NavigationRootContext);

export const useLandingPage = () => useContext(NavigationRootContext)?.allCoursesNavigation;
