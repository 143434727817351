import styled from 'styled-components';
import { StyledExpandableTextOverlay as ExpandableTextOverlay } from './ExpandableTextOverlay.jsx';
import { StyledResponsiveSlideImage as SlideResponsiveImage } from './SlideResponsiveImage.jsx';

export const SlideImage = ({
  galleryImage,
  onTextOverlayToggle,
  isTextOverlayOpened,
  isCurrentIndex,
  ...restProps
}) => {
  const { name: title, description: teaser } = galleryImage;
  return (
    <div {...restProps}>
      <ExpandableTextOverlay
        className="text-overlay"
        title={title}
        teaser={teaser}
        onTextOverlayToggle={onTextOverlayToggle}
        isTextOverlayOpened={isTextOverlayOpened}
        isCurrentIndex={isCurrentIndex}
      />
      <SlideResponsiveImage galleryImage={galleryImage} />
    </div>
  );
};

export const StyledSlideImage = styled(SlideImage)``;
