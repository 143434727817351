const profileEditKeyPath = 'profile.edit';
const professionKeyPath = `${profileEditKeyPath}.profession`;
export const sexOptions = Object.freeze([
  { value: 'FEMALE', key: `${profileEditKeyPath}.sex.female` },
  { value: 'MALE', key: `${profileEditKeyPath}.sex.male` },
  { value: 'OTHER', key: `${profileEditKeyPath}.sex.other` },
]);

export const professionOptions = Object.freeze([
  { value: 'ZFA_TEACHER', key: `${professionKeyPath}.zfa_teacher` },
  { value: 'PRIVATE_TEACHER', key: `${professionKeyPath}.private_teacher` },
  { value: 'INSTITUTE_MANAGER', key: `${professionKeyPath}.institute_manager` },
  { value: 'INSTITUTE_EMPLOYEE', key: `${professionKeyPath}.institute_employee` },
  { value: 'OTHER', key: `${professionKeyPath}.other` },
]);

const languageLevelKeyPath = `${profileEditKeyPath}.languageLevel`;
export const languageLevelOptions = Object.freeze([
  { value: 'ZERO', key: `${languageLevelKeyPath}.zero` },
  { value: 'BEGINNER', key: `${languageLevelKeyPath}.beginner` },
  { value: 'INTERMEDIATE', key: `${languageLevelKeyPath}.intermediate` },
  { value: 'EXPERT', key: `${languageLevelKeyPath}.expert` },
]);

const writingReadingLevelKeyPath = `${profileEditKeyPath}.writingReadingLevel`;
export const writingReadingLevelOptions = Object.freeze([
  { value: 'ZERO', key: `${writingReadingLevelKeyPath}.zero` },
  { value: 'NOT_LATIN', key: `${writingReadingLevelKeyPath}.not_latin` },
  { value: 'UNCERTAIN', key: `${writingReadingLevelKeyPath}.uncertain` },
  { value: 'CAN', key: `${writingReadingLevelKeyPath}.can` },
]);

const handicapKeyPath = `${profileEditKeyPath}.handicap`;
export const handicapOptions = Object.freeze([
  {
    value: 'BLIND',
    key: `${handicapKeyPath}.blind.title`,
    ariaKey: `${handicapKeyPath}.blind.aria`,
  },
  {
    value: 'POOR_EYESIGHT',
    key: `${handicapKeyPath}.poor_eyesight.title`,
    ariaKey: `${handicapKeyPath}.blind.aria`,
  },
  {
    value: 'WEAK_HEARING',
    key: `${handicapKeyPath}.weak_hearing.title`,
    ariaKey: `${handicapKeyPath}.blind.aria`,
  },
  {
    value: 'MOUSE_USAGE',
    key: `${handicapKeyPath}.mouse_usage.title`,
    ariaKey: `${handicapKeyPath}.blind.aria`,
  },
]);

const learningTypeKeyPath = `${profileEditKeyPath}.learningType`;
export const learningTypeOptions = Object.freeze([
  { value: 'MUSIC', key: `${learningTypeKeyPath}.music` },
  { value: 'AUDIO', key: `${learningTypeKeyPath}.audio` },
  { value: 'AUTHENTIC_TEXT', key: `${learningTypeKeyPath}.authentic_text` },
]);
