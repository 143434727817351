import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { memoizeWith } from 'ramda';
import { wrapTablesWithDiv } from '../utils/replacers/htmlReplacer';
import { toLang } from '../utils/mappers/langMapper';
import { WithGraphQLQueryHandling } from '../components/WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { PageMetaData } from '../components/Page/PageMetaData.jsx';
import { GtmScriptWithDataLayer, gtmDataLayerFragment } from '../components/GoogleTagManager';
import {
  knowledgesFragment,
  StyledLessonKnowledge as LessonKnowledge,
} from '../components/Lesson/LessonKnowledge/LessonKnowledge.jsx';
import { GTM_LESSON_PARTS } from '../constants/pageTypes';
import { useStructuralLessonData } from '../context/StructuralLessonDataContext.jsx';
import { useLanguage } from '../context/LanguageContext';

const queryName = 'LessonKnowledge';
export const lessonKnowledgeQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}($knowledgeId: Int!, $lessonLang: Language!, $contextId:ID!) {
        content(id: $knowledgeId, lang: $lessonLang) {
          ... on Knowledge {
            name
            text
            namedUrl(contextId:$contextId)
          }
          ...${knowledgesFragment.name({ type: 'Knowledge' })}
          ${gtmDataLayerFragment.partial()}
        }
      }
      ${knowledgesFragment.fragment({ type: 'Knowledge' })}
    `,
  ),
};

export const LessonKnowledgePage = ({ pageType, lessonName, lessonKeywordStrings }) => {
  const { lessonId } = useStructuralLessonData().structuralData;
  const { contentId } = useParams();
  const { langCode } = useLanguage();
  const lessonKnowledgeQueryVariables = {
    knowledgeId: +contentId,
    lessonLang: toLang(langCode),
    contextId: lessonId,
  };

  return (
    <WithGraphQLQueryHandling
      query={lessonKnowledgeQuery.query()}
      queryVariables={lessonKnowledgeQueryVariables}
    >
      {({ data }) => {
        const { content } = data;
        const { name, text, knowledges } = content;
        const knowledgeContent = wrapTablesWithDiv(text);

        return (
          <>
            <PageMetaData
              pageType={pageType}
              content={{ ...content, keywordStrings: lessonKeywordStrings }}
              parentContentName={lessonName}
              useDescriptionTranslation={true}
              relativeCanonicalUrl={content.namedUrl}
            />
            <GtmScriptWithDataLayer content={content} lessonItem={GTM_LESSON_PARTS[pageType]} />
            <LessonKnowledge
              name={name}
              knowledges={knowledges}
              knowledgeContent={knowledgeContent}
            />
          </>
        );
      }}
    </WithGraphQLQueryHandling>
  );
};
