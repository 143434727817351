import { createKeywords, PAGE_TYPE_TO_KEYWORDS_TRANSLATION_KEYS } from '../utils/pageMetadata';
import { useTranslation } from './useTranslation';

export const useMetaKeywords = ({ pageType, useOnlyCmsKeywords, keywordStrings } = {}) => {
  const defaultKeywords = useTranslation('metadata.keywords');
  const pageKeywords = useTranslation(PAGE_TYPE_TO_KEYWORDS_TRANSLATION_KEYS[pageType]);

  const additionalKeyword = pageKeywords || defaultKeywords;
  const keywords = useOnlyCmsKeywords ? keywordStrings : [...keywordStrings, additionalKeyword];

  return createKeywords({ keywords });
};
