import classnames from 'classnames';
import { useFormError } from '../../../../hooks/useFormError';
import { deconstructDateOfBirth } from '../../../../utils/userUtils';
import { I18nText } from '../../../I18n/I18nText.jsx';
import { StyledValidationMessage as ValidationMessage } from '../../ValidationMessage.jsx';
import { StyledInput } from '../StyledInput.jsx';

export const BirthDateInput = ({
  initialDateOfBirth,
  labelKey = 'profile.register.dateOfBirth',
  className,
}) => {
  const { year: initYear } = deconstructDateOfBirth(initialDateOfBirth);
  const { hasError } = useFormError('year');

  return (
    <div className={classnames(className, { error: hasError })} aria-live="assertive">
      <div>
        <StyledInput
          id="birthDateInput"
          name="year"
          type="number"
          labelKey={labelKey}
          defaultValue={initYear}
          hideValidationError
          registerOptions={{
            min: 1900,
            max: 9999,
            maxLength: 4,
          }}
        />
      </div>
      {hasError && <I18nText isA={ValidationMessage} translation="client.validator.date" />}
    </div>
  );
};
