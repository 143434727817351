import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { WithGraphQLQueryHandling } from '../components/WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import {
  manuscriptFragment,
  StyledManuscript as Manuscript,
} from '../components/Manuscript/Manuscript.jsx';
import { knowledgesFragment } from '../components/Lesson/LessonKnowledge/LessonKnowledge.jsx';
import {
  MediaInputChooser,
  mediaInputChooserFragment,
} from '../components/Lesson/MediaInputChooser/MediaInputChooser.jsx';
import { metaDataFragment, PageMetaData } from '../components/Page/PageMetaData.jsx';
import { GTM_LESSON_PARTS, PAGE_TYPES } from '../constants/pageTypes';
import { gtmDataLayerFragment, GtmScriptWithDataLayer } from '../components/GoogleTagManager';
import { AudioProvider } from '../context/AudioContext.jsx';
import { LESSON_MANUSCRIPT_SUFFIX } from '../utils/url/urlFactory';
import { useStructuralLessonData } from '../context/StructuralLessonDataContext.jsx';

const queryName = 'ManuscriptPage';
export const ManuscriptQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}($id: Int!, $lang: Language!) {
        content(id: $id, lang: $lang) {
          ...${mediaInputChooserFragment.name({ type: 'Lesson' })}
          ...${manuscriptFragment.name}
          ...${knowledgesFragment.name({ type: 'Lesson' })}
          ...${metaDataFragment.name({ type: 'Lesson' })}
          ${gtmDataLayerFragment.partial()}
        }
      }
      ${mediaInputChooserFragment.fragment({ type: 'Lesson' })}
      ${manuscriptFragment.fragment()}
      ${knowledgesFragment.fragment({ type: 'Lesson' })}
      ${metaDataFragment.fragment({ type: 'Lesson' })}
    `,
  ),
};

export const ManuscriptPage = ({ contentId, language }) => {
  const { lessonPath } = useStructuralLessonData().structuralData;
  const ManuscriptPageQueryVariables = {
    id: +contentId,
    lang: language,
  };

  return (
    <WithGraphQLQueryHandling
      query={ManuscriptQuery.query()}
      queryVariables={ManuscriptPageQueryVariables}
    >
      {({ data }) => (
        <AudioProvider>
          <PageMetaData
            pageType={PAGE_TYPES.MANUSCRIPT}
            content={data.content}
            translationFirst={true}
            useDescriptionTranslation={true}
            relativeCanonicalUrl={`${lessonPath}/${LESSON_MANUSCRIPT_SUFFIX}`}
          />
          <GtmScriptWithDataLayer
            content={data.content}
            lessonItem={GTM_LESSON_PARTS[PAGE_TYPES.MANUSCRIPT]}
          />
          <MediaInputChooser data={data} />
          <Manuscript
            knowledges={data.content.knowledges}
            manuscript={data.content.manuscript}
            isStandalone
          />
        </AudioProvider>
      )}
    </WithGraphQLQueryHandling>
  );
};
