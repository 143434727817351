import styled from 'styled-components';
import classnames from 'classnames';
import { StyledPlayIcon } from '../../../assets/icons/slideShow/components/Play.jsx';
import { StyledPauseIcon } from '../../../assets/icons/slideShow/components/Pause';
import { StyledIconButton } from '../../StandardButton/IconButton.jsx';
import { colors, mediaMin } from '../../../utils/css';
import { I18nText } from '../../I18n/I18nText.jsx';
import { usePageEmbeddingContext } from '../../../context/PageEmbeddingContext.jsx';
import { useTranslation } from '../../../hooks/useTranslation';
import { StyledA11yAriaLive as A11yAriaLive } from '../../a11y/A11yAriaLive.jsx';
import { DateTimeInfo } from '../../Info/Info.jsx';

export const STATUS = Object.freeze({
  play: 'play',
  pause: 'pause',
  init: 'init',
});

export const ControlBar = ({ className, slidesCount, slideNumber, onPlayPause, playingState }) => {
  const pageContext = usePageEmbeddingContext();
  const alignedSlideNumber = slideNumber === 0 ? 1 : slideNumber;

  const clicked = playingState !== STATUS.init;
  const playing = playingState === STATUS.play;

  const PlayPauseIcon = playing ? StyledPauseIcon : StyledPlayIcon;
  const buttonTitle = useTranslation(`slideshow.play_button.${playing ? 'pause' : 'play'}.label`);
  const ariaLiveTranslation = {
    key: 'slideshow.paging_live_region.label',
    parameters: {
      currentImage: slideNumber,
      totalImages: slidesCount,
    },
  };
  const ariaLiveTranslationCover = {
    key: `slideshow.number_of_images.${slidesCount === 1 ? 'singular' : 'plural'}`,
    parameters: { count: slidesCount },
  };

  return (
    <div className={className}>
      <div className={classnames('control-bar-wrapper', { 'is-hero': !pageContext.isInRichText })}>
        <StyledIconButton
          className={classnames('play-pause-button', {
            playing,
            clicked,
          })}
          onClick={onPlayPause}
          title={buttonTitle}
        >
          <PlayPauseIcon />
        </StyledIconButton>
        <A11yAriaLive
          translation={slideNumber === 0 ? ariaLiveTranslationCover : ariaLiveTranslation}
        />
        <div aria-hidden="true" className={classnames('slide-info', { clicked })}>
          <DateTimeInfo className="slide-position">{`${alignedSlideNumber} | ${slidesCount}`}</DateTimeInfo>
          <I18nText
            className="image-count"
            isA={DateTimeInfo}
            translation={ariaLiveTranslationCover}
          />
        </div>
      </div>
    </div>
  );
};

export const StyledControlBar = styled(ControlBar)`
  background-color: ${colors.LG_BLACK};
  ${DateTimeInfo} {
    color: ${colors.DW_GREY_03};
  }

  .control-bar-wrapper {
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: flex-start;
    /*! @noflip */
    direction: ltr;
  }

  .play-pause-button {
    display: flex;
    padding: 15px;

    svg {
      height: 20px;
      width: 20px;
      stroke-width: 0.8px;
    }
  }

  .play-pause-button {
    /*! @noflip */
    background: linear-gradient(
      to right,
      ${colors.DW_LIGHT_BLUE_NEW} 0%,
      ${colors.DW_LIGHT_BLUE_NEW} 50%,
      ${colors.LG_BLACK} 50%,
      ${colors.LG_BLACK} 100%
    );
    background-size: 200%;
    transition: background-position 800ms cubic-bezier(0.43, 0, 0.09, 1);
    svg {
      fill: ${colors.LG_WHITE};
    }

    &.playing,
    &.clicked {
      /*! @noflip */
      background-position: right;
      transition: background-position 800ms cubic-bezier(0.91, 0, 0.57, 1);

      svg {
        fill: none;
      }
    }
  }

  /*! @noflip */
  .slide-info {
    direction: ltr;
    width: calc(100% - 100px);
    position: relative;
    line-height: 1.5;
    font-size: 16px;
    height: 50px;
    padding: 15px 0;
    user-select: none;

    /*! @noflip */
    > .slide-position {
      position: absolute;

      left: 0;
      opacity: 0;

      transition-property: left, opacity, transform;
      transition-duration: 800ms;
      transition-timing-function: cubic-bezier(0.91, 0, 0.57, 1);
    }

    /*! @noflip */
    > .image-count {
      position: absolute;

      transform: translateX(15px);
      left: 15px;
      opacity: 1;

      transition-property: left, opacity, transform;
      transition-duration: 800ms;
      transition-timing-function: cubic-bezier(0.91, 0, 0.57, 1);
    }

    &.clicked {
      /*! @noflip */
      > .slide-position {
        transform: translateX(-50%);
        left: 50%;
        opacity: 1;

        transition-timing-function: cubic-bezier(0.43, 0, 0.09, 1);
      }

      /*! @noflip */
      > .image-count {
        transform: translateX(-50%);
        left: 50%;
        opacity: 0;

        transition-timing-function: cubic-bezier(0.43, 0, 0.09, 1);
      }
    }
  }

  ${mediaMin.md`
    .slide-info {
      font-size: 14px;
    }
  `}

  ${mediaMin.lg`
    .play-pause-button {
      padding: 18px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    .slide-info {
      width: calc(100% - 120px);
      height: 60px;
      padding: 18px 0;
    }
    .control-bar-wrapper {
      max-width: 960px;
      margin: auto;
      &.is-hero {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  `}
  ${mediaMin.xl`
    .control-bar-wrapper {
      max-width: 1140px;
    }
  `}
`;
