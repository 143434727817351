import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import styled from 'styled-components';
import { LinkButton } from '../../LinkButton/LinkButton.jsx';
import { RichTextWithKnowledge } from '../../RichText/RichTextWithKnowledge.jsx';
import {
  contentDetailActionsFragment,
  StyledContentDetailActions,
} from '../../ContentDetailActions/ContentDetailActions.jsx';
import {
  publicationDateDeliveryFragment,
  publicationDateModelFragment,
  StyledPublicationDate as PublicationDate,
} from '../../PublicationDate/PublicationDate.jsx';
import { useExerciseManager } from '../../../hooks/useExerciseManager';
import { colors, columnSize, mediaMin } from '../../../utils/css';
import { StyledLessonExerciseHint as LessonExerciseHint } from '../LessonExercise/LessonExerciseHint/LessonExerciseHint.jsx';
import { DetailPageHeadline } from '../../Headline/Headline.jsx';
import { I18nText } from '../../I18n/I18nText.jsx';

const fragmentName = 'LessonContentDescription';
export const lessonContentDescriptionFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Lesson {
        id
        description
        learningTargetHeadline
        learningTargetDescription
        ...${publicationDateDeliveryFragment.name()}
        ...${publicationDateModelFragment.name()}
        ...${contentDetailActionsFragment.name({ type: 'Lesson' })}
      }
      ${publicationDateDeliveryFragment.fragment()}
      ${publicationDateModelFragment.fragment()}
      ${contentDetailActionsFragment.fragment({ type: 'Lesson' })}
    `,
  ),
};

export const LessonContentDescription = ({ className, content, isHintHidden }) => {
  const { description, learningTargetHeadline, learningTargetDescription, knowledges } = content;

  const firstExercisePath = useExerciseManager().firstExerciseLink();

  return (
    <div className={className}>
      <div className="main-content">
        <PublicationDate {...content} />
        <RichTextWithKnowledge content={description} knowledges={knowledges} />
        <I18nText isA={DetailPageHeadline} as="h2" translation={learningTargetHeadline} />
        <StyledContentDetailActions content={content} />
        <RichTextWithKnowledge content={learningTargetDescription} knowledges={knowledges} />
      </div>
      {!isHintHidden && <LessonExerciseHint description="lesson.noexercise" />}
      {firstExercisePath && (
        <div className="start-lecture">
          <LinkButton
            titleKey="lesson.start"
            to={firstExercisePath}
            id="start-lesson"
            data-testid="startLessonButton"
          />
        </div>
      )}
    </div>
  );
};

export const StyledLessonContentDescription = styled(LessonContentDescription)`
  > .main-content {
    background-color: ${colors.LG_WHITE};
    padding: 10% ${columnSize.c2};

    ${mediaMin.lg`
      padding: 10% ${columnSize.c4};
    `}
  }

  > .start-lecture {
    background-color: ${colors.BLUE_GREY_01};
    padding: 70px ${columnSize.c2} 50px;
    text-align: center;
  }
`;
