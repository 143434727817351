import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { I18nText } from '../I18n/I18nText.jsx';
import { StyledInput as Input } from '../FormElements/Input/StyledInput.jsx';
import { StyledStandardButton as StandardButton } from '../StandardButton/StandardButton.jsx';
import { changePassword } from '../../services/passwordService';
import { feedbackHelper } from '../../utils/url/feedbackHelpers';
import { NewPasswordInputs } from '../Input/NewPassword/NewPasswordInputs.jsx';
import { userDataSelector } from '../../state/user/userSelectors';
import { mediaMin } from '../../utils/css';
import { useLanguage } from '../../context/LanguageContext';
import { DetailPageHeadline } from '../Headline/Headline.jsx';
import { DetailIntroText } from '../Text/Text.jsx';

export const PasswordChange = ({ className }) => {
  const { langCode } = useLanguage();
  const history = useHistory();

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const { handleSubmit } = formMethods;

  const { email } = useSelector(userDataSelector);

  const onSubmit = async data => {
    const { oldPassword, password } = data;

    try {
      const changePasswordResponse = await changePassword(oldPassword, password);

      if (!changePasswordResponse.ok) {
        const jsonResponse = await changePasswordResponse.json();
        history.push(feedbackHelper.getForPasswordChangeErrorCode(jsonResponse, langCode));
      } else {
        history.push(feedbackHelper.forPasswordChanged(langCode));
      }
    } catch (err) {
      history.push(feedbackHelper.forGeneralError(langCode));
    }
  };

  return (
    <div className={className}>
      <I18nText isA={DetailPageHeadline} translation="profile.edit.password.change.title" />
      <I18nText isA={DetailIntroText} translation="profile.edit.password.change.subtitle" />

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            labelKey="profile.edit.password.change.oldPassword"
            name="oldPassword"
            type="password"
            registerOptions={{ required: true }}
          />
          <NewPasswordInputs
            passwordTitleKey="profile.edit.password.change.newPassword"
            passwordRepeatTitleKey="profile.edit.password.change.newPasswordRepeat"
            email={email}
          />
          <StandardButton
            titleKey="profile.edit.password.change.save"
            data-testid="confirmChangePasswordButton"
          />
        </form>
      </FormProvider>
    </div>
  );
};

export const StyledPasswordChange = styled(PasswordChange)`
  padding-right: 20px;
  padding-left: 20px;

  ${mediaMin.lg`
    margin-left: 25%;
    margin-right: 25%;
  `}

  > form {
    margin-top: 60px;
    text-align: left;

    > ${StandardButton} {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
    }
  }
`;
