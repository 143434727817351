import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ContentSection } from '../ContentContainer/ContentSection/ContentSection.jsx';
import { I18nText } from '../I18n/I18nText.jsx';
import { LessonMenu } from '../Lesson/LessonMenu/LessonMenu.jsx';
import { StyledSummary as Summary } from '../Lesson/LessonExercise/LessonExerciseResult/Summary.jsx';
import { resultInPercent } from '../../state/progress/learnProgressSelectors';
import { StyledDwLink as DwLink } from '../DwLink/DwLink.jsx';
import {
  calculateCourseNote,
  learningLevelMapper,
  nextRecommendedLevel,
  useLevelUrl,
} from '../../utils/courseUtils';
import { readLessonProgress } from '../../state/progress/learnProgressDuck';
import { filterLessonExerciseOverviewItems, hasLessonExtras } from '../../utils/lessonUtils';
import { StructuralLessonContextProvider } from '../../context/StructuralLessonDataContext.jsx';
import { StyledNextCourseRecommendation as NextCourseRecommendation } from './NextCourseRecommendation.jsx';
import { TestResultStyles } from './TestResultStyles';
import { DetailIntroText } from '../Text/Text.jsx';

export const PlacementTestResult = ({ className, lesson, course }) => {
  const { dwLinks, overviewParts } = lesson;

  const lessonPath = lesson.namedUrl.split('?')[0];
  const lessonExerciseOverviewItems = filterLessonExerciseOverviewItems(overviewParts);

  const structuralData = {
    ...course,
    lessonId: lesson.id,
    lessonName: lesson.name,
    lessonExerciseOverviewItems,
    lessonPath,
    isFinalTest: false,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(readLessonProgress(lesson.id));
  }, [dispatch, lesson.id]);

  const level = learningLevelMapper[lesson.dkLearningLevel] || 'A1';
  const percentile = useSelector(resultInPercent(lesson.id));

  const nextLevel = nextRecommendedLevel(level, percentile);
  const nextCourseUrl = useLevelUrl(nextLevel);

  return (
    <StructuralLessonContextProvider structuralData={structuralData}>
      <LessonMenu
        lessonId={lesson.id}
        lessonName={lesson.name}
        lessonOverviewParts={overviewParts}
        hasExtras={hasLessonExtras(lesson)}
      />
      <div className={className}>
        <Summary lessonId={lesson.id} showTitle={false} big />
        <I18nText
          isA={DetailIntroText}
          className="result-text"
          translation={`placementTest.${level}.${calculateCourseNote(percentile)}`}
        />
        {nextCourseUrl && <NextCourseRecommendation {...{ nextLevel, nextCourseUrl }} />}
        {dwLinks && dwLinks.length > 0 && (
          <ContentSection title="placementTest.moreStuff">
            {dwLinks.map(link =>
              link.name.includes(nextLevel) ? <DwLink key={link.id} {...link} /> : '',
            )}
          </ContentSection>
        )}
      </div>
    </StructuralLessonContextProvider>
  );
};

export const StyledPlacementTestResult = styled(PlacementTestResult)`
  ${TestResultStyles}
`;
