import { useMemo, useState } from 'react';
import { useTimeout } from './useTimeout';

export const TRANSITION_STATUS = Object.freeze({
  INITIAL: 'initial',
  BUSY: 'busy',
  READY: 'ready',
});

export const useTransition = ({ delay }) => {
  const readyState = useMemo(() => ({ status: TRANSITION_STATUS.READY }), []);
  const [transition, setTransition] = useState({ status: TRANSITION_STATUS.INITIAL });

  useTimeout(
    {
      callback: () => {
        transition.onTransitionEnd?.();
        setTransition(readyState);
      },
      delay,
    },
    [transition],
  );

  const startTransition = afterTransitionFunc => {
    setTransition({ onTransitionEnd: afterTransitionFunc, status: TRANSITION_STATUS.BUSY });
  };

  return { transitionStatus: transition.status, startTransition };
};
