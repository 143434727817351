import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { toLang } from '../../utils/mappers/langMapper';

export const knowledgeType = { field: 'knowledgeType', operator: 'match', value: 'VOCABULARY' };

export const getCondition = (searchQuery, field) => {
  if (!searchQuery) {
    return [knowledgeType];
  }
  return [knowledgeType, { field, operator: 'match', value: searchQuery }];
};

export const getQueryVariables = ({
  langCode,
  offset = 0,
  amount = 12,
  searchQuery,
  sort = true,
}) => ({
  lang: toLang(langCode),
  offset,
  amount,
  /*
   * NOTE: searchQuery can not be passed directly, because it may be empty.
   *       An empty search condition implicitly looks for exact matches.
   *       The entire condition object has to be omited instead.
   */
  originalCondition: getCondition(searchQuery, 'search_name'),
  translationCondition: getCondition(searchQuery, 'search_text'),
  sort: !searchQuery && sort ? [{ field: 'sort_name', order: 'ASC' }] : [],
});

const vocabularyFragmentName = 'vocabularyFragment';
export const vocabularyFragment = {
  name: vocabularyFragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${vocabularyFragmentName} on Knowledge {
        id
        text
        name
        subTitle
        audios {
          mp3Src
          mainContentImage {
            id
            staticUrl
          }
        }
      }
    `,
  ),
};

const resultFragmentName = 'searchResult';
export const searchResultFragment = {
  name: resultFragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${resultFragmentName} on SearchResult {
        totalHits
        amount
        offset
        hits {
          ...${vocabularyFragment.name}
        }
      }
      ${vocabularyFragment.fragment()}
    `,
  ),
};

const searchQueryPart = name => `${name}: findContents(
      lang: $lang
      types: [KNOWLEDGE]
      offset: $offset
      amount: $amount
      must: $${name}Condition
      sort: $sort
    ) {
      ...${searchResultFragment.name}
    }
  `;

const queryName = 'VocabularySearch';
export const vocabularySearchQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}(
        $lang: Language!, $offset: Int!
        $amount: Int!
        $originalCondition: [QueryConditionInput!]
        $translationCondition: [QueryConditionInput!] 
        $sort: [QuerySortInput!]
      ) {
        ${searchQueryPart('original')}
        ${searchQueryPart('translation')}
      }
      ${searchResultFragment.fragment()}
    `,
  ),
};
