import styled from 'styled-components';
import { useLanguage } from '../../context/LanguageContext';
import { useTranslation } from '../../hooks/useTranslation';
import { DateTimeInfo } from '../Info/Info.jsx';

export const VocabularyTrainerStatus = ({ number, total, className }) => {
  const { isRtl } = useLanguage();
  const correctText = useTranslation('vocabularyTrainer.correct');
  const outOfText = useTranslation('vocabularyTrainer.outOf');

  const rtlText = `${correctText} ${number} ${outOfText} ${total}`;
  const ltrText = `${number} ${outOfText} ${total} ${correctText}`;

  return (
    <DateTimeInfo as="p" className={className}>
      {isRtl ? rtlText : ltrText}
    </DateTimeInfo>
  );
};

export const StyledVocabularyTrainerStatus = styled(VocabularyTrainerStatus)`
  text-align: center;
`;
