import { replaceWithComponent } from './replaceCommons';
import { StyledWidgetIFrame as WidgetIFrame } from '../../components/WidgetIFrame/WidgetIFrame.jsx';

export const widgetComponentFn = matchedElement => {
  const { id, langCode } = matchedElement.dataset;

  if (!id || !langCode) {
    console.error('id or lang-code missing in widget placeholder');
    return null;
  }

  return <WidgetIFrame widgetId={id} langCode={langCode} />;
};

export const replaceWidgets = ({ node }) => {
  return replaceWithComponent({
    selector: '.dw-widget',
    node,
    elementType: 'div',
    componentFn: widgetComponentFn,
  }).portals;
};
