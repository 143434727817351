import { useState, useCallback } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useOnEsc } from '../../../hooks/useOnEsc';
import { StyledKnowledgeModalHeader as KnowledgeModalHeader } from './KnowledgeModalHeader.jsx';
import { StyledKnowledgeModalBody as KnowledgeModalBody } from './KnowledgeModalBody.jsx';
import { colors, mediaMin } from '../../../utils/css';
import { useTimeout } from '../../../hooks/useTimeout';

const MODAL_STATES = Object.freeze({
  INITIAL: 'INITIAL',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
});

export const KnowledgeModal = ({
  className,
  knowledgeType,
  image,
  audioFile,
  title,
  shortTitle,
  name,
  text,
  subTitle,
  onClose,
}) => {
  const [modalState, setModalState] = useState(MODAL_STATES.INITIAL);
  useTimeout({ callback: () => setModalState(MODAL_STATES.OPEN), delay: 10 });
  useTimeout({ callback: onClose, delay: 300, conditional: modalState === MODAL_STATES.CLOSED });
  const initialSound = knowledgeType === 'INITIAL_SOUND';
  const isVisible = modalState === MODAL_STATES.OPEN;

  const startClosing = useCallback(() => {
    if (modalState !== MODAL_STATES.CLOSED) {
      setModalState(MODAL_STATES.CLOSED);
    }
  }, [modalState]);

  const closeModal = useCallback(
    e => {
      e.preventDefault();
      startClosing();
    },
    [startClosing],
  );

  useOnEsc(startClosing);

  return (
    <div className={classnames(className, { in: isVisible })}>
      <div className="modal-container">
        <div className="modal-content" role="document">
          <KnowledgeModalHeader
            {...{
              closeModal,
              audioFile,
              title: initialSound ? shortTitle : title,
              initialSound,
              subTitle,
            }}
          />
          <KnowledgeModalBody {...{ audioFile, name, text, image, initialSound }} />
        </div>
      </div>
      <div
        className="modal-background"
        role="button"
        tabIndex="0"
        aria-label="Close"
        onClick={closeModal}
        onKeyDown={closeModal}
      />
    </div>
  );
};

export const StyledKnowledgeModal = styled(KnowledgeModal)`
  position: fixed;
  z-index: 1050;
  .modal-container {
    display: block;
    position: fixed;
    z-index: 1050;
    outline: 0;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.15s linear;

    div {
      display: block;
    }
  }
  .modal-background {
    cursor: pointer;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 1040;
    background-color: ${colors.DARK_BLUE_GREY_02};
    opacity: 0;
    transition: opacity 0.15s linear;
  }
  .modal-content {
    background-color: ${colors.LG_WHITE};
    background-clip: padding-box;

    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);

    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    min-width: 280px;

    pointer-events: all;

    transform: translate(0, -50%);
    transition: transform 0.3s ease-out;
  }
  .modal-content:after {
    display: block;
    position: absolute;
    bottom: -19px;
    left: 30%;
    z-index: 10;
    content: '';
    background-color: transparent;
    border-top: 20px solid ${colors.LG_WHITE};
    border-right: 20px solid transparent;
  }
  &.in {
    .modal-container {
      opacity: 1;
    }
    .modal-background {
      opacity: 0.5;
    }
    .modal-content {
      transform: translate(-50%, -50%);
    }
  }
  ${mediaMin.md`
    &.in {
      .modal-content {
        box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
      }
    }
    .modal-content {
      width: 400px;
    }
  `}
`;
