import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { toLang } from '../utils/mappers/langMapper';
import { PageContainer } from '../components/Page/PageContainer.jsx';
import { StyledPlacementTest as PlacementTest } from '../components/PlacementTest/PlacementTest.jsx';
import { WithGraphQLQueryHandling } from '../components/WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { metaDataFragment, PageMetaData } from '../components/Page/PageMetaData.jsx';
import { PAGE_TYPES } from '../constants/pageTypes';
import { gtmDataLayerFragment, GtmScriptWithDataLayer } from '../components/GoogleTagManager';
import { useLanguage } from '../context/LanguageContext';
import { heroImageFragment } from '../components/HeroImage/HeroImage.jsx';

const queryName = 'PlacementTestPage';
export const placementTestPageQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}($lang: Language!) {
        placementTestCourse(lang: $lang) {
          id
          ...${heroImageFragment.name}
          lessons {
            subTitle: teaser
            id
            name
            dkLearningLevel
            namedUrl
            isRtl
          }
          ...${metaDataFragment.name({ type: 'Course' })}
          ${gtmDataLayerFragment.partial()}
        }
      }
      ${heroImageFragment.fragment()}
      ${metaDataFragment.fragment({ type: 'Course' })}
    `,
  ),
};

export const PlacementTestPage = () => {
  const { langCode } = useLanguage();

  const placementTestPageQueryVariables = {
    lang: toLang(langCode),
  };

  return (
    <PageContainer>
      <WithGraphQLQueryHandling
        query={placementTestPageQuery.query()}
        queryVariables={placementTestPageQueryVariables}
      >
        {({ data: { placementTestCourse } }) => (
          <>
            <PageMetaData
              pageType={PAGE_TYPES.PLACEMENT_DASHBOARD}
              content={placementTestCourse}
              useOnlyCmsKeywords={true}
            />
            <GtmScriptWithDataLayer content={placementTestCourse} />
            <PlacementTest {...{ placementTestCourse }} />
          </>
        )}
      </WithGraphQLQueryHandling>
    </PageContainer>
  );
};
