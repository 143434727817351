import gql from 'graphql-tag';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { memoizeWith } from 'ramda';
import styled from 'styled-components';
import { DetailPageHeadline } from '../Headline/Headline.jsx';
import { I18nText } from '../I18n/I18nText.jsx';
import { useGlobalsContext } from '../../context/GlobalsContext';

const fragmentName = 'VendorSettings';

export const vendorSettingsFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on PrivacySettings {
        title
      }
    `,
  ),
};

export const VendorSettings = ({ className, privacySettings }) => {
  const { window } = useGlobalsContext();
  useEffect(() => {
    if (window.cmp_cdid) {
      window.location.reload(true);
    }
    import('../ConsentManagement/cmp-style-overwrite.css');
  }, [window]);

  return (
    <div className={className}>
      <I18nText isA={DetailPageHeadline} translation={privacySettings.title} />
      <div id="cmpinlinepreferencesbox"></div>
      <Helmet>
        <link rel="stylesheet" href="https://consent.dw.com/delivery/cmpinline.min.css" />
      </Helmet>
    </div>
  );
};

export const StyledVendorSettings = styled(VendorSettings)`
  #cmpinlinepreferencesbox {
    .cmptxt_btn_back {
      display: none !important;
    }
  }
`;
