import styled from 'styled-components';
import { memoizeWith } from 'ramda';
import gql from 'graphql-tag';
import { StyledLessonExerciseTextInput as LessonExerciseTextInput } from '../LessonExerciseTextInput/LessonExerciseTextInput.jsx';
import { StyledMultiSelectionExercise as MultiSelectionExercise } from '../MultiSelectionExercise/MultiSelectionExercise.jsx';
import { StyledRepeatExercise as RepeatExercise } from '../RepeatExercise/RepeatExercise.jsx';
import { StyledDictationExercise as DictationExercise } from '../DictationExercise/DictationExercise.jsx';
import { StyledSingleSelectionExercise as SingleSelectionExercise } from '../SingleSelectionExercise/SingleSelectionExercise.jsx';
import { StyledManyToManyExercise as ManyToManyExercise } from '../ManyToManyExercise/ManyToManyExercise.jsx';
import {
  clozeExerciseFragment,
  StyledClozeExercise as ClozeExercise,
} from '../ClozeExercise/ClozeExercise.jsx';
import {
  sortingExerciseFragment,
  StyledSortingExercise as SortingExercise,
} from '../SortingExercise/SortingExercise.jsx';
import { mediaMin } from '../../../../utils/css';

export const currentlyStyledExercises = ['DICTATION', 'REPEAT'];

const fragmentName = 'ExerciseArea';
export const exerciseAreaFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Inquiry {
        ...${sortingExerciseFragment.name}
        ...${clozeExerciseFragment.name}
      }
      ${sortingExerciseFragment.fragment()}
      ${clozeExerciseFragment.fragment()}      
    `,
  ),
};

export const getExerciseComponentBySelectionType = selectionType => {
  switch (selectionType) {
    case 'SINGLE':
      return SingleSelectionExercise;
    case 'DIRECT':
      return ManyToManyExercise;
    case 'MULTIPLE':
      return MultiSelectionExercise;
    default:
      return null;
  }
};

export const getExerciseComponentByType = inquiries => {
  const { inquiryType, selectionType } = inquiries[0];
  switch (inquiryType) {
    case 'ASSOCIATION':
      return getExerciseComponentBySelectionType(selectionType);
    case 'DICTATION':
      return DictationExercise;
    case 'REPEAT':
      return RepeatExercise;
    case 'CLOZE':
      return ClozeExercise;
    case 'SORTING':
      return SortingExercise;
    default:
      return 'OTHER EXERCISE TYPE PLACEHOLDER';
  }
};

const wrapInAreaStyle = inquiryType => {
  if (currentlyStyledExercises.includes(inquiryType)) {
    return StyledExerciseArea;
  }

  return 'section';
};

export const ExerciseArea = ({ className, data }) => {
  const { id, inputText, inquiries, knowledges } = data.content;
  const { inquiryType } = inquiries[0];
  const ContentComponent = getExerciseComponentByType(inquiries);
  const Tag = wrapInAreaStyle(inquiryType);

  return (
    <Tag className={className}>
      <LessonExerciseTextInput inputText={inputText} knowledges={knowledges} />
      {inquiries.map(inquiry => (
        <section key={inquiry.id}>
          <ContentComponent exerciseId={id} {...{ inquiry, knowledges }} />
        </section>
      ))}
    </Tag>
  );
};

export const StyledExerciseArea = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 51px;
  row-gap: 51px;

  ${mediaMin.md`
    row-gap: 0;
    margin-bottom: 50px;
  `}
`;
