import styled, { css } from 'styled-components';

import { mediaMin } from '../../utils/css';
import { StyledAudio } from '../../assets/icons/mediaTag/components/Audio.jsx';
import { StyledVideo } from '../../assets/icons/mediaTag/components/Video.jsx';
import { StyledGallery } from '../../assets/icons/mediaTag/components/Gallery.jsx';
import { StyledInfographics } from '../../assets/icons/mediaTag/components/Infographics.jsx';
import { StyledInteractive } from '../../assets/icons/mediaTag/components/Interactive.jsx';

const typeToTag = Object.freeze({
  Audio: StyledAudio,
  Video: StyledVideo,
  Channel: StyledVideo,
  Livestream: StyledVideo,
  ImageGallery: StyledGallery,
  Infographics: StyledInfographics,
  Interactive: StyledInteractive,
});

// @used in ROAD
export const MediaTag = ({ contentType, ...restProps }) => {
  const Tag = typeToTag[contentType];

  if (!Tag) {
    return null;
  }
  return <Tag {...restProps} />;
};

export const mediaTagStyles = css`
  width: 0.9em;
  height: 0.9em;
  margin-bottom: 0.18em;
  margin-right: 7px;

  path {
    fill: currentColor;
  }

  ${mediaMin.lg`
    margin-right: 9px;
  `}

  ${mediaMin.xl`
    margin-right: 10px;
  `}
`;

export const StyledMediaTag = styled(MediaTag)`
  ${mediaTagStyles}
`;
