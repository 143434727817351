import styled from 'styled-components';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { replaceInPattern } from '../../utils/i18n';
import { socialMediaToIcon } from '../../utils/socialBookmarks';
import { mediaDevice } from '../../utils/css';
import { useTranslation } from '../../hooks/useTranslation';
import { PageLinkInteraction } from '../Interaction/Interaction.jsx';

const fragmentName = 'SocialMediaIconLink';
export const socialMediaIconLinkFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on SocialMediaEntry {
        id
        name
        url
      }
    `,
  ),
};

// @used in ROAD
export const SocialMediaIconLink = ({
  socialMediaEntry = {},
  contentUrl,
  className,
  isHidden,
  ...restProps
}) => {
  const externalLinkPrefix = useTranslation('common.external_link');
  const socialMediaSharingLinkTitle = useTranslation({
    key: 'common.socialMedia.sharing.share_with_service',
    parameters: {
      service: socialMediaEntry.name,
    },
  });

  const title = `${externalLinkPrefix} — ${socialMediaSharingLinkTitle}`;
  const socialMediaIconDef = socialMediaToIcon[socialMediaEntry.id];

  if (!socialMediaIconDef) {
    return null;
  }

  const SocialMediaIconTag = socialMediaIconDef.Tag;
  const urlWithReplacedVars = replaceInPattern(socialMediaEntry.url, {
    contentUrl: encodeURIComponent(contentUrl),
  });
  const propsBeingValidHtmlForNotLazy = isHidden ? { isHidden } : {};

  return (
    <PageLinkInteraction
      as="a"
      className={`${className} ${socialMediaEntry.id}`}
      href={urlWithReplacedVars}
      title={title}
      target="_blank"
      rel="noopener noreferrer nofollow"
      {...restProps}
    >
      <SocialMediaIconTag {...propsBeingValidHtmlForNotLazy} />
    </PageLinkInteraction>
  );
};

export const socialMediaHoverColors = Object.entries(socialMediaToIcon)
  .map(
    ([key, { color }]) =>
      `&.${key}:hover svg, &.${key}:focus svg {
        fill: ${color};
      }`,
  )
  .join('\n');

export const StyledSocialMediaIconLink = styled(SocialMediaIconLink)`
  > svg {
    fill: currentcolor;
  }

  ${mediaDevice.mouse`
    ${socialMediaHoverColors}
  `}
`;
