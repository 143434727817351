import { useStaticInfoForGtm } from './useStaticInfoForGtm';
import { generateContentGtmInfo } from '../TrackingUtils';
import { useAddEmbeddingsToGtm } from './useAddEmbeddingsToGtm';

export const useMediaTracking = content => {
  const { level1Id, macaParam, pageUrl } = useStaticInfoForGtm();
  const contentDictionary = {
    [content.id]: generateContentGtmInfo({
      content,
      level1Id,
      macaParam,
      pageUrl,
      learningRelatedData: {},
      userRelatedData: {},
    }),
  };

  useAddEmbeddingsToGtm({ dictionary: contentDictionary });
};
