import styled from 'styled-components';
import classNames from 'classnames';
import { StyledExerciseButton } from './ExerciseButton.jsx';
import { colors, fontSize } from '../../utils/css';

/**
 * Points to the right.
 * The pointer's actual width is 20, but 6 units padding (to the left) are needed,
 * to display border and (formerly) shadow.
 */
export const PointerSvg = styled(props => (
  <svg width="26" height="22" viewBox="0 0 26 22" {...props}>
    <defs>
      <path id="outline" d="M6,0 V1 L26,21 H6 V22" />
      <path id="background" d="M0,0 H6 V1 L26,21 H6 V22 H0Z" />
      {/* We only want an inner border, so we clip away the outer part */}
      <clipPath id="outlineClip">
        <use href="#background" />
      </clipPath>
    </defs>

    <use className="pointerBackground" href="#background" clipPath="url(#outlineClip)" />
    <use className="border" href="#outline" fill="transparent" clipPath="url(#outlineClip)" />
  </svg>
))``;

const UnstyledBubbleButton = ({ className, question, children, ...restProps }) => {
  return (
    <StyledExerciseButton className={classNames(className, { question })} {...restProps}>
      {children}
      <PointerSvg />
    </StyledExerciseButton>
  );
};

export const BubbleButton = styled(UnstyledBubbleButton)`
  position: relative;

  :not(.question):not(.btnSelected):not(.btnCorrect):not(.btnIncorrect) {
    :not(:hover):not(:active),
    :disabled {
      > ${PointerSvg} {
        opacity: 0;
      }
    }
  }

  > ${PointerSvg} {
    display: block;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);

    /* @noflip */
    right: calc(-1 * (20px + var(--border-width)));

    > .pointerBackground {
      fill: var(--bg-color);
    }

    > .border {
      /* Double border width, because we clip the outer half in the svg */
      stroke-width: calc(2 * var(--border-width));
      stroke: var(--border-color);
    }
  }

  &.question {
    text-align: start;
    ${fontSize.plus1}
    line-height: 1.4;

    :not(.btnSelected) {
      --border-color: ${colors.DW_YELLOW};
      --bg-color: ${colors.LG_WHITE};
    }

    :hover:not(:disabled),
    :active:not(:disabled) {
      --border-color: ${colors.DW_DARK_BLUE_NEW};
    }
    :active:not(:disabled) {
      --bg-color: ${colors.DW_DARK_BLUE_NEW};
    }

    /* Move pointer to the other side */
    /* @noflip */
    > ${PointerSvg} {
      transform: translateY(-50%) scaleX(-1);
      right: unset;
      left: calc(-1 * (20px + var(--border-width)));
    }
  }
`;
