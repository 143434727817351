import styled from 'styled-components';
import { memoizeWith } from 'ramda';
import gql from 'graphql-tag';
import { colors, columnSize, mediaMin } from '../../../utils/css';
import { ContentBlockHeadline } from '../../Headline/Headline.jsx';

const fragmentName = 'subHeaderFragment';
export const subHeaderFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on CompositionComponentConfiguration {
        freeTitle
      }
    `,
  ),
};

export const SubHeaderBlock = ({ className, configuration: { freeTitle } }) => {
  return (
    <div className={className}>
      <ContentBlockHeadline>{freeTitle}</ContentBlockHeadline>
    </div>
  );
};

export const StyledSubHeaderBlock = styled(SubHeaderBlock)`
  background-color: ${colors.BLUE_GREY_01};
  min-height: 106px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: max-content;

  ${mediaMin.xl`
    min-height: 94px;
  `}

  ${ContentBlockHeadline} {
    margin-top: 19px;
    margin-bottom: 19px;
    padding-left: ${columnSize.c2};
    padding-right: ${columnSize.c2};
  }
`;
