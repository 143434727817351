import { useCallback, useState } from 'react';
import { INQUIRY_STATE } from './inquiryState';
import { useExerciseProgress } from './ExerciseProgress.jsx';
import { useStructuralLessonData } from '../../../../context/StructuralLessonDataContext.jsx';

export const useInquiryHandling = ({ inquiryId, achievableScore, subInquiries = 1 }) => {
  const [inquiryState, setInquiryState] = useState(INQUIRY_STATE.INITIAL);

  const { addDoneInquiry } = useExerciseProgress();
  const {
    structuralData: { isFromPlacementCourse, isFinalTest },
  } = useStructuralLessonData();
  const isIncorrigible = isFromPlacementCourse || isFinalTest;

  const isDisabled =
    inquiryState === INQUIRY_STATE.CORRECT ||
    inquiryState === INQUIRY_STATE.SOLVED ||
    (inquiryState === INQUIRY_STATE.WRONG && isIncorrigible);

  const checkAndMarkAsDone = useCallback(
    (score = 0) => {
      if (inquiryState === INQUIRY_STATE.INITIAL) {
        addDoneInquiry({ inquiryId, achievableScore, score });
      }
    },
    [achievableScore, addDoneInquiry, inquiryId, inquiryState],
  );

  const handleCorrectAnswer = useCallback(() => {
    setInquiryState(INQUIRY_STATE.CORRECT);
    checkAndMarkAsDone(achievableScore);
  }, [achievableScore, checkAndMarkAsDone]);

  const handleWrongAnswer = useCallback(
    (correctSubInquiries = 0) => {
      setInquiryState(INQUIRY_STATE.WRONG);
      checkAndMarkAsDone((achievableScore * correctSubInquiries) / subInquiries);
    },
    [achievableScore, checkAndMarkAsDone, subInquiries],
  );

  const handleSolved = useCallback(() => {
    setInquiryState(INQUIRY_STATE.SOLVED);
    checkAndMarkAsDone();
  }, [checkAndMarkAsDone]);

  return {
    isSolveButtonDisabled:
      inquiryState === INQUIRY_STATE.CORRECT || inquiryState === INQUIRY_STATE.SOLVED,
    isCheckButtonDisabled: isDisabled,
    inquiryState,
    isIncorrigible,
    isDisabled,
    handleCorrectAnswer,
    handleWrongAnswer,
    handleSolved,
  };
};
