import { LESSON_PARTS } from '../../constants/lessonParts';

const LESSON_PART_TRANSLATION_CODES = Object.freeze({
  [LESSON_PARTS.PHONEMIC_CHART]: 'lesson.menu.alphabeticalOrder',
  [LESSON_PARTS.EXERCISE]: '',
  [LESSON_PARTS.GRAMMAR]: 'lesson.menu.grammar',
  [LESSON_PARTS.VOCABULARY_GLOSSARY]: 'lesson.menu.vocabulary',
  [LESSON_PARTS.REGIONAL_STUDIES]: 'lesson.menu.regionalStudies',
  [LESSON_PARTS.MANUSCRIPT]: '',
  [LESSON_PARTS.DOWNLOAD]: '',
});

export const getTranslationCodeForLessonPart = lessonPart =>
  LESSON_PART_TRANSLATION_CODES[lessonPart];
