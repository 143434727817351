import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StyledVocabularyQuestion as VocabularyQuestion } from './VocabularyQuestion.jsx';
import { mediaMin } from '../../utils/css';
import { StyledVocabularyAnswer as VocabularyAnswer } from './VocabularyAnswer.jsx';
import { StyledStandardButton } from '../StandardButton/StandardButton.jsx';

export const VocabularyTrainerItem = ({
  vocabulary: { text, audios, subTitle, name },
  onSolved,
  isHidden,
  className,
}) => {
  const { mp3Src, mainContentImage } = audios[0] || {};
  const [isSolved, setSolved] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const onSolveClicked = event => {
    event?.preventDefault();
    setSolved(true);
    setDisabled(true);
  };

  useEffect(() => {
    if (!isHidden) {
      setSolved(false);
      setDisabled(false);
    }
  }, [text, isHidden]);

  return (
    <div className={className}>
      <div className="vocabulary">
        <div className="item">
          <VocabularyQuestion mainContentImage={mainContentImage}>{text}</VocabularyQuestion>
        </div>
        <div className="item">
          <VocabularyAnswer
            {...{
              isSolved,
              mp3Src,
              name,
              subTitle,
              onSolveClicked,
              onSolved,
            }}
          />
        </div>
      </div>
      {!isHidden && (
        <div className="solve-button">
          <StyledStandardButton
            onClick={onSolveClicked}
            disabled={isDisabled}
            titleKey="exercise.resolve"
          />
        </div>
      )}
    </div>
  );
};

export const StyledVocabularyTrainerItem = styled(VocabularyTrainerItem)`
  * {
    /* @noflip */
    direction: ltr;
  }

  .vocabulary {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .item {
      width: 90%;
      margin-left: 5%;
      margin-top: 20px;
    }

    ${mediaMin.md`
      flex-direction: row;

      .item {
        width: 40%;
        margin: 10px;
      }
    `}

    ${mediaMin.xl`
      flex-direction: row;

      .item {
        width: 32%;
        margin: 10px;
      }
    `}
  }

  .solve-button {
    margin-top: 35px;
    margin-bottom: 35px;
    display: flex;
    justify-content: center;

    > ${StyledStandardButton} {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
