import { remove } from 'ramda';

export const CHECK_ANSWERS = 'CHECK_ANSWERS';
// expect action.id
export const SELECT_LEFT = 'SELECT_LEFT';
export const SELECT_RIGHT = 'SELECT_RIGHT';
// expect action.index
export const DESELECT = 'DESELECT';

export const defaultState = {
  unselectedLeft: [],
  preselectionLeft: null, // id | null
  unselectedRight: [],
  preselectionRight: null, // id | null
  selection: [], // [leftElement, rightElement][]
  checkedTuples: [], // [leftElement, rightElement][]
};

const makePair = state => {
  const leftElement = state.unselectedLeft.find(l => l.id === state.preselectionLeft);
  const rightElement = state.unselectedRight.find(r => r.id === state.preselectionRight);

  if (!leftElement || !rightElement) {
    return state;
  }

  return {
    ...state,
    unselectedLeft: state.unselectedLeft.filter(l => l !== leftElement),
    preselectionLeft: null,
    unselectedRight: state.unselectedRight.filter(r => r !== rightElement),
    preselectionRight: null,
    selection: [...state.selection, [leftElement, rightElement]],
  };
};

const unselectAt = (index, state) => {
  const selTuple = state.selection[index];

  if (!selTuple) {
    return state;
  }

  const [leftElement, rightElement] = selTuple;

  return {
    ...state,
    unselectedLeft: [...state.unselectedLeft, leftElement],
    unselectedRight: [...state.unselectedRight, rightElement],
    selection: remove(index, 1, state.selection),
    checkedTuples: state.checkedTuples.filter(tuple => tuple !== selTuple),
  };
};

export const reducer = (state = defaultState, action = { type: 'NOOP' }) => {
  switch (action.type) {
    case SELECT_LEFT:
      return state.preselectionLeft === action.id
        ? { ...state, preselectionLeft: null }
        : makePair({ ...state, preselectionLeft: action.id });

    case SELECT_RIGHT:
      return state.preselectionRight === action.id
        ? { ...state, preselectionRight: null }
        : makePair({ ...state, preselectionRight: action.id });

    case DESELECT:
      return unselectAt(action.index, state);

    case CHECK_ANSWERS:
      return { ...state, checkedTuples: state.selection };

    default:
      return state;
  }
};
