import styled from 'styled-components';
import classnames from 'classnames';
import { contentAreaPaddingStyles } from '../../Carousel/Indicator.jsx';
import { StyledIconButton } from '../../StandardButton/IconButton.jsx';
import { StyledToggleableArrow as OpenCloseArrow } from '../../ToggleableArrow/ToggleableArrow.jsx';
import { TeaserHeadline } from '../../Headline/Headline.jsx';
import { TeaserText } from '../../Text/Text.jsx';
import { mediaMin, colors } from '../../../utils/css';
import { useTranslation } from '../../../hooks/useTranslation';
import { bgHeadline } from './TextOverlay.jsx';
import { usePageEmbeddingContext } from '../../../context/PageEmbeddingContext.jsx';
import { I18nText } from '../../I18n/I18nText.jsx';

export const ExpandableTextOverlay = ({
  className,
  title,
  teaser,
  onTextOverlayToggle,
  isTextOverlayOpened,
  isCurrentIndex,
}) => {
  const pageContext = usePageEmbeddingContext();
  const textOverlayExpandedClass = isTextOverlayOpened ? 'opened' : 'closed';
  const buttonTitle = useTranslation(
    `slideshow.text_overlay_button.label.${isTextOverlayOpened ? 'close' : 'open'}`,
  );

  return (
    <section className={classnames(className, textOverlayExpandedClass)}>
      <div className="headline-background-container">
        <div className="headline-container">
          <I18nText
            isA={TeaserHeadline}
            as={pageContext.isInRichText ? undefined : 'h2'}
            translation={title}
          />
          <StyledIconButton
            onClick={onTextOverlayToggle}
            title={buttonTitle}
            tabIndex={!isCurrentIndex ? -1 : 0}
            aria-expanded={isTextOverlayOpened}
            className="arrow"
            data-tracking-name="slide-show-overlay-text-arrow" // used in gtm
          >
            <OpenCloseArrow />
          </StyledIconButton>
        </div>
      </div>
      <div className="description-container">
        <TeaserText className="description">{teaser}</TeaserText>
      </div>
    </section>
  );
};

export const StyledExpandableTextOverlay = styled(ExpandableTextOverlay)`
  ${TeaserText} {
    display: inline-block;
    margin-bottom: 0;
  }

  will-change: transform;
  &.opened {
    .arrow svg {
      transform: rotate(0deg);
      transition: transform 450ms cubic-bezier(0.43, 0, 0.09, 1);
    }
  }

  &.closed {
    .description-container {
      max-height: 0;
    }
    .description {
      margin-top: 12px;
      transition-property: margin-top;
      transition-delay: 450ms;
    }
  }

  .headline-background-container,
  .description-container {
    & > * {
      margin: 0;
      ${contentAreaPaddingStyles}
    }
  }

  .headline-background-container,
  .description-container {
    position: relative;
    &:before {
      content: '';
      width: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      position: absolute;
    }
  }

  .headline-background-container:before {
    ${bgHeadline}
  }

  .description-container:before {
    height: calc(100% + 15px);
    background-color: rgba(0, 0, 0, 0.9);
  }

  .description-container {
    max-height: 32vw;
    transition: max-height 450ms cubic-bezier(0.43, 0, 0.09, 1);
  }

  .headline-background-container {
    .headline-container {
      margin-top: 10px;
      display: flex;
      padding-top: 47px;
      padding-bottom: 10px;
    }
  }

  ${TeaserHeadline} {
    flex-grow: 1;
    max-width: calc(100% - 40px);
    margin-bottom: 0;
    text-transform: none;
  }

  .arrow {
    margin-top: -10px;
    margin-bottom: -10px;
    align-self: flex-start;
    svg {
      padding: 7.5px;
      transform: rotate(180deg);
      transition: transform 450ms cubic-bezier(0.43, 0, 0.09, 1);
      path {
        stroke: ${colors.LG_WHITE};
      }
    }
  }

  .description {
    max-height: 32vw;
    padding-bottom: 5px;
    overflow-y: scroll;

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  ${mediaMin.sm`
    .headline-background-container {
      .headline-container {
        padding-top: 60px;
      }
    }
  `}

  ${mediaMin.md`
    .headline-background-container {
      .headline-container {
        padding-top: 70px;
      }
    }
  `}

  ${mediaMin.lg`
    .headline-background-container {
      .headline-container {
        padding-top: 85px;
      }
    }
  `}

  ${mediaMin.xl`
    .headline-background-container, .description-container {
      & > * {
        margin-left: auto;
        margin-right: auto;
      }
    }
  `}
`;
