import { formatLg, replaceFormatInUrl } from '../../utils/imgUtils';
import { heroPictureConfig } from '../ResponsiveDwPicture/pictureConfigs';

const getStaticUrlWithExtension = ({ staticUrlTemplate, ext }) => {
  const DOT = '.';
  if (staticUrlTemplate.endsWith(ext)) {
    return staticUrlTemplate;
  }
  return [...staticUrlTemplate.split(DOT).slice(0, -1), ext].join(DOT);
};

const maxWidthDefault = 1110;

export const buildSrcSet = ({
  staticUrlTemplate,
  formatGroupName,
  maxWidth = maxWidthDefault,
  ext = 'jpg',
}) => {
  const maxAllowedWidth = Math.min(maxWidth, maxWidthDefault);
  const staticUrlExtTemplate = getStaticUrlWithExtension({ staticUrlTemplate, ext });
  const allowedImgWidthFormats = [
    ...formatLg[formatGroupName].formats.filter(({ width }) => width < maxAllowedWidth),
    formatLg[formatGroupName].formats.find(({ width }) => width >= maxAllowedWidth),
  ].filter(Boolean);

  return allowedImgWidthFormats
    .map(
      imgFormat => `${replaceFormatInUrl(staticUrlExtTemplate, imgFormat.id)} ${imgFormat.width}w`,
    )
    .join(', ');
};

export const getSourcesMeta = ({ staticUrlTemplate, ext }) => {
  const picSrcs = heroPictureConfig.map(({ minWidth, maxWidth, formatGroupName }, index) => {
    const { aspectRatio } = formatLg[formatGroupName];
    const notLast = index !== heroPictureConfig.length - 1;

    return {
      srcSet: buildSrcSet({
        staticUrlTemplate,
        formatGroupName,
        maxWidth,
        ext,
      }),
      media: `(min-width: ${minWidth}px)${notLast ? ` and (max-width: ${maxWidth}px)` : ''}`,
      height: aspectRatio,
    };
  });

  return picSrcs;
};
