import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { useExerciseManager } from '../hooks/useExerciseManager';
import { userDataSelector } from '../state/user/userSelectors';
import { metaDataFragment, PageMetaData } from '../components/Page/PageMetaData.jsx';
import { PAGE_TYPES } from '../constants/pageTypes';
import { WithGraphQLQueryHandling } from '../components/WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { gtmDataLayerFragment, GtmScriptWithDataLayer } from '../components/GoogleTagManager';
import { ExerciseDataContext } from '../context/ExerciseDataContext';
import { scoreFragment } from '../state/progress/learnProgress';
import { mediaInputChooserFragment } from '../components/Lesson/MediaInputChooser/MediaInputChooser.jsx';
import { lessonExerciseItemFragment } from '../components/Lesson/LessonExercise/LessonExerciseItem/LessonExerciseItem.jsx';
import { knowledgesFragment } from '../components/Lesson/LessonKnowledge/LessonKnowledge.jsx';
import { lessonExerciseHeadlineFragment } from '../components/Lesson/LessonExercise/LessonExerciseHeadline/LessonExerciseHeadline.jsx';
import { StyledLessonExerciseNavigation as LessonExerciseNavigation } from '../components/Lesson/LessonExercise/LessonExerciseNavigation/LessonExerciseNavigation.jsx';
import { useStructuralLessonData } from '../context/StructuralLessonDataContext.jsx';

const queryName = ({ handicap }) => `LessonExercise${handicap || ''}`;
export const lessonExerciseQuery = {
  name: queryName,
  query: memoizeWith(
    ({ handicap }) => handicap,
    ({ handicap }) => gql`
      query ${queryName({ handicap })}($exerciseId: Int!, $lessonLang: Language!, $contextId: ID!) {
        content(id: $exerciseId, lang: $lessonLang) {
          ... on Exercise {
            id
            name
            inputType
            inputText
            namedUrl(contextId: $contextId)
            images {
              id
            }
            inquiries {
              ...${lessonExerciseItemFragment.name}
            }
            ...${lessonExerciseHeadlineFragment.name}
            ...${mediaInputChooserFragment.name({ type: 'Exercise' })}
            ...${scoreFragment.name({ type: 'Exercise', handicap })}
            ...${knowledgesFragment.name({ type: 'Exercise' })}
            ...${metaDataFragment.name({ type: 'Exercise' })}
            ${gtmDataLayerFragment.partial()}
          }
        }
      }
      ${lessonExerciseHeadlineFragment.fragment()}
      ${lessonExerciseItemFragment.fragment()}
      ${mediaInputChooserFragment.fragment({ type: 'Exercise' })}
      ${scoreFragment.fragment({ type: 'Exercise', handicap })}
      ${knowledgesFragment.fragment({ type: 'Exercise' })}
      ${metaDataFragment.fragment({ type: 'Exercise' })}
    `,
  ),
};

export const LessonExerciseNavigationPage = ({
  lessonLang,
  lessonName,
  lessonAchievableScore,
  courseId,
  manuscriptContent,
  lessonPath,
}) => {
  const { handicap } = useSelector(userDataSelector) || {};
  const { exerciseId } = useParams();
  const { lessonId } = useStructuralLessonData().structuralData;

  const lessonExerciseNavQueryVariables = {
    exerciseId: +exerciseId,
    lessonLang,
    contextId: lessonId,
  };

  const { currentExerciseCount } = useExerciseManager();

  return (
    <WithGraphQLQueryHandling
      query={lessonExerciseQuery.query({ handicap })}
      queryVariables={lessonExerciseNavQueryVariables}
    >
      {({ data }) => {
        return (
          <ExerciseDataContext.Provider
            value={{ exerciseNumber: currentExerciseCount, exerciseName: data.content.name }}
          >
            <PageMetaData
              pageType={PAGE_TYPES.EXERCISE}
              content={data.content}
              parentContentName={lessonName}
              useOnlyCmsKeywords={true}
              relativeCanonicalUrl={lessonPath} // All exercises point to lesson entry
            />
            <GtmScriptWithDataLayer content={data.content} />
            <LessonExerciseNavigation
              data={data}
              lessonAchievableScore={lessonAchievableScore}
              courseId={courseId}
              manuscriptContent={manuscriptContent}
              lessonPath={lessonPath}
            />
          </ExerciseDataContext.Provider>
        );
      }}
    </WithGraphQLQueryHandling>
  );
};
