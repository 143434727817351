import { StyledLessonExerciseQuestionText as LessonExerciseQuestionText } from './LessonExerciseQuestionText.jsx';
import { StyledResponsiveDwPicture as ResponsiveDwPicture } from '../../../../ResponsiveDwPicture/ResponsiveDwPicture.jsx';

export const LessonExerciseQuestionImage = ({ inquiryText, mainContentImage }) => {
  const { name } = mainContentImage;
  return (
    <>
      <LessonExerciseQuestionText joinBottom inquiryText={inquiryText} />
      <ResponsiveDwPicture alt={name} image={mainContentImage} />
    </>
  );
};
