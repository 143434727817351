const MULTIPLE_WHITESPACES_REGEX = /\s\s+/g;
const MINUS_CHARACTERS_REGEX = /[\u002D\u2010\u2027\u2011\u2013\u2014\u2212]/g;
const SPECIAL_CHARACTERS = ',\\.!\\?:;\\-';
const WHITESPACE_BEFORE_SPECIAL_REGEX = new RegExp(` ([${SPECIAL_CHARACTERS}])`, 'g');
const WHITESPACE_AFTER_SPECIAL_REGEX = new RegExp(`([${SPECIAL_CHARACTERS}]) `, 'g');

export const normalizeText = str => {
  if (!str) {
    return str;
  }
  return str
    .trim()
    .replace(MULTIPLE_WHITESPACES_REGEX, ' ')
    .replace(MINUS_CHARACTERS_REGEX, '-')
    .replace(WHITESPACE_BEFORE_SPECIAL_REGEX, '$1')
    .replace(WHITESPACE_AFTER_SPECIAL_REGEX, '$1');
};

export const isNormalizedTextEqual = (str1, str2) => {
  const normalizedText1 = normalizeText(str1);
  const normalizedText2 = normalizeText(str2);
  return (
    normalizedText1 !== undefined &&
    normalizedText2 !== undefined &&
    normalizedText1 === normalizedText2
  );
};
