import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { WithGraphQLQueryHandling } from '../components/WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import {
  lessonExtrasFragment,
  LessonExtras,
} from '../components/Lesson/LessonExtras/LessonExtras.jsx';
import { metaDataFragment, PageMetaData } from '../components/Page/PageMetaData.jsx';
import { GTM_LESSON_PARTS, PAGE_TYPES } from '../constants/pageTypes';
import { gtmDataLayerFragment, GtmScriptWithDataLayer } from '../components/GoogleTagManager';
import { useStructuralLessonData } from '../context/StructuralLessonDataContext.jsx';
import { LESSON_EXTRAS_SUFFIX } from '../utils/url/urlFactory';

const queryName = 'LessonExtrasPage';
export const LessonExtrasQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}($id: Int!, $lang: Language!) {
        content(id: $id, lang: $lang) {
          ...${lessonExtrasFragment.name}
          ...${metaDataFragment.name({ type: 'Lesson' })}
          ${gtmDataLayerFragment.partial()}
        }
      }
      ${lessonExtrasFragment.fragment()}
      ${metaDataFragment.fragment({ type: 'Lesson' })}
    `,
  ),
};

export const LessonExtrasPage = ({ contentId, language }) => {
  const { lessonPath } = useStructuralLessonData().structuralData;
  const LessonExtraPageQueryVariables = {
    id: +contentId,
    lang: language,
  };

  return (
    <WithGraphQLQueryHandling
      query={LessonExtrasQuery.query()}
      queryVariables={LessonExtraPageQueryVariables}
    >
      {({ data: { content } }) => (
        <>
          <PageMetaData
            pageType={PAGE_TYPES.EXTRAS}
            content={content}
            translationFirst={true}
            useDescriptionTranslation={true}
            relativeCanonicalUrl={`${lessonPath}/${LESSON_EXTRAS_SUFFIX}`}
          />
          <GtmScriptWithDataLayer
            content={content}
            lessonItem={GTM_LESSON_PARTS[PAGE_TYPES.EXTRAS]}
          />
          <LessonExtras content={content} />
        </>
      )}
    </WithGraphQLQueryHandling>
  );
};
