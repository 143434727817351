import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { findLessonLearnProgressSelector } from '../../state/progress/learnProgressSelectors';
import { learningLevelMapper } from '../../utils/courseUtils';
import { Link } from '../Link/Link.jsx';
import { LearnProgressIcon } from '../LearnProgressIcon/LearnProgressIcon.jsx';
import { I18nText } from '../I18n/I18nText.jsx';
import { colors, columnSize, mediaMax } from '../../utils/css';
import { TeaserHeadline } from '../Headline/Headline.jsx';
import { TeaserText } from '../Text/Text.jsx';

export const PlacementTile = ({ className, lesson }) => {
  const { dkLearningLevel, id, name, subTitle, namedUrl, isRtl } = lesson;
  const lessonProgress = useSelector(findLessonLearnProgressSelector(id));
  const level = learningLevelMapper[dkLearningLevel];

  return (
    <Link className={className} to={namedUrl} dir={isRtl ? 'rtl' : 'ltr'}>
      <TeaserHeadline as="h2">{level}</TeaserHeadline>
      <div>
        <LearnProgressIcon learnProgress={lessonProgress} />
      </div>
      <I18nText
        isA={TeaserText}
        as="p"
        className="tile-name"
        translation={name}
        aria-label="course-title"
      />
      <I18nText isA={TeaserText} as="p" className="tile-subTitle" translation={subTitle} />
    </Link>
  );
};

export const StyledPlacementTile = styled(PlacementTile)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, max-content);
  padding: 20px ${columnSize.c1} 30px;

  border-color: ${colors.LG_WHITE};
  border-style: solid;
  border-width: 1px 1px 0 0;
  text-decoration: none;
  background-color: ${colors.BLUE_GREY_01};

  /* @noflip */
  :dir(ltr) {
    text-align: left;
  }

  > * {
    color: ${({ color }) => color};
  }

  :hover,
  :active,
  :focus {
    text-decoration: none;
    > * {
      opacity: 0.5;
    }
  }

  &:nth-child(4n) {
    border-right-width: 0;
  }

  > h2 {
    margin: 10px 0 30px 0;
  }

  .tile-name {
    grid-column: 1 / 3;
  }

  .tile-subTitle {
    grid-column: 1 / 3;
  }

  > div {
    justify-self: end;
    grid-column-start: 2;
  }

  ${mediaMax.lg`
    border-width: 1px 1px 0 0;
    &:nth-child(2n) {
      border-right-width: 0;
    }
  `}

  ${mediaMax.sm`
    border-width: 1px 0 0 0;
  `}
`;
