import styled from 'styled-components';
import { colors, mediaMax } from '../../utils/css';
import { ButtonInteraction } from '../Interaction/Interaction.jsx';

export const Modal = ({ className, fadeIn, children }) => {
  const faded = fadeIn ? 'in' : '';
  const classNameString = `${className} fade ${faded}`;
  return (
    <div className={classNameString}>
      <div className="modal-background" />
      <div className="modal-dialogue">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export const StyledModal = styled(Modal)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10040;

  .modal-content {
    position: relative;
    background-color: ${colors.LG_WHITE};
    background-clip: padding-box;
    border-radius: 0;
    outline: 0;
    box-shadow: 0 3px 9px ${colors.LG_TRANSPARENT_BLACK_50};
    border: 1px solid ${colors.LG_TRANSPARENT_BLACK_20};
  }

  .modal-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${colors.DARK_BLUE_GREY_02};
    opacity: 0.5;
  }

  .modal-dialogue {
    position: relative;
    background-color: ${colors.LG_WHITE};
    width: 900px;
    margin: 30px auto;
  }

  &.fade {
    opacity: 0;
    transition: opacity 0.15s linear;
  }

  &.fade.in {
    opacity: 1;
  }

  &.rtl {
    direction: rtl;
  }

  ${mediaMax.md`
    .modal-dialogue {
      width: 600px;
    }
  `}

  ${mediaMax.sm`
    .modal-dialogue {
      width: auto;
      margin: 10px;
    }
  `}
`;

export const CloseModalButton = styled(ButtonInteraction)`
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  /* @noflip */
  float: right;
  filter: alpha(opacity=20);
  opacity: 0.2;
  margin: 0;
  margin-top: -2px;
`;
