import styled, { css } from 'styled-components';
import { TeaserText } from '../../Text/Text.jsx';
import { mediaMin } from '../../../utils/css';
import { usePageEmbeddingContext } from '../../../context/PageEmbeddingContext.jsx';
import { MediaTitle } from '../../MediaTitle/MediaTitle.jsx';
import { useTranslation } from '../../../hooks/useTranslation';
import { contentAreaPaddingStyles } from '../../Carousel/Indicator.jsx';

export const TextOverlay = ({ className, title, teaser }) => {
  const { isInRichText } = usePageEmbeddingContext();
  const contentTypePrefix = useTranslation(
    `slideshow.title_prefix.${isInRichText ? 'embedded' : 'regular'}`,
  );
  const ariaLabel = `${contentTypePrefix} — ${title}`;

  return (
    <section className={className}>
      <div className="headline-container">
        <MediaTitle
          className="headline"
          as={isInRichText ? 'h2' : 'h1'}
          aria-label={ariaLabel}
          contentType="ImageGallery"
          title={title}
        />
      </div>
      <div className="description-container">
        <TeaserText className="description" as={isInRichText ? 'h3' : 'h2'}>
          {teaser}
        </TeaserText>
      </div>
    </section>
  );
};

export const bgHeadline = css`
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9) 90%);
`;

export const StyledTextOverlay = styled(TextOverlay)`
  ${TeaserText} {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  > div > * {
    ${contentAreaPaddingStyles}
  }

  > div {
    position: relative;
    &:before {
      content: '';
      width: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      position: absolute;
    }
  }

  .headline-container:before {
    ${bgHeadline}
  }

  .description-container:before {
    height: calc(100% + 20px);
    background-color: rgba(0, 0, 0, 0.9);
  }

  .headline {
    margin: 10px 0 0 0;
    padding-top: 47px;
  }

  ${mediaMin.sm`
    .headline {
      padding-top: 60px;
    }
  `}

  ${mediaMin.md`
    .headline {
      padding-top: 70px;
    }
  `}

  ${mediaMin.lg`
    .headline {
      padding-top: 85px;
    }
  `}

  ${mediaMin.xl`
    .headline-container, .description-container {
      & > * {
        margin-left: auto;
        margin-right: auto;
      }
    }
  `}
`;
