import { createPortal } from 'react-dom';
import globals from '../globals';

export const replaceWithComponent = ({
  selector,
  componentFn,
  node,
  param = null,
  elementType = 'span',
}) => {
  /** @type {React.ReactPortal[]} */
  const allPortals = [];

  [...node.querySelectorAll(selector)].forEach((matchedElem, index) => {
    const renderContainer = globals.document.createElement(elementType);
    const placeholderClassNames = matchedElem.className;
    renderContainer.setAttribute('class', `render-container ${placeholderClassNames}`.trim());
    matchedElem.parentNode.replaceChild(renderContainer, matchedElem);
    const componentToRender = param
      ? componentFn(matchedElem, param, index)
      : componentFn(matchedElem, index);
    const portal = createPortal(componentToRender, renderContainer);

    allPortals.push(portal);
  });

  return {
    document: globals.document,
    portals: allPortals,
  };
};
