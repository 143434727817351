import styled, { css } from 'styled-components';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { useId } from 'react';
import { StyledFooterLink as FooterLink, footerLinkFragment } from './FooterLink.jsx';
import { NavigationSectionHeadline } from '../Headline/Headline.jsx';

const fragmentName = 'FooterSection';
export const footerSectionFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`fragment ${fragmentName} on FooterSection {
      headline
      links {
        ...${footerLinkFragment.name}
      }
    }
    ${footerLinkFragment.fragment()}
  `,
  ),
};

// @used in ROAD
export const FooterSectionMainGroup = ({ className, section }) => {
  const sectionId = useId();
  return (
    <ul className={className} role="group" aria-labelledby={sectionId}>
      <NavigationSectionHeadline id={sectionId}>{section.headline}</NavigationSectionHeadline>
      <ul>
        {section.links.map(link => (
          <FooterLink link={link} key={link.text} />
        ))}
      </ul>
    </ul>
  );
};

export const sharedFooterSectionStyles = css`
  margin-bottom: 30px;
  padding: 0;

  > ul {
    list-style: none;
    padding: 0;
  }
`;

export const StyledFooterSectionMainGroup = styled(FooterSectionMainGroup)`
  ${sharedFooterSectionStyles}
`;
