import styled from 'styled-components';
import { columnSize, mediaMax } from '../../utils/css';
import { StyledLogo as Logo } from '../Logo/Logo.jsx';

export const DesktopBanner = ({ className, homeURL }) => {
  return (
    <aside className={className}>
      <Logo url={homeURL} />
    </aside>
  );
};

export const StyledDesktopBanner = styled(DesktopBanner)`
  display: grid;
  height: 110px;
  margin-left: -20px;
  margin-right: -20px;
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: ${columnSize.c1};
  width: 100%;

  ${mediaMax.lg`
    display: none !important;
  `}
`;
