import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { getAudioDownloadUrl } from '../../../utils/url/url';
import { getAudioLinkTitle } from '../../../utils/audioUtils';
import { StyledLinkTeaser as LinkTeaser } from '../../CoCo/Teaser/LinkTeaser.jsx';

const fragmentName = 'audioDownloadTeaser';
export const audioDownloadTeaserFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on ContentLink {
        name
        target {
          ...on Audio {
            audioResourceLinks {
              url
              size
              format
            }
          }
        }
      }
    `,
  ),
};

export const AudioDownloadTeaser = ({ className, link }) => {
  const { name } = link;
  const { url, size, format } = link.target.audioResourceLinks[0];
  const targetLink = getAudioDownloadUrl({ audioUrl: url });
  const title = getAudioLinkTitle({ name, size, format });

  const contents = { name: title, url: targetLink };

  return <LinkTeaser className={className} contents={contents} />;
};
