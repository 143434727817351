import styled from 'styled-components';
import { StyledResponsiveDwPicture as ResponsiveDwPicture } from '../../ResponsiveDwPicture/ResponsiveDwPicture.jsx';
import { StyledRichText } from '../../RichText/RichText.jsx';
import { StyledModalAudio as ModalAudio } from './ModalAudio.jsx';

export const KnowledgeModalBody = ({ className, audioFile, image, initialSound, name, text }) => (
  <div className={className}>
    {image && <ResponsiveDwPicture image={image} alt={image.name} />}
    {initialSound && <ModalAudio {...{ title: name, audioFile }} />}
    <StyledRichText content={text} />
  </div>
);

export const StyledKnowledgeModalBody = styled(KnowledgeModalBody)`
  padding: 0.5rem 1rem;

  ${StyledRichText} {
    margin: 0;
  }
`;
