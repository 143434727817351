import styled from 'styled-components';
import { StyledSummary as Summary } from '../LessonExercise/LessonExerciseResult/Summary.jsx';
import { getDashboardUrl } from '../../../utils/url/urlFactory';
import { I18nText } from '../../I18n/I18nText.jsx';
import { toLangCode } from '../../../utils/mappers/langMapper';
import { useStructuralLessonData } from '../../../context/StructuralLessonDataContext.jsx';
import { isFinalTestPredicate } from '../../../utils/commons';
import { mediaMin } from '../../../utils/css';
import { LinkButton } from '../../LinkButton/LinkButton.jsx';
import { gridSpacingForResults } from '../LessonExercise/utils/exerciseCustomCSS';
import { DetailIntroText } from '../../Text/Text.jsx';

export const LessonSummary = ({ className, data, lessonId, language }) => {
  const { structuralData } = useStructuralLessonData();
  const { contentURL, lessonParentType } = structuralData;
  const { contentLinks = [] } = data.content || {};
  const currentLessonLink = contentLinks.find(link => link.targetId === lessonId);
  const currentLessonIndex = contentLinks.indexOf(currentLessonLink);

  const generateRedirectInfos = () => {
    const nextLessonContentLink = contentLinks[currentLessonIndex + 1] || {};
    const { lesson } = nextLessonContentLink;

    if (!lesson) {
      if (!contentURL) {
        return {
          url: getDashboardUrl(toLangCode(language)),
          redirectMessage: 'lesson.exercises.result.toOverview',
          linkMessage: 'lesson.menu.overview',
        };
      }
      const isCourse = lessonParentType === 'Course';
      return {
        url: contentURL,
        redirectMessage: isCourse
          ? 'lesson.exercises.result.toDashboard'
          : 'lesson.exercises.result.toOverview',
        linkMessage: isCourse ? 'common.next' : 'lesson.menu.overview',
      };
    }

    const { namedUrl: url } = lesson;

    const redirectMessage = isFinalTestPredicate(nextLessonContentLink)
      ? 'lesson.exercises.result.toFinalTest'
      : 'lesson.exercises.result.nextLesson';

    return { url, redirectMessage, linkMessage: 'common.next' };
  };

  const { url, redirectMessage, linkMessage } = generateRedirectInfos();

  return (
    <div className={className}>
      <Summary lessonId={lessonId} />
      <I18nText isA={DetailIntroText} translation={redirectMessage} />
      <LinkButton
        variant="primary"
        data-testid="lessonSummaryNextButton"
        to={url}
        titleKey={linkMessage}
      />
    </div>
  );
};

export const StyledLessonSummary = styled(LessonSummary)`
  padding-bottom: 40px;

  ${gridSpacingForResults}

  > ${DetailIntroText} {
    text-align: center;
    margin: 40px 0;
  }

  > ${LinkButton} {
    margin: 90px 0 50px 0;

    ${mediaMin.md`
      width: 33.33333333%;
      margin-left: 33.33333333%
    `}
  }
`;
