import { resolutions } from '../../utils/css';

/**
 * IMPORTANT: widths can have non integer values,
 * so maxWidth must always be exactly equal to next entry's minWidth
 */

export const landscapeFormatGroupName = '60X';

export const heroPictureConfig = [
  {
    minWidth: resolutions.min.xs,
    maxWidth: resolutions.max.xs,
    formatGroupName: '80X',
  },
  {
    minWidth: resolutions.min.sm,
    maxWidth: resolutions.max.lg,
    formatGroupName: '60X',
  },
  {
    minWidth: resolutions.min.xl,
    maxWidth: resolutions.max.xl,
    formatGroupName: '100X',
  },
];
