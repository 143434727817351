import { useEffect } from 'react';
import { useGlobalsContext } from '../context/GlobalsContext';
import { keyCodes } from '../constants/keyCodes';

/**
 * @param {() => void} callback
 */
export const useOnEsc = callback => {
  const { document } = useGlobalsContext();

  useEffect(() => {
    const escHandler = e => {
      if (e.key === keyCodes.ESCAPE) {
        callback();
      }
    };

    document.addEventListener('keydown', escHandler);

    return () => {
      document.removeEventListener('keydown', escHandler);
    };
  }, [document, callback]);
};
