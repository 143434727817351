import { useCallback, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { update } from 'ramda';
import { cleanupAndAddPlaceholderComponents, placeholderConfig } from '../clozeExerciseUtils';
import { StyledExerciseRichText as ExerciseRichText } from '../ExerciseRichText.jsx';
import { isNormalizedTextEqual } from '../../utils/exerciseUtils';
import { StyledClozeInput as ClozeInput } from '../ClozeInput.jsx';
import { colors } from '../../../../../utils/css';

export const WritingAnswer = ({
  inquiry,
  onCorrectAnswerAmountChanged,
  inquiryState,
  isDisabled,
  knowledges,
  className,
}) => {
  const { subInquiries, clozeLayoutType } = inquiry;

  /** @type {{ id: number, text: string, alternativeId: number }[]} */
  const correctAnswers = useMemo(
    () =>
      subInquiries.map(s => ({
        id: s.id,
        text: s.alternatives[0].alternativeText,
        alternativeId: s.alternatives[0].id,
      })),
    [subInquiries],
  );

  /** @type {React.MutableRefObject<string[]>} */
  const givenAnswersRef = useRef(Array(correctAnswers.length).fill(''));

  const handleOnInputChange = useCallback(
    (index, value) => {
      givenAnswersRef.current = update(index, value, givenAnswersRef.current);

      onCorrectAnswerAmountChanged(
        givenAnswersRef.current.filter((answer, i) =>
          isNormalizedTextEqual(correctAnswers[i].text, answer),
        ).length,
      );
    },
    [correctAnswers, onCorrectAnswerAmountChanged],
  );

  const createPlaceholder = useCallback(
    (index, matchedElement) => {
      const config =
        placeholderConfig.find(entry => entry.size === matchedElement.innerText) ||
        placeholderConfig[0];
      return (
        <ClozeInput
          inquiryState={inquiryState}
          index={index}
          givenAnswersRef={givenAnswersRef}
          correctAnswer={correctAnswers[index]}
          config={config}
          handleOnInputChange={handleOnInputChange}
          isDisabled={isDisabled}
        />
      );
    },
    [correctAnswers, handleOnInputChange, inquiryState, isDisabled],
  );

  const answerContent = useMemo(
    () =>
      cleanupAndAddPlaceholderComponents(
        clozeLayoutType === 'CONTINUOUS_TEXT'
          ? inquiry.text
          : subInquiries.map(s => s.subInquiryText).join('<p></p>'),
      ),
    [clozeLayoutType, inquiry.text, subInquiries],
  );

  return (
    <div className={className}>
      <ExerciseRichText
        content={answerContent}
        createComponent={createPlaceholder}
        knowledges={knowledges}
      />
    </div>
  );
};

export const StyledWritingAnswer = styled(WritingAnswer)`
  /* @noflip */
  text-align: left;
  /* @noflip */
  direction: ltr;

  position: relative;
  background-color: ${colors.BLUE_GREY_01};
  padding: 20px;

  ${ClozeInput} {
    margin: 0.15rem 0.3rem;
  }
`;
