import styled from 'styled-components';
import { mediaMin } from '../../../utils/css';

export const StyledVerticalTextArea = styled.textarea.attrs({
  rows: 8,
})`
  padding: 10px 10px;
  border: 2px solid #d1c9c2;
  resize: vertical;
  height: 8em;
  overflow: auto;
  width: 100%;
  margin-bottom: 50px;

  :disabled {
    cursor: not-allowed;
    opacity: 1;
    background-color: #eee;
  }

  :focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }

  ${mediaMin.md`
    width: 93.33333333%;
    margin-bottom: 0;
  `}
`;
