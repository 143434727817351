import { replaceWithComponent } from './replaceCommons';
import { StyledVideoPlayer as VideoPlayer } from '../../components/MediaPlayer/Video/VideoPlayer.jsx';
import { StyledAudioPlayer as AudioPlayer } from '../../components/MediaPlayer/Audio/AudioPlayer.jsx';

/**
 * @param {object} param
 * @param {Element} param.node
 * @param {Parameters<typeof mediaComponentFn>[0]} [param.videos]
 * @param {Parameters<typeof mediaComponentFn>[1]} [param.audios]
 * @returns {React.ReactPortal[]}
 */
export function replaceMedia({ node, videos = [], audios = [] }) {
  return replaceWithComponent({
    selector: 'video, audio',
    node,
    elementType: 'div',
    componentFn: mediaComponentFn({ videos, audios }),
  }).portals;
}

export function mediaComponentFn({ videos, audios }) {
  return (
    /** @type {HTMLElement} */
    matchedElement,
  ) => {
    const isVideoElement = matchedElement.tagName === 'VIDEO';
    if (isVideoElement) {
      const videoId = matchedElement.dataset.id;
      const video = videos.find(v => v.id === +videoId);

      if (video === undefined) {
        console.error("Can't find video with id", videoId);
        return null;
      }

      return <VideoPlayer video={video} />;
    }

    const isAudioElement = matchedElement.tagName === 'AUDIO';
    if (isAudioElement) {
      const audioId = matchedElement.dataset.id;
      const audio = audios.find(a => a.id === +audioId);

      if (audio === undefined) {
        console.error("Can't find audio with id", audioId);
        return null;
      }

      return <AudioPlayer audio={audio} />;
    }

    return null;
  };
}
