import { Prompt } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';
import { useTranslation } from '../../../../hooks/useTranslation';
import { isPathInExerciseNavigationWhiteList } from '../../../../utils/url/urlUtils';

export const ExerciseLeavingPrompt = () => {
  useBeforeunload(event => {
    event.preventDefault();
  });

  const leaveMessage = useTranslation('client.exercise.interrupt');
  return (
    <Prompt
      message={(location, action) => {
        if (action === 'POP') {
          return leaveMessage;
        }
        return isPathInExerciseNavigationWhiteList(location.pathname) ? true : leaveMessage;
      }}
    />
  );
};
