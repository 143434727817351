import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { StyledLessonExerciseQuestionAudio as LessonExerciseQuestionAudio } from './QuestionTypes/LessonExerciseQuestionAudio.jsx';
import { LessonExerciseQuestionImage } from './QuestionTypes/LessonExerciseQuestionImage.jsx';
import { StyledLessonExerciseQuestionText as LessonExerciseQuestionText } from './QuestionTypes/LessonExerciseQuestionText.jsx';
import { LessonExerciseQuestionVideo } from './QuestionTypes/LessonExerciseQuestionVideo.jsx';
import { findQuestionAudio } from '../../../../utils/audioUtils';
import { QUESTION_TYPES } from './QuestionTypes/questionTypes';
import { mediaInputVideoFragment } from '../../../MediaInput/MediaInputVideo/MediaInputVideo.jsx';
import { responsiveDwPictureFragment } from '../../../ResponsiveDwPicture/ResponsiveDwPicture.jsx';

const fragmentName = 'LessonExerciseQuestion';
export const lessonExerciseQuestionFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Inquiry {
        inquiryText
        mainContentImage {
          id
          name
          staticUrl
        }
        audios: contentLinks(targetTypes: [AUDIO]) {
          id
          mainContent
          target {
            ...on Audio {
              mp3Src
              mainContentImage {
                imageId: id
                imageName: name
                ...${responsiveDwPictureFragment.name}
              }
            }
          }
        }
        ...${mediaInputVideoFragment.name({ type: 'Inquiry' })}
      }
      ${mediaInputVideoFragment.fragment({ type: 'Inquiry' })}
      ${responsiveDwPictureFragment.fragment()}
    `,
  ),
};

export const exerciseQuestionTypeSelector = ({ inquiry }) => {
  const { videos, audios, mainContentImage } = inquiry;
  if (mainContentImage) {
    return QUESTION_TYPES.IMAGE;
  }
  if (videos.length > 0) {
    return QUESTION_TYPES.VIDEO;
  }
  if (findQuestionAudio(audios)) {
    return QUESTION_TYPES.AUDIO;
  }
  return QUESTION_TYPES.TEXT;
};

export const LessonExerciseQuestion = ({ inquiry, useSubInquiryTextAsLabel = false }) => {
  const { inquiryText, videos, audios, mainContentImage, subInquiries } = inquiry;
  const questionType = exerciseQuestionTypeSelector({ inquiry });

  const getLabelText = () => {
    if (inquiryText) return inquiryText;
    if (subInquiries && useSubInquiryTextAsLabel) return subInquiries[0].subInquiryText;
    return '';
  };

  const labelText = getLabelText(inquiryText, subInquiries);
  const questionAudio = findQuestionAudio(audios);

  switch (questionType) {
    case QUESTION_TYPES.IMAGE:
      return <LessonExerciseQuestionImage {...{ inquiryText, mainContentImage }} />;
    case QUESTION_TYPES.VIDEO:
      return <LessonExerciseQuestionVideo {...{ inquiryText, videos }} />;
    case QUESTION_TYPES.AUDIO:
      return <LessonExerciseQuestionAudio audio={questionAudio} inquiryText={labelText} />;
    case QUESTION_TYPES.TEXT:
      return <LessonExerciseQuestionText inquiryText={labelText} />;
    default:
      return null;
  }
};
