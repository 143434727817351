import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  findInquiryLearnProgressSelector,
  findLessonLearnProgressSelector,
} from '../state/progress/learnProgressSelectors';
import { createLinkToResultPage } from '../utils/url/urlFactory';
import { isLessonInImproveModeSelector } from '../state/mode/lessonExerciseModeSelectors';
import { useStructuralLessonData } from '../context/StructuralLessonDataContext.jsx';

export const useExerciseManager = () => {
  const { lessonId, exerciseId } = useParams();
  const lessonProgress = useSelector(findLessonLearnProgressSelector(+lessonId));
  const isLessonInImproveMode = useSelector(isLessonInImproveModeSelector(+lessonId));
  const inquiryProgresses = useSelector(findInquiryLearnProgressSelector(+lessonId, +exerciseId));
  const {
    structuralData: { isFromPlacementCourse, isFinalTest, lessonPath, lessonExerciseOverviewItems },
  } = useStructuralLessonData();

  const getExerciseLink = useCallback(exercise => exercise && exercise.exerciseUrl, []);

  const pointsMissing = useCallback(
    exercise =>
      lessonProgress?.exerciseProgresses.every(
        progress =>
          progress.cosExerciseId !== exercise.exerciseId ||
          progress.maxPoints !== progress.resultPoints,
      ) ?? true,
    [lessonProgress],
  );

  const noProgress = useCallback(
    exercise =>
      lessonProgress?.exerciseProgresses.every(
        ({ cosExerciseId }) => exercise.exerciseId !== cosExerciseId,
      ) ?? true,
    [lessonProgress],
  );

  const lessonExercises = useMemo(
    () =>
      lessonExerciseOverviewItems.map(item => ({
        exerciseId: item.targetId,
        exerciseName: item.target.name,
        exerciseUrl: item.target.namedUrl,
      })),
    [lessonExerciseOverviewItems],
  );

  const getFirstExerciseItem = useCallback(
    isImproveMode => {
      if (!lessonProgress) {
        return lessonExercises[0];
      }

      if (isLessonInImproveMode || isImproveMode) {
        return lessonExercises.find(pointsMissing);
      }

      return lessonExercises.find(noProgress);
    },
    [pointsMissing, noProgress, isLessonInImproveMode, lessonExercises, lessonProgress],
  );

  /** @type {string} */
  const resultPageLink = useMemo(
    () =>
      createLinkToResultPage({
        isFromPlacementCourse,
        isFinalTest,
        lessonPath,
      }),
    [isFromPlacementCourse, isFinalTest, lessonPath],
  );

  const getFirstExercisePath = useCallback(
    isImproveMode => getExerciseLink(getFirstExerciseItem(isImproveMode)) ?? resultPageLink,
    [getFirstExerciseItem, resultPageLink, getExerciseLink],
  );

  const currentIndex = useMemo(
    () => lessonExerciseOverviewItems.findIndex(({ targetId }) => +exerciseId === targetId),
    [exerciseId, lessonExerciseOverviewItems],
  );

  const nextExerciseLink = useMemo(
    () =>
      getExerciseLink(
        isLessonInImproveMode
          ? lessonExercises.find((e, idx) => idx > currentIndex && pointsMissing(e))
          : lessonExercises[currentIndex + 1],
      ),
    [lessonExercises, currentIndex, getExerciseLink, isLessonInImproveMode, pointsMissing],
  );

  const isFullScoreAchieved = useMemo(() => {
    if (!lessonProgress) {
      return false;
    }
    return lessonProgress.maxPointsForAllExercises === lessonProgress.resultPoints;
  }, [lessonProgress]);

  const scoreMissing = useCallback(
    inquiry =>
      inquiryProgresses.every(
        progress => progress.inquiryId !== inquiry.id || progress.score !== progress.maxScore,
      ),
    [inquiryProgresses],
  );

  const filterExercises = useCallback(
    data => {
      if (!isLessonInImproveMode) {
        return data;
      }

      return {
        ...data,
        content: {
          ...data.content,
          inquiries: data.content.inquiries?.filter(scoreMissing) ?? [],
        },
      };
    },
    [scoreMissing, isLessonInImproveMode],
  );

  return {
    firstExerciseLink: getFirstExercisePath,
    nextExerciseLink,
    isFullScoreAchieved,
    currentExerciseCount: currentIndex + 1,
    allExerciseCount: lessonExerciseOverviewItems.length,
    filterExerciseDataIfInImproveMode: filterExercises,
  };
};
