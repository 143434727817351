import { useState } from 'react';
import { StyledKeyboardIcon as KeyboardIcon } from './KeyboardIcon.jsx';
import { StyledSimpleKeyboard } from './SimpleKeyboard.jsx';

export const KeyboardWrapper = props => {
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);

  const toggleKeyboard = () => {
    setKeyboardVisible(!isKeyboardVisible);
  };

  const closeKeyboard = () => {
    setKeyboardVisible(false);
  };

  return (
    <>
      <KeyboardIcon onClick={toggleKeyboard} />
      {isKeyboardVisible && <StyledSimpleKeyboard {...props} closeKeyboard={closeKeyboard} />}
    </>
  );
};
