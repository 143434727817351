export const ARTICLE_KIND_ENUM = Object.freeze({
  STANDARD: 'STANDARD',
  BIG_PICTURE: 'BIG_PICTURE',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  GALLERY: 'GALLERY',
  LIVE: 'LIVE',
  BREAKING_NEWS: 'BREAKING_NEWS',
  LIVE_VIDEO: 'LIVE_VIDEO',
});
