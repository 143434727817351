import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { PageContainer } from '../components/Page/PageContainer.jsx';
import { toLang } from '../utils/mappers/langMapper';
import { PageMetaData } from '../components/Page/PageMetaData.jsx';
import { PAGE_TYPES } from '../constants/pageTypes';
import { WithGraphQLQueryHandling } from '../components/WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import {
  compositionComponentFragment,
  NavigationContentComposition,
} from '../components/CoCo/NavigationContentComposition.jsx';
import { gtmDataLayerFragment, GtmScriptWithDataLayer } from '../components/GoogleTagManager';
import { heroImageFragment } from '../components/HeroImage/HeroImage.jsx';
import { useLanguage } from '../context/LanguageContext';
import { RedirectWrapper } from '../components/Redirect/RedirectWrapper.jsx';

const queryName = 'NavigationPage';
export const navigationPageQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}($id: Int!, $lang: Language!) {
        content(id: $id, lang: $lang) {
          ... on Navigation {
            name
            pageTitle
            metaDescription
            lastPublicationDate
            firstPublicationDate
            ...${heroImageFragment.name}
            namedUrl
            url
            contentComposition {
              id
              informationSpaces {
                id
                name
                compositionComponents {
                  ...${compositionComponentFragment.name}
                }
              }
            }
          }
          ${gtmDataLayerFragment.partial()}
        }
      }
      ${heroImageFragment.fragment()}
      ${compositionComponentFragment.fragment()}
    `,
  ),
};

export const NavigationPage = () => {
  const { contentId } = useParams();
  const { langCode } = useLanguage();
  const navigationPageQueryVariables = { id: +contentId, lang: toLang(langCode) };

  return (
    <PageContainer>
      <WithGraphQLQueryHandling
        query={navigationPageQuery.query()}
        queryVariables={navigationPageQueryVariables}
      >
        {({ data: { content } }) => (
          <RedirectWrapper url={content.url}>
            <PageMetaData
              pageType={PAGE_TYPES.NAVIGATION}
              content={content}
              relativeCanonicalUrl={content.namedUrl}
            />
            <GtmScriptWithDataLayer content={content} />
            {content.contentComposition?.informationSpaces?.[0]?.compositionComponents && (
              <NavigationContentComposition
                mainContentImageLink={content.mainContentImageLink}
                compositionComponents={
                  content.contentComposition.informationSpaces[0].compositionComponents
                }
              />
            )}
          </RedirectWrapper>
        )}
      </WithGraphQLQueryHandling>
    </PageContainer>
  );
};
