import { useCallback, useEffect, useState } from 'react';
import { isServer } from '../../utils/ssr';
import { useGlobalsContext } from '../../context/GlobalsContext';

/**
 * @param{React.MutableRefObject<HTMLDetailsElement>} detailsRef
 */
function useTransitionState(detailsRef) {
  const [animation, setAnimation] = useState(null);

  const isClosing = useCallback(() => {
    return detailsRef.current.getAttribute('data-isclosing') === 'true';
  }, [detailsRef]);

  const setIsClosing = useCallback(
    newClosing => {
      detailsRef.current.setAttribute('data-isclosing', newClosing);
    },
    [detailsRef],
  );

  const finishTransition = useCallback(() => {
    setIsClosing(false);
    setAnimation(null);
  }, [setIsClosing]);

  /** @type {(startHeight: string, endHeight: string) => Animation} */
  const startAnimation = useCallback(
    (startHeight, endHeight) => {
      animation?.cancel();

      const newAnimation = detailsRef.current.animate(
        { height: [startHeight, endHeight] },
        { duration: 700, easing: 'ease' },
      );

      setAnimation(newAnimation);
      newAnimation.addEventListener('finish', finishTransition, { once: true });
      return newAnimation;
    },
    [animation, detailsRef, finishTransition],
  );

  /** @type {typeof startAnimation} */
  const startOpeningTransition = useCallback(
    (startHeight, endHeight) => {
      setIsClosing(false);
      return startAnimation(startHeight, endHeight);
    },
    [setIsClosing, startAnimation],
  );

  /** @type {typeof startAnimation} */
  const startClosingTransition = useCallback(
    (startHeight, endHeight) => {
      setIsClosing(true);
      return startAnimation(startHeight, endHeight);
    },
    [setIsClosing, startAnimation],
  );

  return {
    isClosing,
    startOpeningTransition,
    startClosingTransition,
  };
}

/**
 * @param {{
 *   detailsRef: React.MutableRefObject<HTMLDetailsElement>,
 *   summaryRef: React.MutableRefObject<HTMLElement>,
 *   contentRef: React.MutableRefObject<HTMLElement>,
 * }} params
 */
export function useDetailsAnimation({ detailsRef, summaryRef, contentRef }) {
  const { isClosing, startClosingTransition, startOpeningTransition } =
    useTransitionState(detailsRef);
  const { window } = useGlobalsContext();

  const close = useCallback(() => {
    const currentHeight = `${detailsRef.current.offsetHeight}px`;
    const summaryHeight = `${summaryRef.current.offsetHeight}px`;

    startClosingTransition(currentHeight, summaryHeight).addEventListener(
      'finish',
      () => {
        // open has to stay true till the animation is over, or content will disappear immidiately.
        detailsRef.current.open = false; // eslint-disable-line no-param-reassign
        detailsRef.current.style.height = ''; // eslint-disable-line no-param-reassign
      },
      { once: true },
    );
  }, [detailsRef, startClosingTransition, summaryRef]);

  const open = useCallback(() => {
    const currentHeight = `${detailsRef.current.offsetHeight}px`;
    // Fixate height, otherwise it will jump open before the animation starts.
    // eslint-disable-next-line no-param-reassign
    detailsRef.current.style.height = currentHeight;
    detailsRef.current.open = true; // eslint-disable-line no-param-reassign
    const completeHeight = `${summaryRef.current.offsetHeight + contentRef.current.offsetHeight}px`;

    startOpeningTransition(currentHeight, completeHeight).addEventListener(
      'finish',
      () => {
        detailsRef.current.style.height = ''; // eslint-disable-line no-param-reassign
      },
      { once: true },
    );
  }, [contentRef, detailsRef, startOpeningTransition, summaryRef]);

  const clickSummary = useCallback(
    event => {
      if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
        return;
      }

      event.preventDefault();

      if (isClosing() || !detailsRef.current.open) {
        open();
      } else {
        close();
      }
    },
    [close, detailsRef, isClosing, open, window],
  );

  useEffect(() => {
    if (!detailsRef.current || !summaryRef.current || !contentRef.current || isServer()) {
      return undefined;
    }

    const summary = summaryRef.current;

    summary.addEventListener('click', clickSummary);
    return () => {
      summary.removeEventListener('click', clickSummary);
    };
  }, [clickSummary, contentRef, detailsRef, summaryRef]);
}
