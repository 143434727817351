import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { feedbackHelper } from '../../utils/url/feedbackHelpers';
import { StyledLoadingMessage as LoadingMessage } from '../commons/LoadingMessage.jsx';
import { confirmUserEmailChange } from '../../state/user/userDuck';
import { useLanguage } from '../../context/LanguageContext';

export const ConfirmEmailChangeContainer = ({ confirmationId }) => {
  const { langCode } = useLanguage();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    async function confirmAndForward() {
      try {
        await dispatch(confirmUserEmailChange(confirmationId));
        history.push(feedbackHelper.forMailChanged(langCode));
      } catch (err) {
        const errorResponse = await err.json();
        history.push(feedbackHelper.getForErrorCode(errorResponse, langCode));
      }
    }
    confirmAndForward();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingMessage />;
};
