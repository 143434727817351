import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { StyledDwLink as DwLink } from '../DwLink/DwLink.jsx';
import { ContentSection } from '../ContentContainer/ContentSection/ContentSection.jsx';
import { I18nText } from '../I18n/I18nText.jsx';
import { LessonMenu } from '../Lesson/LessonMenu/LessonMenu.jsx';
import { StyledSummary as Summary } from '../Lesson/LessonExercise/LessonExerciseResult/Summary.jsx';
import { resultInPercent } from '../../state/progress/learnProgressSelectors';
import {
  calculateCourseNote,
  learningLevelMapper,
  nextRecommendedLevel,
  useLevelUrl,
} from '../../utils/courseUtils';
import { MARKS } from '../../constants/marks';
import { userIsLoggedIn } from '../../state/user/userSelectors';
import { handleCertificateGeneration } from '../../utils/certificateUtils';
import { readLessonProgress } from '../../state/progress/learnProgressDuck';
import { filterLessonExerciseOverviewItems, hasLessonExtras } from '../../utils/lessonUtils';
import { StructuralLessonContextProvider } from '../../context/StructuralLessonDataContext.jsx';
import { useLanguage } from '../../context/LanguageContext';
import { StyledStandardButton } from '../StandardButton/StandardButton.jsx';
import { StyledNextCourseRecommendation as NextCourseRecommendation } from './NextCourseRecommendation.jsx';
import { TestResultStyles } from './TestResultStyles';
import { DetailIntroText } from '../Text/Text.jsx';

export const FinalTestResult = ({ className, lesson, course }) => {
  const { dwLinks, overviewParts } = lesson;

  const lessonPath = lesson.namedUrl.split('?')[0];
  const lessonExerciseOverviewItems = filterLessonExerciseOverviewItems(overviewParts);

  const structuralData = {
    ...course,
    lessonId: lesson.id,
    lessonName: lesson.name,
    lessonExerciseOverviewItems,
    lessonPath,
    isFinalTest: true,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(readLessonProgress(lesson.id));
  }, [dispatch, lesson.id]);

  const level = learningLevelMapper[course.dkLearningLevel] || 'A1';
  const percentile = useSelector(resultInPercent(lesson.id));

  const nextLevel = nextRecommendedLevel(level, percentile);
  const nextCourseUrl = useLevelUrl(nextLevel);

  const { showCertificateButton, handleOnCertificateClick } = useCertificateGeneration(
    lesson.id,
    percentile,
  );

  return (
    <StructuralLessonContextProvider structuralData={structuralData}>
      <LessonMenu
        lessonId={lesson.id}
        lessonName={lesson.name}
        lessonOverviewParts={overviewParts}
        hasExtras={hasLessonExtras(lesson)}
      />
      <div className={className}>
        <Summary lessonId={lesson.id} showTitle={false} big />
        <I18nText
          isA={DetailIntroText}
          className="result-text"
          translation={`placementTest.${level}.${calculateCourseNote(percentile)}`}
        />
        {showCertificateButton && (
          <div className="certButtonArea">
            <StyledStandardButton
              id="cert-button"
              isA="button"
              onClick={handleOnCertificateClick}
              titleKey="course.certificate"
            />
          </div>
        )}
        {nextCourseUrl && <NextCourseRecommendation {...{ nextLevel, nextCourseUrl }} />}
        {dwLinks && dwLinks.length > 0 && (
          <ContentSection title="placementTest.moreStuff">
            {dwLinks.map(link =>
              link.name.includes(nextLevel) ? <DwLink key={link.id} {...link} /> : '',
            )}
          </ContentSection>
        )}
      </div>
    </StructuralLessonContextProvider>
  );
};

export const StyledFinalTestResult = styled(FinalTestResult)`
  ${TestResultStyles}

  > .certButtonArea {
    margin: 0 20px 50px;
    text-align: center;
  }
`;

function useCertificateGeneration(lessonId, percentile) {
  const history = useHistory();
  const { langCode } = useLanguage();
  const isLoggedIn = useSelector(userIsLoggedIn);

  const showCertificateButton = isLoggedIn && percentile >= MARKS.MEDIUM;

  const handleOnCertificateClick = () =>
    handleCertificateGeneration({ id: lessonId, langCode, history });

  return { showCertificateButton, handleOnCertificateClick };
}
