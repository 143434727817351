import { useRef, useCallback } from 'react';
import { useI18nContext } from '../../../context/I18nContext.jsx';
import { subtitlesLabels } from '../../../utils/mappers/subtitlesLabel';
import { initPluginsForPlayer } from '../../../video';
import { useVideoJsCommons } from '../useVideoJsCommons';
import { useLanguage } from '../../../context/LanguageContext';

/** @typedef {{ subtitleUrl: string, srcLanguage: string, language: string }} Subtitle */

/**
 * @param {{
 *   videoRef: React.MutableRefObject<HTMLVideoElement>,
 *   video: {
 *     hlsVideoSrc: string,
 *     subtitles: Subtitle[],
 *     type: string | undefined,
 *     formattedDuration: string,
 *   },
 *   noTracking?: boolean,
 * }}
 */
export function useVideoPlayer({
  videoRef,
  video: { hlsVideoSrc, subtitles, type = 'application/x-mpegURL', formattedDuration },
  noTracking,
}) {
  /** @type {React.MutableRefObject<string[]>} */
  const componentNameRef = useRef([]);
  const i18nContext = useI18nContext();
  const { isRtl } = useLanguage();

  /** @type {(player: videojs.VideoJsPlayer) => void} */
  const updatePlayer = useCallback(
    player =>
      player.ready(() => {
        player.src({ type, src: hlsVideoSrc });

        initPluginsForPlayer(player);

        player.setAttribute('data-duration', formattedDuration);
        player.addClass('rendered');
        updateTracks(subtitles, player);
        addChildren(componentNameRef.current, player, i18nContext, isRtl);
      }),
    [formattedDuration, hlsVideoSrc, subtitles, type, i18nContext, isRtl],
  );

  useVideoJsCommons({
    elementRef: videoRef,
    componentNameRef,
    updatePlayer,
    isVideo: true,
    noTracking,
  });
}

/**
 * @param {Subtitle}
 * @returns {Parameters<videojs.VideoJsPlayer["addRemoteTextTrack"]>[0]}
 */
function toRemoteTrack({ subtitleUrl, srcLanguage, language }) {
  return {
    default: true,
    src: subtitleUrl,
    srclang: srcLanguage,
    label: subtitlesLabels[language],
  };
}

/**
 * @param {Subtitle[]} subtitles
 * @param {videojs.VideoJsPlayer} player
 */
function updateTracks(subtitles, player) {
  // eslint-disable-next-line no-underscore-dangle
  player.remoteTextTracks().tracks_?.forEach(track => player.removeRemoteTextTrack(track));
  subtitles.map(toRemoteTrack).forEach(track => player.addRemoteTextTrack(track, false));
}

/**
 * @param {string[]} children
 * @param {videojs.VideoJsPlayer} player
 * @param {I18nContext} i18nContext
 * @param {boolean} isRtl
 */
function addChildren(children, player, i18nContext, isRtl) {
  children.forEach(c => player.addChild(c, { i18nContext, isRtl }));
}
