import styled from 'styled-components';
import { useLazyPosterImage } from './useLazyPosterImage';

// @used in ROAD
export const PlaybackLazyPosterContainer = ({
  children,
  className,
  posterImageUrl,
  fallbackImageStaticUrl,
}) => {
  const { backgroundImageUrl, sizeRef, inViewRef } = useLazyPosterImage({
    posterImageUrl,
    fallbackImageStaticUrl,
  });

  return (
    <div className={className} ref={sizeRef}>
      <div
        ref={inViewRef}
        style={{ backgroundImage: backgroundImageUrl && `url(${backgroundImageUrl})` }}
      >
        {children}
      </div>
    </div>
  );
};

export const StyledPlaybackLazyPosterContainer = styled(PlaybackLazyPosterContainer)`
  position: relative;
  padding-bottom: 56.25%;
  background-color: black;
  > div {
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
  .video-js,
  > div {
    position: absolute;
    top: 0;
    left: 0;
  }
  .video-js,
  .vjs-poster {
    background-color: transparent;
  }
`;
