import { StyledSlideshow } from '../../components/Slideshow/Slideshow.jsx';
import { replaceWithComponent } from './replaceCommons';

export const replaceImageGallery = ({ node, imageGalleries = [] }) =>
  replaceWithComponent({
    selector: '.image-gallery',
    node,
    elementType: 'div',
    componentFn: imageGalleryFn({ imageGalleries }),
  }).portals;

export const imageGalleryFn =
  ({ imageGalleries }) =>
  matchedElem => {
    const imageGalleryId = +matchedElem.dataset.id;
    const imageGallery = imageGalleries.find(ig => ig.id === imageGalleryId);

    return imageGallery && <StyledSlideshow imageGallery={imageGallery} />;
  };
