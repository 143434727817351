import { useFormContext } from 'react-hook-form';
import { StyledInput as Input } from '../../FormElements/Input/StyledInput.jsx';
import {
  passwordDiffersFromEmail,
  passwordRetypeValid,
  passwordValid,
} from '../../../utils/validation/validators';

export const NewPasswordInputs = ({ passwordTitleKey, passwordRepeatTitleKey, email }) => {
  const { getValues } = useFormContext();

  return (
    <>
      <Input
        labelKey={passwordTitleKey}
        name="password"
        type="password"
        registerOptions={{
          required: true,
          validate: {
            pattern: v => passwordValid(v, email),
            differsFromEmail: v => passwordDiffersFromEmail(v, email),
          },
        }}
      />
      <Input
        labelKey={passwordRepeatTitleKey}
        name="passwordRetype"
        type="password"
        registerOptions={{
          required: true,
          validate: {
            passwordRetype: v => passwordRetypeValid(v, getValues().password),
          },
        }}
      />
    </>
  );
};
