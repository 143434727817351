import { createContext, useContext, useReducer } from 'react';
import { createInquiryProgressMetaData } from '../../../../state/progress/learnProgress';

// Actions
export const EXERCISE_PROGRESS_ACTION_TYPES = Object.freeze({
  ADD_DONE_INQUIRY: 'ADD_DONE_INQUIRY',
  RESET: 'RESET',
});

// Reducer
const exerciseProgressReducer = (state, action) => {
  switch (action.type) {
    case EXERCISE_PROGRESS_ACTION_TYPES.ADD_DONE_INQUIRY:
      return {
        ...state,
        inquiryProgresses: [
          ...state.inquiryProgresses,
          createInquiryProgressMetaData({
            inquiryId: action.inquiryId,
            score: action.score,
            achievableScore: action.achievableScore,
          }),
        ],
        numberOfDoneInquiries: state.numberOfDoneInquiries + 1,
        currentExerciseScore: state.currentExerciseScore + action.score,
      };
    case EXERCISE_PROGRESS_ACTION_TYPES.RESET:
      return initialState;
    default:
      throw new Error('unexpected action type');
  }
};

const initialState = {
  inquiryProgresses: [],
  numberOfDoneInquiries: 0,
  currentExerciseScore: 0,
};

export const ExerciseProgressContext = createContext(null);

export const ExerciseProgressProvider = ({ children }) => {
  const [exerciseStats, dispatch] = useReducer(exerciseProgressReducer, initialState);

  return (
    <ExerciseProgressContext.Provider
      value={[
        {
          ...exerciseStats,
        },
        dispatch,
      ]}
    >
      {children}
    </ExerciseProgressContext.Provider>
  );
};

// usage hook with "action creators"
export const useExerciseProgress = () => {
  const [exerciseProgress, dispatch] = useContext(ExerciseProgressContext);

  const addDoneInquiry = ({ inquiryId, score, achievableScore }) => {
    dispatch({
      type: EXERCISE_PROGRESS_ACTION_TYPES.ADD_DONE_INQUIRY,
      inquiryId,
      score,
      achievableScore,
    });
  };

  const resetProgress = () => {
    dispatch({ type: EXERCISE_PROGRESS_ACTION_TYPES.RESET });
  };

  return { exerciseProgress, addDoneInquiry, resetProgress };
};
