import styled from 'styled-components';
import { ReactComponent as KeyboardIcon } from '../../../../assets/svg/keyboard.svg';
import { mediaMin } from '../../../../utils/css';

export const StyledKeyboardIcon = styled(KeyboardIcon)`
  display: none;

  ${mediaMin.lg`
    display: block;
    .placeholder-cloze > & {
      display: inline-block;
    }
  `}
`;
