import styled from 'styled-components';
import IconHintSimple from '../../../../assets/svg/icon-hint.svg';
import IconHintCircleOrange from '../../../../assets/svg/icon-hint-circle-orange.svg';
import { StyledRichText as RichText } from '../../../RichText/RichText.jsx';
import { columnSize, mediaMin } from '../../../../utils/css';
import { useTranslation } from '../../../../hooks/useTranslation';

export const LessonExerciseHint = ({
  description,
  isSimpleIconHint = false,
  isA = 'div',
  className,
}) => {
  const translatedText = useTranslation(description);
  const hintSVGSource = isSimpleIconHint ? IconHintSimple : IconHintCircleOrange;

  return (
    description && (
      <div className={className}>
        <img alt="hint icon" src={hintSVGSource} width="18" height="18" />
        <RichText isA={isA} dir="auto" content={translatedText} noContainer />
      </div>
    )
  );
};

export const StyledLessonExerciseHint = styled(LessonExerciseHint)`
  display: flex;
  padding: 50px ${columnSize.c1} 7px;
  flex-direction: column;
  align-items: center;
  direction: ltr;

  ${mediaMin.md`
    gap: 10px;
    padding: 17px ${columnSize.c1} 19px;
    flex-direction: row;
    justify-content: left;
  `}

  img {
    margin-bottom: 10px;
  }

  p {
    text-align: center;

    ${mediaMin.md`
      text-align: left;
    `}
  }
`;
