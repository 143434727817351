import styled from 'styled-components';
import classnames from 'classnames';
import { colors, mediaMax } from '../../utils/css';
import { useTranslation } from '../../hooks/useTranslation';
import { ButtonInteraction } from '../Interaction/Interaction.jsx';

const ExerciseButton = ({
  titleKey,
  children,
  className,
  selected,
  correct,
  incorrect,
  ...restProps
}) => {
  const label = useTranslation(titleKey);

  return (
    <ButtonInteraction
      className={classnames(className, {
        btnSelected: selected,
        btnCorrect: correct,
        btnIncorrect: incorrect,
      })}
      {...restProps}
    >
      {titleKey ? label : children}
    </ButtonInteraction>
  );
};

export const StyledExerciseButton = styled(ExerciseButton)`
  text-align: center;
  padding: calc(1rem - var(--border-width));
  background-color: var(--bg-color);
  outline: none;
  cursor: pointer;

  /* transparency makes sure the content doesn't readjust on hover */
  --border-color: transparent;
  --border-width: 3px;
  border: solid var(--border-width) var(--border-color);
  border-radius: 8px;

  --bg-color: ${colors.BLUE_GREY_02};

  ${mediaMax.xs`
    width: 100%;
  `}

  :disabled {
    cursor: default;
    pointer-events: none;
  }

  :hover:not(:disabled) {
    --border-color: ${colors.DW_DARK_BLUE_NEW};
  }

  :active:not(:disabled),
  &.btnSelected {
    --bg-color: ${colors.DW_DARK_BLUE_NEW};
    color: ${colors.LG_WHITE};
  }

  && {
    &.btnCorrect,
    &.btnCorrect:hover {
      --bg-color: ${colors.ACCENT_GREEN};
      color: ${colors.DARK_BLUE_GREY_02};
    }

    &.btnIncorrect,
    &.btnIncorrect:hover {
      --bg-color: ${colors.ACCENT_RED};
      color: ${colors.DARK_BLUE_GREY_02};
    }
  }

  :disabled:not(.btnSelected, .btnCorrect, .btnIncorrect) {
    --bg-color: ${colors.BLUE_GREY_02};
    color: ${colors.BLUE_GREY_03};
  }
`;
