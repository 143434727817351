import styled from 'styled-components';
import { I18nText } from '../../I18n/I18nText.jsx';
import { KickerInfo } from '../../Info/Info.jsx';

export const ContentNavTitle = ({ className, title, ...restProps }) => (
  <section className={className}>
    <I18nText isA={KickerInfo} translation={title} {...restProps} />
  </section>
);

export const StyledContentNavTitle = styled(ContentNavTitle)`
  text-align: center;
  /* @noflip */
  direction: ltr;
  padding: 2rem 0;
`;
