import styled from 'styled-components';
import { colors, mediaMin } from '../../utils/css';

export const StyledIconBar = styled('span')`
  margin-block: 4px;
  display: block;
  border-radius: 0;
  width: 14px;
  height: 2px;
  background-color: ${colors.DW_DARK_BLUE_NEW};

  ${mediaMin.md`
    width: 21px;
    height: 3px;
  `}
`;
