import styled from 'styled-components';
import classnames from 'classnames';
import { INQUIRY_MEDIA_TYPE } from '../../../../../constants/inquiryMediaTypes';
import { StyledResponsiveDwPicture as ResponsiveDwPicture } from '../../../../ResponsiveDwPicture/ResponsiveDwPicture.jsx';
import { Choicechip } from '../../../../Choicechip/Choicechip.jsx';

export const ManyToManyAnswer = ({ className, answer, mediaType, isLeftAnswer, ...restProps }) => {
  const { id, subInquiryText, alternatives } = answer;
  const answerText = isLeftAnswer ? subInquiryText : alternatives[0].alternativeText;
  const isImage = mediaType === INQUIRY_MEDIA_TYPE.IMAGE && !isLeftAnswer;

  return (
    <div
      className={classnames(className, {
        rightAnswer: !isLeftAnswer,
      })}
    >
      <Choicechip data-exercise-answer-id={isLeftAnswer ? id : alternatives[0].id} {...restProps}>
        {isImage ? (
          <ResponsiveDwPicture image={alternatives[0].images[0]} alt={answerText} />
        ) : (
          answerText
        )}
      </Choicechip>
    </div>
  );
};

export const StyledManyToManyAnswer = styled(ManyToManyAnswer)`
  /* @noflip */
  direction: ltr;

  grid-column: 1;

  &.rightAnswer {
    grid-column: 2;
  }

  text-overflow: ellipsis;

  display: block;

  padding: 0;
  margin: 0;
  overflow: hidden;

  > ${Choicechip} {
    max-width: 100%;

    :has(> figure):disabled {
      opacity: 0.65;
    }

    > figure {
      width: 107px;
      height: 60px;
    }
  }
`;
