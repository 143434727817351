import styled from 'styled-components';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import {
  StyledFooterSectionMainGroup as FooterSectionMainGroup,
  footerSectionFragment,
} from './FooterSectionMainGroup.jsx';
import {
  footerSocialMediaFragment,
  StyledFooterSocialMedia as FooterSocialMedia,
} from './FooterSocialMedia.jsx';
import { columnSize } from '../../utils/css';
import { isValidList } from '../../utils/commons';

const fragmentName = 'FooterSectionMain';
export const footerSectionMainFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`fragment ${fragmentName} on Footer {
      sections {
        ...${footerSectionFragment.name}
      }
      ...${footerSocialMediaFragment.name}
    }
    ${footerSectionFragment.fragment()}
    ${footerSocialMediaFragment.fragment()}
  `,
  ),
};

// @used in ROAD
export const FooterSectionMain = ({ className, footer }) => {
  if (!isValidList(footer.sections)) {
    return null;
  }
  return (
    <section className={className} data-tracking-name="footer-section-main">
      {footer.sections.map(
        section =>
          isValidList(section.links) && (
            <FooterSectionMainGroup key={section.headline} section={section} />
          ),
      )}
      <FooterSocialMedia footer={footer} />
    </section>
  );
};

export const StyledFooterSectionMain = styled(FooterSectionMain)`
  display: grid;
  grid-template-columns: ${columnSize.c14} 1fr;
  padding-left: ${columnSize.c2};
  padding-right: ${columnSize.c2};

  & > *:last-child {
    margin-bottom: 20px;
    grid-column: span 2;
  }
`;
