import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { memoizeWith } from 'ramda';
import { PageContainer } from '../components/Page/PageContainer.jsx';
import { LANG_CODES, toLang } from '../utils/mappers/langMapper';
import { WithGraphQLQueryHandling } from '../components/WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { scoreFragment } from '../state/progress/learnProgress';
import { Lesson, lessonOverviewPartsFragment } from '../components/Lesson/Lesson.jsx';
import { userDataSelector } from '../state/user/userSelectors';
import { manuscriptFragment } from '../components/Manuscript/Manuscript.jsx';
import { knowledgesFragment } from '../components/Lesson/LessonKnowledge/LessonKnowledge.jsx';
import { metaDataFragment } from '../components/Page/PageMetaData.jsx';
import { imageOGMetaDataFragment } from '../components/ImageOGMetaData/ImageOGMetaData.jsx';
import { gtmDataLayerFragment } from '../components/GoogleTagManager';
import { hasLessonExtrasFragment } from '../utils/lessonUtils';
import { useLanguage } from '../context/LanguageContext';

const queryName = ({ handicap, contextId }) => `LessonPage${handicap || ''}For${contextId}`;
export const lessonPageQuery = {
  name: queryName,
  query: memoizeWith(
    params => queryName(params),
    ({ handicap, contextId }) => gql`
      query ${queryName({
        handicap,
        contextId,
      })}($lessonId: Int!, $lessonIdString: String!, $lang: Language!) {
        content(id: $lessonId, lang: $lang) {
          ... on Lesson {
            id
            name
            language
            namedUrl
            ...${lessonOverviewPartsFragment.name({ scope: 'Lesson', handicap, contextId })}
            ...${scoreFragment.name({ type: 'Lesson', handicap })}
            ...${metaDataFragment.name({ type: 'Lesson' })}
          }
          ...${manuscriptFragment.name}
          ...${knowledgesFragment.name({ type: 'Lesson' })}
          ...${imageOGMetaDataFragment.name({ type: 'Lesson' })}
          ...${hasLessonExtrasFragment.name}
          ${gtmDataLayerFragment.partial()}
        }

        contentsByType(
          lang: $lang
          type: COURSE
          amount: 1
          filters: { field: "contentLinks.targetId", value: [$lessonIdString] }
        ) {
          ... on Course {
            id
            name
            title
            language
            dkLearningLevel
            isAlphabeticalCourse
            isPlacementTestCourse
            namedUrl
            contentLinks(targetTypes: LESSON) {
              targetId
              additionalInformation
              groupName
            }
          }
          ...${imageOGMetaDataFragment.name({ type: 'Course' })}
        }
      }
      ${lessonOverviewPartsFragment.fragment({ scope: 'Lesson', handicap, contextId })}
      ${scoreFragment.fragment({ type: 'Lesson', handicap })}
      ${manuscriptFragment.fragment()}
      ${knowledgesFragment.fragment({ type: 'Lesson' })}
      ${imageOGMetaDataFragment.fragment({ type: 'Lesson' })}
      ${imageOGMetaDataFragment.fragment({ type: 'Course' })}
      ${metaDataFragment.fragment({ type: 'Lesson' })}
      ${hasLessonExtrasFragment.fragment()}
    `,
  ),
};

export const LessonPage = () => {
  const { lessonId } = useParams();
  const { langCode } = useLanguage();
  const { handicap } = useSelector(userDataSelector) || {};

  const queryVariables = {
    lessonId: +lessonId,
    lessonIdString: lessonId,
    lang: toLang(langCode),
  };

  return (
    <PageContainer>
      <WithGraphQLQueryHandling
        query={lessonPageQuery.query({ handicap, contextId: lessonId })}
        queryVariables={queryVariables}
      >
        {({ data, refetch }) => {
          if (!data.content) {
            refetch({
              ...queryVariables,
              lang: toLang(LANG_CODES.GERMAN),
            });
            return null;
          }
          return <Lesson {...{ data, queryVariables }} />;
        }}
      </WithGraphQLQueryHandling>
    </PageContainer>
  );
};
