import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { StyledProgressResetButton as ProgressResetButton } from './ProgressResetButton.jsx';
import { LearnProgressIcon } from '../../LearnProgressIcon/LearnProgressIcon.jsx';
import { findCourseLearnProgressSelector } from '../../../state/progress/learnProgressSelectors';
import { mediaMax, columnSize } from '../../../utils/css';

export const CourseProgress = ({ className, courseId, finalLessonId, onClickResetCourse }) => {
  const { courseProgress } = useSelector(findCourseLearnProgressSelector(courseId));

  return (
    <section className={className}>
      <ProgressResetButton onClickReset={onClickResetCourse} />
      <div>
        <LearnProgressIcon learnProgress={courseProgress} finalLessonId={finalLessonId} />
      </div>
    </section>
  );
};

const progressIconWidth = '16px';

export const StyledCourseProgress = styled(CourseProgress)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 14px ${columnSize.c1};
  column-gap: calc(${columnSize.c1} - ${progressIconWidth});

  ${mediaMax.xs`
    padding: 14px ${columnSize.c2};
    column-gap: calc(${columnSize.c2} - ${progressIconWidth});
  `}
`;
