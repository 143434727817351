import { useMemo } from 'react';
import styled from 'styled-components';
import { mediaMin, columnSize } from '../../../utils/css';
import { contentBlockStyles } from '../cssCocoStyles';
import { StyledArticleInfo as ArticleInfo } from '../ArticleInfo.jsx';

/**
 * @param {{
 *   className?: string,
 *   contents: {
 *     id: React.Key,
 *     modelType: unknown,
 *     name: string,
 *     text: string,
 *   }[],
 * }} props
 */
export const InfoBlock = ({ className, contents }) => {
  const filteredContents = useMemo(() => contents.filter(isArticleInfo), [contents]);

  return (
    <div className={className}>
      {filteredContents.map(info => (
        <ArticleInfo key={info.id} contents={info} />
      ))}
    </div>
  );
};

export const StyledInfoBlock = styled(InfoBlock)`
  ${contentBlockStyles}

  display: grid;
  grid-template-areas: 'eyecatcher';
  grid-template-columns: 1fr;
  padding: 0 ${columnSize.c2};

  ${mediaMin.xl`
    grid-template-areas: 'eyecatcher eyecatcher';
    grid-template-columns: 1fr 1fr;
  `}

  > ${ArticleInfo} {
    margin-bottom: 30px;

    &:first-of-type {
      grid-area: eyecatcher;
    }
  }
`;

/**
 * @param {{ modelType: unknown }
 */
function isArticleInfo(info) {
  return info.modelType === 'ARTICLE';
}
