import styled from 'styled-components';
import { MainContentArea } from '../ContentDetailAreas/MainContentArea.jsx';
import { StyledRecommendedArea as RecommendedArea } from '../ContentDetailAreas/RecommendedArea.jsx';

export const ContentDetail = ({ className, data }) => {
  const { content } = data;
  return (
    <div className={className} data-content-id={content.id}>
      <section>
        <div>
          <MainContentArea {...data} />
        </div>
      </section>
      <section>
        <div>
          <RecommendedArea content={content} />
        </div>
      </section>
    </div>
  );
};

export const StyledContentDetail = styled(ContentDetail)`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  > section {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    > div {
      padding: 0;
      flex-basis: 0;
      flex-grow: 1;
      width: 100vw;
    }
  }
`;
