import styled from 'styled-components';
import { I18nText } from '../../I18n/I18nText.jsx';
import { StyledStandardButton } from '../../StandardButton/StandardButton.jsx';

export const ProgressResetButton = ({ className, onClickReset }) => {
  return (
    <StyledStandardButton
      onClick={e => onClickReset(e)}
      className={className}
      variant="secondary"
      data-testid="progressResetButton"
    >
      <I18nText translation="course.reset" />
    </StyledStandardButton>
  );
};

export const StyledProgressResetButton = styled(ProgressResetButton)`
  min-height: 31px;
  padding: 3px 10px;
`;

export const StyledSmallProgressResetButton = styled(StyledProgressResetButton)`
  min-height: 27px;
  padding: 1px 7px;
`;
