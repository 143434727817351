import styled from 'styled-components';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import {
  StyledFooterSectionStandard as FooterSectionStandard,
  footerSectionStandardFragment,
} from './FooterSectionStandard.jsx';
import {
  footerSectionMainFragment,
  StyledFooterSectionMain as FooterSectionMain,
} from './FooterSectionMain.jsx';
import { colors } from '../../utils/css';
import { DarkThemeProvider } from '../../context/ThemeContext.jsx';

const fragmentName = 'Footer';
export const footerFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Query {
        footer(lang: $lang) {
          ...${footerSectionMainFragment.name}
          ...${footerSectionStandardFragment.name}
        }
      }
      ${footerSectionMainFragment.fragment()}
      ${footerSectionStandardFragment.fragment()}
    `,
  ),
};

// @used in ROAD
export const Footer = ({ className, data }) => {
  return (
    <DarkThemeProvider>
      <footer className={className}>
        <h2 className="sr-only">Footer</h2>
        <FooterSectionMain footer={data.footer} />
        <FooterSectionStandard footer={data.footer} />
      </footer>
    </DarkThemeProvider>
  );
};

export const StyledFooter = styled(Footer)`
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: ${colors.DARK_BLUE_GREY_01};
`;
