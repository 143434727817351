import styled from 'styled-components';
import { Suspense, useEffect, useRef, useState } from 'react';
import { importKeyboardAddons, KeyboardLazy } from '../../../../utils/keyboard';
import { StyledVerticalTextArea } from '../../../Input/TextAreaInput/TextAreaInput.jsx';
import { colors } from '../../../../utils/css';
import { ButtonInteraction } from '../../../Interaction/Interaction.jsx';
import { keyCodes } from '../../../../constants/keyCodes';

export const SimpleKeyboard = ({
  inputMaxLength,
  onChange,
  inputValue,
  inputClassName = '',
  inputSize,
  isTextArea = false,
  closeKeyboard,
  className,
}) => {
  const [layout, setLayout] = useState(null);
  const [shiftActive, setShiftActive] = useState(false);

  const keyboardRef = useRef();
  const inputRef = useRef();
  const textAreaRef = useRef();

  useEffect(() => {
    if (layout) {
      keyboardRef.current?.setInput(inputValue);
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [layout, inputValue]);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      const germanLayout = await importKeyboardAddons();
      if (!isCancelled) {
        setLayout(germanLayout);
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, []);

  const handleKeyPress = pressedKey => {
    if (pressedKey === '{enter}') closeKeyboard();
    if (pressedKey === '{shift}' || pressedKey === '{lock}') setShiftActive(s => !s);
  };

  const onInputKeyDown = e => {
    if (e.key === keyCodes.ESCAPE) {
      closeKeyboard();
    }
  };

  const onChangeInput = input => {
    keyboardRef.current.setInput(input);
    onChange(input);
  };

  const onChangeLocalInput = e => {
    onChangeInput(e.target.value);
  };

  if (!layout) {
    return null;
  }

  return (
    <>
      <div className={className}>
        <ButtonInteraction onClick={closeKeyboard} aria-label="Close">
          <span aria-hidden="true">×</span>
        </ButtonInteraction>
        {!isTextArea && (
          <input
            className={inputClassName}
            value={inputValue}
            onChange={onChangeLocalInput}
            onKeyDown={onInputKeyDown}
            maxLength={inputMaxLength}
            data-size={inputSize}
            ref={inputRef}
            type="text"
          />
        )}
        {isTextArea && (
          <StyledVerticalTextArea
            value={inputValue}
            onChange={onChangeLocalInput}
            onKeyDown={onInputKeyDown}
            ref={textAreaRef}
          />
        )}
        <Suspense fallback={<div>Loading...</div>}>
          <KeyboardLazy
            keyboardRef={r => {
              keyboardRef.current = r;
            }}
            onChange={onChangeInput}
            onKeyPress={handleKeyPress}
            layoutName={shiftActive ? 'shift' : 'default'}
            {...layout}
          />
        </Suspense>
      </div>
      <StyledKeyboardBackdrop onClick={closeKeyboard} data-testid="keyboardBackdrop" />
    </>
  );
};

export const StyledSimpleKeyboard = styled(SimpleKeyboard)`
  width: 540px;
  /* @noflip */
  direction: ltr;

  text-align: center;
  padding: 0.3em;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 16000;
  display: block;
  margin: 2px auto 0 auto;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: ${colors.LG_WHITE};
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);

  span {
    font-size: 20px;
  }

  > button {
    font-weight: 600;
    opacity: 0.2;
    color: inherit;
    line-height: 1;
    float: right;
    border: 0;
    background: 0 0;
    padding: 0;
  }
`;

export const StyledKeyboardBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
`;
