import styled from 'styled-components';
import { mediaMin, colors, columnSize, fontSize } from '../../utils/css';
import dwLogoMobile from '../../assets/svg/dw-logo-mobile.svg';
import { I18nText } from '../I18n/I18nText.jsx';
import { TeaserHeadline } from '../Headline/Headline.jsx';

export const MobileBanner = ({ className, homeURL, title }) => {
  return (
    <aside className={className}>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a href={homeURL} data-testid="dwMobileLogo" />
      <I18nText isA={TeaserHeadline} as="div" translation={title} />
    </aside>
  );
};

export const StyledMobileBanner = styled(MobileBanner)`
  background-color: ${colors.LG_WHITE};
  border-width: 0 0 1px;
  box-shadow: inset 0 1px 0 ${colors.LG_TRANSPARENT_WHITE_15},
    0 1px 5px ${colors.LG_TRANSPARENT_BLACK_075};
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 1030;
  border-bottom: 1px solid ${colors.DW_GREY_02};
  height: 60px;

  ${mediaMin.md`
    height: 80px;
  `}

  ${mediaMin.xl`
    display: none !important;
  `}

  > a {
    background: url(${dwLogoMobile});
    /*! @noflip */
    float: left;
    height: 28px;
    width: 49px;
    margin: 16px ${columnSize.c1} 0 ${columnSize.c1};

    ${mediaMin.md`
      margin-top: 26px;
    `}
  }

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    ${fontSize.plus2}
  }
`;
