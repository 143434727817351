import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { Redirect, useLocation } from 'react-router-dom';
import { toLang } from '../utils/mappers/langMapper';
import {
  StyledVendorSettings as VendorSettings,
  vendorSettingsFragment,
} from '../components/VendorSettings/VendorSettings.jsx';
import { isPathMatching } from '../utils/url/pathAnalyser';
import { PageMetaData } from '../components/Page/PageMetaData.jsx';
import { PageContainer } from '../components/Page/PageContainer.jsx';
import { WithGraphQLQueryHandling } from '../components/WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { PAGE_TYPES } from '../constants/pageTypes';
import { useLanguage } from '../context/LanguageContext';

const queryName = 'VendorConsent';
export const vendorConsentQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}($lang: Language!) {
        privacySettings(lang: $lang){
          namedUrl
          ...${vendorSettingsFragment.name}
        }
      }
      ${vendorSettingsFragment.fragment()}
    `,
  ),
};

export const VendorConsentPage = () => {
  const { langCode } = useLanguage();
  const { pathname } = useLocation();
  const queryVariables = {
    lang: toLang(langCode),
  };

  return (
    <PageContainer>
      <WithGraphQLQueryHandling query={vendorConsentQuery.query()} queryVariables={queryVariables}>
        {({ data: { privacySettings } }) => {
          if (!isPathMatching({ namedUrl: privacySettings.namedUrl, pathname })) {
            return <Redirect to={privacySettings.namedUrl} />;
          }
          return (
            <>
              <PageMetaData pageType={PAGE_TYPES.CONSENT} useDescriptionTranslation={true} />
              <VendorSettings privacySettings={privacySettings} />
            </>
          );
        }}
      </WithGraphQLQueryHandling>
    </PageContainer>
  );
};
