import styled from 'styled-components';
import { colors } from '../../../../utils/css';
import { PageLinkInteraction } from '../../../Interaction/Interaction.jsx';
import { CaptionText } from '../../../Text/Text.jsx';

/**
 * @param {{
 *   href: string,
 *   content: React.ReactNode,
 *   description?: string | null,
 * }} props
 */
export const LessonExtrasLink = ({ className, href, content, description }) => {
  return (
    <div className={className}>
      <PageLinkInteraction as="a" href={href}>
        {content}
      </PageLinkInteraction>
      {!description ? null : <CaptionText as="p">{description}</CaptionText>}
    </div>
  );
};

export const StyledLessonExtrasLink = styled(LessonExtrasLink)`
  border-top: 1px solid ${colors.DW_GREY_07};
  padding-top: 5px;
  padding-bottom: 6px;

  > p {
    margin-bottom: 0;
  }
`;
