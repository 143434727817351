import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { columnSize, fontSize, mediaMax } from '../../../utils/css';
import { getDashboardUrl } from '../../../utils/url/urlFactory';
import { I18nText } from '../../I18n/I18nText.jsx';
import { TeaserHeadline } from '../../Headline/Headline.jsx';

export const OnlyDesktopBrand = ({ langCode, title, className }) => {
  return (
    <div id="main-nav" className={className}>
      <I18nText isA={TeaserHeadline} as={Link} translation={title} to={getDashboardUrl(langCode)} />
    </div>
  );
};

const makeRoomForRtl = '85px';
const linkPadding = '15px';

export const StyledOnlyDesktopBrand = styled(OnlyDesktopBrand)`
  align-items: center;
  display: flex;
  height: 85px;
  justify-content: flex-start;
  /*! @noflip */
  padding-right: ${makeRoomForRtl};
  /*! @noflip */
  padding-left: calc(${columnSize.c1} - ${linkPadding});

  ${mediaMax.sm`
    display: none;
  `}

  > ${TeaserHeadline} {
    ${fontSize.plus2}
    padding: ${linkPadding};
    text-decoration: none;
  }
`;
