import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { StyledBigImageTeaser as BigImageTeaser } from './BigImageTeaser.jsx';
import {
  publicationDateDeliveryFragment,
  publicationDateModelFragment,
} from '../../PublicationDate/PublicationDate.jsx';

const fragmentName = 'referenceFragment';
export const referenceFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Reference {
        id
        modelType
        isRtl
        title
        shortTitle
        shortTeaser
        mainContentImage {
          id
          name
          staticUrl
        }
        externalLinks {
          id
          name
          description
          url
        }
        dkLearningLevel
        internalLinks: contentLinks(targetTypes: [NAVIGATION, ARTICLE, COURSE, LESSON]) {
          id
          name
          description
          targetType
          target {
            ... on UrlAspect {
              namedUrl
            }
          }
        }
        ...${publicationDateDeliveryFragment.name({ type: 'Reference' })}
        ...${publicationDateModelFragment.name({ type: 'Reference' })}
      }
      ${publicationDateDeliveryFragment.fragment({ type: 'Reference' })}
      ${publicationDateModelFragment.fragment({ type: 'Reference' })}
    `,
  ),
};

export const ReferenceTeaser = ({ className, contents, ...restProps }) => {
  const {
    mainContentImage,
    shortTitle,
    shortTeaser,
    externalLinks,
    dkLearningLevel,
    internalLinks,
    title,
    isRtl,
  } = contents;
  const externalUrl = externalLinks?.[0]?.url;
  const internalUrl = internalLinks?.[0]?.target?.namedUrl;

  const targetProps = decideLinkProps(externalUrl, internalUrl);

  return (
    <BigImageTeaser
      className={className}
      image={mainContentImage}
      title={shortTitle}
      referenceTitle={title}
      teaser={shortTeaser}
      publicationDateConfig={contents}
      dkLearningLevel={dkLearningLevel}
      isRtl={isRtl}
      {...targetProps}
      {...restProps}
    />
  );
};

export const StyledReferenceTeaser = ReferenceTeaser;

const internalTargetRegex = /^https:\/\/learngerman.dw.com/;

function decideLinkProps(externalUrl, internalUrl) {
  if (!externalUrl) {
    return { to: internalUrl };
  }

  /*
   * Internal links can only be defined for contents in cms. Non content targets can only be linked
   * with an external link. This makes sure those links are treated as internal.
   */
  if (internalTargetRegex.test(externalUrl)) {
    return { to: externalUrl.replace(internalTargetRegex, '') };
  }

  return { native: true, to: externalUrl, target: '_blank' };
}
