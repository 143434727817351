import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { adjust } from 'ramda';
import { LessonExerciseHiddenAudio } from '../LessonExerciseHiddenAudio/LessonExerciseHiddenAudio.jsx';
import { StyledLessonExerciseHint as LessonExerciseHint } from '../LessonExerciseHint/LessonExerciseHint.jsx';
import { LessonExerciseQuestion } from '../LessonExerciseQuestion/LessonExerciseQuestion.jsx';
import { findFeedbackAudio } from '../../../../utils/audioUtils';
import { useAudio } from '../../../../hooks/useAudio';
import { useInquiryHandling } from '../workflow/useInquiryHandling';
import { ANSWER_STATE } from '../LessonExerciseAnswer/answerState';
import { ExerciseAnswer } from '../LessonExerciseAnswer/ExerciseAnswer.jsx';
import { AnswersGridDefinition } from '../LessonExerciseAnswer/styling';
import { baseExerciseStyles } from '../utils/exerciseCustomCSS';

export const SingleSelectionExercise = ({ className, exerciseId, inquiry }) => {
  const {
    id: inquiryId,
    inquiryDescription,
    audios,
    subInquiries,
    mainContentImage,
    learningMetaInfo: { achievableScore },
  } = inquiry;
  const isSolutionAudioFallbackMode = Boolean(mainContentImage);
  const correctAnswerAudio = findFeedbackAudio(isSolutionAudioFallbackMode, audios);
  const { target: answerAudio } = correctAnswerAudio || {};
  const { mp3Src: correctAnswerAudioUrl } = answerAudio || {};
  const { alternatives } = subInquiries[0];
  const answerMediaType = inquiry.mediaType.toLowerCase();

  const [answersFrozen, setAnswersFrozen] = useState(false);
  const [answers, setAnswers] = useState(
    alternatives.map(alternative => ({
      ...alternative,
      answerState: ANSWER_STATE.INITIAL,
    })),
  );

  const { handleCorrectAnswer, handleWrongAnswer, isIncorrigible } = useInquiryHandling({
    inquiryId,
    achievableScore,
  });

  const { playWrongDefaultAudioFeedBack, playCorrectDefaultAudioFeedBack, addRef, playAudio } =
    useAudio();
  const correctAnswerAudioRef = useRef();

  useEffect(
    () => (correctAnswerAudioUrl ? addRef(correctAnswerAudioRef) : undefined),
    [addRef, correctAnswerAudioUrl],
  );

  const onAnswerClicked = chosenAnswerIndex => {
    setAnswers(
      adjust(chosenAnswerIndex, answer => ({
        ...answer,
        answerState: answer.isCorrect ? ANSWER_STATE.CORRECT : ANSWER_STATE.WRONG,
      })),
    );

    if (answers[chosenAnswerIndex].isCorrect) {
      handleCorrectAnswer();
      setAnswersFrozen(true);
      if (correctAnswerAudioUrl) {
        playAudio({ preventDefault: () => undefined }, correctAnswerAudioRef);
      } else {
        playCorrectDefaultAudioFeedBack();
      }
    } else {
      handleWrongAnswer();
      if (isIncorrigible) setAnswersFrozen(true);
      playWrongDefaultAudioFeedBack();
    }
  };

  return (
    <div
      className={className}
      data-type={`allocation${answerMediaType}`}
      data-exercise-id={exerciseId}
      aria-atomic="true"
    >
      <div>
        <div className="question">
          <LessonExerciseQuestion {...{ inquiry }} />
        </div>
        <div>
          <div className={`answers ${answerMediaType}`} aria-multiselectable="false">
            {answers.map((answer, index) => (
              <ExerciseAnswer
                key={answer.id}
                disabled={answersFrozen || answer.answerState !== ANSWER_STATE.INITIAL}
                answerMediaType={answerMediaType}
                answer={answer}
                index={index}
                onAnswerClicked={onAnswerClicked}
              />
            ))}
          </div>
        </div>
      </div>
      <div>
        <div>
          <LessonExerciseHint description={inquiryDescription} />
        </div>
      </div>
      {correctAnswerAudioUrl && (
        <LessonExerciseHiddenAudio ref={correctAnswerAudioRef} src={correctAnswerAudioUrl} />
      )}
    </div>
  );
};

export const StyledSingleSelectionExercise = styled(SingleSelectionExercise)`
  ${AnswersGridDefinition}
  ${baseExerciseStyles}
`;
