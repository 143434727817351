import gql from 'graphql-tag';
import { useCallback, useRef, useSyncExternalStore } from 'react';
import styled, { css } from 'styled-components';

import { memoizeWith } from 'ramda';
import { formatLg, getFormatIdForWidth } from '../../utils/imgUtils';

import { StyledLazyLoadDwPicture as LazyLoadDwPicture } from '../DwPicture/LazyLoadDwPicture.jsx';
import { landscapeFormatGroupName } from './pictureConfigs';

const fragmentName = 'ResponsiveDwPicture';
export const responsiveDwPictureFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Image {
        id
        staticUrl
      }
    `,
  ),
};

// @used in ROAD
export const ResponsiveDwPicture = ({
  image,
  alt,
  className,
  formatGroupName = landscapeFormatGroupName,
  aspectRatio,
  isA,
}) => {
  const ref = useRef();
  const lqFormatId = getFormatIdForWidth({ imgWidth: 0, formatGroupName });

  const subscribe = useCallback(callback => {
    const resizeObserver = new ResizeObserver(callback);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }
    return () => resizeObserver.disconnect();
  }, []);

  const hqFormatId = useSyncExternalStore(
    subscribe,
    () =>
      getFormatIdForWidth({
        imgWidth: ref?.current?.clientWidth || 0,
        formatGroupName,
      }),
    () => lqFormatId,
  );

  return (
    <LazyLoadDwPicture
      ref={ref}
      className={className}
      image={image}
      alt={alt}
      lqFormatId={lqFormatId}
      hqFormatId={hqFormatId}
      aspectRatio={aspectRatio}
      isA={isA}
    />
  );
};

// HINT: we try to avoid construct with "props" in styled components
// due to uncontrolled amount of CSS being generated when props change
// (easy to make a mistake which results in lots of CSS being generated).
// In this case however, we deal with a well defined, finite set of format configs,
// and we really need that additional CSS to avoid CLS on desktop.
export const StyledResponsiveDwPicture = styled(ResponsiveDwPicture)`
  ${({ formatGroupName = landscapeFormatGroupName }) =>
    css`
      padding-bottom: ${formatLg[formatGroupName].aspectRatio}%;
    `}
`;
