import styled from 'styled-components';
import { useTranslation } from '../../hooks/useTranslation';

export const A11yAriaLive = ({ translation, ...restProps }) => {
  const text = useTranslation(translation);
  return (
    <span aria-live="polite" {...restProps}>
      {text}
    </span>
  );
};

export const StyledA11yAriaLive = styled(A11yAriaLive)`
  position: absolute;
  left: -9999px;
`;
