import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useGlobalsContext } from '../../context/GlobalsContext';
import { DisableScrollingStyles } from './DisableScrollingStyles';

const focusableElementSelector =
  'a:not([disabled]), button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled]), *[tabindex]:not([disabled])';

export const ModalIntegration = ({ isOpen, children }) => {
  const childContainerRef = useRef();
  const { document } = useGlobalsContext();

  useEffect(() => {
    if (!isOpen || !childContainerRef.current) {
      return undefined;
    }

    const focusableElements = [...document.querySelectorAll(focusableElementSelector)].filter(
      el => !childContainerRef.current.contains(el),
    );

    const indices = focusableElements.map(el => el.getAttribute('tabindex'));

    focusableElements.forEach(el => {
      el.setAttribute('aria-disabled', 'true');
      el.setAttribute('tabindex', '-1');
    });

    document.activeElement.blur();

    return () => {
      focusableElements.forEach((el, i) => {
        el.removeAttribute('aria-disabled');

        const tabIndex = indices[i];
        if (tabIndex !== null) {
          el.setAttribute('tabindex', tabIndex);
        } else {
          el.removeAttribute('tabindex');
        }
      });
    };
  }, [isOpen, document]);

  if (!isOpen) return null;
  const componentToCreate = (
    <>
      <DisableScrollingStyles />
      <div ref={childContainerRef}>{children}</div>
    </>
  );
  return createPortal(componentToCreate, document.body);
};
