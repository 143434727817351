export const placeholderConfig = [
  { placeholder: '#p#', size: 'medium', maxLength: 15 },
  { placeholder: '#ps#', size: 'small', maxLength: 7 },
  { placeholder: '#pm#', size: 'medium', maxLength: 15 },
  { placeholder: '#pl#', size: 'full', maxLength: null },
];

export const cleanupAndAddPlaceholderComponents = answertext => {
  return placeholderConfig.reduce(
    (str, { placeholder, size }) =>
      str.replace(new RegExp(placeholder, 'g'), `<span class="placeholder-cloze">${size}</span>`),
    answertext,
  );
};
