import styled from 'styled-components';
import { StyledLogo as LogoIcon } from '../../assets/icons/DwLogo.jsx';
import { StyledClaimLogo as ClaimIcon } from '../../assets/icons/DwClaim.jsx';
import { mediaMin } from '../../utils/css';

// @used in ROAD
export const Logo = ({ className, url }) => {
  return (
    <a className={className} href={url} aria-label="Logo">
      <LogoIcon data-testid="dwDesktopLogo" />
      <ClaimIcon className="claim" data-testid="dwClaim" />
    </a>
  );
};

export const StyledLogo = styled(Logo)`
  display: flex;
  align-items: center;
  margin: 0 20px;
  direction: ltr;

  svg.claim {
    display: none;
  }

  ${mediaMin.xl`
    svg.claim {
      display: inline-block;
      margin: 0 17px;
    }
  `}
`;
