import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import {
  StyledLessonContentDescription as LessonContentDescription,
  lessonContentDescriptionFragment,
} from '../LessonContentDescription/LessonContentDescription.jsx';
import {
  MediaInputChooser,
  mediaInputChooserFragment,
} from '../MediaInputChooser/MediaInputChooser.jsx';
import { ImageOGMetaData } from '../../ImageOGMetaData/ImageOGMetaData.jsx';
import { knowledgesFragment } from '../LessonKnowledge/LessonKnowledge.jsx';
import { WithGraphQLQueryHandling } from '../../WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { metaDataFragment, PageMetaData } from '../../Page/PageMetaData.jsx';
import { PAGE_TYPES } from '../../../constants/pageTypes';
import { gtmDataLayerFragment, GtmScriptWithDataLayer } from '../../GoogleTagManager';
import { AudioProvider } from '../../../context/AudioContext.jsx';
import { useStructuralLessonData } from '../../../context/StructuralLessonDataContext.jsx';

const queryName = 'LessonInformation';
export const lessonInformationQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}($lessonId: Int!, $lang: Language!) {
        content(id: $lessonId, lang: $lang) {
          ... on Lesson {
            ...${mediaInputChooserFragment.name({ type: 'Lesson' })}
            ...${lessonContentDescriptionFragment.name}
            ...${knowledgesFragment.name({ type: 'Lesson' })}
            ...${metaDataFragment.name({ type: 'Lesson' })}
            ${gtmDataLayerFragment.partial()}
          }
        }
      }
      ${mediaInputChooserFragment.fragment({ type: 'Lesson' })}
      ${lessonContentDescriptionFragment.fragment()}
      ${knowledgesFragment.fragment({ type: 'Lesson' })}
      ${metaDataFragment.fragment({ type: 'Lesson' })}
    `,
  ),
};

export const LessonInformation = ({
  lessonLang,
  courseName,
  isContentDescriptionHintVisible = false,
  ogImage,
}) => {
  const { lessonId } = useParams();
  const { lessonPath } = useStructuralLessonData().structuralData;

  const lessonInformationQueryVariables = {
    lessonId: +lessonId,
    lang: lessonLang,
  };

  return (
    <WithGraphQLQueryHandling
      query={lessonInformationQuery.query()}
      queryVariables={lessonInformationQueryVariables}
    >
      {({ data }) => (
        <AudioProvider>
          <PageMetaData
            pageType={PAGE_TYPES.LESSON}
            content={data.content}
            parentContentName={courseName}
            useOnlyCmsKeywords={true}
            useTeaserAsDescription={true}
            relativeCanonicalUrl={lessonPath}
          />
          <ImageOGMetaData image={ogImage} />
          <GtmScriptWithDataLayer content={data.content} />
          <MediaInputChooser data={data} />
          <LessonContentDescription
            content={data.content}
            isHintHidden={!isContentDescriptionHintVisible}
          />
        </AudioProvider>
      )}
    </WithGraphQLQueryHandling>
  );
};
