import { getMediaFileSizeInKBorMB } from './commons';

const avcFullHd = 'AVC_1920x1080';
const avcHd = 'AVC_1280x720';
const avcQHd = 'AVC_960x540';
const avcSd360p = 'AVC_640x360';
const avcSd288p = 'AVC_512x288';
const podcastHd = 'PODCAST_HD';
const podcastSd = 'PODCAST_SD';

const hdSelectionOrder = [avcFullHd, avcHd];
const sdSelectionOrder = [avcSd360p, avcSd288p, avcQHd];

const findFirstMatchingVideo = (selectionOrderArray, videoResourceLinks) => {
  return selectionOrderArray.reduce((result, techFormat) => {
    if (!result) {
      const foundElement = videoResourceLinks.find(({ techFormat: t }) => t === techFormat);
      if (foundElement) {
        return foundElement;
      }
    }
    return result;
  }, null);
};

/**
 * @template {{ techFormat: string }} T
 * @param {T[]} videoResourceLinks
 */
export const selectVideoResourcesForDownload = videoResourceLinks => {
  const hdLink = findFirstMatchingVideo(hdSelectionOrder, videoResourceLinks);
  const sdLink = findFirstMatchingVideo(sdSelectionOrder, videoResourceLinks);

  const avcLinks = [hdLink, sdLink].filter(Boolean);

  if (avcLinks.length > 0) {
    return avcLinks;
  }

  return videoResourceLinks.filter(({ techFormat: t }) => podcastHd === t || podcastSd === t);
};

const resolutionFor = techFormat => {
  switch (techFormat) {
    case avcFullHd:
      return 'Full HD';
    case avcHd:
    case podcastHd:
      return 'HD';
    case podcastSd:
    case avcSd360p:
    case avcSd288p:
    case avcQHd:
      return 'SD';
    default:
      return undefined;
  }
};

/**
 * @param {object} param
 * @param {string} param.name
 * @param {string} param.format
 * @param {number | null} param.size
 * @param {string} [param.techFormat]
 */
export const getVideoLinkTitle = ({ name, format, size, techFormat }) => {
  const resolution = resolutionFor(techFormat);

  if (size === null) {
    return `${name} | ${format}${resolution ? ` | ${resolution}` : ''}`;
  }

  const byteSize = getMediaFileSizeInKBorMB(size);

  return `${name} | ${format} | ${byteSize}${resolution ? ` (${resolution})` : ''}`;
};
