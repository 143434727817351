import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { I18nText } from '../I18n/I18nText.jsx';
import { feedbackHelper } from '../../utils/url/feedbackHelpers';
import { getTargetPageUrl } from '../../utils/url/urlFactory';
import { COMMON_FEEDBACK_TRANSLATION_KEYS, FEEDBACK_TYPES } from '../../constants/feedback';
import { useLanguage } from '../../context/LanguageContext';
import { columnSize, mediaMin } from '../../utils/css';
import { DetailPageHeadline } from '../Headline/Headline.jsx';
import { LinkButton } from '../LinkButton/LinkButton.jsx';
import { DetailIntroText } from '../Text/Text.jsx';
import { logInUser } from '../../state/user/userDuck';
import { userIsLoggedIn, userLoading } from '../../state/user/userSelectors';

export const StatusFeedback = ({ className }) => {
  const { feedbackType } = useParams();
  const { langCode } = useLanguage();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector(userLoading);
  const isLoggedIn = useSelector(userIsLoggedIn);

  const feedbackContentKey = feedbackHelper.getFeedbackContentKey(feedbackType);
  const urlParams = new URLSearchParams(search);
  const targetPath = urlParams.get('target');
  const targetPageUrl = targetPath || getTargetPageUrl(feedbackType, langCode);

  useEffect(() => {
    if (feedbackType === FEEDBACK_TYPES.LOGIN && !isLoggedIn && !isLoading) {
      dispatch(logInUser());
    }
  }, [dispatch, feedbackType, isLoading, isLoggedIn]);

  return (
    <div className={className}>
      <I18nText isA={DetailPageHeadline} translation={COMMON_FEEDBACK_TRANSLATION_KEYS.TITLE} />
      <I18nText isA={DetailIntroText} translation={feedbackContentKey} />
      <LinkButton
        to={targetPageUrl}
        titleKey={COMMON_FEEDBACK_TRANSLATION_KEYS.OK_BUTTON}
        variant="primary"
        data-testid="statusFeedbackButton"
      />
    </div>
  );
};

export const StyledStatusFeedback = styled(StatusFeedback)`
  padding-top: 1px;
  padding-left: ${columnSize.c1};
  padding-right: ${columnSize.c1};

  ${mediaMin.lg`
    margin-left: ${columnSize.c6};
    width: ${columnSize.c16};
  `}

  ${LinkButton} {
    margin: 20px 0;
    width: 100%;
  }
`;
