import { importBarComponent, importVjsPlayButton } from './componentImports';
import { importVideoJs } from './videojs';
import { isSafari } from '../utils/commons';

const commonOptions = {
  bigPlayButton: false,
};

const videoOptions = {
  ...commonOptions,
  html5: {
    vhs: {
      overrideNative: !isSafari(),
    },
    nativeAudioTracks: false,
    nativeVideoTracks: false,
  },
};

const audioOptions = {
  ...commonOptions,
  controlBar: {
    pictureInPictureToggle: false,
    fullscreenToggle: false,
  },
  playbackRates: [1, 1.3, 1.5],
};

export const getOptions = videoNode => {
  if (videoNode.tagName === 'AUDIO') {
    return audioOptions;
  }
  if (videoNode.tagName === 'VIDEO') {
    return videoOptions;
  }
  return undefined;
};

export const seekButtonsInitialized = Symbol('seekInit');

const initAudio = player => {
  if (!player[seekButtonsInitialized]) {
    player.seekButtons({
      forward: 30,
      back: 10,
    });
    player[seekButtonsInitialized] = true; // eslint-disable-line no-param-reassign
  }
};

const initVideo = player => {
  if (typeof player.hlsQualitySelector === 'function') {
    player.hlsQualitySelector();
  }
};

export const initPluginsForPlayer = player => {
  if (player.isAudio()) {
    initAudio(player);
  } else {
    initVideo(player);
  }
};

const importAudioPlugins = async () => {
  const { register } = await import('./audioPlugins');
  register();
};

const importVideoPlugins = async () => {
  const { register } = await import('./videoPlugins');
  register();
};

/**
 * @param {Object} [optionalImports]
 * @param {boolean} [optionalImports.audioPlugins=false]
 * @param {boolean} [optionalImports.videoPlugins=false]
 */
export function importVideoJsScripts({ audioPlugins = false, videoPlugins = false } = {}) {
  return Promise.all([
    importVideoJs(),
    importBarComponent(),
    importVjsPlayButton(),
    audioPlugins && importAudioPlugins(),
    videoPlugins && importVideoPlugins(),
  ]).then(([{ videojs }, { durationBarComponentName }, { videoPlayButtonComponentName }]) => ({
    videojs,
    childcomponentNames: [durationBarComponentName, videoPlayButtonComponentName],
  }));
}
