import styled from 'styled-components';
import { StyledIconButton } from '../../StandardButton/IconButton.jsx';
import { StyledLeftArrow } from '../../../assets/icons/LeftArrow';
import { StyledRightArrow } from '../../../assets/icons/RightArrow';
import { mediaMin } from '../../../utils/css';
import { useTranslation } from '../../../hooks/useTranslation';
import { useLanguage } from '../../../context/LanguageContext';

export const SlideArrows = ({ className, onPrev, onNext, disablePrevArrow, disableNextArrow }) => {
  const { isRtl } = useLanguage();

  const prevArrowTitle = useTranslation('slideshow.previous_button.label');
  const nextArrowTitle = useTranslation('slideshow.next_button.label');

  return (
    <div className={className}>
      <StyledIconButton
        className="prev"
        onClick={onPrev}
        disabled={disablePrevArrow}
        title={prevArrowTitle}
      >
        {isRtl ? <StyledRightArrow /> : <StyledLeftArrow />}
      </StyledIconButton>
      <StyledIconButton
        className="next"
        onClick={onNext}
        disabled={disableNextArrow}
        title={nextArrowTitle}
      >
        {isRtl ? <StyledLeftArrow /> : <StyledRightArrow />}
      </StyledIconButton>
    </div>
  );
};

export const StyledSlideArrows = styled(SlideArrows)`
  --svg-width: 60px;
  --svg-height: 55px;

  .prev,
  .next {
    position: absolute;
    top: calc(50% - var(--svg-height));
    z-index: 3;
    filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.3));
    opacity: 1;

    transition-property: left, right, opacity, visibility;
    transition-duration: 800ms;
    transition-timing-function: cubic-bezier(0, 0, 0.09, 1);

    :disabled {
      transition-timing-function: cubic-bezier(0.91, 0, 1, 1);
      visibility: hidden;
      opacity: 0;
    }

    svg {
      width: var(--svg-width);
      height: var(--svg-height);
    }
  }

  .prev {
    left: 0;

    :disabled {
      left: calc(-1 * var(--svg-width));
    }
  }

  .next {
    right: 0;

    :disabled {
      right: calc(-1 * var(--svg-width));
    }
  }

  ${mediaMin.sm`
    --svg-width: 70px;
    --svg-height: 65px;
  `}

  ${mediaMin.lg`
    --svg-width: 80px;
    --svg-height: 70px;
  `}

  ${mediaMin.xl`
    --svg-height: 80px;
  `}
`;
