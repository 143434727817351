import styled from 'styled-components';
import { mediaMax } from '../../utils/css';
import { StyledStandardButton } from '../StandardButton/StandardButton.jsx';

export const SolveCheckButton = ({
  className,
  isSolveButtonDisabled,
  isCheckButtonDisabled,
  handleSolveClicked,
  handleCheckClicked,
}) => (
  <div className={`${className} check`}>
    <StyledStandardButton
      data-testid="solveButton"
      titleKey="exercise.resolve"
      disabled={isSolveButtonDisabled}
      onClick={handleSolveClicked}
      variant="tertiary"
    />
    <StyledStandardButton
      data-testid="checkButton"
      titleKey="exercise.check"
      disabled={isCheckButtonDisabled}
      onClick={handleCheckClicked}
      variant="primary"
    />
  </div>
);

export const StyledSolveCheckButton = styled(SolveCheckButton)`
  /* @noflip */
  direction: ltr;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  ${mediaMax.xs`
    flex-direction: column;
  `}

  > ${StyledStandardButton}:first-child {
    /* @noflip */
    margin: 0 20px 0 0;

    ${mediaMax.xs`
      margin: 0 0 20px 0;
    `}
  }
`;
