import styled from 'styled-components';
import { colors } from '../../utils/css';
import { useTranslation } from '../../hooks/useTranslation';

export const progressState = Object.freeze({
  EMPTY: 'empty',
  HALF: 'half',
  FULL: 'full',
});

const translationKeyPrefix = 'common.progress';

const getTitleTranslationKey = progress => {
  switch (progress) {
    case progressState.HALF:
      return `${translationKeyPrefix}.half`;
    case progressState.FULL:
      return `${translationKeyPrefix}.full`;
    case progressState.EMPTY:
    default:
      return `${translationKeyPrefix}.none`;
  }
};

const ProgressIcon = ({ className, progress }) => {
  const title = useTranslation(getTitleTranslationKey(progress));

  return (
    <div className={className} title={title}>
      <div className={progress} />
    </div>
  );
};

export const StyledProgressIcon = styled(ProgressIcon)`
  border: 2px solid ${colors.DW_GREY_07};
  width: 16px;
  height: 16px;
  border-radius: 50%;

  > div {
    height: 100%;
    background: ${colors.DW_GREY_07};
    width: 0;

    &.full {
      width: 100%;
      border-radius: 50%;
    }

    &.half {
      width: 50%;
      border-radius: 50% 0 0 50%;
    }
  }
`;
