import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { toLang } from '../utils/mappers/langMapper';
import { WithGraphQLQueryHandling } from '../components/WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { PageContainer } from '../components/Page/PageContainer.jsx';
import { GrammarOverview } from '../components/GrammarOverview/GrammarOverview.jsx';
import { PageMetaData } from '../components/Page/PageMetaData.jsx';
import { GTM_NO_CONTENT_PAGES, PAGE_TYPES } from '../constants/pageTypes';
import { GtmScriptWithDataLayer } from '../components/GoogleTagManager';
import { useLanguage } from '../context/LanguageContext';

const fragmentName = 'GrammarOverviewItems';
export const grammarOverviewPageFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on GrammarOverviewCategory {
        id
        headline
        grammars {
          id
          shortTitle
        }
      }
    `,
  ),
};

const queryName = 'GrammarOverviewPage';
export const grammarOverviewPageQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}($lang: Language!) {
        grammarOverview(lang: $lang) {
          verbs {
            ...${grammarOverviewPageFragment.name}
          }
          tenses {
            ...${grammarOverviewPageFragment.name}
          }
          nounsAndArticles {
            ...${grammarOverviewPageFragment.name}
          }
          declination {
            ...${grammarOverviewPageFragment.name}
          }
          negation {
            ...${grammarOverviewPageFragment.name}
          }
          pronoun {
            ...${grammarOverviewPageFragment.name}
          }
          prepositions {
            ...${grammarOverviewPageFragment.name}
          }
          adjectivesAndAdverbs {
            ...${grammarOverviewPageFragment.name}
          }
          sentenceStructure {
            ...${grammarOverviewPageFragment.name}
          }
          numbers {
            ...${grammarOverviewPageFragment.name}
          }
          spellingOrthography {
            ...${grammarOverviewPageFragment.name}
          }
          others {
            ...${grammarOverviewPageFragment.name}
          }
        }
      }
      ${grammarOverviewPageFragment.fragment()}
    `,
  ),
};

export const GrammarOverviewPage = () => {
  const { langCode } = useLanguage();

  const grammarPageQueryVariables = { lang: toLang(langCode) };

  return (
    <PageContainer>
      <WithGraphQLQueryHandling
        query={grammarOverviewPageQuery.query()}
        queryVariables={grammarPageQueryVariables}
      >
        {({ data }) => (
          <>
            <PageMetaData pageType={PAGE_TYPES.GRAMMAR_OVERVIEW} />
            <GtmScriptWithDataLayer noContentPageName={GTM_NO_CONTENT_PAGES.GRAMMAR_OVERVIEW} />
            <GrammarOverview grammarOverview={data.grammarOverview} />
          </>
        )}
      </WithGraphQLQueryHandling>
    </PageContainer>
  );
};
