import { css } from 'styled-components';
import { mediaMin } from '../../utils/css';

export const contentBlockStyles = css`
  padding-bottom: 20px;

  ${mediaMin.md`
    padding-bottom: 30px;
  `}

  ${mediaMin.xl`
    padding-bottom: 50px;
  `}
`;
