import styled from 'styled-components';
import { StyledRichTextWithKnowledge as RichTextWithKnowledge } from '../../../RichText/RichTextWithKnowledge.jsx';

export const LessonExerciseTextInput = ({ inputText, knowledges, className }) =>
  inputText && (
    <div className={className}>
      <RichTextWithKnowledge knowledges={knowledges} content={inputText} />
    </div>
  );

export const StyledLessonExerciseTextInput = styled(LessonExerciseTextInput)`
  margin: 0 0 60px 0;
`;
