import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import styled from 'styled-components';
import { DateTimeInfo } from '../Info/Info.jsx';

const defaultDeliveryType = 'DeliveryAspect';
const deliveryFragmentName = ({ type = defaultDeliveryType } = {}) =>
  `${type}PublicationDateDelivery`;
export const publicationDateDeliveryFragment = {
  name: deliveryFragmentName,
  fragment: memoizeWith(
    ({ type = defaultDeliveryType } = {}) => type,
    ({ type = defaultDeliveryType } = {}) => gql`
      fragment ${deliveryFragmentName({ type })} on ${type} {
        contentDateMode
        firstPublicationDate
        localizedFirstPublicationDate
        lastPublicationDate
        localizedLastPublicationDate
        freeDate
        localizedFreeDate
      }
    `,
  ),
};

const defaultModelType = 'ModelAspect';
const modelFragmentName = ({ type = defaultModelType } = {}) => `${type}PublicationDateModel`;
export const publicationDateModelFragment = {
  name: modelFragmentName,
  fragment: memoizeWith(
    ({ type = defaultModelType } = {}) => type,
    ({ type = defaultModelType } = {}) => gql`
      fragment ${modelFragmentName({ type })} on ${type} {
        creationDate
        localizedCreationDate
      }
    `,
  ),
};

/**
 * @typedef {{
 *   contentDateMode?:
 *     | 'CREATION_DATE'
 *     | 'LAST_PUBLICATION_DATE'
 *     | 'FREE_DATE'
 *     | 'NO_DATE'
 *     | 'FIRST_PUBLICATION_DATE'
 *     | 'FIRST_AND_LAST_PUBLICATION_DATE',
 *   firstPublicationDate?: string | null,
 *   localizedFirstPublicationDate?: string | null,
 *   lastPublicationDate?: string | null,
 *   localizedLastPublicationDate?: string | null,
 *   freeDate?: string | null,
 *   localizedFreeDate?: string | null,
 *   creationDate?: string | null,
 *   localizedCreationDate?: string | null,
 * }} PublicationDateConfig
 */

/**
 * @param {{
 *   className?: string,
 * } & PublicationDateConfig} content
 */
export const PublicationDate = ({
  className,
  contentDateMode,
  firstPublicationDate,
  localizedFirstPublicationDate,
  lastPublicationDate,
  localizedLastPublicationDate,
  freeDate,
  localizedFreeDate,
  creationDate,
  localizedCreationDate,
}) => {
  if (
    contentDateMode === 'FIRST_AND_LAST_PUBLICATION_DATE' &&
    firstPublicationDate &&
    lastPublicationDate &&
    firstPublicationDate !== lastPublicationDate
  ) {
    return (
      <div className={className}>
        <Time isoTime={firstPublicationDate} localizedTime={localizedFirstPublicationDate} />
        <DateTimeInfo as="time"> | </DateTimeInfo>
        <Time isoTime={lastPublicationDate} localizedTime={localizedLastPublicationDate} />
      </div>
    );
  }

  const selectedDate = (() => {
    switch (contentDateMode) {
      case 'CREATION_DATE':
        return { isoTime: creationDate, localizedTime: localizedCreationDate };
      case 'LAST_PUBLICATION_DATE':
        return { isoTime: lastPublicationDate, localizedTime: localizedLastPublicationDate };
      case 'FREE_DATE':
        return { isoTime: freeDate, localizedTime: localizedFreeDate };
      case 'FIRST_PUBLICATION_DATE':
        return { isoTime: firstPublicationDate, localizedTime: localizedFirstPublicationDate };
      case 'FIRST_AND_LAST_PUBLICATION_DATE':
        // Fallback if one is missing
        return firstPublicationDate
          ? { isoTime: firstPublicationDate, localizedTime: localizedFirstPublicationDate }
          : { isoTime: lastPublicationDate, localizedTime: localizedLastPublicationDate };
      default:
        return null;
    }
  })();

  if (selectedDate === null || !selectedDate.isoTime) {
    return null;
  }

  return (
    <div className={className}>
      <Time {...selectedDate} />
    </div>
  );
};

/**
 * @param {{ isoTime: string, localizedTime?: string | null }} props
 */
function Time({ isoTime, localizedTime }) {
  return (
    <DateTimeInfo as="time" dateTime={isoTime}>
      {localizedTime || isoTime}
    </DateTimeInfo>
  );
}

/**
 * @param {PublicationDateConfig} config
 * @returns {boolean}
 */
export function canDisplayPublicationDate({
  contentDateMode,
  creationDate,
  lastPublicationDate,
  freeDate,
  firstPublicationDate,
}) {
  switch (contentDateMode) {
    case 'CREATION_DATE':
      return Boolean(creationDate);
    case 'LAST_PUBLICATION_DATE':
      return Boolean(lastPublicationDate);
    case 'FREE_DATE':
      return Boolean(freeDate);
    case 'FIRST_PUBLICATION_DATE':
      return Boolean(firstPublicationDate);
    case 'FIRST_AND_LAST_PUBLICATION_DATE':
      return Boolean(firstPublicationDate) || Boolean(lastPublicationDate);
    default:
      return false;
  }
}

export const StyledPublicationDate = styled(PublicationDate)``;
