import styled, { css } from 'styled-components';
import { colors, fontSize } from '../../utils/css';

export const inputBoldBorder = css`
  box-shadow: 0 0 0 2px ${colors.DW_DARK_BLUE_NEW};
`;

export const inputBorderStyles = css`
  border: none;
  box-shadow: 0 0 0 1px ${colors.DW_GREY_11};
  &:hover {
    ${inputBoldBorder}
  }
  &:focus {
    outline: none;
  }
`;
export const BaseInput = styled('input')`
  color: ${colors.DARK_BLUE_GREY_01};
  ${fontSize.minus1}

  ${inputBorderStyles}
  &:active,
  &:focus {
    box-shadow: 0 2px 0 ${colors.DW_DARK_BLUE_NEW}, 0 0 0 1px ${colors.DW_GREY_11};
  }
  .error & {
    box-shadow: 0 2px 0 ${colors.BREAKING_RED}, 0 0 0 1px ${colors.DW_GREY_11};
  }
`;
