import { useCallback } from 'react';
import styled from 'styled-components';
import { StyledRichText as RichText } from './RichText.jsx';
import { replacePlaceholderMarkupWithKnowledges } from '../../utils/replacers/knowledgeReplacer.jsx';

/**
 * @param{{
 *   knowledges?: Parameters<typeof replacePlaceholderMarkupWithKnowledges>[0]["knowledges"],
 * } & React.ComponentProps<typeof RichText>}props
 */
export const RichTextWithKnowledge = ({ knowledges, additionalReplacements, ...richTextProps }) => {
  const insertKnowledges = useCallback(
    node => {
      const knowledgePortals = knowledges
        ? replacePlaceholderMarkupWithKnowledges({ node, param: { knowledges } })
        : undefined;

      const additionalPortals = additionalReplacements?.(node);

      return [...(knowledgePortals ?? []), ...(additionalPortals ?? [])];
    },
    [knowledges, additionalReplacements],
  );

  return <RichText additionalReplacements={insertKnowledges} {...richTextProps} />;
};

export const StyledRichTextWithKnowledge = styled(RichTextWithKnowledge)``;
