import { StyledLessonExtrasLink as LessonExtrasLink } from './LessonExtrasLink.jsx';

/**
 * @param {{
 *   data: {
 *     id: number,
 *     name: string | null,
 *     description: string | null,
 *     url: string,
 *   }[]
 * }}
 */
export const ExternalLinks = ({ data }) => {
  return data.map(({ id, name, description, url }) => (
    <LessonExtrasLink key={id} href={url} content={name} description={description} />
  ));
};
