import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { toLang } from '../utils/mappers/langMapper';
import { PageContainer } from '../components/Page/PageContainer.jsx';
import { WithGraphQLQueryHandling } from '../components/WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { StyledContentDetail as ContentDetail } from '../components/ContentDetail/ContentDetail.jsx';
import { mainContentAreaFragment } from '../components/ContentDetailAreas/MainContentArea.jsx';
import { recommendedAreaFragment } from '../components/ContentDetailAreas/RecommendedArea.jsx';
import { metaDataFragment, PageMetaData } from '../components/Page/PageMetaData.jsx';
import {
  ImageOGMetaData,
  imageOGMetaDataFragment,
} from '../components/ImageOGMetaData/ImageOGMetaData.jsx';
import { gtmDataLayerFragment, GtmScriptWithDataLayer } from '../components/GoogleTagManager';
import {
  ConditionalSubdomainRedirect,
  conditionalSubdomainRedirectFragment,
} from '../components/Redirect/ConditionalSubdomainRedirect.jsx';

const queryName = ({ type }) => `${type}contentPage`;
export const contentDetailPageQuery = {
  name: queryName,
  query: memoizeWith(
    ({ type }) => type,
    ({ type }) => gql`
      query ${queryName({ type })}($id: Int!, $lang: Language!) {
        content(id: $id, lang: $lang) {
          ... on ModelAspect {
            id
          }
          ... on TextualAspect {
            text
          }
          ... on UrlAspect {
            namedUrl
          }
          ...${conditionalSubdomainRedirectFragment.name({ type })}
          ...${mainContentAreaFragment.name({ type })}
          ...${recommendedAreaFragment.name({ type })}
          ...${metaDataFragment.name({ type })}
          ...${imageOGMetaDataFragment.name({ type })}
          ${gtmDataLayerFragment.partial()}
        }
      }
      ${conditionalSubdomainRedirectFragment.fragment({ type })}
      ${mainContentAreaFragment.fragment({ type })}
      ${recommendedAreaFragment.fragment({ type })}
      ${metaDataFragment.fragment({ type })}
      ${imageOGMetaDataFragment.fragment({ type })}
    `,
  ),
};

export const ContentDetailPage = ({ contentId, langCode, type }) => {
  const contentDetailPageQueryVariables = {
    id: +contentId,
    lang: toLang(langCode),
  };

  return (
    <PageContainer>
      <WithGraphQLQueryHandling
        query={contentDetailPageQuery.query({ type })}
        queryVariables={contentDetailPageQueryVariables}
      >
        {({ data }) => (
          <>
            <ConditionalSubdomainRedirect {...data.content} />
            <PageMetaData
              pageType={type.toUpperCase()}
              content={data.content}
              useOnlyCmsKeywords={true}
              relativeCanonicalUrl={data.content.namedUrl}
            />
            <ImageOGMetaData image={data.content.mainContentImage} />
            <GtmScriptWithDataLayer content={data.content} />
            <ContentDetail {...{ data }} />
          </>
        )}
      </WithGraphQLQueryHandling>
    </PageContainer>
  );
};
