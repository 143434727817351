import styled from 'styled-components';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import {
  StyledContentDetailActions as ContentDetailActions,
  contentDetailActionsFragment,
} from '../ContentDetailActions/ContentDetailActions.jsx';
import { CONTENT_COMPOSITION_TYPE } from '../../constants/contentCompositionTypes';
import {
  publicationDateDeliveryFragment,
  publicationDateModelFragment,
  StyledPublicationDate as PublicationDate,
} from '../PublicationDate/PublicationDate.jsx';
import { DetailPageHeadline } from '../Headline/Headline.jsx';
import { KickerInfo } from '../Info/Info.jsx';
import { Link } from '../Link/Link.jsx';
import { DetailIntroText } from '../Text/Text.jsx';

const fragmentName = ({ type }) => `${type}ContentDetailHeader`;
export const contentDetailHeaderFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    ({ type }) => type,
    ({ type }) => gql`
      fragment ${fragmentName({ type })} on ${type} {
        name 
        shortTitle
        teaser
        ...on AssociationsAspect {
          navigations {
            id
            name
            namedUrl
            templateType
          }
        }
        ...${publicationDateDeliveryFragment.name()}
        ...${publicationDateModelFragment.name()}
        ...${contentDetailActionsFragment.name()}
      }
      ${contentDetailActionsFragment.fragment()}
      ${publicationDateDeliveryFragment.fragment()}
      ${publicationDateModelFragment.fragment()}
    `,
  ),
};

export const ContentDetailHeader = ({ className, content }) => {
  const { name, shortTitle, teaser, navigations = [] } = content || {};
  const { name: navigationName, namedUrl, templateType } = navigations[0] || {};
  const headline = shortTitle || name;
  const isNavigationLinkVisible =
    navigationName && templateType === CONTENT_COMPOSITION_TYPE.LEARNGERMAN_STANDARD;

  return (
    <header className={className}>
      {isNavigationLinkVisible && (
        <KickerInfo as={Link} to={namedUrl}>
          {navigationName}
        </KickerInfo>
      )}
      <DetailPageHeadline>{headline}</DetailPageHeadline>
      <PublicationDate {...content} />
      <DetailIntroText>{teaser}</DetailIntroText>
      <ContentDetailActions content={content} />
    </header>
  );
};

export const StyledContentDetailHeader = styled(ContentDetailHeader)`
  > ${KickerInfo} {
    display: inline-block;
    margin-top: 1rem;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }

  > ${DetailPageHeadline} {
    margin: 0.5rem 0;
  }

  > ${DetailIntroText} {
    margin: 1rem 0;
  }

  > ${ContentDetailActions} {
    margin-bottom: 1.5rem;
  }
`;
