import simpleMerger from 'redux-storage-merger-simple';

import { LOAD } from './constants';

const reducerFunc = (reducer, merger = simpleMerger) => {
  return (state, action) =>
    reducer(action.type === LOAD ? merger(state, action.payload) : state, action);
};

export default reducerFunc;
