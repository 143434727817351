import styled from 'styled-components';
import { useChineseSimpleTraditionalSwitch } from './useChineseSimpleTraditionalSwitch';
import { columnSize, mediaMin } from '../../utils/css';
import { useNavigationContext } from './NavigationContainer/NavigationContext.jsx';
import { ButtonInteraction } from '../Interaction/Interaction.jsx';

// @VisibleForTesting
export const toggleButtonId = 'zh-convert';

export const ChineseVariantSwitch = ({ className }) => {
  const { isTraditionalChin } = useNavigationContext();
  const { trans2Simple, trans2Traditional } = useChineseSimpleTraditionalSwitch(toggleButtonId);

  const transFunction = isTraditionalChin ? trans2Simple : trans2Traditional;

  return <ButtonInteraction id={toggleButtonId} className={className} onClick={transFunction} />;
};

const buttonPadding = '10px'; // enlarges clickable area

export const StyledChineseVariantSwitch = styled(ChineseVariantSwitch)`
  background-color: transparent;
  padding: ${buttonPadding};
  position: fixed;
  /*! @noflip */
  right: calc(${columnSize.c3} - ${buttonPadding});
  z-index: 10000;
  border: none;
  cursor: pointer;
  transform: translateY(-50%);
  top: 30px;

  ${mediaMin.md`
    top: 40px;
    right: calc(${columnSize.c2} - ${buttonPadding});
  `}

  ${mediaMin.xl`
    position: absolute;
    top: 50%;
  `}
`;
