/* eslint-disable jsx-a11y/media-has-caption */
import { useRef } from 'react';
import styled from 'styled-components';
import { useVideoPlayer } from './useVideoPlayer';
import { useMediaTracking } from '../../GoogleTagManager/hooks/useMediaTracking';
import { Spinner } from '../../Spinner.jsx';
import { StyledPlaybackLazyPosterContainer as PlaybackLazyPosterContainer } from '../PosterImage/PlaybackLazyPosterContainer.jsx';

/**
 * @used in ROAD
 * @param {object} props
 * @param {string} [props.className]
 * @param {Parameters<typeof useVideoPlayer>[0]["video"]} props.video
 * @param {boolean} [props.noTracking]
 */
export const VideoPlayer = ({ className, video, noTracking }) => {
  /** @type {React.MutableRefObject<HTMLVideoElement} */
  const videoRef = useRef();

  useVideoPlayer({ videoRef, video, noTracking });
  useMediaTracking(video);

  return (
    <PlaybackLazyPosterContainer
      className={className}
      posterImageUrl={video.posterImageUrl}
      key={video.id}
    >
      <video
        id={`video-${video.id}`}
        ref={videoRef}
        className="video-js vjs-fluid"
        preload="none"
        controls
        crossOrigin="anonymous"
        playsInline
      >
        <p className="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a web browser that{' '}
          <a
            href="https://videojs.com/html5-video-support/"
            rel="noopener noreferrer"
            target="_blank"
          >
            supports HTML5 video
          </a>
        </p>
      </video>
      <Spinner />
    </PlaybackLazyPosterContainer>
  );
};

export const StyledVideoPlayer = styled(VideoPlayer)`
  ${Spinner} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .rendered ~ ${Spinner} {
    display: none;
  }

  .video-js {
    position: absolute;
    top: 0;
    left: 0;

    &:not(.rendered) {
      display: none;
    }
  }
`;
