export function importBarComponent() {
  return import(
    /* webpackChunkName: 'player-custom' */ '../components/MediaPlayer/Video/CustomPlayerComponents/InfoBar/VjsDurationInfoBar.jsx'
  );
}

export function importVjsPlayButton() {
  return import(
    /* webpackChunkName: 'player-custom' */ '../components/MediaPlayer/Video/CustomPlayerComponents/PlayButton/VjsPlayButton.jsx'
  );
}
