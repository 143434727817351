export const getXCoordinate = e =>
  e.changedTouches !== undefined ? e.changedTouches[0].clientX : e.pageX;

export const getXMouseMovement = (e, xStart) => getXCoordinate(e) - xStart;

export const setTranslateX = ({ target, x }) => {
  Object.assign(target, {
    transform: `translateX(${x}px)`,
    transition: 'none',
    willChange: 'transform',
  });
};

export const transition = 'all ease-in-out 400ms';

export const detachTranslateX = ({ target, originalX }) => {
  Object.assign(target, {
    transform: originalX,
    transition,
    willChange: null,
  });
};

export const getSliderPositionStyles = ({ childrenLength, currentIndex, isRtl = false }) => {
  if (childrenLength === 0) {
    console.warn(`Division by zero! ${childrenLength}`);
    return {};
  }
  const directionIndicator = isRtl ? 1 : -1;
  return {
    width: `${childrenLength * 100}%`,
    transform: `translateX(${(currentIndex * 100 * directionIndicator) / childrenLength}%)`,
  };
};
