import styled from 'styled-components';
import { StyledContentNavTitle } from './ContentNavTitle/ContentNavTitle.jsx';
import { colors, columnSize } from '../../utils/css';
import { gridSpacingStyles } from '../Lesson/LessonExercise/utils/exerciseCustomCSS';

export const ContentContainer = ({ className, title, children }) => (
  <details open className={className}>
    <summary>
      <StyledContentNavTitle title={title} data-testid="containerTitleTop" />
    </summary>
    <div className="container">{children}</div>
    <StyledContentNavTitle title={title} data-testid="containerTitleBottom" />
  </details>
);

export const StyledContentContainer = styled(ContentContainer)`
  max-width: 100vw;
  background-color: ${colors.LG_WHITE};

  > summary {
    list-style: none;
    pointer-events: none;
  }

  > summary::-webkit-details-marker {
    display: none;
  }

  .container {
    width: auto;
    ${gridSpacingStyles({ spacing: columnSize.c1 })}
  }
`;
