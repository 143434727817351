import { useRef } from 'react';
import styled from 'styled-components';
import { colors, resolutions, mediaMax, mediaMin } from '../../../utils/css';
import { StyledNavMenu as NavMenu } from './NavMenu/NavMenu.jsx';
import { StyledBurgerButton as BurgerButton } from '../BurgerButton/BurgerButton.jsx';
import { StyledOnlyDesktopBrand as OnlyDesktopBrand } from './OnlyDesktopBrand.jsx';
import { LANG_CODES } from '../../../utils/mappers/langMapper';
import { StyledChineseVariantSwitch as ChineseVariantSwitch } from '../ChineseVariantSwitch.jsx';
import { NavigationContextProvider, useNavigationContext } from './NavigationContext.jsx';
import { useOnFocusout } from '../../../hooks/useOnFocusout';
import { useLanguage } from '../../../context/LanguageContext';
import { keyCodes } from '../../../constants/keyCodes';

const NavigationContextWrapper = props => (
  <NavigationContextProvider>
    <NavigationContainer {...props} />
  </NavigationContextProvider>
);

const NavigationContainer = ({ className, title }) => {
  const { isNavMenuOpen, closeMenu } = useNavigationContext();
  const { langCode } = useLanguage();

  /** @type {React.MutableRefObject<HTMLElement | undefined>} */
  const navRef = useRef();
  useOnFocusout(navRef, closeMenu);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <nav
      className={className}
      role="navigation"
      aria-label="Main navigation"
      ref={navRef}
      tabIndex="-1"
      onKeyDown={e => {
        if (e.key === keyCodes.ESCAPE) {
          closeMenu();
        }
      }}
    >
      <OnlyDesktopBrand langCode={langCode} title={title} />
      {langCode === LANG_CODES.CHINESE && <ChineseVariantSwitch />}
      <BurgerButton />
      {isNavMenuOpen && (
        <div id="navbar" className="navigation-container">
          <NavMenu />
        </div>
      )}
    </nav>
  );
};

export const StyledNavigationContainer = styled(NavigationContextWrapper)`
  position: relative;

  ${mediaMax.sm`
    margin-bottom: 0px;
  `}

  @media (min-width: ${resolutions.min.md}px) and (max-width: ${resolutions.max.lg}px) {
    margin-bottom: -7px;
  }

  ${mediaMin.xl`
    background-color: ${colors.DW_LIGHT_BLUE_NEW};
    height: 85px;
    left: 0px;
    margin-left: 0px;
    margin-right: 0px;
    position: relative;
    right: inherit;
    top: inherit;
  `}

  .navigation-container {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 ${colors.LG_TRANSPARENT_WHITE_10};
    overflow-x: visible;
    padding: 0px;
    -webkit-overflow-scrolling: touch;

    ${mediaMax.sm`
      position: fixed;
      z-index: 3;
      padding: 60px 0 0 0;
    `}

    ${mediaMax.lg`{
      position: fixed;
      z-index: 4;
      border: none;
    `}
  }
`;
