/* eslint-disable jsx-a11y/media-has-caption, react/no-unknown-property */
import { useRef } from 'react';
import styled from 'styled-components';
import { I18nText } from '../../I18n/I18nText.jsx';
import { useAudioPlayer } from './useAudioPlayer';
import { Spinner } from '../../Spinner.jsx';
import { useMediaTracking } from '../../GoogleTagManager/hooks/useMediaTracking';
import { StyledPlaybackLazyPosterContainer as PlaybackLazyPosterContainer } from '../PosterImage/PlaybackLazyPosterContainer.jsx';

// @used in ROAD
export const AudioPlayer = ({ className, audio, fallbackImageStaticUrl }) => {
  const { id, contentDate, mp3Src } = audio;
  const audioRef = useRef();
  useAudioPlayer({ audioRef, audio });
  useMediaTracking(audio);

  return (
    <PlaybackLazyPosterContainer
      className={className}
      posterImageUrl={audio.posterImageUrl}
      fallbackImageStaticUrl={fallbackImageStaticUrl}
      key={audio.id}
    >
      <audio
        ref={audioRef}
        controls
        id={`audio-${id}`}
        className="video-js vjs-fluid"
        content-date={contentDate}
      >
        <source src={mp3Src} />
        <I18nText translation="common.avplayer.playerNotSupported" />
      </audio>
      <Spinner />
    </PlaybackLazyPosterContainer>
  );
};

export const StyledAudioPlayer = styled(AudioPlayer)`
  ${Spinner} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .rendered ~ ${Spinner} {
    display: none;
  }

  .video-js {
    position: absolute;
    top: 0;
    left: 0;

    &:not(.rendered) {
      display: none;
    }
  }

  .vjs-icon-placeholder {
    font-family: VideoJS;
  }
`;
