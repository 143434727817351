import { useEffect } from 'react';
import { useGlobalsContext } from '../../context/GlobalsContext';

export const RedirectWrapper = ({ url, children }) => {
  const { window } = useGlobalsContext();

  useEffect(() => {
    if (url) {
      window.location?.replace?.(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return children;
};
