import styled from 'styled-components';
import { StyledTextOverlay as TextOverlay } from './TextOverlay.jsx';
import { StyledResponsiveSlideImage as SlideResponsiveImage } from './SlideResponsiveImage.jsx';

export const CoverImage = ({ title, teaser, galleryImage, className, ...restProps }) => (
  <div className={`${className} cover`} {...restProps}>
    <TextOverlay className="text-overlay" title={title} teaser={teaser} />
    <SlideResponsiveImage galleryImage={galleryImage} preload />
  </div>
);

export const StyledCoverImage = styled(CoverImage)``;
