import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { toLang } from '../utils/mappers/langMapper';
import { PageContainer } from '../components/Page/PageContainer.jsx';
import { WithGraphQLQueryHandling } from '../components/WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { helpFragment, StyledHelp as Help } from '../components/Help/Help.jsx';
import { PageMetaData } from '../components/Page/PageMetaData.jsx';
import { PAGE_TYPES } from '../constants/pageTypes';
import { gtmDataLayerFragment, GtmScriptWithDataLayer } from '../components/GoogleTagManager';
import { useLanguage } from '../context/LanguageContext';

const queryName = 'HelpPage';
export const helpPageQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}($lang: Language!) {
        help(lang: $lang) {
          ...${helpFragment.name}
          ${gtmDataLayerFragment.partial()}
        }
      }
      ${helpFragment.fragment()}
    `,
  ),
};

export const HelpPage = () => {
  const { langCode } = useLanguage();

  const helpPageQueryVariables = {
    lang: toLang(langCode),
  };

  return (
    <PageContainer>
      <WithGraphQLQueryHandling
        query={helpPageQuery.query()}
        queryVariables={helpPageQueryVariables}
      >
        {({ data: { help } }) => (
          <>
            <PageMetaData pageType={PAGE_TYPES.HELP} useDescriptionTranslation={true} />
            <GtmScriptWithDataLayer content={help} />
            <Help help={help} />
          </>
        )}
      </WithGraphQLQueryHandling>
    </PageContainer>
  );
};
