import { StyledKnowledge as Knowledge } from '../../components/Modal/KnowledgeModal/Knowledge.jsx';
import { replaceWithComponent } from './replaceCommons';

export const placeholderToKnowledgeComponent = (matchedElement, { knowledges, isRtl }) => {
  const knowledge = knowledges.find(
    element => element.id === parseInt(matchedElement.dataset.id, 10),
  );

  if (knowledge === undefined) {
    return matchedElement.textContent;
  }

  return (
    <Knowledge knowledge={knowledge} isRtl={isRtl}>
      {matchedElement.textContent}
    </Knowledge>
  );
};

export const replacePlaceholderMarkupWithKnowledges = ({ node, param }) => {
  return replaceWithComponent({
    selector: '.placeholder.editable:not(.render-container)',
    node,
    componentFn: placeholderToKnowledgeComponent,
    param,
  }).portals;
};
