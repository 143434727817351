import styled from 'styled-components';
import { useState } from 'react';
import classnames from 'classnames';
import { useInquiryHandling } from '../workflow/useInquiryHandling';
import { KeyboardWrapper } from '../Keyboard/KeyboardWrapper.jsx';
import { StyledVerticalTextArea } from '../../../Input/TextAreaInput/TextAreaInput.jsx';
import { StyledLessonExerciseHint as LessonExerciseHint } from '../LessonExerciseHint/LessonExerciseHint.jsx';
import { StyledLessonExerciseQuestionAudio as LessonExerciseQuestionAudio } from '../LessonExerciseQuestion/QuestionTypes/LessonExerciseQuestionAudio.jsx';
import { StyledSolveCheckButton as SolveCheckButton } from '../../../SolveCheckButton/SolveCheckButton.jsx';
import { findQuestionAudio } from '../../../../utils/audioUtils';
import { useAudio } from '../../../../hooks/useAudio';
import { isNormalizedTextEqual } from '../utils/exerciseUtils';
import { exerciseBasicGrid, joinQuestionStyles } from '../utils/exerciseCustomCSS';
import { INQUIRY_STATE } from '../workflow/inquiryState';
import { useTranslation } from '../../../../hooks/useTranslation';
import { colors } from '../../../../utils/css';

export const DictationExercise = ({ className, inquiry }) => {
  const [input, setInput] = useState('');

  const {
    inquiryDescription,
    audios,
    text: solutionText,
    learningMetaInfo: { achievableScore },
    id: inquiryId,
  } = inquiry;
  const questionAudio = findQuestionAudio(audios);

  const {
    isSolveButtonDisabled,
    isCheckButtonDisabled,
    inquiryState,
    handleCorrectAnswer,
    handleWrongAnswer,
    handleSolved,
    isDisabled,
  } = useInquiryHandling({ inquiryId, achievableScore });
  const { playWrongDefaultAudioFeedBack, playCorrectDefaultAudioFeedBack } = useAudio();

  const onSolveClicked = () => {
    setInput(solutionText);
    handleSolved();
  };
  const onCheckClicked = () => {
    if (isNormalizedTextEqual(solutionText, input)) {
      handleCorrectAnswer();
      playCorrectDefaultAudioFeedBack();
    } else {
      handleWrongAnswer();
      playWrongDefaultAudioFeedBack();
    }
  };

  const onChange = event => {
    const newInput = event.target.value;
    setInput(newInput);
  };
  const textareaPlaceholder = useTranslation('exercise.typeHere');

  return (
    <div className={className} aria-atomic="true">
      <div className="question">
        <LessonExerciseQuestionAudio audio={questionAudio} />
      </div>
      <div className="answer-input">
        <StyledVerticalTextArea
          className={classnames({
            wrong: inquiryState === INQUIRY_STATE.WRONG,
            correct: inquiryState === INQUIRY_STATE.CORRECT,
          })}
          placeholder={textareaPlaceholder}
          value={input}
          onChange={onChange}
          disabled={isDisabled}
          aria-haspopup="true"
        />
        {!isDisabled && (
          <KeyboardWrapper onChange={setInput} inputValue={input} isTextArea={true} />
        )}
      </div>
      <LessonExerciseHint className="hint" description={inquiryDescription} />
      <SolveCheckButton
        handleCheckClicked={() => onCheckClicked()}
        handleSolveClicked={() => onSolveClicked()}
        isSolveButtonDisabled={isSolveButtonDisabled}
        isCheckButtonDisabled={isCheckButtonDisabled}
      />
    </div>
  );
};

export const StyledDictationExercise = styled(DictationExercise)`
  ${exerciseBasicGrid}
  ${joinQuestionStyles}

  ${StyledVerticalTextArea}.correct {
    border-color: ${colors.ACCENT_GREEN};
  }

  ${StyledVerticalTextArea}.wrong {
    border-color: ${colors.ACCENT_RED};
  }

  > ${LessonExerciseQuestionAudio} {
    margin-bottom: 30px;
  }
`;
