import { useReducer, useRef } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useQuery } from '@apollo/client';
import useDebounce from '../../../hooks/useDebounce';
import {
  reducer,
  TYPE_ANY_CHARACTER_TYPED,
  TYPE_KEY_BACKSPACE_CLICKED,
  TYPE_KEY_ENTER_CLICKED,
  TYPE_KEY_ESCAPE_CLICKED,
  TYPE_SET_CURSOR_ARROW_DOWN,
  TYPE_SET_CURSOR_ARROW_UP,
  TYPE_SET_HINTS_CLOSED,
  TYPE_SET_HINTS_OPENED,
  TYPE_SET_INPUT,
  TYPE_ITEM_CLICKED,
} from './duck/vocabularySearchDuck';
import {
  useGetCachedHintsResponse,
  useGetHintsResponseAndCacheIt,
} from '../../../hooks/vocabularySearchHooks';
import { useTranslation } from '../../../hooks/useTranslation';
import { getQueryVariables, vocabularySearchQuery } from '../VocabularyQuery.jsx';
import { StyledRichText as RichText } from '../../RichText/RichText.jsx';
import { useLanguage } from '../../../context/LanguageContext';
import { colors, mediaMin } from '../../../utils/css';
import magnifierIcon from '../../../assets/svg/icon-lupe.svg';
import { BaseInput } from '../../FormElements/BaseInput.jsx';
import { keyCodes } from '../../../constants/keyCodes';

const specialCharactersRegex = /[^\p{L} @]/giu;

const VocabularySearch = ({ className, onItemSelected }) => {
  const { langCode } = useLanguage();
  const { refetch } = useQuery(vocabularySearchQuery.query(), {
    variables: getQueryVariables({
      langCode,
    }),
  });
  const [state, dispatch] = useReducer(reducer, {
    input: '',
    searchQuery: '',
    vocabularyItems: [],
    cursor: -1,
    isOpened: false,
    recentlySearched: new Map(),
    isEscapeClicked: false,
    isEnterClicked: false,
    amount: 6,
    offset: 0,
  });

  const vocabularyOverviewPlaceholder = useTranslation('vocabularyOverview.placeholder');
  const vocabularyOverviewAria = useTranslation('vocabularyOverview.aria');

  const { isOpened, input, cursor, vocabularyItems, searchQuery } = state;

  const debouncedSearchQuery = useDebounce(searchQuery, 350);

  useGetCachedHintsResponse(state, dispatch);
  useGetHintsResponseAndCacheIt(debouncedSearchQuery, state, dispatch, refetch);

  const searchInput = useRef(null);

  const handleFocus = () => {
    dispatch({ type: TYPE_SET_HINTS_OPENED });
  };

  const handleBlur = () => {
    dispatch({ type: TYPE_SET_HINTS_CLOSED });
  };

  const handleClick = e => {
    const target = e.target.tagName === 'STRONG' ? e.target.parentNode : e.target;
    const { textContent } = target;
    dispatch({
      type: TYPE_ITEM_CLICKED,
      payload: {
        input: textContent,
      },
    });
    onItemSelected(textContent);
  };

  const handleKeys = e => {
    switch (e.key) {
      case keyCodes.ARROW_UP:
        dispatch({
          type: TYPE_SET_CURSOR_ARROW_UP,
          payload: {
            input: distinctSuggestions,
          },
        });
        break;

      case keyCodes.ARROW_DOWN:
        dispatch({
          type: TYPE_SET_CURSOR_ARROW_DOWN,
          payload: {
            input: distinctSuggestions,
          },
        });
        break;

      case keyCodes.ENTER:
      case keyCodes.NUMPAD_ENTER: {
        const conditionalSearchQuery = input;
        onItemSelected(conditionalSearchQuery);
        dispatch({
          type: TYPE_KEY_ENTER_CLICKED,
          payload: {
            searchQuery: conditionalSearchQuery,
          },
        });
        break;
      }
      case keyCodes.ESCAPE:
        dispatch({ type: TYPE_KEY_ESCAPE_CLICKED });
        break;

      case keyCodes.BACKSPACE:
        dispatch({
          type: TYPE_KEY_BACKSPACE_CLICKED,
          payload: {
            input: e.target.value,
            searchQuery: e.target.value,
          },
        });
        break;

      default:
        dispatch({
          type: TYPE_ANY_CHARACTER_TYPED,
          payload: {
            input: e.target.value,
            searchQuery: e.target.value,
          },
        });
    }
  };

  const cleanSearchQuery = searchQuery.replace(specialCharactersRegex, '');
  const wordsRegex = new RegExp(
    `((?:(?:\\p{P}|\\p{Z} )*)((\\p{L}*)${cleanSearchQuery}(\\p{L}*))(?:(?:\\p{P}|\\p{Z} )*))`,
    'imu',
  );

  const suggestions = vocabularyItems
    .map(item => wordsRegex.exec(item)?.[2])
    .filter((item, index, arr) => item && arr.indexOf(item) === index);

  const distinctSuggestions = suggestions.slice(0, Math.min(8, suggestions.length));

  const boldSuggestions = distinctSuggestions.map(item =>
    item.replace(new RegExp(cleanSearchQuery, 'gi'), m => `<strong>${m}</strong>`),
  );

  return (
    <div className={className} role="search">
      <BaseInput
        id="search-vocabulary"
        placeholder={vocabularyOverviewPlaceholder}
        aria-labelledby={vocabularyOverviewAria}
        autoComplete="off"
        dir="auto"
        value={input}
        onChange={e =>
          dispatch({
            type: TYPE_SET_INPUT,
            payload: { input: e.target.value },
          })
        }
        onKeyUp={handleKeys}
        onBlur={handleBlur}
        onFocus={handleFocus}
        ref={searchInput}
      />
      {isOpened && (
        <div>
          {boldSuggestions.map((suggestion, index) => (
            <RichText
              isA="div"
              id={index}
              className={classnames({ highlighted: cursor === index })}
              onMouseDown={handleClick}
              key={index}
              content={suggestion}
              noContainer
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const StyledVocabularySearch = styled(VocabularySearch)`
  position: relative;
  margin: 3rem 0;

  input {
    /* @noflip */
    background-position: 97% 0.5rem;
    background-image: url(${magnifierIcon});
    background-repeat: no-repeat;
    /* @noflip */
    padding-left: 0.5rem;

    box-sizing: border-box;
    -webkit-appearance: none;

    width: 100%;
    height: 46px;
    color: ${colors.BLUE_GREY_03};
    font-style: italic;

    ${mediaMin.lg`
      width: 50%;
      margin: 0 25%;
  `}
  }

  > div {
    position: absolute;
    top: auto;
    width: auto;
    background-color: ${colors.LG_WHITE};
    border-left: 1px solid ${colors.BLUE_GREY_04};
    border-right: 1px solid ${colors.BLUE_GREY_04};
    z-index: 1001;
    ${mediaMin.lg`
      margin: 0 25%;
  `}
  }

  > div > ${RichText} {
    display: block;
    width: 100%;
    margin-bottom: 0;
    padding: 10px 20px;
    text-decoration: none;
    border-bottom: 0;
    box-shadow: 0 1px 0 0 ${colors.DW_GREY_07};

    :hover,
    &.highlighted {
      border-color: ${colors.DW_DARK_BLUE_NEW};
      box-shadow: 0 0 0 2px ${colors.DW_DARK_BLUE_NEW};
      border-bottom: 0;
    }
  }
`;
