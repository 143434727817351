import { isServer } from '../ssr';
import globals from '../globals';
import config from '../../config';
import { toDwLangCode } from '../mappers/dwLangCodeConverter';

const typeToUrlPrefixMap = {
  COURSE: 'c',
  GRAMMAR: 'gr',
  LESSON: 'l',
  PLACEMENT: 'placement',
  FINAL: 'final',
  NAVIGATION: 's',
  ARTICLE: 'a',
};

export const typeToUrlPrefix = type => {
  const typeName = type
    .replace(/([A-Z])/g, '_$1')
    .slice(1)
    .toUpperCase();
  return typeToUrlPrefixMap[type] || typeToUrlPrefixMap[typeName] || type.toLowerCase();
};

// If it gets too complex here, then maybe better to avoid negative match
// and list special characters. But then watch out for very special characters ◕◡◕ 😛
const allowedUrlCharactersRegexParts = {
  general: 'a-zA-Z',
  numbers: '0-9',
  whiteSpaces: ' ',
  allowedSpecial: '\\-äöüÄÖÜß',
  arabic: '\\u0600-\\u06FF',
  cyrillic: '\\u0400-\\u04FF',
  greek_and_coptic: '\\u0370-\\u03FF',
  chinese: '\\u4E00-\\u9FFF',
  devanagari: '\\u0900-\\u097F',
  bengali: '\\u0980-\\u09FF',
  ethiopian: '\\u1200-\\u137F',
};
// eslint-disable-next-line no-misleading-character-class
const onlySpecialCharactersRegex = new RegExp(
  `[^${Object.values(allowedUrlCharactersRegexParts).join('')}]`,
  'g',
);

/**
 * @deprecated use namedUrl from webapi instead.
 */
export const normalizeUrlName = name =>
  name.replace(onlySpecialCharactersRegex, '').replace(/\s/g, '-').toLowerCase();

export const mainContentFullUrl = () => (isServer() ? null : globals.window.location.href);

export const getVideoDownloadUrl = ({ videoUrl, format }) => {
  if (
    videoUrl.startsWith('vps/') ||
    videoUrl.startsWith(config.download.videoMp4Path) ||
    videoUrl.startsWith(config.download.videoFlvPath)
  ) {
    return `${config.download.videoBaseUrl}${videoUrl}`;
  }

  return `${config.download.videoBaseUrl}${
    format === 'FLV' ? config.download.videoFlvPath : config.download.videoMp4Path
  }${videoUrl}`;
};

export const getAudioDownloadUrl = ({ audioUrl }) => `${config.download.audioBaseUrl}${audioUrl}`;

export const getDownloadUrl = ({ staticBaseHost, downloadId, filename }) =>
  `${staticBaseHost}/downloads/${downloadId}/${filename.toLowerCase()}`;

export const getDWStructureUrl = ({ id, name, langCode }) =>
  `${config.dw.baseUrl}/${langCode}/${normalizeUrlName(name)}/s-${id}`;

export const getQueryParamValueFrom = ({ from = globals.window.location.search, value }) =>
  new URLSearchParams(from).get(value);

export const objectToQueryParams = obj => new URLSearchParams(obj).toString();

export const removeZhongwenUrlSearchParamFromPath = to => {
  const urlParts = to?.split('?');
  if (urlParts && urlParts.length > 1) {
    const params = new URLSearchParams(urlParts[1]);
    params.delete('zhongwen');
    const searchParams = params.toString();
    return searchParams.length > 0 ? `${urlParts[0]}?${searchParams}` : urlParts[0];
  }
  return to;
};

export const getUrlWithFallbackToHomePage = ({ name, localeLang, id, __typename } = {}) => {
  if (!name || !localeLang || !id || !__typename) {
    return '/';
  }

  const localeLangCode = localeLang.replace('_', '-');
  const dwLang = toDwLangCode(localeLangCode);

  // namedUrl is not used, because the footer articles come from other subdomains,
  // and we don't want a redirect to those subdomains.
  return `/${dwLang}/${normalizeUrlName(name)}/${typeToUrlPrefix(__typename)}-${id}`;
};
