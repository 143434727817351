import styled from 'styled-components';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useId } from 'react';
import { StyledValidationMessage } from '../ValidationMessage.jsx';
import { colors } from '../../../utils/css';
import { useTranslation } from '../../../hooks/useTranslation';
import { useFormError } from '../../../hooks/useFormError';
import { BaseInput } from '../BaseInput.jsx';
import { StyledInteractionLabel as InteractionLabel } from '../InteractionLabel.jsx';

export const Select = ({
  className,
  name,
  defaultOptionKey,
  options,
  registerOptions,
  ...restProps
}) => {
  const labelText = useTranslation(defaultOptionKey);
  const { register } = useFormContext();
  const { hasError, errorMessage } = useFormError(name, labelText);

  const inputId = useId();

  return (
    <div className={classnames(className, { error: hasError })} aria-live="assertive">
      <InteractionLabel
        htmlForName={inputId}
        labelKey={defaultOptionKey}
        registerOptions={registerOptions}
      />
      <BaseInput id={inputId} as="select" {...register(name, registerOptions)} {...restProps}>
        {labelText && <option value="">{labelText}</option>}
        {options?.map(option => (
          <Option key={option.value} option={option} />
        ))}
      </BaseInput>
      {errorMessage && <StyledValidationMessage>{errorMessage}</StyledValidationMessage>}
    </div>
  );
};

const Option = ({ option: { key, name: optionName, value, ariaKey } }) => {
  const ariaLabel = useTranslation(ariaKey);
  const optionLabel = useTranslation(key) ?? optionName;

  return (
    <option value={value} aria-label={ariaLabel}>
      {optionLabel}
    </option>
  );
};

export const StyledSelect = styled(Select)`
  display: block;
  margin: 0;
  padding: 0 0 20px;
  box-sizing: border-box;

  ${BaseInput} {
    display: block;
    width: 100%;
    height: 50px;

    padding: 14px 22px 10px 18px;
    box-sizing: border-box;

    background-color: ${colors.LG_WHITE};
  }

  option {
    appearance: none;
    display: block;
    white-space: nowrap;
    min-height: 50px;
    height: 50px;
    padding: 14px 22px 18px;
  }
`;
