import { useHistory, useParams } from 'react-router-dom';
import { LessonVocabulary } from '../LessonVocabulary/LessonVocabulary.jsx';
import { LessonPhonemicChart } from '../LessonPhonemicChart/LessonPhonemicChart.jsx';
import {
  ALPHABETICAL_ORDER_URL_SUFFIX,
  getLessonPhonemicChartUrl,
  getSummaryUrl,
  LESSON_VOCABULARY_URL_SUFFIX,
} from '../../../utils/url/urlFactory';
import { LessonKnowledgePage } from '../../../pages/LessonKnowledgePage.jsx';
import { LESSON_PARTS } from '../../../constants/lessonParts';
import { PAGE_TYPES } from '../../../constants/pageTypes';
import { StyledLessonKnowledgeNavigationContainer as LessonKnowledgeNavigationContainer } from './LessonKnowledgeNavigationContainer.jsx';
import { useStructuralLessonData } from '../../../context/StructuralLessonDataContext.jsx';
import { useLanguage } from '../../../context/LanguageContext';

const LESSON_PARTS_SUFFIX = {
  [LESSON_VOCABULARY_URL_SUFFIX]: LESSON_PARTS.VOCABULARY_GLOSSARY,
  [ALPHABETICAL_ORDER_URL_SUFFIX]: LESSON_PARTS.PHONEMIC_CHART,
};

export const LessonKnowledgeNavigation = ({
  lessonKeywordStrings,
  lessonKnowledgeOverviewItems,
}) => {
  const history = useHistory();
  const { lessonId, knowledgeType, contentId } = useParams();
  const { langCode } = useLanguage();
  const {
    structuralData: { lessonPath, lessonName },
  } = useStructuralLessonData();

  const getCurrentItem = () => {
    if (contentId) {
      return lessonKnowledgeOverviewItems.find(item => item.targetId.toString() === contentId);
    }
    return lessonKnowledgeOverviewItems.find(
      item => item.lessonPart === LESSON_PARTS_SUFFIX[knowledgeType],
    );
  };

  const currentItem = getCurrentItem();
  const currentItemIndex = lessonKnowledgeOverviewItems.indexOf(currentItem);
  const previousItem = lessonKnowledgeOverviewItems[currentItemIndex - 1];
  const nextItem = lessonKnowledgeOverviewItems[currentItemIndex + 1];

  const moveTo = item => {
    if (item.lessonPart === LESSON_PARTS.PHONEMIC_CHART) {
      return getLessonPhonemicChartUrl(lessonPath);
    }
    return item.target.namedUrl;
  };

  const getCurrentComponent = () => {
    if ([LESSON_PARTS.GRAMMAR, LESSON_PARTS.REGIONAL_STUDIES].includes(currentItem.lessonPart)) {
      const pageType =
        currentItem.lessonPart === LESSON_PARTS.GRAMMAR
          ? PAGE_TYPES.GRAMMAR
          : PAGE_TYPES.REGIONAL_STUDIES;
      return (
        <LessonKnowledgePage
          pageType={pageType}
          lessonName={lessonName}
          lessonKeywordStrings={lessonKeywordStrings}
        />
      );
    }

    if (currentItem.lessonPart === LESSON_PARTS.PHONEMIC_CHART) {
      return <LessonPhonemicChart lessonId={lessonId} langCode={langCode} />;
    }
    return <LessonVocabulary />;
  };

  const goNextWithButton = () => {
    const nextPath = nextItem ? moveTo(nextItem) : getSummaryUrl(lessonPath);
    history.push(nextPath);
  };

  return (
    <LessonKnowledgeNavigationContainer
      currentItem={currentItem}
      previousItem={previousItem}
      nextItem={nextItem}
      moveTo={moveTo}
      onNextClicked={goNextWithButton}
    >
      {currentItem && getCurrentComponent()}
    </LessonKnowledgeNavigationContainer>
  );
};
