import styled from 'styled-components';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useId } from 'react';
import { StyledValidationMessage } from '../ValidationMessage.jsx';
import { colors } from '../../../utils/css';
import { useTranslation } from '../../../hooks/useTranslation';
import { useFormError } from '../../../hooks/useFormError';
import { inputBoldBorder, inputBorderStyles } from '../BaseInput.jsx';
import { StyledInteractionLabel as InteractionLabel } from '../InteractionLabel.jsx';
import { keyCodes } from '../../../constants/keyCodes';

export const Checkbox = ({
  className,
  name,
  labelText,
  translationKeyForErrorInsertion,
  registerOptions,
  ...restProps
}) => {
  const labelForError = useTranslation(translationKeyForErrorInsertion);
  const { register } = useFormContext();
  const { hasError, errorMessage } = useFormError(name, labelForError || labelText);

  const inputId = useId();

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classnames(className, { error: hasError })}
      onKeyDown={e => {
        e.key === keyCodes.ENTER && e.preventDefault();
      }}
    >
      <div>
        <span>
          <input id={inputId} type="checkbox" {...register(name, registerOptions)} {...restProps} />
        </span>
        <InteractionLabel htmlForName={inputId} labelKey={labelText} {...{ registerOptions }} />
      </div>
      {errorMessage && <StyledValidationMessage>{errorMessage}</StyledValidationMessage>}
    </div>
  );
};

export const StyledCheckbox = styled(Checkbox)`
  display: block;
  padding: 0px;
  margin: 0;

  & > div {
    position: relative;
    display: table;
    border-collapse: separate;
    margin: 0px;
    padding: 0px;

    > span {
      display: table-cell;
      padding: 6px 10px;
      text-align: center;
      width: 1%;
      white-space: nowrap;
      vertical-align: top;

      > input {
        position: relative;
        z-index: 2;
        place-content: center;
        height: 20px;
        width: 20px;
        -webkit-appearance: none;
        appearance: none;

        margin: 4px 0 0 0;
        background-color: transparent;
        ${inputBorderStyles}
        &:active,
        &:focus {
          ${inputBoldBorder}
        }

        &::after {
          content: '';
          position: absolute;
          /* @noflip */
          left: 8px;
          top: 3px;
          height: 12px;
          width: 6px;

          border: 2px solid ${colors.DW_DARK_BLUE_NEW};
          /* @noflip */
          border-left: 0;
          border-top: 0;
          transform: rotate(45deg) scale(0);
        }

        &:checked::after {
          transform: rotate(45deg) scale(1);
        }
      }
    }

    > label {
      position: relative;
      z-index: 2;
      margin-bottom: 0;
      padding: 10px 0 15px 0px;
      width: 100%;
      display: inline-block;
      max-width: 100%;
    }
  }
`;
