import styled from 'styled-components';
import { useSelector } from 'react-redux';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { mediaMax, colors, columnSize } from '../../../../utils/css';
import { StyledLessonScore as LessonScore } from './LessonScore.jsx';
import { StyledSmallProgressResetButton as ProgressResetButton } from '../../Progress/ProgressResetButton.jsx';
import { LearnProgressIcon } from '../../../LearnProgressIcon/LearnProgressIcon.jsx';
import { findLessonLearnProgressSelector } from '../../../../state/progress/learnProgressSelectors';
import { Link } from '../../../Link/Link.jsx';
import { TeaserHeadline } from '../../../Headline/Headline.jsx';
import { TeaserText } from '../../../Text/Text.jsx';

const fragmentName = 'LessonDetails';
export const lessonDetails = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Lesson {
        id
        shortTitle
        learningTargetHeadline
        grammarDescription
        namedUrl
      }
    `,
  ),
};

export const LessonItem = ({ lesson, className, onClickReset }) => {
  const { id: lessonId, namedUrl, shortTitle, learningTargetHeadline, grammarDescription } = lesson;
  const lessonProgress = useSelector(findLessonLearnProgressSelector(lessonId));

  const onHandleResetClick = e => {
    onClickReset(e, lessonId);
  };

  return (
    <>
      <Link className={className} to={namedUrl} tabIndex={0}>
        <TeaserHeadline>{shortTitle}</TeaserHeadline>
        <div className="tile-progress">
          <LearnProgressIcon learnProgress={lessonProgress} />
        </div>
        <TeaserText className="learningTarget">{learningTargetHeadline}</TeaserText>
        {lessonProgress && (
          <>
            <LessonScore
              lessonProgress={lessonProgress}
              onClickReset={onClickReset}
              lessonId={lessonId}
            />
            <ProgressResetButton onClickReset={onHandleResetClick} />
          </>
        )}
        <TeaserText className="categories">{grammarDescription}</TeaserText>
      </Link>
    </>
  );
};

export const StyledLessonItem = styled(LessonItem)`
  border-top: 1px solid ${colors.LG_GRAY_TRANSPARENT_1};
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(3, 1fr);
  height: 127px;
  padding: 10px ${columnSize.c1};
  overflow-y: hidden;
  text-decoration: none;

  ${mediaMax.xs`
    padding: 10px ${columnSize.c2};
  `}
  &:hover {
    text-decoration: none;
  }

  > ${TeaserHeadline} {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .learningTarget,
  .categories {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .categories {
    grid-column: 1 / 2;
  }

  .tile-progress {
    display: flex;
    justify-content: flex-end;
    padding: 5px 0 0 0;
  }

  ${LessonScore} {
    grid-column-start: 2;
    justify-self: flex-end;
    padding-right: 0;
  }

  ${ProgressResetButton} {
    grid-column-start: 2;
    grid-row-start: 3;
    justify-self: center;
  }
`;
