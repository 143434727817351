import { useEffect } from 'react';
import {
  TYPE_GET_CACHED_RESULTS,
  TYPE_SET_HINTS_DATA,
} from '../components/Vocabulary/VocabularySearch/duck/vocabularySearchDuck';
import { getQueryVariables } from '../components/Vocabulary/VocabularyQuery.jsx';
import { useLanguage } from '../context/LanguageContext';

export const hasMoreResults = ({ original, translation }) =>
  original.totalHits > original.offset + original.amount ||
  translation.totalHits > translation.offset + translation.amount;

const equalVocabItem = item => {
  return otherItem =>
    item.name === otherItem.name &&
    item.text === otherItem.text &&
    item.subTitle === otherItem.subTitle;
};

export const combineVocabularyData = ({
  original: { hits: oHits },
  translation: { hits: tHits },
}) => {
  // NOTE: Uses a zip to ensure consistent ordering
  const result = [];
  let iO = 0;
  let iT = 0;

  while (iO < oHits.length || iT < tHits.length) {
    while (iO < oHits.length && result.some(equalVocabItem(oHits[iO]))) {
      iO += 1;
    }

    if (iO < oHits.length) {
      result.push(oHits[iO]);
      iO += 1;
    }

    while (iT < tHits.length && result.some(equalVocabItem(tHits[iT]))) {
      iT += 1;
    }

    if (iT < tHits.length) {
      result.push(tHits[iT]);
      iT += 1;
    }
  }

  return result;
};

export const useGetCachedHintsResponse = (state, dispatch) => {
  useEffect(
    () => {
      if (state.recentlySearched.get(state.input) && state.cursor === -1) {
        dispatch({ type: TYPE_GET_CACHED_RESULTS });
      }
    }, // eslint-disable-next-line
    [state.searchQuery, state.cursor],
  );
};

// Only use the cleaned up text content
const combineSuggestions = ({ original, translation }) => [
  ...original.hits.map(item => item.name.replace(/\|/g, '')),
  ...translation.hits.map(item =>
    item.text
      .replace(/<[^>]*>?/gm, '')
      .replace(/\|/g, '')
      .trim(),
  ),
];
export const useGetHintsResponseAndCacheIt = (debouncedSearchQuery, state, dispatch, refetch) => {
  const { langCode } = useLanguage();
  useEffect(
    () => {
      if (debouncedSearchQuery) {
        const { searchQuery } = state;
        if (!state.recentlySearched.get(debouncedSearchQuery)) {
          refetch(
            getQueryVariables({
              langCode,
              searchQuery,
              sort: false,
            }),
          ).then(res => {
            return dispatch({
              type: TYPE_SET_HINTS_DATA,
              payload: {
                searchQuery: debouncedSearchQuery,
                response: combineSuggestions(res.data),
              },
            });
          });
        }
      }
    }, // eslint-disable-next-line
    [debouncedSearchQuery],
  );
};
