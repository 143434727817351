import styled from 'styled-components';
import classnames from 'classnames';
import { StyledModalAudio as ModalAudio } from './ModalAudio.jsx';
import { colors } from '../../../utils/css';
import { ButtonInteraction, PageLinkInteraction } from '../../Interaction/Interaction.jsx';
import { TeaserText } from '../../Text/Text.jsx';
import { StyledCloseIcon } from '../../../assets/icons/CloseIcon.jsx';

export const KnowledgeModalHeader = ({
  className,
  title,
  subTitle,
  audioFile,
  closeModal,
  initialSound,
}) => (
  <div className={classnames(className, { 'no-line': initialSound })}>
    <ButtonInteraction
      data-dismiss="modal"
      aria-label="Close"
      onClick={closeModal}
      data-testid="knowledgeModalClose"
    >
      <span aria-hidden="true">
        <StyledCloseIcon />
      </span>
    </ButtonInteraction>
    {initialSound ? (
      <PageLinkInteraction as="h4">{title}</PageLinkInteraction>
    ) : (
      <>
        <ModalAudio {...{ title, audioFile }} />
        {subTitle && <TeaserText>{subTitle}</TeaserText>}
      </>
    )}
  </div>
);

export const StyledKnowledgeModalHeader = styled(KnowledgeModalHeader)`
  border-bottom: 1px solid ${colors.BLUE_GREY_03};
  padding: 0.5rem 0;
  margin: 0 1rem;
  /* @noflip */
  direction: ltr;
  /* @noflip */
  text-align: left;

  ${ButtonInteraction} {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;

    position: absolute;
    /* @noflip */
    right: 0.5rem;
    top: 0.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  ${PageLinkInteraction} {
    margin: 0;
    /* @noflip */
    padding-right: 1rem;
  }

  ${TeaserText} {
    display: inline-block;
    text-transform: none;
    padding-top: 0.5rem;
    font-weight: 600;
  }

  &.no-line {
    border-bottom: 0;
  }
`;
