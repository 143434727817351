import styled from 'styled-components';
import { Fragment, useEffect, useMemo, useRef } from 'react';
import { colors, mediaMin } from '../../../utils/css';
import { StyledResponsiveDwPicture as ResponsiveDwPicture } from '../../ResponsiveDwPicture/ResponsiveDwPicture.jsx';
import { StyledRichText as RichText } from '../../RichText/RichText.jsx';
import { useAudio } from '../../../hooks/useAudio';
import { isVowelTitle } from '../../../utils/commons';
import AudioIcon from '../../../assets/svg/audio-speaker.svg';
import { ContentBlockHeadline } from '../../Headline/Headline.jsx';
import { ButtonInteraction } from '../../Interaction/Interaction.jsx';
import { DateTimeInfo } from '../../Info/Info.jsx';

/**
 * @param {{
 *   className?: string;
 *   currentLessonNumber: number | null;
 *   course: {
 *     lessons: {
 *      exercises: {
 *        knowledges: {
 *          id: number | null;
 *          knowledgeType: string;
 *          name: string;
 *          audios: ({
 *            mp3Src: string | null;
 *            mainContentImage: {
 *              id: number | null;
 *              name: string | null;
 *              staticUrl: string | null;
 *            } | null;
 *          } | null)[] | null;
 *        mainContentImage: {
 *          id: number | null;
 *          name: string | null;
 *          staticUrl: string | null;
 *        } | null;
 *        text: string | null;
 *      }[];
 *     }[];
 *    }[];
 *   };
 * }} props
 */
const LessonPhonemicItemsComponent = ({ className, course, currentLessonNumber }) => {
  const items = useMemo(() => {
    const result = [];

    course.lessons.forEach((lesson, index) => {
      const lessonIndex = index + 1;
      lesson.exercises.forEach(exer => {
        exer.knowledges.forEach(knowledge => {
          if (
            knowledge.knowledgeType === 'INITIAL_SOUND' &&
            result.every(({ id }) => id !== knowledge.id)
          ) {
            result.push({ ...knowledge, lessonIndex });
          }
        });
      });
    });

    return result.sort((a, b) => a.name.localeCompare(b.name));
  }, [course.lessons]);

  return (
    <div className={className}>
      {items.map(({ name, id, audios, mainContentImage, text, lessonIndex }) => {
        const audio = audios?.[0];
        const image = mainContentImage ?? audio?.mainContentImage;
        const notVisited = lessonIndex > currentLessonNumber ? ' alphabet-not-visited' : '';

        return (
          <Fragment key={id}>
            <div className={`first-column${notVisited}`}>
              <ContentBlockHeadline data-is-vowel={isVowelTitle(name)}>{name}</ContentBlockHeadline>
            </div>
            <div className={`phonemic-image${notVisited}`}>
              {image && <ResponsiveDwPicture image={image} alt={image.name} />}
            </div>
            <div className={`phonemic-description${notVisited}`}>
              <Audio name={name} audioSrc={audio?.mp3Src} />
              <RichText isA="div" content={text} noContainer />
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

/**
 * @param {{ name: string, audioSrc: string | null }} props
 */
const Audio = ({ name, audioSrc }) => {
  const audioRef = useRef();
  const { addRef, playAudio } = useAudio();

  useEffect(() => addRef(audioRef), [addRef]);

  if (!audioSrc) {
    return (
      <DateTimeInfo>
        <b>{name}</b>
      </DateTimeInfo>
    );
  }

  return (
    <ButtonInteraction onClick={e => playAudio(e, audioRef)}>
      <b>{name}</b>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef}>
        <source src={audioSrc} type="audio/MP3" />
      </audio>
    </ButtonInteraction>
  );
};

export const LessonPhonemicItems = styled(LessonPhonemicItemsComponent)`
  ${mediaMin.md`
    display: grid;
    grid-template-columns: 3fr 3fr 4fr;
  `}

  ${mediaMin.xl`
    display: grid;
    grid-template-columns: 3fr 2fr 3fr;
  `}

  > div {
    &.first-column,
    &.phonemic-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    padding: 10px 0;

    ${mediaMin.md`
      border-top: 1px solid ${colors.DW_GREY_07};
      padding: 20px 0;
    `}

    &.alphabet-not-visited {
      opacity: 0.33;
    }

    &.first-column {
      border-top: 1px solid ${colors.DW_GREY_07};
      padding-top: 20px;

      > ${ContentBlockHeadline} {
        &[data-is-vowel='true'] {
          color: ${colors.LG_ORANGE_DARK};
        }
      }
    }
  }

  button {
    border: none;
    background-color: inherit;

    background-image: url(${AudioIcon});
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position-y: 50%;

    b {
      /* @noflip */
      padding-left: 24px;
    }
  }
`;
