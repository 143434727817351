import styled from 'styled-components';
import gql from 'graphql-tag';
import classnames from 'classnames';
import { memoizeWith } from 'ramda';
import { useCallback, useSyncExternalStore } from 'react';
import { resolutions, mediaMin, aspectRatioAware } from '../../../utils/css';
import { HeroImagePreloader } from '../../HeroImagePreloader/HeroImagePreloader.jsx';
import { usePageEmbeddingContext } from '../../../context/PageEmbeddingContext.jsx';
import { landscapeFormatGroupName } from '../../ResponsiveDwPicture/pictureConfigs';
import {
  StyledResponsiveDwPicture as ResponsiveDwPicture,
  responsiveDwPictureFragment,
} from '../../ResponsiveDwPicture/ResponsiveDwPicture.jsx';
import { useGlobalsContext } from '../../../context/GlobalsContext';

const fragmentName = 'SlideResponsiveImage';
export const slideResponsiveImageFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => {
      return gql`fragment ${fragmentName} on GalleryImage {
      name
      description
      altText
      assignedImage {
        licenserSupplement
        imageType
        ...${responsiveDwPictureFragment.name}
      }
    }
    ${responsiveDwPictureFragment.fragment()}
    `;
    },
  ),
};

const commonSlideshowImageFormatConfig = [
  {
    minWidth: resolutions.min.xs,
    maxWidth: resolutions.max.xs,
    formatGroupName: '70X',
  },
  {
    minWidth: resolutions.min.sm,
    maxWidth: resolutions.max.sm,
    formatGroupName: '80X',
  },
];

const slideshowConfig = [
  ...commonSlideshowImageFormatConfig,
  {
    minWidth: resolutions.min.md,
    maxWidth: resolutions.max.lg,
    formatGroupName: '60X',
  },
  {
    minWidth: resolutions.min.xl,
    maxWidth: resolutions.max.xl,
    formatGroupName: '100X',
  },
];

const richTextConfig = [
  ...commonSlideshowImageFormatConfig,
  {
    minWidth: resolutions.min.md,
    maxWidth: resolutions.max.xl,
    formatGroupName: '60X',
  },
];

export const SlideResponsiveImage = ({ className, galleryImage = {}, preload }) => {
  const { assignedImage, altText } = galleryImage;
  const { isInRichText } = usePageEmbeddingContext();
  const { window } = useGlobalsContext();

  const isCaricatureImage = assignedImage?.imageType === 'CARICATURE';

  const subscribe = useCallback(
    callback => {
      window.addEventListener('resize', callback);
      return () => window.removeEventListener('resize', callback);
    },
    [window],
  );

  const formatGroupName = useSyncExternalStore(
    subscribe,
    () => {
      if (isCaricatureImage) {
        return landscapeFormatGroupName;
      }

      const { innerWidth } = window;

      return (isInRichText ? richTextConfig : slideshowConfig).findLast(
        ({ minWidth, maxWidth }) => innerWidth >= minWidth && innerWidth <= maxWidth,
      ).formatGroupName;
    },
    () => landscapeFormatGroupName,
  );

  if (!assignedImage) {
    return null;
  }

  return (
    <>
      <ResponsiveDwPicture
        className={classnames(className, {
          'is-caricature': isCaricatureImage,
          'is-richtext': isInRichText,
        })}
        image={assignedImage}
        formatGroupName={formatGroupName}
        alt={altText}
      />
      {preload && !isInRichText && (
        <HeroImagePreloader image={assignedImage} formatConfig={slideshowConfig} />
      )}
    </>
  );
};

export const StyledResponsiveSlideImage = styled(SlideResponsiveImage)`
  top: 50%;
  transform: translateY(-50%);
  ${mediaMin.xl`
      &.is-caricature {
        padding-bottom: 43.75%;
        margin: 0 auto;
        &:not(.is-richtext) {
          max-width: 77.77%;
        }
      }
    `}
  ${aspectRatioAware.xl.ultraWide`
      &.is-caricature {
        margin: 0;
      }
    `}
`;
