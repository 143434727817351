import { isServer } from '../../utils/ssr';
import globals from '../../utils/globals';

import { GtmLoadScript } from './GtmLoadScript.jsx';
import { GtmDataLayer } from './GtmDataLayer.jsx';

export { gtmDataLayerFragment } from './GtmDataLayer.jsx';

const noContent = {};

// @used in ROAD
export const GtmScriptWithDataLayer = ({ content = noContent, noContentPageName, lessonItem }) => {
  const isGtmAlreadyInitialized = !isServer() && !!globals.window.dataLayer;
  return (
    <>
      <GtmDataLayer
        content={content}
        noContentPageName={noContentPageName}
        lessonItem={lessonItem}
      />
      {!isGtmAlreadyInitialized && <GtmLoadScript />}
    </>
  );
};
