import { colors } from '../utils/css';

export const tileColors = [
  colors.LG_ORANGE,
  colors.LG_ORANGE_DARK,
  colors.LG_PINK,
  colors.LG_RED,
  colors.LG_GREEN_LIGHT,
  colors.LG_GREEN,
  colors.DW_LIGHT_BLUE_NEW,
  colors.LG_BLUE_5,
  colors.LG_RED,
  colors.LG_PINK,
  colors.LG_ORANGE_DARK,
  colors.LG_ORANGE,
  colors.LG_BLUE_5,
  colors.DW_LIGHT_BLUE_NEW,
  colors.LG_GREEN,
  colors.LG_GREEN_LIGHT,
];

export const placementTestTileTextColors = [
  colors.LG_ORANGE,
  colors.LG_ORANGE_DARK,
  colors.LG_PINK,
  colors.LG_RED,
];
