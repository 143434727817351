import { lazy } from 'react';
import { SuspendedComponent } from './SuspendedComponent.jsx';

export const suspendedComponentFactory = (importFn, { fallback } = {}) => {
  const namedExportHandler = {
    get(_, exportName) {
      const Component = lazy(() => importFn().then(module => ({ default: module[exportName] })));
      return props => <SuspendedComponent isA={Component} fallback={fallback} {...props} />;
    },
  };
  return new Proxy({}, namedExportHandler);
};
