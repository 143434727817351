import { Suspense } from 'react';
import styled from 'styled-components';
import { BrowserOnly } from './BrowserOnly.jsx';

export const SquarePlaceholder = styled.svg`
  width: 100%;
  padding-bottom: 100%;
  height: 0;
`;

// @used in ROAD
export const SuspendedComponent = ({
  isA,
  className,
  fallback = <SquarePlaceholder className={className} />,
  ...props
}) => {
  const Tag = isA;
  return (
    <BrowserOnly fallback={fallback}>
      <Suspense fallback={fallback}>
        <Tag className={className} {...props} />
      </Suspense>
    </BrowserOnly>
  );
};
