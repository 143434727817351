export const TYPE_SET_INPUT = 'TYPE_SET_INPUT';
export const TYPE_GET_CACHED_RESULTS = 'TYPE_GET_CACHED_RESULTS';
export const TYPE_SET_HINTS_DATA = 'TYPE_RESET_CURSOR_AND_SET_HINTS_DATA';
export const TYPE_SET_HINTS_OPENED = 'TYPE_SET_HINTS_OPENED';
export const TYPE_SET_HINTS_CLOSED = 'TYPE_SET_HINTS_CLOSED';
export const TYPE_SET_CURSOR_ARROW_UP = 'TYPE_SET_CURSOR_ARROW_UP';
export const TYPE_SET_CURSOR_ARROW_DOWN = 'TYPE_SET_CURSOR_ARROW_DOWN';
export const TYPE_KEY_ESCAPE_CLICKED = 'TYPE_KEY_ESCAPE_CLICKED';
export const TYPE_KEY_BACKSPACE_CLICKED = 'TYPE_KEY_BACKSPACE_CLICKED';
export const TYPE_ANY_CHARACTER_TYPED = 'TYPE_KEY_ANY_CLICKED';
export const TYPE_ITEM_CLICKED = 'TYPE_ITEM_CLICKED';
export const TYPE_KEY_ENTER_CLICKED = 'TYPE_KEY_ENTER_CLICKED';

export const reducer = (state, action) => {
  switch (action.type) {
    case TYPE_GET_CACHED_RESULTS:
      return {
        ...state,
        vocabularyItems: state.recentlySearched.get(state.input),
        isOpened:
          !state.isEscapeClicked && !state.isEnterClicked && state.recentlySearched.get(state.input)
            ? state.recentlySearched.get(state.input).length > 0
            : false,
        isEscapeClicked: false,
        isEnterClicked: false,
      };
    case TYPE_SET_HINTS_DATA: {
      const { response, searchQuery } = action.payload;
      return {
        ...state,
        vocabularyItems: response || [],
        recentlySearched: state.recentlySearched.set(searchQuery, response || []),
        cursor: -1,
        isOpened:
          !state.isEnterClicked && !state.isEscapeClicked && response ? response.length > 0 : false,
      };
    }
    case TYPE_SET_HINTS_OPENED:
      return {
        ...state,
        isOpened: true,
      };
    case TYPE_SET_HINTS_CLOSED:
      return {
        ...state,
        isOpened: false,
      };
    case TYPE_SET_INPUT:
      return {
        ...state,
        ...action.payload,
      };
    case TYPE_SET_CURSOR_ARROW_UP: {
      const payloadInput = action.payload.input;
      const cursorUp =
        state.cursor <= 0 ? payloadInput.length - 1 : (state.cursor - 1) % payloadInput.length;
      return {
        ...state,
        isOpened: true,
        cursor: cursorUp,
        input: payloadInput[cursorUp],
      };
    }
    case TYPE_SET_CURSOR_ARROW_DOWN: {
      const payloadInput = action.payload.input;
      const cursorDown = (state.cursor + 1) % payloadInput.length;
      return {
        ...state,
        isOpened: true,
        cursor: cursorDown,
        input: payloadInput[cursorDown],
      };
    }
    case TYPE_KEY_ESCAPE_CLICKED:
      return {
        ...state,
        cursor: -1,
        input: '',
        isOpened: false,
        isEscapeClicked: true,
        vocabularyItems: [],
      };
    case TYPE_KEY_BACKSPACE_CLICKED:
      return {
        ...state,
        ...action.payload,
        cursor: -1,
        vocabularyItems: action.payload.input === '' ? [] : state.vocabularyItems,
        isEnterClicked: false,
        isEscapeClicked: false,
      };
    case TYPE_ITEM_CLICKED:
      return {
        ...state,
        ...action.payload,
        cursor: -1,
        isOpened: false,
      };
    case TYPE_ANY_CHARACTER_TYPED:
      return {
        ...state,
        ...action.payload,
        cursor: -1,
        isOpened: true,
      };
    case TYPE_KEY_ENTER_CLICKED:
      return {
        ...state,
        ...action.payload,
        cursor: -1,
        isOpened: false,
        isEnterClicked: true,
      };
    default:
      return state;
  }
};
