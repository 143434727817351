import styled from 'styled-components';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { useId } from 'react';
import { StyledSocialMediaAccountLink as SocialMediaAccountLink } from './SocialMediaAccountLink.jsx';
import { sharedFooterSectionStyles } from './FooterSectionMainGroup.jsx';
import { useTranslation } from '../../hooks/useTranslation';
import { isValidList } from '../../utils/commons';
import { NavigationSectionHeadline } from '../Headline/Headline.jsx';

const fragmentName = 'FooterSocialMedia';
export const footerSocialMediaFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`fragment ${fragmentName} on Footer {
      linkedSocialMediaAccounts {
        service
        url
      }
    }
  `,
  ),
};

// @used in ROAD
export const FooterSocialMedia = ({ className, footer }) => {
  const headline = useTranslation('footer.socialmediachannels.headline');
  const sectionId = useId();

  if (!isValidList(footer.linkedSocialMediaAccounts)) {
    return null;
  }
  return (
    <ul className={className} role="group" aria-labelledby={sectionId}>
      <NavigationSectionHeadline id={sectionId}>{headline}</NavigationSectionHeadline>
      <ul>
        {footer.linkedSocialMediaAccounts.map(socialMediaAccount => (
          <SocialMediaAccountLink
            key={socialMediaAccount.service}
            socialMediaAccount={socialMediaAccount}
          />
        ))}
      </ul>
    </ul>
  );
};

export const StyledFooterSocialMedia = styled(FooterSocialMedia)`
  ${sharedFooterSectionStyles}
  padding: 0;

  > ul {
    display: flex;
    flex-direction: row;
  }
`;
