import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { StyledBigImageTeaser as BigImageTeaser } from './BigImageTeaser.jsx';
import {
  publicationDateDeliveryFragment,
  publicationDateModelFragment,
} from '../../PublicationDate/PublicationDate.jsx';

const fragmentName = 'courseFragment';
export const courseFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Course {
        id
        modelType
        isRtl
        name
        shortTeaser
        dkLearningLevel
        namedUrl
        mainContentImage {
          id
          name
          staticUrl
        }
        ...${publicationDateDeliveryFragment.name({ type: 'Course' })}
        ...${publicationDateModelFragment.name({ type: 'Course' })}
      }
      ${publicationDateDeliveryFragment.fragment({ type: 'Course' })}
      ${publicationDateModelFragment.fragment({ type: 'Course' })}
    `,
  ),
};

export const CourseTeaser = ({ className, contents, ...restProps }) => {
  const { mainContentImage, name, shortTeaser, dkLearningLevel, namedUrl, isRtl } = contents;

  return (
    <BigImageTeaser
      className={className}
      image={mainContentImage}
      title={name}
      teaser={shortTeaser}
      publicationDateConfig={contents}
      dkLearningLevel={dkLearningLevel}
      to={namedUrl}
      isRtl={isRtl}
      {...restProps}
    />
  );
};

export const StyledCourseTeaser = CourseTeaser;
