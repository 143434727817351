export const subtitlesLabels = Object.freeze({
  GERMAN: 'Deutsch/Deutsch',
  ENGLISH: 'English',
  RUSSIAN: 'Русский',
  CHINESE: '中文',
  PORTUGUESE_BRAZIL: 'Português',
  ALBANIAN: 'Shqip',
  AMHARIC: 'አማርኛ',
  ARABIC: 'عربي',
  BENGALI: 'বাংলা',
  BOSNIAN: 'Bosanski',
  BULGARIAN: 'Български',
  DARI: 'دری',
  FRENCH: 'Français',
  GREEK: 'Ελληνικά',
  HAUSA: 'Hausa',
  HINDI: 'हिन्दी',
  INDONESIAN: 'Bahasa Indonesia',
  KISWAHILI: 'Kiswahili',
  CROATIAN: 'Hrvatski',
  MACEDONIAN: 'Македонски',
  PASHTO: 'پښتو',
  PERSIAN: 'فارسی',
  POLISH: 'Polski',
  ROMANIAN: 'Română',
  SERBIAN: 'Srpski',
  SPANISH: 'Español',
  TURKISH: 'Türkçe',
  UKRANIAN: 'Українська мова',
  URDU: 'اردو',
});
