import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../hooks/useTranslation';
import { handleCertificateGeneration } from '../../utils/certificateUtils';
import CertificateGray from '../../assets/svg/icon-course-cert-gray.svg';
import { useLanguage } from '../../context/LanguageContext';

export const CertificateIcon = ({ className, id }) => {
  const history = useHistory();
  const { langCode } = useLanguage();
  const title = useTranslation('common.progress.cert');

  const handleOnCertificateClick = async () => {
    if (id) {
      await handleCertificateGeneration({ id, langCode, history });
    }
  };

  return (
    <div role="none" onClick={handleOnCertificateClick}>
      <img alt="progress-icon cert" className={className} src={CertificateGray} title={title} />
    </div>
  );
};

export const StyledCertificateIcon = styled(CertificateIcon)`
  width: 16px;
  height: 24px;
  cursor: pointer;
`;
