import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { findAudioWithContentLinkMatching } from '../../../utils/audioUtils';
import { gtmDataLayerFragment } from '../../GoogleTagManager';
import { StyledAudioPlayer as AudioPlayer } from '../../MediaPlayer/Audio/AudioPlayer.jsx';

const fragmentName = ({ type }) => `${type}MediaInputAudio`;
export const mediaInputAudioFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    ({ type }) => type,
    ({ type }) => gql`
      fragment ${fragmentName({ type })} on ${type} {
        audios {
          id
          name
          contentDate
          posterImageUrl
          mp3Src
          formattedDuration
          ${gtmDataLayerFragment.partial()}
        }
        contentLinks {
          targetId
          mainContent
        }
      }
    `,
  ),
};

export const MediaInputAudio = ({ data }) => {
  // description audio has mainContent === true, input audio has mainContent === false
  const inputAudio = findAudioWithContentLinkMatching(cl => !cl.mainContent, data.content);

  return (
    <AudioPlayer
      audio={inputAudio}
      fallbackImageStaticUrl={data.content?.mainContentImage?.staticUrl}
    />
  );
};
