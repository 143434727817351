import { useCallback } from 'react';
import styled from 'styled-components';
import { replacePlaceholderWithAnswerComponent } from '../../../../utils/replacers/exerciseReplacer';
import { RichTextWithKnowledge } from '../../../RichText/RichTextWithKnowledge.jsx';

/**
 * @param {Pick<
 *     Parameters<typeof replacePlaceholderWithAnswerComponent>[0],
 *     'scopeClassName' | 'createComponent'> &
 *   React.ComponentProps<typeof RichTextWithKnowledge>
 * } props
 */
export const ExerciseRichText = ({
  createComponent,
  scopeClassName,
  isA = 'div',
  additionalReplacements,
  ...richTextWithKnowledgeProps
}) => {
  const insertAnswers = useCallback(
    node => {
      const answerPortals = replacePlaceholderWithAnswerComponent({
        createComponent,
        scopeClassName,
        node,
      });
      const additionalPortals = additionalReplacements?.(node);

      return [...(answerPortals ?? []), ...(additionalPortals ?? [])];
    },
    [createComponent, scopeClassName, additionalReplacements],
  );

  return (
    <RichTextWithKnowledge
      additionalReplacements={insertAnswers}
      isA={isA}
      noContainer
      {...richTextWithKnowledgeProps}
    />
  );
};

export const StyledExerciseRichText = styled(ExerciseRichText)`
  span {
    margin: 0px !important;
  }
`;
