import { resolutions } from '../../../../utils/css';
import { useToggle } from '../../../../hooks/useToggle';
import { StyledCourseHeader as CourseHeader } from '../CourseHeader/CourseHeader.jsx';
import { StyledLessonList as LessonList } from '../LessonList/LessonList.jsx';
import globals from '../../../../utils/globals';

export const CourseAccordion = ({ groupNameTranslationKey, groupedLessons, onClickReset }) => {
  const isOnDesktop = globals.window.innerWidth >= resolutions.min.xl;
  const [isOpen, { toggleOnClick, toggleOnKeyPress }] = useToggle({ initialState: isOnDesktop });

  return (
    <div className="accordion">
      <CourseHeader
        isOpen={isOpen}
        groupNameTranslationKey={groupNameTranslationKey}
        onClick={toggleOnClick}
        onKeyDown={toggleOnKeyPress}
      />
      <LessonList isOpen={isOpen} groupedLessons={groupedLessons} onClickReset={onClickReset} />
    </div>
  );
};
