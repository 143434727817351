import styled from 'styled-components';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { Fragment, useEffect } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { getUrlWithFallbackToHomePage } from '../../utils/url/url';
import { Link } from '../Link/Link.jsx';
import {
  FooterVendorSettingsLink,
  footerVendorSettingsLinkFragment,
} from './FooterVendorSettingsLink.jsx';
import { CopyrightInfo, DateTimeInfo } from '../Info/Info.jsx';
import { FooterLinkInteraction } from '../Interaction/Interaction.jsx';
import { columnSize } from '../../utils/css';

const footerLinkFields = ['policy', 'legal', 'accessibility'];

const fragmentName = 'FooterSectionStandard';
export const footerSectionStandardFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`fragment ${fragmentName} on Footer {
      ...${footerVendorSettingsLinkFragment.name}
      ${footerLinkFields
        .map(
          fieldName => `
        ${fieldName} {
          id
          localeLang
          name
        }
      `,
        )
        .join('\n')}
    }
    ${footerVendorSettingsLinkFragment.fragment()}
  `,
  ),
};

const StandardLink = ({ fieldName, fieldTarget }) => {
  const translation = useTranslation(`footer.${fieldName}`);

  useEffect(() => {
    if (!fieldTarget) {
      console.warn(`No ${fieldName} article configured. Footer link can not be displayed.`);
    }
  }, [fieldName, fieldTarget]);

  if (!fieldTarget) {
    return null;
  }

  return (
    <FooterLinkInteraction as={Link} to={getUrlWithFallbackToHomePage(fieldTarget)}>
      {translation}
    </FooterLinkInteraction>
  );
};

// @used in ROAD
export const FooterSectionStandard = ({ className, footer }) => {
  const currentYear = new Date().getFullYear();

  return (
    <section className={className} data-tracking-name="footer-section-standard-links">
      <CopyrightInfo>© {currentYear} Deutsche Welle</CopyrightInfo>
      {footerLinkFields.map(fieldName => (
        <Fragment key={fieldName}>
          <DateTimeInfo>|</DateTimeInfo>
          <StandardLink fieldName={fieldName} fieldTarget={footer[fieldName]} />
        </Fragment>
      ))}
      <DateTimeInfo>|</DateTimeInfo>
      <FooterVendorSettingsLink privacySettings={footer.privacySettings} />
    </section>
  );
};

export const StyledFooterSectionStandard = styled(FooterSectionStandard)`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  padding-left: ${columnSize.c2};
  padding-right: ${columnSize.c2};

  > * {
    white-space: nowrap;
  }

  > ${DateTimeInfo}:not(:first-child) {
    margin: 0 5px;
  }
`;
