import styled from 'styled-components';
import { StyledLinkListReferenceTeaser as LinkListReferenceTeaser } from '../Teaser/LinkListReferenceTeaser.jsx';

/**
 * @param {{
 *   className?: string,
 *   contents: {
 *     id: React.Key,
 *     modelType: unknown,
 *     shortTitle: string,
 *     navigations: [],
 *     externalLinks: [],
 *   }[],
 * }} props
 */
export const LinkListBlock = ({ className, contents }) => {
  const references = contents.filter(isReference);
  const isContentAvailable = references.length > 0;

  return isContentAvailable ? (
    <div className={className}>
      {references.map(content => (
        <LinkListReferenceTeaser contents={content} key={content.id} />
      ))}
    </div>
  ) : null;
};

export const StyledLinkListBlock = styled(LinkListBlock)``;

const isReference = content => content.modelType === 'REFERENCE';
