import styled from 'styled-components';
import { colors, mediaMin } from '../../../../utils/css';
import { useTranslation } from '../../../../hooks/useTranslation';
import { Link } from '../../../Link/Link.jsx';
import { NavigationLinkInteraction } from '../../../Interaction/Interaction.jsx';

export const LessonMenuItem = ({
  to,
  translationKey,
  id,
  ariaLabel,
  target,
  rel,
  onClick,
  onKeyDown,
}) => {
  const label = useTranslation(translationKey);
  return (
    <StyledLessonMenuItem>
      <NavigationLinkInteraction
        as={Link}
        to={to}
        id={id}
        target={target}
        rel={rel}
        onClick={onClick}
        onKeyDown={onKeyDown}
        aria-label={ariaLabel}
      >
        {label}
      </NavigationLinkInteraction>
    </StyledLessonMenuItem>
  );
};

export const StyledLessonMenuItem = styled('li')`
  padding: 0;
  text-align: center;
  width: 100%;

  ${mediaMin.md`
    border-bottom: 1px solid ${colors.LG_WHITE};
  `}

  > ${NavigationLinkInteraction} {
    display: inline-block;
    padding: 20px;
    text-decoration: none;
    width: 100%;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: ${colors.LG_ORANGE1};
      }
    }
    @media (hover: none) and (pointer: coarse) {
      &:hover {
        background-color: ${colors.LG_ORANGE1};
      }
    }
    &:active {
      background-color: ${colors.LG_ORANGE1};
    }
    &:focus {
      background-color: ${colors.DARK_BLUE_GREY_02};
    }
  }
`;
