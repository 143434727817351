import { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  detachTranslateX,
  getXCoordinate,
  getXMouseMovement,
  setTranslateX,
} from '../../utils/draggableUtils';
import { useLanguage } from '../../context/LanguageContext';

const initialState = {};
export const useDragReducer = ({ draggableRef, wrapperRef, isRtl }) => {
  const [state, setState] = useState(initialState);

  const startingSide = isRtl ? 'right' : 'left';
  const endingSide = isRtl ? 'left' : 'right';

  const isFirstIndex = wrapperBoundingClientRect => {
    const wrapperStart = Math.floor(wrapperBoundingClientRect[startingSide]);
    const draggableStart = Math.floor(
      draggableRef.current.children[0].getBoundingClientRect()[startingSide],
    );

    return wrapperStart === draggableStart;
  };
  const isLastIndex = wrapperBoundingClientRect => {
    const lastChild = draggableRef.current.children.length - 1;
    const lastChildBoundingClientRect =
      draggableRef.current.children[lastChild].getBoundingClientRect();

    const draggableEnd = Math.floor(lastChildBoundingClientRect[endingSide]);
    const wrapperEnd = Math.floor(wrapperBoundingClientRect[endingSide]);

    return draggableEnd === wrapperEnd;
  };

  const drag = e => {
    const wrapperBoundingClientRect = wrapperRef.current.getBoundingClientRect();
    const draggableBoundingClientRect = draggableRef.current.getBoundingClientRect();

    setState({
      dragInProgress: true,
      xPositionOfEventStart: getXCoordinate(e),
      draggableOffsetStart:
        draggableBoundingClientRect[startingSide] - wrapperBoundingClientRect[startingSide],
      draggableTransformStart: draggableRef.current.style.transform,
      isFirstIndex: isFirstIndex(wrapperBoundingClientRect),
      isLastIndex: isLastIndex(wrapperBoundingClientRect),
    });
  };

  const reset = () => {
    setState(initialState);
  };

  return [state, { drag, reset }];
};

const boundarySlowingFactor = 0.1;
const minMovementThreshold = 0.05;

const getSlowedDownOffset = ({ draggableOffsetStart, xMouseMovement }) =>
  draggableOffsetStart + xMouseMovement * boundarySlowingFactor;

const preventDragging = e => {
  e.preventDefault();
};

const getMovesIntoPrevSlideDirection = (isRtl, xMouseMovement) => {
  const mouseMovesRight = xMouseMovement > 0;
  return isRtl ? !mouseMovesRight : mouseMovesRight;
};

const getDraggableOffsetWithBoundaries = ({ event, state, isRtl }) => {
  const xMouseMovement = getXMouseMovement(event, state.xPositionOfEventStart);
  const movesIntoPrevSlideDirection = getMovesIntoPrevSlideDirection(isRtl, xMouseMovement);

  if (
    (state.isFirstIndex && movesIntoPrevSlideDirection) ||
    (state.isLastIndex && !movesIntoPrevSlideDirection)
  ) {
    return getSlowedDownOffset({
      draggableOffsetStart: state.draggableOffsetStart,
      xMouseMovement,
    });
  }
  return xMouseMovement + state.draggableOffsetStart;
};

// @VisibleForTesting
export const checkBoundries = ({ event, state, isRtl, draggableRef }) => {
  const draggableOffsetWithBoundaries = getDraggableOffsetWithBoundaries({ event, state, isRtl });

  const boundaryLimit = Math.ceil(draggableRef.current.parentNode.offsetWidth * 0.1);
  const boundaryOffsetWidthLimit = Math.abs(draggableOffsetWithBoundaries) - boundaryLimit;
  const offsetWidth =
    draggableRef.current.getBoundingClientRect().width -
    event.currentTarget.getBoundingClientRect().width;

  const slidesWidth =
    event.currentTarget.getBoundingClientRect().width * draggableRef.current.children.length;

  const boundaryLimitOutOffDraggableOffsetWithBoundaries = isRtl
    ? draggableOffsetWithBoundaries >= -boundaryLimit
    : draggableOffsetWithBoundaries <= boundaryLimit;

  const isInBoundary =
    boundaryOffsetWidthLimit <= offsetWidth &&
    boundaryLimitOutOffDraggableOffsetWithBoundaries &&
    Math.abs(draggableOffsetWithBoundaries) <= slidesWidth + boundaryLimit;

  return {
    isInBoundary,
    draggableOffsetWithBoundaries,
  };
};

// @used in ROAD
export const WithDraggable = ({ className, prev, next, children, isRtl: propRtl }) => {
  const { isRtl: contextRtl } = useLanguage();
  const isRtl = propRtl ?? contextRtl;

  const draggableRef = useRef();
  const wrapperRef = useRef();

  const [state, { drag, reset }] = useDragReducer({ draggableRef, wrapperRef, isRtl });

  const handleDragStart = e => {
    drag(e);
  };

  const handleDragMove = e => {
    if (!state.dragInProgress) {
      return;
    }
    const { isInBoundary, draggableOffsetWithBoundaries } = checkBoundries({
      event: e,
      state,
      isRtl,
      draggableRef,
    });

    if (isInBoundary) {
      setTranslateX({ target: draggableRef.current.style, x: draggableOffsetWithBoundaries });
    }
  };

  const isDragDistanceShorterThanThreshold = xMouseMovement =>
    Math.abs(xMouseMovement) < wrapperRef.current.offsetWidth * minMovementThreshold;

  const handleDragEnd = e => {
    if (!state.dragInProgress) {
      return;
    }
    detachTranslateX({
      target: draggableRef.current.style,
      originalX: state.draggableTransformStart,
    });
    reset();
    const xMouseMovement = getXMouseMovement(e, state.xPositionOfEventStart);
    if (isDragDistanceShorterThanThreshold(xMouseMovement)) {
      return;
    }
    const movesIntoPrevSlideDirection = getMovesIntoPrevSlideDirection(isRtl, xMouseMovement);

    if (movesIntoPrevSlideDirection) {
      prev();
    } else {
      next();
    }
  };

  return (
    <div
      className={className}
      onMouseDown={handleDragStart}
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchMove={handleDragMove}
      onTouchEnd={handleDragEnd}
      onTouchCancel={handleDragEnd}
      onDragStart={preventDragging}
      role="presentation"
      ref={wrapperRef}
    >
      {children(draggableRef)}
    </div>
  );
};

export const StyledWithDraggable = styled(WithDraggable)`
  cursor: grab;
  user-select: none;

  &:active {
    cursor: grabbing;
  }
`;
