import styled from 'styled-components';
import { ANSWER_STATE } from '../answerState';
import { StyledResponsiveDwPicture } from '../../../../ResponsiveDwPicture/ResponsiveDwPicture.jsx';
import { StyledExerciseButton } from '../../../../BubbleButton/ExerciseButton.jsx';

export const LessonExerciseImageAnswer = ({ answer, handleAnswerClicked, ...restProps }) => {
  const { id, answerState, alternativeText, images } = answer;
  const image = images[0];

  return (
    <StyledExerciseButton
      aria-selected={answerState === ANSWER_STATE.SELECTED}
      selected={answerState === ANSWER_STATE.SELECTED || answerState === ANSWER_STATE.SOLVED}
      correct={answerState === ANSWER_STATE.CORRECT}
      incorrect={answerState === ANSWER_STATE.WRONG}
      data-exercise-answer-id={id}
      onClick={handleAnswerClicked}
      {...restProps}
    >
      {image && <StyledResponsiveDwPicture alt={alternativeText} image={image} />}
    </StyledExerciseButton>
  );
};

export const StyledLessonExerciseImageAnswer = styled(LessonExerciseImageAnswer)`
  height: unset;
  padding: 3px;
`;
