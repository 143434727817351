import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { useMemo } from 'react';
import { useGlobalsContext } from '../../context/GlobalsContext';
import { useFooter } from '../../context/FooterContext';

const fragmentName = ({ type }) => `${type}ConditionalSubdomainRedirectFragment`;
export const conditionalSubdomainRedirectFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    ({ type }) => type,
    ({ type }) => gql`
      fragment ${fragmentName({ type })} on ${type} {
        subdomain
        subdomainUrl
        namedUrl
      }
    `,
  ),
};

export const useConditionalSubdomainRedirect = ({ subdomain, subdomainUrl, namedUrl }) => {
  const { window } = useGlobalsContext();
  const footer = useFooter();

  const noRedirectUrls = useMemo(
    () =>
      !footer
        ? []
        : Object.values(footer)
            .filter(Boolean)
            .map(f => f.id)
            .filter(Boolean),
    [footer],
  );

  // useEffect is not used here, to make it work in ssr
  if (
    subdomain !== 'LEARN_GERMAN' &&
    subdomainUrl &&
    namedUrl &&
    noRedirectUrls.every(id => !namedUrl.endsWith(id))
  ) {
    // namedUrl may already have the subdomainUrl prepended, depending on webapi version
    const isRelativeUrl = /^\//.test(namedUrl);
    window.location?.replace?.(isRelativeUrl ? `${subdomainUrl}${namedUrl}` : namedUrl);
  }
};

/**
 * Wraps useConditionalSubdomainRedirect so it can be used in render callbacks
 *
 * @param {Parameters<typeof useConditionalSubdomainRedirect>[0]} props
 */
export const ConditionalSubdomainRedirect = props => {
  useConditionalSubdomainRedirect(props);

  return null;
};
