import { useEffect, useRef } from 'react';

export const useIsMountedRef = () => {
  const isMountedRef = useRef(false);

  const setMounted = isMounted => {
    isMountedRef.current = isMounted;
  };

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  });

  return isMountedRef;
};
