import styled from 'styled-components';
import { useRef } from 'react';
import { ReactComponent as AudioIcon } from '../../../assets/svg/audio-speaker.svg';
import { PageLinkInteraction } from '../../Interaction/Interaction.jsx';

export const ModalAudio = ({ className, title, audioFile }) => {
  const audioRef = useRef();

  const handlePlayAudio = e => {
    e.preventDefault();
    audioRef.current.play();
  };

  if (!audioFile) {
    return (
      <PageLinkInteraction as="h4" className={className}>
        {title}
      </PageLinkInteraction>
    );
  }
  return (
    <>
      <a
        className={className}
        href="/#"
        onClick={e => handlePlayAudio(e)}
        role="button"
        tabIndex={0}
        data-testid="knowledgeModalAudio"
      >
        <PageLinkInteraction as="h4" className="audio">
          <AudioIcon />
          {title}
        </PageLinkInteraction>
      </a>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef} tabIndex="0">
        <source src={audioFile} data-testid="audio-source" type="audio/MP3" />
      </audio>
    </>
  );
};

export const StyledModalAudio = styled(ModalAudio)`
  display: flex;
  padding: 0;
  margin: 0.5rem 0 0;
  text-decoration: none;

  ${PageLinkInteraction} {
    margin: 0;
    svg {
      height: 1rem;
      /* @noflip */
      margin: 0 0.5rem 0.2rem 0;
      vertical-align: middle;
    }
  }
`;
