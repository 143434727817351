import { replaceInPattern } from './i18n';

const ratioToPercentage = (widthProportion, heightProportion) =>
  (heightProportion / widthProportion) * 100;

export const ASPECT_RATIO = {
  landscape: ratioToPercentage(16, 9),
  landscape_legacy: ratioToPercentage(4, 3),
  portrait: ratioToPercentage(3, 4),
  square: ratioToPercentage(1, 1),
  cinemascope: ratioToPercentage(16, 7),
};

export const replaceFormatInUrl = (url, formatId) => replaceInPattern(url, { formatId });

export const formatLg = Object.freeze({
  '60X': {
    aspectRatio: ASPECT_RATIO.landscape,
    formats: [
      {
        id: 600,
        width: 78,
      },
      {
        id: 601,
        width: 201,
      },
      {
        id: 602,
        width: 379,
      },
      {
        id: 603,
        width: 545,
      },
      {
        id: 604,
        width: 767,
      },
      {
        id: 605,
        width: 1199,
      },
      {
        id: 606,
        width: 1568,
      },
      {
        id: 607,
        width: 1920,
      },
    ],
  },
  '70X': {
    aspectRatio: ASPECT_RATIO.portrait,
    formats: [
      {
        id: 700,
        width: 48,
      },
      {
        id: 701,
        width: 300,
      },
      {
        id: 702,
        width: 440,
      },
      {
        id: 703,
        width: 575,
      },
      {
        id: 704,
        width: 767,
      },
      {
        id: 705,
        width: 991,
      },
    ],
  },
  '80X': {
    aspectRatio: ASPECT_RATIO.square,
    formats: [
      {
        id: 800,
        width: 50,
      },
      {
        id: 801,
        width: 129,
      },
      {
        id: 802,
        width: 352,
      },
      {
        id: 803,
        width: 575,
      },
      {
        id: 804,
        width: 767,
      },
      {
        id: 805,
        width: 1024,
      },
      {
        id: 806,
        width: 1400,
      },
    ],
  },
  '90X': {
    aspectRatio: null, // unknown, can be 16:9 and 3:4, needs more info
    formats: [
      {
        id: 900,
        width: 48,
      },
      {
        id: 901,
        width: 375,
      },
      {
        id: 902,
        width: 475,
      },
      {
        id: 903,
        width: 600,
      },
      {
        id: 904,
        width: 768,
      },
      {
        id: 905,
        width: 960,
      },
      {
        id: 906,
        width: 1110,
      },
    ],
  },
  '100X': {
    aspectRatio: ASPECT_RATIO.cinemascope,
    formats: [
      {
        id: 1000,
        width: 80,
      },
      {
        id: 1001,
        width: 576,
      },
      {
        id: 1002,
        width: 768,
      },
      {
        id: 1003,
        width: 992,
      },
      {
        id: 1004,
        width: 1200,
      },
      {
        id: 1005,
        width: 1408,
      },
      {
        id: 1006,
        width: 1600,
      },
    ],
  },
  FREE: {
    aspectRatio: null, // unknown
    formats: [
      {
        id: 7,
        width: 0,
      },
    ],
  },
  LEGACY: {
    aspectRatio: null, // unknown, can be 4:3 and 3:4, needs more info
    formats: [
      {
        id: 4,
        width: 0,
      },
    ],
  },
});

/**
 * @param {{ formatGroupName: keyof formatLg, imgWidth: number }}
 */
export const getFormatIdForWidth = ({ formatGroupName, imgWidth }) => {
  const { formats } = formatLg[formatGroupName];
  const formatDef = formats.find(({ width }) => width >= imgWidth) || formats[formats.length - 1];
  return formatDef.id;
};
