import classNames from 'classnames';
import { forwardRef, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { colors } from '../../utils/css';
import { replaceFormatInUrl } from '../../utils/imgUtils';
import { useGlobalsContext } from '../../context/GlobalsContext';

export const INTERSECTION_OBSERVER_SETTINGS = {
  triggerOnce: true,
  rootMargin: '1000px 1000px',
};

// @used in ROAD
export const LazyLoadDwPicture = forwardRef(
  (
    { image: { staticUrl }, alt, className, lqFormatId, hqFormatId, aspectRatio, isA = 'figure' },
    ownRef,
  ) => {
    const { Image } = useGlobalsContext();
    const [inViewRef, inView] = useInView(INTERSECTION_OBSERVER_SETTINGS);

    const [lqLoaded, setLqLoaded] = useState(false);
    const [hqLoaded, setHqLoaded] = useState(false);
    const [isDirectlyAvailableInCache, setIsDirectlyAvailableInCache] = useState(false);

    useEffect(() => {
      if (inView) {
        const hqImg = new Image();
        hqImg.src = replaceFormatInUrl(staticUrl, hqFormatId);
        if (hqImg.complete) {
          setIsDirectlyAvailableInCache(true);
          hqImg.decode().then(onHqLoad);
        }
      }
    }, [Image, hqFormatId, inView, staticUrl]);

    const onHqLoad = () => {
      setHqLoaded(true);
    };

    const onLqLoad = () => {
      if (!hqLoaded) {
        const hqImg = new Image();
        hqImg.src = replaceFormatInUrl(staticUrl, hqFormatId);
        setLqLoaded(true);
        hqImg.decode().then(onHqLoad);
      }
    };
    const Tag = isA;
    return (
      <Tag
        className={`${className} lazy-load-container`}
        ref={ownRef}
        style={aspectRatio ? { paddingBottom: `${aspectRatio}%` } : {}}
      >
        <img
          ref={inViewRef}
          onLoad={onLqLoad}
          src={inView ? replaceFormatInUrl(staticUrl, lqFormatId) : ''}
          className={classNames('lq-img', { loaded: lqLoaded })}
          aria-hidden="true"
          alt={alt}
        />
        <img
          className={classNames('hq-img', {
            loaded: hqLoaded,
            'quick-transition': isDirectlyAvailableInCache,
          })}
          src={hqLoaded ? replaceFormatInUrl(staticUrl, hqFormatId) : ''}
          alt={alt}
        />
      </Tag>
    );
  },
);

export const dwPicStyles = `
  background-color: ${colors.DW_GREY_02};
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  margin: 0;
`;

export const dwPicImgStyles = `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
`;

export const StyledLazyLoadDwPicture = styled(LazyLoadDwPicture)`
  ${dwPicStyles}

  img {
    ${dwPicImgStyles}
    opacity: 0;
    transition: opacity 1s linear;
  }

  img.quick-transition {
    transition: opacity 250ms linear;
  }

  img.loaded {
    opacity: 1;
  }

  .lq-img {
    filter: blur(15px);
  }
`;
