import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { StyledContentContainer as ContentContainer } from '../../ContentContainer/ContentContainer.jsx';
import { ContentSection } from '../../ContentContainer/ContentSection/ContentSection.jsx';
import { ArticleLinks } from './Links/ArticleLinks.jsx';
import { AudioLinks } from './Links/AudioLinks.jsx';
import { DownloadLinks } from './Links/DownloadLinks.jsx';
import { VideoLinks, videoResourceLinksFragment } from './Links/VideoLinks.jsx';
import { ExternalLinks } from './Links/ExternalLinks.jsx';
import { I18nText } from '../../I18n/I18nText.jsx';
import { DetailPageHeadline } from '../../Headline/Headline.jsx';

const fragmentName = 'LessonExtras';
export const lessonExtrasFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Lesson {
        id
        contentLinks(targetTypes: [ARTICLE, DOWNLOAD, AUDIO, VIDEO]) {
          id
          name
          description
          targetId
          targetType
          target {
            ... on Download {
              filename
            }
            ... on Article {
              name
              namedUrl
            }
            ... on Audio {
              id
              name
              audioResourceLinks {
                id
                url
                size
                format
                variant
              }
            }
            ...${videoResourceLinksFragment.name}
          }
        }
        externalLinks {
          id
          name
          description
          modelType
          url
        }
      }
      ${videoResourceLinksFragment.fragment()}
    `,
  ),
};

const filterContentByType = type => contentLink => contentLink.targetType === type;

export const LessonExtras = ({ content = {} }) => {
  const { contentLinks = [] } = content;
  const downloads = contentLinks.filter(filterContentByType('DOWNLOAD'));
  const articles = contentLinks.filter(filterContentByType('ARTICLE'));
  const audios = contentLinks.filter(filterContentByType('AUDIO'));
  const videos = contentLinks.filter(filterContentByType('VIDEO'));
  const externalLinks = content.externalLinks?.filter(eL => eL.modelType === 'EXTERNAL_LINK') ?? [];

  const hasDownloads = downloads.length > 0;
  const hasVideos = videos.length > 0;
  const hasAudios = audios.length > 0;
  const hasArticles = articles.length > 0;
  const hasExternalLinks = externalLinks.length > 0;

  return (
    <ContentContainer title="lesson.menu.extras">
      <I18nText isA={DetailPageHeadline} as="h2" translation="lesson.menu.extras" />
      {hasDownloads && (
        <ContentSection title="lesson.downloads">
          <DownloadLinks data={downloads} />
        </ContentSection>
      )}
      {(hasVideos || hasAudios) && (
        <ContentSection title="lesson.audioVideos">
          {hasVideos && <VideoLinks data={videos} />}
          {hasAudios && <AudioLinks data={audios} />}
        </ContentSection>
      )}
      {hasArticles && (
        <ContentSection title="lesson.links.internal">
          <ArticleLinks data={articles} />
        </ContentSection>
      )}
      {hasExternalLinks && (
        <ContentSection title="lesson.links.external">
          <ExternalLinks data={externalLinks} />
        </ContentSection>
      )}
    </ContentContainer>
  );
};
