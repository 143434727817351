import { css } from 'styled-components';
import { columnSize, mediaMax, mediaMin } from '../../../../utils/css';
import { Bubble } from '../../../Bubble/Bubble.jsx';
import { BubbleButton } from '../../../BubbleButton/BubbleButton.jsx';

export const exerciseBasicGrid = css`
  /* @noflip */
   {
    direction: ltr;
    text-align: left;
  }

  display: grid;
  grid-template-columns: 1fr;

  ${mediaMin.md`
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    column-gap: ${columnSize.c2};
    
    > .question {
      grid-area: 1 / 1 / span 1 / span 1;
      margin-bottom: 50px;
    }
    > .subInquiry {
      grid-area: 1 / 2 / span 1 / span 1;
      position:relative;
      margin-bottom: 50px;
    }
    > .selfAssessment {
      grid-area: 3 / 1 / span 1 / span 1;
      position:relative;
    }
    > .answer-input {
      grid-area: 1 / 2 / span 1 / span 1;
      margin-bottom: 50px;
      position: relative;
    }
    > .hint {
      grid-area: 2 / 1 / span 1 / span 2;
      margin-bottom: 50px;
    }
    > .check {
      grid-area: 3 / 1 / span 1 / span 2;
      margin-bottom: 50px;
    }
  `}
`;

export const gridSpacingForResults = css`
  --grid-spacing: ${columnSize.c2};

  ${mediaMin.lg`
    --grid-spacing: ${columnSize.c4};
    --grid-width: ${columnSize.c22};      
  `}

  margin-left: var(--grid-spacing);
  margin-right: var(--grid-spacing);
  width: var(--grid-width);
`;

export const gridSpacingStyles = ({ spacing = `0` } = {}) => css`
  --grid-spacing: ${spacing};

  ${mediaMin.md`
      --grid-spacing: ${columnSize.c2};
      --grid-width: ${columnSize.c26};
    `}

  ${mediaMin.lg`
      --grid-spacing: ${columnSize.c4};
      --grid-width: ${columnSize.c22};
    `}

  margin-left: var(--grid-spacing);
  margin-right: var(--grid-spacing);
  width: var(--grid-width);
`;

export const baseExerciseStyles = css`
  /* @noflip */
  direction: ltr;
  margin: 0;

  > div {
    margin-bottom: 50px;
    column-gap: ${columnSize.c2};
    ${mediaMin.md`
      display: flex;
      justify-content: space-between;
      align-items: start;
    `}

    > div {
      position: relative;
      width: 100%;

      padding: 0;
    }
  }
`;

export const joinQuestionStyles = css`
  ${mediaMax.sm`
    ${Bubble}, ${BubbleButton}.question {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  `}
`;
