import { useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { INQUIRY_STATE } from '../workflow/inquiryState';
import { isNormalizedTextEqual } from '../utils/exerciseUtils';
import { KeyboardWrapper } from '../Keyboard/KeyboardWrapper.jsx';
import { colors } from '../../../../utils/css';
import { BaseInput } from '../../../FormElements/BaseInput.jsx';

export const ClozeInput = ({
  inquiryState,
  index,
  config,
  handleOnInputChange,
  isDisabled,
  // expecting a ref here, because we don't want to remount on changes.
  // Otherweise focus and selection state would be reset on every change.
  givenAnswersRef,
  correctAnswer,
  className,
}) => {
  const [, setArbitraryState] = useState(false);
  const forceRerender = () => setArbitraryState(a => !a);

  const givenAnswer = givenAnswersRef.current[index];

  const isCorrect = isNormalizedTextEqual(correctAnswer.text, givenAnswer);

  const handleChange = input => {
    handleOnInputChange(index, input);
    // Manual rerender necessary, because ref updates don't trigger a rerender.
    forceRerender();
  };

  return (
    <>
      <BaseInput
        type="text"
        aria-required="true"
        disabled={isDisabled}
        maxLength={config.maxLength}
        className={classnames(
          className,
          (inquiryState === INQUIRY_STATE.CORRECT || inquiryState === INQUIRY_STATE.WRONG) &&
            (isCorrect ? 'correctAnswer' : 'wrongAnswer'),
          inquiryState === INQUIRY_STATE.SOLVED && 'solvedAnswer',
        )}
        aria-label="exercise-input"
        data-exercise-answer-id={correctAnswer.alternativeId}
        data-size={config.size}
        aria-haspopup="true"
        onChange={e => handleChange(e.target.value)}
        value={inquiryState === INQUIRY_STATE.SOLVED ? correctAnswer.text : givenAnswer}
        autoComplete="off"
        data-testid="lueckenTextInput"
      />
      {!isDisabled && (
        <KeyboardWrapper
          onChange={handleChange}
          inputValue={givenAnswer}
          inputSize={config.size}
          inputMaxLength={config.maxLength}
          inputClassName={className}
        />
      )}
    </>
  );
};

// NOTE: styles are applied to both this input and the keyboard input
export const StyledClozeInput = styled(ClozeInput)`
  height: 2rem;
  display: inline;
  text-align: center;
  background-color: ${colors.LG_WHITE};

  :focus {
    outline: 0;
  }

  &[data-size='small'] {
    width: 85px;
  }

  &[data-size='medium'] {
    width: 150px;
  }

  &[data-size='full'] {
    width: 50%;
    padding: 6px 2%;
    margin: 0 5px 0 0;
  }

  &.correctAnswer {
    background-color: ${colors.ACCENT_GREEN};
    color: ${colors.LG_WHITE};

    :active:not([disabled]),
    :hover:not([disabled]),
    :focus {
      color: ${colors.DARK_BLUE_GREY_02};
    }
  }

  &.wrongAnswer {
    background-color: ${colors.ACCENT_RED};
    color: ${colors.LG_WHITE};

    :active:not([disabled]),
    :hover:not([disabled]),
    :focus {
      color: ${colors.DARK_BLUE_GREY_02};
    }
  }

  &.solvedAnswer {
    background-color: ${colors.DARK_BLUE_GREY_02};
    color: ${colors.LG_WHITE};

    :hover,
    :active,
    :focus {
      background-color: ${colors.DARK_BLUE_GREY_02};
      color: ${colors.LG_WHITE};
    }
  }

  &[disabled] {
    cursor: not-allowed;
  }
`;
