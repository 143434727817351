import { useEffect, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { StyledVocabularyTrainerStatus as VocabularyTrainerStatus } from './VocabularyTrainerStatus.jsx';
import { StyledVocabularyTrainerItem as VocabularyTrainerItem } from './VocabularyTrainerItem.jsx';
import { StyledVocabularyTrainerSmileys as VocabularyTrainerSmileys } from './VocabularyTrainerSmileys.jsx';
import { useTransition } from '../../hooks/useTransition';
import { getFeedbackUrl } from '../../utils/url/urlFactory';
import { FEEDBACK_TYPES } from '../../constants/feedback';
import { mediaMin } from '../../utils/css';
import { useLanguage } from '../../context/LanguageContext';

export const VocabularyTrainerExercise = ({ vocabularies, handleExerciseEnd, className }) => {
  const [isSolved, setSolved] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [trainerVocabularies, setTrainerVocabularies] = useState(vocabularies);
  const history = useHistory();
  const { langCode } = useLanguage();

  const nextVocabulary = newTrainerVocabularies => {
    const slicedTrainerVocabularies = newTrainerVocabularies.slice(1);
    setTrainerVocabularies(slicedTrainerVocabularies);
    setSolved(false);
    if (!slicedTrainerVocabularies.length) {
      handleExerciseEnd();
    }
  };

  const { transitionStatus, startTransition } = useTransition({
    delay: 300,
  });

  useEffect(() => {
    if (vocabularies.length === 0) {
      history.push(getFeedbackUrl(FEEDBACK_TYPES.VOCABULARY_TRAINER_NO_VOCABULARIES, langCode));
    }
  }, [history, langCode, vocabularies.length]);

  const onSolved = () => {
    setSolved(true);
  };

  const handleCorrectAnswer = () => {
    startTransition(() => nextVocabulary(trainerVocabularies));
    setCorrectAnswers(value => value + 1);
  };
  const handleWrongAnswer = () => {
    const newTrainerVocabularies = [...trainerVocabularies, trainerVocabularies[0]];
    startTransition(() => nextVocabulary(newTrainerVocabularies));
  };

  if (!trainerVocabularies.length) {
    return null;
  }

  return (
    <div className={classnames(className, transitionStatus)}>
      <VocabularyTrainerStatus
        className="status-top"
        number={correctAnswers}
        total={vocabularies.length}
      />
      <VocabularyTrainerItem
        vocabulary={trainerVocabularies[0]}
        onSolved={onSolved}
        isHidden={isSolved}
      />
      {isSolved && (
        <VocabularyTrainerSmileys
          handleCorrectAnswer={handleCorrectAnswer}
          handleWrongAnswer={handleWrongAnswer}
        />
      )}
      <VocabularyTrainerStatus
        className="status-bottom"
        number={correctAnswers}
        total={vocabularies.length}
      />
    </div>
  );
};

export const StyledVocabularyTrainerExercise = styled(VocabularyTrainerExercise)`
  &.busy {
    opacity: 0;
  }
  &.ready {
    opacity: 1;
  }
  & {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .status-top {
    margin-top: 20px;
    margin-bottom: 10px;

    ${mediaMin.md`
      margin-top: 30px;
      margin-bottom: 20px;
      font-size: 21px;
    `}

    ${mediaMin.xl`
      margin-top: 70px;
      margin-bottom: 30px;
      font-size: 24px;
    `}
  }
  .status-bottom {
    margin-top: 20px;
    margin-bottom: 40px;

    ${mediaMin.md`
      margin-bottom: 40px;
      font-size: 21px;
    `}

    ${mediaMin.xl`
      margin-top: 30px;
      margin-bottom: 70px;
      font-size: 24px;
    `}
  }
`;
