import styled from 'styled-components';
import { forwardRef } from 'react';
import { getSliderPositionStyles, transition } from '../../utils/draggableUtils';
import { mediaMin } from '../../utils/css';

// @used in ROAD
export const Slider = forwardRef(({ className, children, currentIndex, isRtl }, ref) => {
  if (!children || children.length === 0) {
    return null;
  }
  const sliderPositionStyle = getSliderPositionStyles({
    childrenLength: children.length,
    currentIndex,
    isRtl,
  });
  return (
    <div className={className} style={sliderPositionStyle} ref={ref}>
      {children}
    </div>
  );
});

export const StyledSlider = styled(Slider)`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 15px;
  margin-bottom: 20px;
  position: relative;
  transition: ${transition};

  ${mediaMin.md`
    margin-top: 30px;
    margin-bottom: 30px; 
  `}
`;
