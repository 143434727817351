import {
  progressState,
  StyledProgressIcon as ProgressIcon,
} from '../ProgressIcon/ProgressIcon.jsx';
import { calculatePercentage } from '../../utils/commons';
import { StyledCertificateIcon } from '../ProgressIcon/CertificateIcon.jsx';
import { StyledProgressIconStar } from '../ProgressIcon/ProgressIconStar.jsx';

export const LearnProgressIcon = ({ learnProgress, finalLessonId }) => {
  if (!learnProgress) {
    return <ProgressIcon progress={progressState.EMPTY} />;
  }

  const {
    resultPoints,
    maxPointsForAllExercises,
    doneExerciseCount,
    allExerciseCount,
    finalTestPassed,
  } = learnProgress;

  if (doneExerciseCount === 0) {
    return <ProgressIcon progress={progressState.EMPTY} />;
  }

  if (finalTestPassed) {
    return <StyledCertificateIcon id={finalLessonId} />;
  }

  if (doneExerciseCount < allExerciseCount) {
    return <ProgressIcon progress={progressState.HALF} />;
  }

  if (doneExerciseCount === allExerciseCount) {
    const resultInPercentage = calculatePercentage(resultPoints, maxPointsForAllExercises);
    if (resultInPercentage >= 80) {
      return <StyledProgressIconStar />;
    }
    return <ProgressIcon progress={progressState.FULL} />;
  }

  return <ProgressIcon progress={progressState.EMPTY} />;
};
