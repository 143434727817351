import { useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AudioContext } from '../context/AudioContext.jsx';
import { userDataSelector } from '../state/user/userSelectors';
import { noop } from '../utils/commons';

export const useAudio = () => {
  const [audiosRefArray, setAudiosRefArray, wrongAnswerAudioRef, correctAnswerAudioRef] =
    useContext(AudioContext);
  const { signalSoundDisabled } = useSelector(userDataSelector);

  /**
   * @type {(currentAudio?: React.MutableRefObject<HTMLAudioElement>) => void}
   */
  const stopOtherAudios = useCallback(
    currentAudio => {
      audiosRefArray.forEach(audio => {
        if (audio !== currentAudio && audio.current) {
          audio.current.pause();
          // eslint-disable-next-line no-param-reassign
          audio.current.currentTime = 0;
        }
      });
    },
    [audiosRefArray],
  );

  /**
   * @type {(e: Event, ref: React.MutableRefObject<HTMLAudioElement>) => void}
   */
  const playAudio = useCallback(
    (e, ref) => {
      e.preventDefault();
      stopOtherAudios(ref);
      ref.current.play();
    },
    [stopOtherAudios],
  );

  /**
   * @type {(ref: React.MutableRefObject<HTMLAudioElement>) => () => void}
   * returns cleanup function to remove the ref
   */
  const addRef = useCallback(
    ref => {
      setAudiosRefArray(arr => (arr.includes(ref) ? arr : [...arr, ref]));

      return function removeRef() {
        setAudiosRefArray(arr => arr.filter(item => item !== ref));
      };
    },
    [setAudiosRefArray],
  );

  const playWrongDefaultAudioFeedBack = useCallback(() => {
    wrongAnswerAudioRef.current.play();
  }, [wrongAnswerAudioRef]);

  const playCorrectDefaultAudioFeedBack = useCallback(() => {
    correctAnswerAudioRef.current.play();
  }, [correctAnswerAudioRef]);

  return {
    playAudio,
    stopOtherAudios,
    addRef,
    playWrongDefaultAudioFeedBack: signalSoundDisabled ? noop : playWrongDefaultAudioFeedBack,
    playCorrectDefaultAudioFeedBack: signalSoundDisabled ? noop : playCorrectDefaultAudioFeedBack,
  };
};
