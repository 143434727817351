import classnames from 'classnames';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { replacePlaceholderImagesWithResponsivePictures } from '../../utils/replacers/imageReplacer.jsx';
import { modifyExternalLinks } from '../../utils/replacers/linkReplacer';
import { colors } from '../../utils/css';
import { InRichTextProvider } from '../../context/PageEmbeddingContext.jsx';
import { useTranslation } from '../../hooks/useTranslation';
import { richTextHeadlineStyles, richTextSubHeadlineStyles } from '../Headline/Headline.jsx';
import { detailRichtextTextStyles } from '../Text/Text.jsx';
import { richtextLinkInteractionStyles } from '../Interaction/Interaction.jsx';

// @used in ROAD
export const RichText = ({
  content,
  isContainer = true,
  className,
  noContainer,
  additionalReplacements,
  isA: Tag = 'span',
  ...textProps
}) => {
  /** @type {React.MutableRefObject<Element>} */
  const textRef = useRef();
  const [portals, setPortals] = useState(null);

  const externalLinkPrefix = useTranslation('common.link.external_prefix');

  const replace = useCallback(() => {
    if (!content) {
      return;
    }

    modifyExternalLinks({ node: textRef.current, externalLinkPrefix });

    const picturePortals = replacePlaceholderImagesWithResponsivePictures(textRef.current);
    const additionalPortals = additionalReplacements?.(textRef.current);

    setPortals([...(picturePortals ?? []), ...(additionalPortals ?? [])]);
  }, [content, externalLinkPrefix, additionalReplacements]);

  useLayoutEffect(() => replace(), [replace]);

  // Force a rerender when new replacements are passed down
  const key = useMemo(() => (replace !== undefined ? uuid() : undefined), [replace]);

  if (noContainer) {
    return (
      <>
        <Tag
          ref={textRef}
          key={key}
          className={className}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
          {...textProps}
        />
        <InRichTextProvider>{portals}</InRichTextProvider>
      </>
    );
  }

  return (
    <div className={classnames({ 'richtext-content-container': isContainer }, className)}>
      <Tag
        ref={textRef}
        key={key}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
        {...textProps}
      />
      <InRichTextProvider>{portals}</InRichTextProvider>
    </div>
  );
};

export const StyledRichText = styled(RichText)`
  ${detailRichtextTextStyles}

  &.richtext-content-container {
    p {
      margin-bottom: 1rem;
    }

    /* @noflip */
    > span > p[dir='ltr'] {
      text-align: left;
    }
  }

  p {
    margin-bottom: 0.5rem;
  }

  .embed {
    :not(.image-gallery) {
      width: 100%;
    }

    max-width: 100vw;
    margin: 0.5rem auto 1rem auto;
  }

  h2 {
    ${richTextHeadlineStyles}
    margin: 2rem 0 1rem;
  }

  h3 {
    ${richTextSubHeadlineStyles}
    margin: 2rem 0 1rem;
  }

  a {
    ${richtextLinkInteractionStyles}
  }

  > span > figure {
    margin: 0;
    &:not(.big) {
      max-width: 300px;
    }

    margin-bottom: 1rem;
  }

  .placeholder-image figcaption.img-caption {
    word-wrap: break-word;
    hyphens: auto;
    display: flow-root;
    width: 100%;
    padding: 10px 15px;
    background-color: ${colors.BLUE_GREY_01};
  }

  ul {
    margin-bottom: 0.5rem;
  }

  div.tab-wrap {
    margin: 2rem 0;

    table {
      border-spacing: 0;
      border-bottom: 1px solid ${colors.DW_GREY_07};

      td,
      th {
        border-top: 1px solid ${colors.DW_GREY_07};
        padding: 0.5rem 0 1rem;

        &:first-child {
          padding-right: 1rem;
        }

        &:last-child {
          padding-left: 1rem;
        }
      }
    }
  }

  svg {
    fill: currentColor;
  }
`;
