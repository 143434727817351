import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { feedbackHelper } from '../../utils/url/feedbackHelpers';
import { getUserByConfirmationId, setPasswordByToken } from '../../services/passwordService';
import { I18nText } from '../I18n/I18nText.jsx';
import { NewPasswordInputs } from '../Input/NewPassword/NewPasswordInputs.jsx';
import { StyledStandardButton as StandardButton } from '../StandardButton/StandardButton.jsx';
import { StyledLoadingMessage as LoadingMessage } from '../commons/LoadingMessage.jsx';
import { mediaMin } from '../../utils/css';
import { StyledInput } from '../FormElements/Input/StyledInput.jsx';
import { useLanguage } from '../../context/LanguageContext';
import { DetailPageHeadline } from '../Headline/Headline.jsx';
import { DetailIntroText } from '../Text/Text.jsx';

export const SetNewPassword = ({ confirmationId, className }) => {
  const history = useHistory();
  const { langCode } = useLanguage();
  const [email, setEmail] = useState(null);

  useEffect(() => {
    let isCanceled = false; // eslint-disable-line no-unused-vars

    getUserByConfirmationId(confirmationId)
      .then(userEmailResponse => {
        if (isCanceled) {
          return;
        }

        if (!userEmailResponse.includes('@')) {
          history.push(feedbackHelper.forInvalidToken(langCode));
        } else {
          setEmail(userEmailResponse);
        }
      })
      .catch(e => {
        history.push(feedbackHelper.getForErrorCode(e, langCode));
      });

    return () => {
      isCanceled = true;
    };
  }, [confirmationId, history, langCode]);

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const { handleSubmit, getValues } = formMethods;

  const onSubmit = async () => {
    try {
      await setPasswordByToken(getValues().password, confirmationId);
      history.push(feedbackHelper.forSetPassword(langCode));
    } catch (err) {
      history.push(feedbackHelper.getForErrorCode(err, langCode));
    }
  };

  return email ? (
    <div className={className}>
      <I18nText isA={DetailPageHeadline} translation="profile.edit.password.set.title" />
      <I18nText isA={DetailIntroText} translation="profile.edit.password.set.subtitle" />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <NewPasswordInputs
            passwordTitleKey="client.newPassword"
            passwordRepeatTitleKey="client.newPasswordRepeat"
            email={email}
          />
          <StandardButton titleKey="profile.edit.password.set.submit" />
        </form>
      </FormProvider>
    </div>
  ) : (
    <LoadingMessage />
  );
};

export const StyledSetNewPassword = styled(SetNewPassword)`
  padding-left: 20px;
  padding-right: 20px;

  ${mediaMin.lg`
    margin-left: 25%;
    margin-right: 25%;
  `}

  > form {
    margin-top: 60px;
    text-align: left;

    > ${StyledInput} {
      margin-bottom: 15px;
    }

    > ${StandardButton} {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
    }
  }
`;
