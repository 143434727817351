import styled from 'styled-components';
import { colors } from '../../utils/css';
import { DateTimeInfo } from '../Info/Info.jsx';

export const StyledValidationMessage = styled(DateTimeInfo)`
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: ${colors.BREAKING_RED};
`;
