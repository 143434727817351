import { useState, useEffect } from 'react';
import globals from '../utils/globals';
import { isServer } from '../utils/ssr';

const CUSTOM_LS_EVENT_NAME = 'local-storage';

const readValue = key => {
  if (isServer()) {
    return null;
  }
  return JSON.parse(globals.localStorage.getItem(key));
};

export const useLocalStorage = key => {
  const [storedValue, setStoredValue] = useState(readValue(key));

  const setValue = value => {
    if (!isServer()) {
      globals.localStorage.setItem(key, JSON.stringify(value));
      setStoredValue(value);

      // We dispatch a custom event so every useLocalStorage hook are notified
      globals.window.dispatchEvent(new Event(CUSTOM_LS_EVENT_NAME));
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue(key));
    };

    // this only works for other documents, not the current one
    globals.window.addEventListener('storage', handleStorageChange);
    // this is a custom event, triggered in writeValueToLocalStorage
    globals.window.addEventListener(CUSTOM_LS_EVENT_NAME, handleStorageChange);

    return () => {
      globals.window.removeEventListener('storage', handleStorageChange);
      globals.window.removeEventListener(CUSTOM_LS_EVENT_NAME, handleStorageChange);
    };
  }, [key]);

  return [storedValue, setValue];
};
