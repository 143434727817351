import { useGlobalsContext } from '../../../context/GlobalsContext';
import { useLocationQueryParam } from '../../../hooks/useLocationQueryParam';
import { useFrontendConfig } from '../../../context/FrontendConfigContext';

export const useStaticInfoForGtm = () => {
  const pageUrl = useGlobalsContext().window.location.href?.split('?')[0];
  const macaParam = useLocationQueryParam('maca');
  const { gtmLevel1Id, gtmEnvironment } = useFrontendConfig();

  return { gtmLevel1Id, macaParam, pageUrl, gtmEnvironment };
};
