import { StyledMediaTag as MediaTag } from '../MediaTag/MediaTag.jsx';
import { TeaserHeadline } from '../Headline/Headline.jsx';

// @used in ROAD
export const MediaTitle = ({ contentType, title, ...restProps }) => (
  <TeaserHeadline {...restProps}>
    <MediaTag contentType={contentType} />
    <span>{title}</span>
  </TeaserHeadline>
);
