import styled from 'styled-components';
import { mediaMax, mediaMin, colors, columnSize } from '../../../../utils/css';
import { StyledToggleableArrow as ToggleableArrow } from '../../../ToggleableArrow/ToggleableArrow.jsx';
import { ContentBlockHeadline } from '../../../Headline/Headline.jsx';
import { I18nText } from '../../../I18n/I18nText.jsx';

const CourseHeader = ({ className, groupNameTranslationKey, isOpen, ...restProps }) => {
  return (
    <div className={className} role="button" tabIndex="0" {...restProps}>
      <I18nText
        isA={ContentBlockHeadline}
        translation={groupNameTranslationKey || 'course.otherLessons'}
      />
      <ToggleableArrow stroke={colors.BLUE_GREY_04} isUp={isOpen} />
    </div>
  );
};

export const StyledCourseHeader = styled(CourseHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 1px solid ${colors.LG_GRAY_TRANSPARENT_1};
  height: 163px;

  ${ContentBlockHeadline} {
    margin: 0;
    padding: 0 ${columnSize.c1};
  }

  ${ToggleableArrow} {
    display: none;
  }

  ${mediaMin.lg`
    pointer-events: none;
  `}

  ${mediaMax.md`
    height: 94px;

    ${ContentBlockHeadline} {
      padding: 0 ${columnSize.c1} 0 ${columnSize.c1};
      border-top: 0;
    }

    ${ToggleableArrow} {
      display: inline-block;
      right: ${columnSize.c1};
    }
  `}
  ${mediaMax.xs`
    ${ContentBlockHeadline} {
      padding: 0 ${columnSize.c2} 0 ${columnSize.c2};
    }
    ${ToggleableArrow} {
      right: ${columnSize.c2};
    }
  `}
`;
