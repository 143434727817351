import { useMemo } from 'react';
import styled from 'styled-components';
import { StyledGrammarOverviewItem } from './GrammarOverviewItem.jsx';
import { columnSize } from '../../utils/css';
import { I18nText } from '../I18n/I18nText.jsx';
import { DetailPageHeadline } from '../Headline/Headline.jsx';
import { DetailRichtextText } from '../Text/Text.jsx';

const UnstyledGrammarOverview = ({ grammarOverview, ...restProps }) => {
  const items = useMemo(
    () =>
      Object.values(grammarOverview).filter(
        item => Array.isArray(item.grammars) && item.grammars.length,
      ),
    [grammarOverview],
  );

  return (
    <div {...restProps}>
      <I18nText isA={DetailPageHeadline} translation="header.menu.grammar" />
      <I18nText isA={DetailRichtextText} translation="grammar.overview.description" />
      {items.map(({ id, headline, grammars }) => (
        <StyledGrammarOverviewItem key={id} title={headline} contents={grammars} />
      ))}
    </div>
  );
};

export const GrammarOverview = styled(UnstyledGrammarOverview)`
  padding: 1.5rem ${columnSize.c2} 3rem;

  > ${DetailPageHeadline} {
    margin: 0 0 1rem;
  }

  > ${DetailRichtextText} {
    margin-bottom: 3rem;
  }
`;
