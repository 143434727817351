/* eslint-disable jsx-a11y/role-supports-aria-props  */
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useAudio } from '../../../../../hooks/useAudio';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { StyledResponsiveDwPicture as ResponsiveDwPicture } from '../../../../ResponsiveDwPicture/ResponsiveDwPicture.jsx';
import { ReactComponent as AudioIcon } from '../../../../../assets/svg/audio-speaker.svg';
import { BubbleButton } from '../../../../BubbleButton/BubbleButton.jsx';

export const LessonExerciseQuestionAudio = ({ className, audio, inquiryText }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const playAudioLabel = useTranslation('exercise.playAudio');
  const { target: answerAudio } = audio || {};
  const { mp3Src: audioUrl, mainContentImage } = answerAudio || {};

  const audioRef = useRef();
  const { addRef, playAudio } = useAudio();

  useEffect(() => addRef(audioRef), [addRef, audioRef]);

  const audioLabel = inquiryText || playAudioLabel;
  return (
    <>
      <BubbleButton
        question
        selected={isPlaying}
        className={className}
        aria-label={`Audio - ${audioLabel}`}
        onClick={e => {
          playAudio(e, audioRef);
          // isPlaying needs to be set immediately, otherwise we get flickering CSS
          setIsPlaying(true);
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio
          className={classnames(isPlaying && 'playing-now')}
          data-testid="question-audio"
          tabIndex="0"
          preload="none"
          onPlaying={() => setIsPlaying(true)}
          onEnded={() => setIsPlaying(false)}
          onPause={() => setIsPlaying(false)}
          ref={audioRef}
        >
          <source data-testid="question-audio-source" src={audioUrl} type="audio/MP3" />
        </audio>
        {audioLabel}
        <AudioIcon title="audio play icon" />
      </BubbleButton>
      {mainContentImage && (
        <ResponsiveDwPicture
          alt={mainContentImage.name}
          image={mainContentImage}
          className="teaser-image"
        />
      )}
    </>
  );
};

export const StyledLessonExerciseQuestionAudio = styled(LessonExerciseQuestionAudio)`
  > div {
    /* @noflip */
    direction: ${props => (props.isRtl ? 'rtl' : 'ltr')};
    /* @noflip */
    text-align: ${props => (props.isRtl ? 'right' : 'left')};
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 7px;

  :has(+ ${ResponsiveDwPicture}) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  > svg {
    flex-shrink: 0;
  }
`;
