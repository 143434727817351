import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { StyledLessonSummary as LessonSummary } from '../components/Lesson/LessonSummary/LessonSummary.jsx';
import { WithGraphQLQueryHandling } from '../components/WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { PageMetaData } from '../components/Page/PageMetaData.jsx';
import { PAGE_TYPES } from '../constants/pageTypes';
import { GtmScriptWithDataLayer, gtmDataLayerFragment } from '../components/GoogleTagManager';
import { useStructuralLessonData } from '../context/StructuralLessonDataContext.jsx';
import { LESSON_SUMMARY_SUFFIX } from '../utils/url/urlFactory';

const queryName = 'LessonSummaryPage';
export const lessonSummaryPageQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}($id: Int!, $lang: Language!) {
        content(id: $id, lang: $lang) {
          ... on Course {
            id
            contentLinks(targetTypes: LESSON) {
              additionalInformation
              targetId
              lesson: target {
                ... on Lesson {
                  id
                  shortTitle
                  namedUrl
                }
              }
            }
            ${gtmDataLayerFragment.partial()}
          }
        }
      }
    `,
  ),
};

export const LessonSummaryPage = ({
  courseId,
  lessonId,
  language,
  lessonName,
  lessonKeywordStrings,
  lastPublicationDate,
  firstPublicationDate,
}) => {
  const { lessonPath } = useStructuralLessonData().structuralData;
  const LessonSummaryQueryVariables = {
    id: +courseId,
    lang: language,
  };

  const getLessonSummaryComponent = ({ data }) => {
    return (
      <>
        <PageMetaData
          pageType={PAGE_TYPES.LESSON_SUMMARY}
          content={{
            name: lessonName,
            keywordStrings: lessonKeywordStrings,
            lastPublicationDate,
            firstPublicationDate,
          }}
          translationFirst={true}
          relativeCanonicalUrl={`${lessonPath}/${LESSON_SUMMARY_SUFFIX}`}
          noSearchEngine
        />
        <GtmScriptWithDataLayer content={data.content} />
        <LessonSummary {...{ data, lessonId, language, lessonName }} />
      </>
    );
  };

  if (!courseId) {
    return getLessonSummaryComponent({ data: [] });
  }

  return (
    <WithGraphQLQueryHandling
      query={lessonSummaryPageQuery.query()}
      queryVariables={LessonSummaryQueryVariables}
    >
      {({ data }) => getLessonSummaryComponent({ data })}
    </WithGraphQLQueryHandling>
  );
};
