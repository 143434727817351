import { StyledLessonExtrasLink as LessonExtrasLink } from './LessonExtrasLink.jsx';
import { getAudioDownloadUrl } from '../../../../utils/url/url';
import { getAudioLinkTitle } from '../../../../utils/audioUtils';

export const AudioLinks = ({ data }) => {
  return data.map(audioLink =>
    audioLink.target.audioResourceLinks.map(({ id, url, format, size, variant }) => (
      <LessonExtrasLink
        key={id}
        href={getAudioDownloadUrl({ audioUrl: url })}
        content={getAudioLinkTitle({ name: audioLink.name, format, size, variant })}
        description={audioLink.description}
      />
    )),
  );
};
