import { memoizeWith } from 'ramda';
import gql from 'graphql-tag';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames';
import { baseExerciseStyles, joinQuestionStyles } from '../utils/exerciseCustomCSS';
import {
  exerciseQuestionTypeSelector,
  LessonExerciseQuestion,
} from '../LessonExerciseQuestion/LessonExerciseQuestion.jsx';
import { StyledSolveCheckButton } from '../../../SolveCheckButton/SolveCheckButton.jsx';
import { StyledPulldownAnswer } from './AnswerTypes/PulldownAnswer.jsx';
import { QUESTION_TYPES } from '../LessonExerciseQuestion/QuestionTypes/questionTypes';
import { StyledLessonExerciseHint as LessonExerciseHint } from '../LessonExerciseHint/LessonExerciseHint.jsx';
import { StyledChooseAnswer } from './AnswerTypes/ChooseAnswer.jsx';
import { StyledWritingAnswer as WritingAnswer } from './AnswerTypes/WritingAnswer.jsx';
import { useAudio } from '../../../../hooks/useAudio';
import { useInquiryHandling } from '../workflow/useInquiryHandling';
import { findFeedbackAudio } from '../../../../utils/audioUtils';
import { LessonExerciseHiddenAudio } from '../LessonExerciseHiddenAudio/LessonExerciseHiddenAudio.jsx';
import { Bubble } from '../../../Bubble/Bubble.jsx';
import { BubbleButton } from '../../../BubbleButton/BubbleButton.jsx';

const fragmentName = 'clozeExercise';
export const clozeExerciseFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Inquiry {
        text
        clozeLayoutType
        subInquiries {
          clozeType
        }
      }       
    `,
  ),
};

export const ClozeExercise = ({
  inquiry,
  initiallyCorrectInquiries = 0,
  knowledges,
  className,
}) => {
  const { exerciseId } = useParams();
  const {
    audios,
    inquiryDescription,
    id: inquiryId,
    learningMetaInfo: { achievableScore },
    mainContentImage,
  } = inquiry;
  const { clozeType } = inquiry.subInquiries[0];

  const [correctSubInquiries, setCorrectSubInquiries] = useState(initiallyCorrectInquiries);
  const { playWrongDefaultAudioFeedBack, playCorrectDefaultAudioFeedBack, addRef, playAudio } =
    useAudio();
  const isSolutionAudioFallbackMode = Boolean(mainContentImage);
  const feedbackAudio = findFeedbackAudio(isSolutionAudioFallbackMode, audios);
  const { target: answerAudio } = feedbackAudio || {};
  const { mp3Src: feedbackAudioUrl } = answerAudio || {};
  const feedbackAudioRef = useRef();

  const {
    isSolveButtonDisabled,
    isCheckButtonDisabled,
    handleCorrectAnswer,
    handleWrongAnswer,
    handleSolved,
    inquiryState,
    isDisabled,
  } = useInquiryHandling({ inquiryId, achievableScore, subInquiries: inquiry.subInquiries.length });

  useEffect(
    () => (feedbackAudioUrl ? addRef(feedbackAudioRef) : undefined),
    [feedbackAudioUrl, addRef],
  );

  const onHandleSolveButtonClicked = event => {
    handleSolved();
    playCorrectAudioFeedBack(event);
  };

  const onHandleCheckButtonClicked = event => {
    if (correctSubInquiries === inquiry.subInquiries.length) {
      handleCorrectAnswer();
      playCorrectAudioFeedBack(event);
    } else {
      handleWrongAnswer(correctSubInquiries);
      playWrongDefaultAudioFeedBack();
    }
  };
  const playCorrectAudioFeedBack = event => {
    if (feedbackAudioUrl) {
      playAudio(event, feedbackAudioRef);
    } else {
      playCorrectDefaultAudioFeedBack();
    }
  };
  const getAnswerComponent = () => {
    switch (clozeType) {
      case 'PULLDOWN':
        return (
          <StyledPulldownAnswer
            {...{
              inquiry,
              onCorrectAnswerAmountChanged: setCorrectSubInquiries,
              inquiryState,
              isDisabled,
              knowledges,
            }}
          />
        );
      case 'CHOOSE':
        return (
          <StyledChooseAnswer
            {...{
              inquiry,
              onCorrectAnswerAmountChanged: setCorrectSubInquiries,
              inquiryState,
              isDisabled,
              knowledges,
            }}
          />
        );
      case 'WRITING':
        return (
          <WritingAnswer
            {...{
              inquiry,
              onCorrectAnswerAmountChanged: setCorrectSubInquiries,
              inquiryState,
              isDisabled,
              knowledges,
            }}
          />
        );
      default:
        return null;
    }
  };

  const questionType = exerciseQuestionTypeSelector({ inquiry });
  const answerComponent = getAnswerComponent();

  const singleColumnLayout = questionType === QUESTION_TYPES.TEXT && clozeType !== 'CHOOSE';

  return (
    <div
      className={classNames(className, singleColumnLayout && 'singleCol')}
      data-type="cloze"
      data-exercise-id={exerciseId}
      data-testid="lueckenText"
    >
      <div>
        <div>
          <LessonExerciseHiddenAudio ref={feedbackAudioRef} src={feedbackAudioUrl} />
          <LessonExerciseQuestion inquiry={inquiry} />
          {singleColumnLayout && answerComponent}
        </div>
        {!singleColumnLayout && <div>{answerComponent}</div>}
      </div>
      <div>
        <div>
          <LessonExerciseHint description={inquiryDescription} />
        </div>
      </div>
      <div>
        <StyledSolveCheckButton
          handleCheckClicked={onHandleCheckButtonClicked}
          handleSolveClicked={onHandleSolveButtonClicked}
          isCheckButtonDisabled={isCheckButtonDisabled}
          isSolveButtonDisabled={isSolveButtonDisabled}
        />
      </div>
    </div>
  );
};

export const StyledClozeExercise = styled(ClozeExercise)`
  ${baseExerciseStyles}
  ${joinQuestionStyles}

  &.singleCol {
    ${Bubble}, ${BubbleButton}.question {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  > div:first-child {
    /* no margin between question and hint */
    margin-bottom: 0;
  }
`;
