import { StyledLessonExtrasLink as LessonExtrasLink } from './LessonExtrasLink.jsx';
import { getDownloadUrl } from '../../../../utils/url/url';
import { useFrontendConfig } from '../../../../context/FrontendConfigContext';

export const DownloadLinks = ({ data }) => {
  const { staticBaseHost } = useFrontendConfig();

  return data.map(({ targetId, target: { filename }, name, description }) => (
    <LessonExtrasLink
      key={targetId}
      href={getDownloadUrl({ staticBaseHost, downloadId: targetId, filename })}
      content={name}
      description={description}
    />
  ));
};
