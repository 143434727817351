import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { confirmUserRegistration } from '../../services/userService';
import { feedbackHelper } from '../../utils/url/feedbackHelpers';
import { StyledLoadingMessage as LoadingMessage } from '../commons/LoadingMessage.jsx';
import { useLanguage } from '../../context/LanguageContext';

export const ConfirmUserRegistrationContainer = ({ confirmationId }) => {
  const { langCode } = useLanguage();
  const history = useHistory();

  useEffect(() => {
    confirmUserRegistration(confirmationId)
      .then(async response => {
        if (response.ok) {
          history.push(feedbackHelper.forRegistrationConfirm(langCode));
        } else {
          const errorResponse = await response.json();
          history.push(feedbackHelper.getForErrorCode(errorResponse, langCode));
        }
      })
      .catch(e => {
        history.push(feedbackHelper.getForErrorCode(e, langCode));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingMessage />;
};
