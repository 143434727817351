import styled from 'styled-components';
import { getDWStructureUrl } from '../../utils/url/url';
import { useLanguage } from '../../context/LanguageContext';
import { Link } from '../Link/Link.jsx';
import { colors } from '../../utils/css';
import { PageLinkInteraction } from '../Interaction/Interaction.jsx';

/**
 * @param {{
 *   className: string,
 *   id: number,
 *   name: string,
 *   description: string,
 * }} props
 */
const DwLink = ({ className, id, name, description }) => {
  const { langCode } = useLanguage();

  return (
    <div className={className}>
      <p>
        <PageLinkInteraction as={Link} native to={getDWStructureUrl({ id, name, langCode })}>
          {name}
        </PageLinkInteraction>
      </p>
      <p>{description}</p>
    </div>
  );
};

export const StyledDwLink = styled(DwLink)`
  > :first-child {
    border-top: 1px solid ${colors.DW_GREY_07};
    padding: 8px 0;
    margin-bottom: 0;
  }

  > :last-child {
    margin: -8px 0 30px;
  }
`;
