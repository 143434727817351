import gql from 'graphql-tag';
import styled from 'styled-components';
import { memoizeWith } from 'ramda';
import { StyledRichTextWithMedia as RichTextWithMedia } from '../RichText/RichTextWithMedia.jsx';
import {
  MediaInputChooser,
  mediaInputChooserFragment,
} from '../Lesson/MediaInputChooser/MediaInputChooser.jsx';
import {
  contentDetailHeaderFragment,
  StyledContentDetailHeader as ContentDetailHeader,
} from '../ContentDetail/ContentDetailHeader.jsx';
import { colors, columnSize, mediaMin } from '../../utils/css';
import { slideshowFragment } from '../Slideshow/Slideshow.jsx';

const fragmentName = ({ type }) => `${type}ArticleDetail`;
export const articleDetailFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    ({ type }) => type,
    ({ type }) => gql`
      fragment ${fragmentName({ type })} on ${type} {
        ...${contentDetailHeaderFragment.name({ type })}
        ...${mediaInputChooserFragment.name({ type })}
        imageGalleries {
          ...${slideshowFragment.name}
        }
      }
      ${mediaInputChooserFragment.fragment({ type })}
      ${contentDetailHeaderFragment.fragment({ type })}
      ${slideshowFragment.fragment()}
    `,
  ),
};

export const ArticleDetail = ({ className, data }) => {
  const {
    content,
    content: { videos, audios, text, imageGalleries },
  } = data;
  return (
    <article className={className}>
      <div className="content-area">
        <ContentDetailHeader content={content} />
        <MediaInputChooser data={data} />
        <RichTextWithMedia
          videos={videos}
          audios={audios}
          content={text}
          imageGalleries={imageGalleries}
        />
      </div>
    </article>
  );
};

export const StyledArticleDetail = styled(ArticleDetail)`
  overflow-x: hidden;
  background-color: ${colors.LG_WHITE};
  padding: 0;

  ${mediaMin.lg`
    padding: 0 ${columnSize.c2};
  `}
  .content-area {
    max-width: 100%;
    padding: 0 ${columnSize.c2};

    .big,
    .media {
      margin: 1.5rem calc(-1 * ${columnSize.c1});
    }
  }

  > section {
    margin: 1.5rem 0;
  }
`;
