import { useCallback, useState } from 'react';
import { keyCodes } from '../constants/keyCodes';

export const useToggle = ({ initialState = false, key = keyCodes.ENTER } = {}) => {
  const [isEnabled, setEnabled] = useState(initialState);
  const toggleOnClick = useCallback(() => {
    setEnabled(o => !o);
  }, []);

  const toggleOnKeyPress = useCallback(
    e => {
      if (e.key === key) {
        setEnabled(o => !o);
      }
    },
    [key],
  );

  return [isEnabled, { toggleOnClick, toggleOnKeyPress }];
};
