﻿export const buildParamRegex = paramName =>
  new RegExp(`[?&]${encodeURIComponent(paramName)}=([^&]*)`);

// eslint-disable-next-line consistent-return
export const getFromUrl = (url, name, regexBuilder) => {
  const paramRegex = regexBuilder(name);
  const matchArray = paramRegex.exec(url);
  if (matchArray) {
    return decodeURIComponent(matchArray[1]);
  }
};

export const containsSearchParam = (location, name) => {
  const paramRegex = buildParamRegex(name);
  return paramRegex.test(location.search);
};

export const getPathVariable = (location, name) =>
  getFromUrl(location.pathname, name, buildPathVariableRegex);

const buildPathVariableRegex = pathVariablePrefix =>
  new RegExp(`${encodeURIComponent(pathVariablePrefix)}-(\\d*)`);

export const isPathInExerciseNavigationWhiteList = pathname => {
  const permittedNavigationPathsRegexp = /(\/e-[0-9]+|\/ler|\/placement-[0-9]+|\/final-[0-9]+)$/g;
  return permittedNavigationPathsRegexp.test(pathname);
};
