import { lazy } from 'react';

export const importKeyboardAddons = async () => {
  const [simpleKeyboardModule] = await Promise.all([
    import(/* webpackChunkName: 'simpleKeyboard' */ 'simple-keyboard-layouts/build/layouts/german'),
    import(/* webpackChunkName: 'simpleKeyboard' */ 'react-simple-keyboard/build/css/index.css'),
  ]);
  return simpleKeyboardModule.default;
};

export const KeyboardLazy = lazy(() =>
  import(/* webpackChunkName: 'simpleKeyboard' */ 'react-simple-keyboard'),
);
