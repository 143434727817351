import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { StyledLessonExtrasLink as LessonExtrasLink } from './LessonExtrasLink.jsx';
import { selectVideoResourcesForDownload, getVideoLinkTitle } from '../../../../utils/videoUtils';
import { getVideoDownloadUrl } from '../../../../utils/url/url';

const fragmentName = 'VideoResourceLinks';
export const videoResourceLinksFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Video {
        id
        name
        videoResourceLinks {
          url
          techFormat
          size
          format
        }
      }            
    `,
  ),
};

export const VideoLinks = ({ data }) => {
  const videos = [].concat(
    ...data.map(video =>
      selectVideoResourcesForDownload(video.target.videoResourceLinks).map(link => ({
        ...link,
        name: video.name,
        description: video.description,
      })),
    ),
  );
  return videos.map(({ url, description, format, ...restProps }) => (
    <LessonExtrasLink
      key={url}
      href={getVideoDownloadUrl({ videoUrl: url, format })}
      content={getVideoLinkTitle({ format, ...restProps })}
      description={description}
    />
  ));
};
