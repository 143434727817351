import styled from 'styled-components';
import { colors } from '../../../../utils/css';
import { DateTimeInfo } from '../../../Info/Info.jsx';

const resultPointsClassName = ratio => {
  if (ratio >= 0.7) {
    return 'good';
  }
  if (ratio <= 0.4) {
    return 'low';
  }
  return 'mid';
};

export const LessonScore = ({ className, lessonProgress }) => {
  const { resultPoints, maxPointsForAllExercises } = lessonProgress;
  const pointsClass = resultPointsClassName(resultPoints / maxPointsForAllExercises);

  return (
    <DateTimeInfo as="div" className={className}>
      <span className={pointsClass}>{resultPoints}</span>
      <span>/</span>
      <span>{maxPointsForAllExercises}</span>
    </DateTimeInfo>
  );
};

export const StyledLessonScore = styled(LessonScore)`
  .low {
    color: ${colors.ACCENT_RED};
  }
  .mid {
    color: ${colors.DARK_BLUE_GREY_02};
  }
  .good {
    color: ${colors.ACCENT_GREEN};
  }
`;
