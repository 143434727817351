import styled from 'styled-components';
import { colors, mediaDevice } from '../../utils/css';
import { focusTargetClassName } from '../FlyingFocus/constants';

const IconButton = ({ isA = 'button', children, ...restProps }) => {
  const Tag = isA;

  return <Tag {...restProps}>{children}</Tag>;
};

export const darkButtonStyles = `
  --icon-btn-hover-color: ${colors.LG_WHITE};
  --icon-btn-color: ${colors.LG_WHITE};
`;

export const StyledIconButton = styled(IconButton)`
  display: block;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;

  background: transparent;
  svg {
    fill: currentColor;
    path {
      stroke: currentColor;
    }
  }

  && {
    color: var(--icon-btn-color, ${colors.DW_DARK_BLUE_NEW});

    &.${focusTargetClassName} {
      box-shadow: 0 0 0 2px var(--icon-btn-hover-color, ${colors.DW_DARK_BLUE_NEW}) inset;
    }

    ${mediaDevice.mouse`
      &:hover {
        box-shadow: 0 0 0 2px var(--icon-btn-hover-color, ${colors.DW_DARK_BLUE_NEW}) inset;
      }
    `}

    &:active {
      background: ${colors.DARK_BLUE_GREY_01};
      color: ${colors.LG_WHITE};
      box-shadow: none;

      svg {
        background: ${colors.DARK_BLUE_GREY_01};
        fill: ${colors.LG_WHITE};

        path {
          stroke: ${colors.LG_WHITE};
        }
      }
    }
  }

  :disabled {
    cursor: default;
    pointer-events: none;
  }
`;
