import globals from '../../utils/globals';
import {
  GTM_LG_NAVIGATION_TYPE,
  GTM_NO_CONTENT_PAGE_IDS,
  GTM_TECHNICAL_PAGES,
  GTM_USER_RELATED_CATEGORY_NAME,
  GTM_USER_RELATED_PAGES,
} from '../../constants/pageTypes';
import { learningLevelMapper } from '../../utils/courseUtils';
import { HANDICAP_GTM_IDS } from '../../utils/mappers/handicapMapper';
import { langDefs } from '../../utils/mappers/langMapper';
import { CONTENT_COMPOSITION_TYPE } from '../../constants/contentCompositionTypes';

const isValidList = list => list?.length > 0;

const isUserRelatedPage = noContentPageName => GTM_USER_RELATED_PAGES.includes(noContentPageName);
const findNavigationType = navigationType =>
  GTM_LG_NAVIGATION_TYPE[navigationType] || navigationType;

const isLgNav = nav => nav.templateType === CONTENT_COMPOSITION_TYPE.LEARNGERMAN_STANDARD;
const findLastLgNav = nav => (isLgNav(nav) ? nav : nav.navsToRoot.findLast(isLgNav));

export const gtmDataLayerPartial = {
  name: 'GtmDataLayer',
  partial() {
    return `
      ...on ModelAspect {
        id
        gtmLanguageCode
        gtmContentType
      }
      ...on DeliveryAspect {
        gtmFirstPublicationDate
      }
      ...on NamedAspect {
        name
      }
      ...on AssociationsAspect {
        navigations {
          id
          name
          type
          language
          namedUrl
          templateType
          navsToRoot {
            name
            id
            type
            templateType
          }
        }
        subjects {
          name
        }
        topics:categories {
          originId
        }
        departments {
          name
        }
      }
    `;
  },
};

export const generateContentGtmInfo = ({
  content,
  level1Id,
  macaParam,
  pageUrl,
  learningRelatedData,
  userRelatedData,
  noContentPageName,
  gtmEnvironment,
  langCode,
}) => {
  const {
    dkLearningLevel,
    learningContextId,
    learningContextType,
    learningContextName,
    lessonName,
    lessonGroupNumber,
    lessonNumberInLessonGroup,
    lessonNumber,
    exerciseNumber,
    exerciseName,
    lessonItem,
  } = learningRelatedData;
  const { isLoggedIn, handicap } = userRelatedData;
  const handicapForDataLayer = () => {
    if (!isLoggedIn) {
      return undefined;
    }
    return handicap ? HANDICAP_GTM_IDS[handicap] : HANDICAP_GTM_IDS.NO_HANDICAP;
  };
  const navs = isValidList(content.navigations) ? content.navigations : [{ navsToRoot: [] }];
  const matchedNav = findLastLgNav(navs[0]);

  const cleanedLessonName = lessonName
    ? lessonName.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '')
    : undefined;
  const isTechnicalPage = GTM_TECHNICAL_PAGES.includes(noContentPageName);
  const categoryLevel1 = (() => {
    if (noContentPageName) {
      if (isUserRelatedPage(noContentPageName)) {
        return GTM_USER_RELATED_CATEGORY_NAME;
      }
      return noContentPageName;
    }
    return findNavigationType(matchedNav?.type) || '';
  })();
  const categoryLevel2 = (() => {
    if (learningContextName) {
      return learningContextName;
    }
    return matchedNav?.name || content.name || '';
  })();
  const categoryLevel3 = (() => {
    if (isTechnicalPage) {
      return '';
    }
    const level = learningLevelMapper[dkLearningLevel];
    if (!level || level === '') {
      return 'Ohne Niveau';
    }
    return level;
  })();

  return {
    categoryLevel1,
    categoryLevel2,
    categoryLevel3,
    contentTitle: content.name || noContentPageName,
    contentLanguage: content.gtmLanguageCode || langDefs[langCode]?.gtmLanguageCode,
    pageOID: content.id,
    contentType: categoryLevel1,
    contentName: categoryLevel2,
    gtmContentType: content.gtmContentType,
    date: content.gtmFirstPublicationDate,
    subject: content.subjects ? content.subjects[0]?.name : {},
    topicIds: content.topics?.map(topic => topic.originId),
    pageSID: navs[0].id,
    departmentName: content.departments ? content.departments[0]?.name : {},
    categoryType: 1,
    displayForm: 5,
    level1ID: level1Id,
    macaParam,
    pageUrl,
    loggedIn: isLoggedIn,
    handicap: handicapForDataLayer(),
    learningContextId,
    learningContextType,
    learningContextName,
    level: categoryLevel3,
    sprachLevel: categoryLevel3,
    lessonName: cleanedLessonName,
    lessonGroupNumber,
    lessonNumberInLessonGroup,
    lessonNumber,
    lessonItem,
    exerciseNumber,
    exerciseName,
    noContentPageId: GTM_NO_CONTENT_PAGE_IDS[noContentPageName],
    technicalPage: GTM_TECHNICAL_PAGES.includes(noContentPageName) ? 1 : 0,
    titleTest: cleanedLessonName || content.name,
    page: cleanedLessonName || content.name,
    environment: gtmEnvironment,
  };
};

export const TrackingUtils = {
  generateDataLayer: ({
    content,
    level1Id,
    macaParam,
    pageUrl,
    learningRelatedData = {},
    userRelatedData = {},
    noContentPageName,
    gtmEnvironment,
    langCode,
  }) => ({
    pageData: {
      ...generateContentGtmInfo({
        content,
        level1Id,
        macaParam,
        pageUrl,
        learningRelatedData,
        userRelatedData,
        noContentPageName,
        gtmEnvironment,
        langCode,
      }),
      embeddings: {},
    },
  }),
  pushToGoogleTagManager: ({ datalayerObj, withReset = false }) => {
    if (!globals.window.dataLayer) {
      globals.window.dataLayer = [datalayerObj];
      return;
    }
    if (withReset) {
      TrackingUtils.resetDataLayer();
    }
    globals.window.dataLayer?.push(datalayerObj);
  },
  resetDataLayer: () => {
    globals.window.dataLayer.push(function dontUseArrowFunction() {
      this.reset();
    });
  },
};
