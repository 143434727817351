import styled from 'styled-components';
import { useToggle } from '../../../hooks/useToggle';
import { StyledKnowledgeModal as KnowledgeModal } from './KnowledgeModal.jsx';
import { isVowelTitle } from '../../../utils/commons';
import { ModalIntegration } from '../ModalIntegration.jsx';
import { colors } from '../../../utils/css';

const defaultMainContentImage = null;
const getAudioImage = knowledge => knowledge?.audios?.[0]?.mainContentImage;

export const Knowledge = ({ className, children, knowledge }) => {
  const [isOpen, { toggleOnClick, toggleOnKeyPress }] = useToggle();
  const classMap = {
    consonants: 'consonant',
    vocals: 'vocal',
    glossary: 'glossary',
  };
  const getClassName = () => {
    if (knowledge.knowledgeType === 'VOCABULARY') {
      return classMap.glossary;
    }
    if (isVowelTitle(knowledge.shortTitle)) {
      return classMap.vocals;
    }
    return classMap.consonants;
  };

  const handleClick = e => {
    e.preventDefault();
    toggleOnClick();
  };

  const handleKeyDown = e => {
    e.preventDefault();
    toggleOnKeyPress(e);
  };

  const contentImage =
    knowledge.mainContentImage || getAudioImage(knowledge) || defaultMainContentImage;

  return (
    <span className={className}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className={getClassName()} href="#" onClick={handleClick} onKeyDown={handleKeyDown}>
        {children}
      </a>
      <ModalIntegration isOpen={isOpen}>
        <KnowledgeModal
          knowledgeType={knowledge.knowledgeType}
          image={contentImage}
          audioFile={knowledge.audios && knowledge.audios[0] ? knowledge.audios[0].mp3Src : null}
          title={knowledge.name}
          shortTitle={knowledge.shortTitle}
          name={knowledge.name}
          text={knowledge.text}
          subTitle={knowledge.subTitle}
          onClose={toggleOnClick}
        />
      </ModalIntegration>
    </span>
  );
};

export const StyledKnowledge = styled(Knowledge)`
  a {
    outline: 0;
    text-decoration: none;
    border-bottom: 1px dotted;
    cursor: help;
  }
  a:focus {
    outline: 2px solid;
    outline-offset: 1px;
    border: 0;
    color: ${colors.DW_DARK_BLUE_NEW};
  }
  a:hover {
    border-bottom: 2px dotted;
  }
  a.glossary {
    border-color: ${colors.DW_DARK_BLUE_NEW};
    outline-color: ${colors.DW_DARK_BLUE_NEW};
  }
  a.vocal {
    border-color: ${colors.LG_ORANGE_DARK};
    outline-color: ${colors.LG_ORANGE_DARK};
    color: ${colors.LG_ORANGE_DARK};
  }
  a.consonant {
    border-color: ${colors.DW_DARK_BLUE_NEW};
    outline-color: ${colors.DW_DARK_BLUE_NEW};
  }
`;
