import styled from 'styled-components';
import { StyledRichText } from '../RichText/RichText.jsx';
import { DetailPageHeadline } from '../Headline/Headline.jsx';

/**
 * @param {{
 *   className?: string,
 *   contents: {
 *     name: string,
 *     text: string,
 *   },
 * }} props
 */
export const ArticleInfo = ({ className, contents }) => {
  const { name, text } = contents;
  return (
    <div className={className}>
      <DetailPageHeadline as="h2">{name}</DetailPageHeadline>
      <StyledRichText isA="div" content={text} noContainer />
    </div>
  );
};

export const StyledArticleInfo = styled(ArticleInfo)`
  overflow-wrap: anywhere;
`;
