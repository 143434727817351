import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { StyledResponsiveDwPicture as ResponsiveDwPicture } from '../../ResponsiveDwPicture/ResponsiveDwPicture.jsx';

const fragmentName = ({ type }) => `${type}MediaInputImage`;
export const mediaInputImageFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    ({ type }) => type,
    ({ type }) => gql`
      fragment ${fragmentName({ type })} on ${type} {
        mainContentImage {
          id
          name
          staticUrl
        }
      }
    `,
  ),
};

export const MediaInputImage = ({ data }) => {
  const { id: imageId, name: imageName } = data.content.mainContentImage || {};

  return imageId ? (
    <ResponsiveDwPicture alt={imageName} image={data.content.mainContentImage} />
  ) : null;
};
