import styled, { css } from 'styled-components';
import { StyledIconButton } from '../StandardButton/IconButton.jsx';
import { useTranslation } from '../../hooks/useTranslation';
import { StyledArrowLineRight } from '../../assets/icons/carousel/ArrowLineRight.jsx';
import { StyledArrowLineLeft } from '../../assets/icons/carousel/ArrowLineLeft.jsx';
import { colors, mediaMin } from '../../utils/css';

// @used in ROAD
export const CarouselArrows = ({
  className,
  onPrev,
  onNext,
  disablePrevArrow,
  disableNextArrow,
  isRtl,
}) => {
  const prevArrowTitle = useTranslation(
    'content_block.top_story.more_on_top_story.previous_button.label',
  );
  const nextArrowTitle = useTranslation(
    'content_block.top_story.more_on_top_story.next_button.label',
  );
  return (
    <div className={className}>
      <StyledIconButton
        data-testid="caruouselArrowPrev"
        className="prev"
        onClick={onPrev}
        disabled={disablePrevArrow}
        title={prevArrowTitle}
      >
        {isRtl ? <StyledArrowLineRight /> : <StyledArrowLineLeft />}
      </StyledIconButton>
      <StyledIconButton
        data-testid="caruouselArrowNext"
        className="next"
        onClick={onNext}
        disabled={disableNextArrow}
        title={nextArrowTitle}
      >
        {isRtl ? <StyledArrowLineLeft /> : <StyledArrowLineRight />}
      </StyledIconButton>
    </div>
  );
};

const inTimingFunction = 'cubic-bezier(0, 0, 0.09, 1)';
const outTimingFunction = 'cubic-bezier(0.91, 0, 1, 1)';

export const slideInLeft = `
  inset-inline-start: 0;
  transition-timing-function: ${inTimingFunction};
`;

export const slideOutLeft = iconWidth => `
  inset-inline-start: -${iconWidth}px;
  transition-timing-function: ${outTimingFunction};
`;

export const slideInRight = `
  inset-inline-end: 0;
  transition-timing-function: ${inTimingFunction};
`;

export const slideOutRight = iconWidth => `
  inset-inline-end: -${iconWidth}px;
  transition-timing-function: ${outTimingFunction};
`;

export const arrowStyles = css`
  .prev,
  .next {
    position: absolute;
    top: calc(50% - 55px);
    z-index: 3;
    filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.3));
    opacity: 1;

    transition-property: inset-inline-start, inset-inline-end, opacity, visibility;
    transition-duration: 800ms;

    :disabled {
      visibility: hidden;
      opacity: 0;
    }
  }
`;

export const StyledCarouselArrows = styled(CarouselArrows)`
  width: 100%;

  ${arrowStyles}
  .prev, .next {
    transform: translate(0, -50%);
    top: 50%;

    svg {
      width: 40px;
      height: 40px;

      path {
        stroke: ${colors.DW_GREY_07};
      }
    }
  }

  .prev {
    ${slideInLeft}
    :disabled {
      ${slideOutLeft(40)}
    }
  }

  .next {
    ${slideInRight}
    :disabled {
      ${slideOutRight(40)}
    }
  }

  ${mediaMin.sm`
    .prev, .next {
      svg {
        width: 50px;
        height: 50px;
      }
    }
    .prev:disabled {
      ${slideOutLeft(50)}
    }
    .next:disabled {
      ${slideOutRight(50)}
    }
  `}
`;
