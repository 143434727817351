import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { gtmDataLayerFragment } from '../../GoogleTagManager';
import { StyledVideoPlayer as VideoPlayer } from '../../MediaPlayer/Video/VideoPlayer.jsx';

const fragmentName = ({ type }) => `${type}MediaInputVideo`;
export const mediaInputVideoFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    ({ type }) => type,
    ({ type }) => gql`
      fragment ${fragmentName({ type })} on ${type} {
        videos {
          name
          contentDate
          language            
          posterImageUrl
          hlsVideoSrc
          formattedDuration
          subtitles {
            trackId: targetId
            srcLanguage
            language
            subtitleUrl
          }
          ${gtmDataLayerFragment.partial()}
        }
      }
    `,
  ),
};

export const MediaInputVideo = ({ data, ...restProps }) => {
  return <VideoPlayer video={data.content.videos[0]} {...restProps} />;
};
