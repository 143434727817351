import styled, { css } from 'styled-components';
import { ReactComponent as NavigationIcon } from '../../../../assets/svg/arrow-right.svg';

const baseStyles = css`
  margin: 10px;
`;

export const StyledNavigationLeftArrow = styled(props => <NavigationIcon {...props} />)`
  transform: rotate(180deg);
  ${baseStyles}
`;

export const StyledNavigationRightArrow = styled(props => <NavigationIcon {...props} />)`
  ${baseStyles}
`;
