import { USER } from '../../constants/userUrl';
import { FEEDBACK_TYPES, TYPES_WITH_PROFILE_PAGE_AS_TARGET } from '../../constants/feedback';
import config from '../../config';

export const PAGE_OVERVIEW = '/overview';
export const PAGE_REGISTRATION = '/user/register';
export const PAGE_PROFILE = '/user/profile';
export const PAGE_PASSWORD_CHANGE = '/user/password/change';
export const PAGE_PASSWORD_RESET = '/user/password/reset';
export const PAGE_VOCABULARY_TRAINER = '/user/vocabularyTrainer';
export const PAGE_VOCABULARY_TRAINER_START = '/user/vocabularyTrainerStart';
export const STATUS_FEEDBACK = '/user/feedback/status';
export const PAGE_GRAMMAR = '/grammar';
const PAGE_HELP = '/help';
export const PAGE_VOCABULARY = '/vocabulary';
export const PAGE_PLACEMENT_TEST = '/placementDashboard';
export const EXERCISES_RESULT_URL_SUFFIX = 'ler';
const COURSE_PREFIX = 'c-';
const LESSON_PREFIX = 'l-';
const EXERCISE_PREFIX = 'e-';
const LESSON_GRAMMAR_URL_SUFFIX = 'gr-';
export const LESSON_VOCABULARY_URL_SUFFIX = 'lv';
const LESSON_REGIONAL_STUDIES_SUFFIX = 'rs-';
export const ALPHABETICAL_ORDER_URL_SUFFIX = 'la';
export const LESSON_MANUSCRIPT_SUFFIX = 'lm';
export const LESSON_EXTRAS_SUFFIX = 'le';
export const LESSON_EXERCISE_RESULT_SUFFIX = 'ler';
export const LESSON_SUMMARY_SUFFIX = 'sum';
const SUMMARY_URL_SUFFIX = 'sum';

export const getErrorCode = errorResponse => errorResponse && errorResponse.errorCode;

export const getPrefixedLanguageUrl = (url, langCode) => `/${langCode}${url}`;

export const getDashboardUrl = langCode =>
  langCode ? getPrefixedLanguageUrl(PAGE_OVERVIEW, langCode) : '/';

export const getGrammarUrl = langCode => getPrefixedLanguageUrl(PAGE_GRAMMAR, langCode);

export const getVocabularyPageUrl = langCode => getPrefixedLanguageUrl(PAGE_VOCABULARY, langCode);

export const getPlacementDashboardUrl = langCode =>
  getPrefixedLanguageUrl(PAGE_PLACEMENT_TEST, langCode);

export const getLessonExerciseUrl = (languageCode, lessonId, normalizedUrlName, lessonPartId) =>
  `${getLessonUrl(lessonId, languageCode, normalizedUrlName)}/${EXERCISE_PREFIX}${lessonPartId}`;

export const getLessonGrammarUrl = (languageCode, lessonId, normalizedUrlName, lessonPartId) =>
  `${getLessonUrl(
    lessonId,
    languageCode,
    normalizedUrlName,
  )}/${LESSON_GRAMMAR_URL_SUFFIX}${lessonPartId}`;

export const getLessonPhonemicChartUrl = lessonPath =>
  `${lessonPath}/${ALPHABETICAL_ORDER_URL_SUFFIX}`;

export const getLessonRegionalStudiesUrl = (
  languageCode,
  lessonId,
  normalizedUrlName,
  lessonPartId,
) =>
  `${getLessonUrl(
    lessonId,
    languageCode,
    normalizedUrlName,
  )}/${LESSON_REGIONAL_STUDIES_SUFFIX}${lessonPartId}`;

export const getRegisterUrl = langCode => getPrefixedLanguageUrl(PAGE_REGISTRATION, langCode);

export const getUserProfileUrl = langCode => getPrefixedLanguageUrl(PAGE_PROFILE, langCode);

export const getPasswordChangeUrl = langCode =>
  getPrefixedLanguageUrl(PAGE_PASSWORD_CHANGE, langCode);

export const getPasswordResetUrl = langCode =>
  getPrefixedLanguageUrl(PAGE_PASSWORD_RESET, langCode);

export const getCourseUrl = (courseId, langCode, normalizedUrlCourseName) =>
  getPrefixedLanguageUrl(`/${normalizedUrlCourseName}/${COURSE_PREFIX}${courseId}`, langCode);

export const getLessonUrl = (lessonId, languageCode, normalizedUrlLessonName) =>
  getPrefixedLanguageUrl(`/${normalizedUrlLessonName}/${LESSON_PREFIX}${lessonId}`, languageCode);

export const getLessonExercisesResultUrl = lessonUrl =>
  `${lessonUrl}/${EXERCISES_RESULT_URL_SUFFIX}`;

export const getFirstGrammarUrl = (lessonUrl, firstGrammarId) =>
  `${lessonUrl}/gr-${firstGrammarId}`;

export const getVocabularyUrl = lessonUrl => `${lessonUrl}/${LESSON_VOCABULARY_URL_SUFFIX}`;

export const getAlphabeticalOrderUrl = lessonUrl => `${lessonUrl}/${ALPHABETICAL_ORDER_URL_SUFFIX}`;

export const getFirstRegionalStudiesUrl = (lessonUrl, firstRegionalStudiesId) =>
  `${lessonUrl}/${LESSON_REGIONAL_STUDIES_SUFFIX}${firstRegionalStudiesId}`;

export const getManuscriptUrl = lessonUrl => `${lessonUrl}/${LESSON_MANUSCRIPT_SUFFIX}`;

export const getExtrasUrl = lessonUrl => `${lessonUrl}/${LESSON_EXTRAS_SUFFIX}`;

export const getSummaryUrl = lessonUrl => `${lessonUrl}/${SUMMARY_URL_SUFFIX}`;

export const getPlacementTestResultUrl = ({ langCode, id, name }) =>
  getPrefixedLanguageUrl(`/${name}/placement-${id}`, langCode);

export const transformUrlContentPrefix = (lessonPath, type) =>
  lessonPath.replace('/l-', `/${type}-`);

export const createLinkToResultPage = ({ isFromPlacementCourse, isFinalTest, lessonPath }) => {
  if (isFinalTest) return transformUrlContentPrefix(lessonPath, 'final');

  if (isFromPlacementCourse) return transformUrlContentPrefix(lessonPath, 'placement');

  return getLessonExercisesResultUrl(lessonPath);
};

export const getHelpUrl = langCode => getPrefixedLanguageUrl(PAGE_HELP, langCode);

export const getCourseCertificateUrl = paramUrl =>
  getPrefixedLanguageUrl(`/certificate/${paramUrl}`);

export const getVocabularyTrainerStartPage = langCode =>
  getPrefixedLanguageUrl(PAGE_VOCABULARY_TRAINER_START, langCode);

export const getVocabularyTrainerPage = langCode =>
  getPrefixedLanguageUrl(PAGE_VOCABULARY_TRAINER, langCode);

export const getTargetPageUrl = (type, langCode) => {
  return TYPES_WITH_PROFILE_PAGE_AS_TARGET.includes(type)
    ? getPrefixedLanguageUrl(PAGE_PROFILE, langCode)
    : getDashboardUrl(langCode);
};

export const getFeedbackUrl = (type, langCode) =>
  getPrefixedLanguageUrl(`${STATUS_FEEDBACK}/${type}`, langCode);

export const getLoginPage = (origin, langCode, targetPath) => {
  return `${USER.PS_URL}login?service=${getServiceUrl(
    origin,
    langCode,
    targetPath,
  )}&locale=${langCode}`;
};

const getServiceUrl = (origin, langCode, targetPath) => {
  const queryParams = targetPath ? `?target=${encodeURIComponent(targetPath)}` : '';
  const path = getFeedbackUrl(FEEDBACK_TYPES.LOGIN, langCode);
  return encodeURIComponent(`${origin}${path}${queryParams}`);
};

export const getFooterLinkForNamedUrl = ({ namedUrl }) => `${config.dw.mobileUrl}${namedUrl}`;

export const getDwUrlWithLang = ({ langCode }) => `${config.dw.baseUrl}/${langCode}`;

export const getDwContactUrl = () => `${config.dw.mobileUrl}/contact`;
