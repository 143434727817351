import { getCertificate } from '../services/certificateService';
import { FEEDBACK_TYPES } from '../constants/feedback';
import { feedbackHelper } from './url/feedbackHelpers';

export const handleCertificateGeneration = async ({ id, langCode, history }) => {
  if (!id) {
    history.push(feedbackHelper.forInvalidCertificate(langCode));
  }

  try {
    const response = await getCertificate(id);
    response.blob().then(blob => {
      const contentDispositionHeader = response.headers.get('Content-Disposition');
      const headerFilename =
        contentDispositionHeader.split("filename*=UTF-8''")[1] ||
        contentDispositionHeader.split('filename=')[1];
      const filename = headerFilename.replace('?', '–');
      const url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = decodeURIComponent(filename);
      downloadLink.click();
    });
  } catch (error) {
    error.errorCode === FEEDBACK_TYPES.MISSING_TRANSCRIPTION
      ? history.push(feedbackHelper.forCertificateMissingTranscription(langCode))
      : history.push(feedbackHelper.forInvalidCertificate(langCode));
  }
};
