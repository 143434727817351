import { useState, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  handicapOptions,
  languageLevelOptions,
  learningTypeOptions,
  professionOptions,
  sexOptions,
  writingReadingLevelOptions,
} from '../../constants/userInputOptions';
import { MENU_LANGUAGES, CHINESE_TRAD_TRANSLATION_KEY } from '../../constants/menuLanguageCodes';
import { updateUser } from '../../state/user/userDuck';
import { feedbackHelper } from '../../utils/url/feedbackHelpers';
import { I18nText } from '../I18n/I18nText.jsx';
import { StyledDeleteAccountModal as DeleteAccountModal } from '../Modal/DeleteAccountModal/StyledDeleteAccountModal.jsx';
import { ModalIntegration } from '../Modal/ModalIntegration.jsx';
import { StyledStandardButton } from '../StandardButton/StandardButton.jsx';
import { getPasswordChangeUrl } from '../../utils/url/urlFactory';
import { StyledCheckbox as Checkbox } from '../FormElements/Checkbox/StyledCheckbox.jsx';
import { StyledSelect as Select } from '../FormElements/Select/StyledSelect.jsx';
import { BirthDateInput } from '../FormElements/Input/BirthDateInput/BirthDateInput.jsx';
import { countries } from '../../constants/countries';
import { StyledInput as Input } from '../FormElements/Input/StyledInput.jsx';
import { StyledRadio as Radio } from '../FormElements/Radio/StyledRadio.jsx';
import { emailPattern, namePattern, transcriptionPattern } from '../../utils/validation/validators';
import { userDataSelector } from '../../state/user/userSelectors';
import { useI18nContext } from '../../context/I18nContext.jsx';
import { mediaMin, columnSize } from '../../utils/css';
import { useLanguage } from '../../context/LanguageContext';
import { LinkButton } from '../LinkButton/LinkButton.jsx';
import { DetailPageHeadline } from '../Headline/Headline.jsx';
import { DetailIntroText } from '../Text/Text.jsx';

const additionalSelectInputs = [
  { name: 'profession', options: professionOptions },
  { name: 'languageLevel', options: languageLevelOptions },
  { name: 'writingReadingLevel', options: writingReadingLevelOptions },
  { name: 'handicap', options: handicapOptions },
  { name: 'learningType', options: learningTypeOptions },
];

const removeEmptySelectValues = data => {
  const cleanedData = { ...data };
  additionalSelectInputs.forEach(selectInputName => {
    const value = cleanedData[selectInputName.name];
    if (value === '') {
      delete cleanedData[selectInputName.name];
    }
  });
  return cleanedData;
};

export const UserProfile = ({ className }) => {
  const history = useHistory();
  const { langCode } = useLanguage();
  const dispatch = useDispatch();
  const userData = useSelector(userDataSelector);
  const { i18n } = useI18nContext();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const { handleSubmit, getValues } = formMethods;

  const contactLanguageOptions = useMemo(
    () =>
      MENU_LANGUAGES.filter(
        // Backend doesn't support switching between simplified and traditional chinese.
        ({ translationKey }) => translationKey !== CHINESE_TRAD_TRANSLATION_KEY,
      ).map(({ langCode: value, translationKey }) => ({
        key: `${i18n.t(`header.menu.language.${translationKey}`)} - ${i18n.t(
          `header.menu.language.native.${translationKey}`,
        )}`,
        value,
      })),
    [i18n],
  );

  const onSubmit = async data => {
    const cleanedData = removeEmptySelectValues(data);
    try {
      const updatedData = await dispatch(updateUser(cleanedData));
      updatedData.email !== getValues().email
        ? history.push(feedbackHelper.forMailChangeRequest(langCode))
        : history.push(feedbackHelper.forProfileUpdated(langCode));
    } catch (err) {
      const errorResponse = await err.json();
      history.push(feedbackHelper.getForErrorCode(errorResponse, langCode));
    }
  };

  const toggleDeleteAccountModal = () => {
    setDeleteModalOpen(oldValue => !oldValue);
  };

  return (
    <div className={className}>
      <div>
        <I18nText isA={DetailPageHeadline} translation="profile.edit.title" />
        <I18nText isA={DetailIntroText} translation="profile.edit.subtitle" />
        <FormProvider {...formMethods}>
          <form
            id="main-form"
            data-id="update-profile"
            className="validate"
            onSubmit={handleSubmit(onSubmit)}
          >
            <ShortInput
              labelKey="profile.edit.firstName"
              name="firstName"
              registerOptions={{
                required: true,
                pattern: namePattern,
              }}
              defaultValue={userData.firstName}
            />
            <ShortInput
              labelKey="profile.edit.lastName"
              name="lastName"
              registerOptions={{
                required: true,
                pattern: namePattern,
              }}
              defaultValue={userData.lastName}
            />
            <Input
              labelKey="profile.edit.firstNameTranscription"
              name="firstNameTranscription"
              registerOptions={{ pattern: transcriptionPattern }}
              defaultValue={userData.firstNameTranscription}
            />
            <Input
              labelKey="profile.edit.lastNameTranscription"
              name="lastNameTranscription"
              registerOptions={{ pattern: transcriptionPattern }}
              defaultValue={userData.lastNameTranscription}
            />
            <Radio
              name="sex"
              registerOptions={{ required: true }}
              labelKey="profile.edit.sex.title"
              options={sexOptions}
              defaultChecked={userData.sex}
            />
            <Input
              labelKey="profile.edit.email"
              name="email"
              registerOptions={{ required: true, pattern: emailPattern }}
              defaultValue={userData.email}
            />
            <hr />
            <Input
              labelKey="profile.edit.street"
              name="streetName"
              defaultValue={userData.streetName}
            />
            <Input
              labelKey="profile.edit.postalCode"
              name="postalCode"
              defaultValue={userData.postalCode}
            />
            <Input labelKey="profile.edit.city" name="city" defaultValue={userData.city} />
            <Select
              name="country"
              registerOptions={{ required: true }}
              defaultOptionKey="profile.edit.country"
              options={countries}
              defaultValue={userData.country}
            />
            <BirthDateInput
              initialDateOfBirth={userData.dateOfBirth}
              labelKey="profile.edit.dateOfBirth"
              placeholderKey="profile.edit.placeholder.yearOfBirth"
            />
            <hr />
            <Select
              name="contactLanguage"
              options={contactLanguageOptions}
              registerOptions={{ required: true }}
              defaultOptionKey="profile.edit.contactLanguage"
              defaultValue={userData.contactLanguage}
            />
            {additionalSelectInputs.map(({ name, options }) => (
              <Select
                key={name}
                name={name}
                options={options}
                defaultOptionKey={`profile.edit.${name}.title`}
                defaultValue={userData[name]}
              />
            ))}
            <Checkbox
              name="teacher"
              labelText={i18n.t('profile.edit.teacher')}
              defaultChecked={userData.teacher}
            />
            <Checkbox
              name="newsletter"
              labelText={i18n.t('profile.edit.newsletter.subscribe')}
              defaultChecked={userData.newsletter}
            />
            <Checkbox
              name="signalSoundDisabled"
              labelText={i18n.t('profile.edit.signalSoundTurnOff')}
              defaultChecked={userData.signalSoundDisabled}
            />
            <StyledStandardButton
              titleKey="profile.edit.save"
              data-testid="saveUserProfileButton"
            />
          </form>
        </FormProvider>
        <LinkButton
          titleKey="profile.edit.passwordChange"
          data-testid="changePasswordButton"
          variant="tertiary"
          to={getPasswordChangeUrl(langCode)}
        />
        <StyledStandardButton
          titleKey="profile.edit.deleteAccount"
          data-testid="deleteAccountButton"
          onClick={toggleDeleteAccountModal}
        />
        <ModalIntegration isOpen={isDeleteModalOpen}>
          <DeleteAccountModal onClose={toggleDeleteAccountModal} />
        </ModalIntegration>
      </div>
    </div>
  );
};

const ShortInput = styled(Input)`
  ${mediaMin.sm`
    display: inline-block;
    width: 50%;
    vertical-align:top;
  `}
`;

export const StyledUserProfile = styled(UserProfile)`
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: ${columnSize.c1};
  padding-left: ${columnSize.c1};
  padding-bottom: 30px;

  & > div {
    display: block;
    position: relative;
    min-height: 1px;
    > ${StyledStandardButton} {
      margin-top: 20px;
    }
  }

  form {
    ${mediaMin.sm`
      ${ShortInput} + ${ShortInput} {
        padding-left: ${columnSize.c1};
        padding-right: 0;
      }
    `}
  }

  ${mediaMin.md`
    margin: 0 auto;
  `}
  ${mediaMin.lg`
    & > div {
      margin-left: ${columnSize.c6};
      width: ${columnSize.c16};
    }
  `}
  ${mediaMin.xl`
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 10px;
  `}

  ${StyledStandardButton} {
    width: 100%;
  }
`;
