import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import styled from 'styled-components';
import { RichTextWithKnowledge } from '../../RichText/RichTextWithKnowledge.jsx';
import { mediaMin } from '../../../utils/css';
import { DetailPageHeadline } from '../../Headline/Headline.jsx';
import { I18nText } from '../../I18n/I18nText.jsx';

const fragmentName = ({ type }) => `${type}Knowledges`;
export const knowledgesFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    ({ type }) => type,
    ({ type }) => gql`
      fragment ${fragmentName({ type })} on ${type} {
        knowledges {
          id
          text
          knowledgeType
          shortTitle
          subTitle
          name
          mainContentImage {
            id
            name
            staticUrl
          }
          audios {
            mp3Src
            mainContentImage {
              id
              name
              staticUrl
            }
          }
        }
      }
    `,
  ),
};

export const LessonKnowledge = ({ className, name, knowledges, knowledgeContent }) => {
  return (
    <div className={className}>
      <I18nText isA={DetailPageHeadline} as="h2" translation={name} />
      <RichTextWithKnowledge knowledges={knowledges} content={knowledgeContent} />
    </div>
  );
};

export const StyledLessonKnowledge = styled(LessonKnowledge)`
  position: relative;
  min-height: 1px;
  display: block;

  ${mediaMin.xl`
    margin: 0 auto;
  `}
`;
