import { useRef, useCallback, useEffect } from 'react';
import { initPluginsForPlayer } from '../../../video';
import { useVideoJsCommons } from '../useVideoJsCommons';
import { useAudio } from '../../../hooks/useAudio';
import { useI18nContext } from '../../../context/I18nContext.jsx';

/**
 * @param {{
 *   audioRef: React.MutableRefObject<HTMLAudioElement>,
 *   audio: {
 *     mp3Src: string,
 *     type: string | undefined,
 *     formattedDuration: string,
 *   },
 * }}
 */
export function useAudioPlayer({
  audioRef,
  audio: { type = 'audio/mp3', mp3Src, formattedDuration },
}) {
  const { addRef, stopOtherAudios } = useAudio();

  useEffect(() => (mp3Src ? addRef(audioRef) : undefined), [mp3Src, addRef, audioRef]);

  /** @type {React.MutableRefObject<string[]>} */
  const componentNameRef = useRef([]);
  const i18nContext = useI18nContext();

  const playHandler = useCallback(() => {
    stopOtherAudios(audioRef);
  }, [audioRef, stopOtherAudios]);

  const updatePlayer = useCallback(
    player => {
      player.ready(() => {
        player.src({ type, src: mp3Src });

        initPluginsForPlayer(player);

        player.setAttribute('data-duration', formattedDuration);
        player.addClass('rendered');
        addChildren(componentNameRef.current, player, i18nContext);
      });

      player.off('play', playHandler);
      player.on('play', playHandler);
    },
    [playHandler, type, mp3Src, formattedDuration, i18nContext],
  );

  useVideoJsCommons({ elementRef: audioRef, componentNameRef, updatePlayer, isVideo: false });
}

/**
 * @param {string[]} children
 * @param {videojs.VideoJsPlayer} player
 */
function addChildren(children, player, i18nContext) {
  children.forEach(c => player.addChild(c, { i18nContext }));
}
