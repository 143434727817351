import classnames from 'classnames';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useId } from 'react';
import { StyledValidationMessage as ValidationMessage } from '../ValidationMessage.jsx';
import { useTranslation } from '../../../hooks/useTranslation';
import { useFormError } from '../../../hooks/useFormError';
import { BaseInput } from '../BaseInput.jsx';
import { StyledInteractionLabel as InteractionLabel } from '../InteractionLabel.jsx';
import { keyCodes } from '../../../constants/keyCodes';

export const Input = ({
  className,
  labelKey,
  name,
  registerOptions,
  hideValidationError,
  ...restProps
}) => {
  const labelText = useTranslation(labelKey);
  const { register } = useFormContext();
  const { hasError, errorMessage } = useFormError(name, labelText);

  const inputId = useId();

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classnames(className, { error: hasError })}
      onKeyDown={e => {
        e.key === keyCodes.ENTER && e.preventDefault();
      }}
    >
      <InteractionLabel htmlForName={inputId} labelKey={labelKey} {...{ registerOptions }} />
      <BaseInput id={inputId} {...register(name, registerOptions)} {...restProps} />
      {errorMessage && !hideValidationError && (
        <ValidationMessage>{errorMessage}</ValidationMessage>
      )}
    </div>
  );
};

export const StyledInput = styled(Input)`
  > ${BaseInput} {
    width: 100%;
    height: 50px;
    padding: 14px 22px 10px;
    margin-bottom: 20px;
    border-radius: 0;
  }
`;
