import { capitalize, getMediaFileSizeInKBorMB } from './commons';

export const findQuestionAudio = audios => audios.find(audio => audio.mainContent);

export const findFeedbackAudio = (isSolutionAudioFallbackMode, audios) => {
  const feedbackAudio = audios.find(audio => !audio.mainContent);
  return feedbackAudio || (isSolutionAudioFallbackMode ? findQuestionAudio(audios) : undefined);
};

/**
 * @template {{ id: number }} A
 * @template {{ targetId: number }} CL
 * @param {(contentLink: CL) => boolean} predicate
 * @param {{ audios: (A | null)[] | null, contentLinks: (CL | null)[] | null }}
 * @returns {A | undefined}
 */
export function findAudioWithContentLinkMatching(predicate, { audios, contentLinks }) {
  if (!audios || !contentLinks) {
    return undefined;
  }

  return audios.find(a => {
    const matchingLink = contentLinks.find(cl => cl !== null && a?.id === cl.targetId);
    return matchingLink !== undefined && predicate(matchingLink);
  });
}

export const getAudioLinkTitle = ({ name, format, size, variant }) => {
  const variantOutput = variant ? ` (${capitalize(variant)})` : '';
  return `${name} | ${format} | ${getMediaFileSizeInKBorMB(size)}${variantOutput}`;
};
