import { replaceWithComponent } from './replaceCommons';

export const replacePlaceholderWithAnswerComponent = ({
  createComponent,
  scopeClassName,
  node,
}) => {
  return replaceWithComponent({
    selector: scopeClassName
      ? `.${scopeClassName} ${'.placeholder-cloze:not(.render-container)'}`
      : '.placeholder-cloze:not(.render-container)',
    node,
    componentFn: (matchedElem, index) => createComponent(index, matchedElem),
  }).portals;
};
