import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { colors, columnSize, mediaDevice, mediaMin } from '../../../utils/css';
import navCrossBlackMd from '../../../assets/svg/nav-cross-black-md.svg';
import navCrossBlackLg from '../../../assets/svg/nav-cross-black-lg.svg';
import navCrossXs from '../../../assets/svg/nav-cross-xs.svg';
import { StyledIconBar as IconBar } from '../../IconBar/IconBar.jsx';
import { useNavigationContext } from '../NavigationContainer/NavigationContext.jsx';
import { userIsLoggedIn } from '../../../state/user/userSelectors';
import { useGlobalsContext } from '../../../context/GlobalsContext';
import { keyCodes } from '../../../constants/keyCodes';

export const BurgerButton = ({ className }) => {
  const { isNavMenuOpen, toggleNavMenu } = useNavigationContext();
  const isLoggedIn = useSelector(userIsLoggedIn);
  const { document } = useGlobalsContext();

  return (
    <button
      onClick={toggleNavMenu}
      type="button"
      className={className}
      data-toggle="collapse"
      data-target="#nav-user-menu"
      data-testid="openMainMenuButton"
      aria-haspopup="true"
      aria-controls="navbar"
      aria-expanded={isNavMenuOpen}
      onKeyDown={evt => {
        if (isNavMenuOpen && evt.shiftKey && evt.key === keyCodes.TAB) {
          const lastEntry = document.querySelector(
            isLoggedIn ? '#signOutButton>*' : '#signInButton>*',
          );
          if (lastEntry) {
            evt.preventDefault();
            lastEntry.focus();
          } else {
            console.error("Can't find expected entries in navmenu");
          }
        }
      }}
    >
      {isNavMenuOpen ? (
        <span className="close-menu" />
      ) : (
        <>
          <IconBar />
          <IconBar />
          <IconBar />
        </>
      )}
      <span className="sr-only">Toggle navigation</span>
    </button>
  );
};

const defaultHoverStyles = `
  &:hover {
    ${IconBar} {
      background-color: ${colors.DARK_BLUE_GREY_02};
    }
    .close-menu {
      background: url(${navCrossXs});
      background-size: cover;
    }
  }
`;

const buttonPadding = '10px'; // enlarges clickable area

export const StyledBurgerButton = styled(BurgerButton)`
  z-index: 5000;
  display: block;
  position: fixed;
  background-color: transparent;
  background-image: none;
  padding: ${buttonPadding};
  /*! @noflip */
  right: calc(${columnSize.c1} - ${buttonPadding});
  top: 30px;
  transform: translateY(-50%);

  border: none;
  border-radius: 0;

  -webkit-appearance: button;
  cursor: pointer;

  ${mediaMin.md`
    top: 40px;
  `}

  ${mediaMin.xl`
    top: 50%;
    position: absolute;
  `}

  ${mediaDevice.mouse`
    ${defaultHoverStyles}
  `}
  ${mediaDevice.touch`
    ${defaultHoverStyles}
  `}

  &:focus {
    outline: 0;
    ${IconBar} {
      background-color: ${colors.DARK_BLUE_GREY_02};
    }
    .close-menu {
      background: url(${navCrossBlackLg});
      background-size: cover;
    }
  }

  .close-menu {
    background: url(${navCrossXs});
    display: block;
    height: 15px;
    width: 15px;

    ${mediaMin.md`
      background: url(${navCrossBlackMd});
      height: 21px;
      width: 22px;
    `}
  }
`;
