import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { INQUIRY_MEDIA_TYPE } from '../../../../constants/inquiryMediaTypes';
import { LessonExerciseTextAnswer } from './LessonExerciseTextAnswer/LessonExerciseTextAnswer.jsx';
import { StyledLessonExerciseImageAnswer as LessonExerciseImageAnswer } from './LessonExerciseImageAnswer/LessonExerciseImageAnswer.jsx';

const fragmentName = 'LessonExerciseAnswer';
export const lessonExerciseAnswerFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Inquiry {
        subInquiries {
          id
          order
          subInquiryText: inquiryText
          subInquiryDescription: inquiryDescription
          images {
            id
          }
          alternatives {
            id
            alternativeText
            isCorrect
            images {
              id
              staticUrl
            }
          }
        }
      }       
    `,
  ),
};

export const ExerciseAnswer = ({
  answerMediaType,
  answer,
  index,
  onAnswerClicked,
  ...restProps
}) => {
  const Component =
    answerMediaType === INQUIRY_MEDIA_TYPE.TEXT.toLowerCase()
      ? LessonExerciseTextAnswer
      : LessonExerciseImageAnswer;
  return (
    <Component
      key={answer.id}
      answer={answer}
      handleAnswerClicked={() => onAnswerClicked(index)}
      {...restProps}
    />
  );
};
