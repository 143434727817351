import { useSelector } from 'react-redux';
import { StyledHeader as Header } from '../Header/Header.jsx';
import { StyledFooter as Footer } from '../Footer/Footer.jsx';
import { I18nConfigLoader } from '../I18n/I18nConfigLoader.jsx';
import { PageMetaData } from './PageMetaData.jsx';
import { MainPage } from '../../pages/MainPage.jsx';
import { GtmScriptWithDataLayer } from '../GoogleTagManager';
import { GTM_NO_CONTENT_PAGES } from '../../constants/pageTypes';
import { userLoading } from '../../state/user/userSelectors';
import { StyledLoadingMessage } from '../commons/LoadingMessage.jsx';
import { JsonLdScript } from '../JsonLd/JsonLdScript.jsx';
import { ConsentManagement } from '../ConsentManagement/ConsentManagement.jsx';

export const PageContainer = ({ children }) => {
  const userIsLoading = useSelector(userLoading);

  return (
    <MainPage>
      <I18nConfigLoader>
        {data => (
          <>
            <Header />
            <JsonLdScript />
            {userIsLoading ? <StyledLoadingMessage /> : children}
            <Footer data={data} />
            <ConsentManagement data={data} />
          </>
        )}
      </I18nConfigLoader>
    </MainPage>
  );
};

/**
 * @param {React.ComponentProps<typeof PageMetaData>} props
 */
export const PageContainerAndMetadata = ({ children, pageType, ...pageMetaDataProps }) => (
  <PageContainer>
    <PageMetaData pageType={pageType} {...pageMetaDataProps} />
    <GtmScriptWithDataLayer noContentPageName={GTM_NO_CONTENT_PAGES[pageType]} />
    {children}
  </PageContainer>
);
