import { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { cleanupAndAddPlaceholderComponents } from '../clozeExerciseUtils';
import { StyledClozePulldown as ClozePulldown } from '../ClozePulldown.jsx';
import { StyledExerciseRichText as ExerciseRichText } from '../ExerciseRichText.jsx';
import { colors } from '../../../../../utils/css';

export const PulldownAnswer = ({
  className,
  inquiry: { subInquiries, clozeLayoutType, text: inquiryText },
  onCorrectAnswerAmountChanged,
  inquiryState,
  isDisabled,
  knowledges,
}) => {
  // maps pulldownId to the id of the answer selected in that pulldown
  /** @type {React.MutableRefObject<Record<number, number | undefined>} */
  const selectionRef = useRef({});

  const createPulldown = useCallback(
    index => {
      const { id: pulldownId, alternatives } = subInquiries[index];

      return (
        <ClozePulldown
          alternatives={alternatives}
          selectionRef={selectionRef}
          pulldownId={pulldownId}
          inquiryState={inquiryState}
          isDisabled={isDisabled}
          onAnswerSelected={answerId => {
            console.log(answerId);
            selectionRef.current = { ...selectionRef.current, [pulldownId]: answerId };

            onCorrectAnswerAmountChanged(
              subInquiries.filter(({ id, alternatives: alts }) =>
                alts.some(a => a.isCorrect && a.id === selectionRef.current[id]),
              ).length,
            );
          }}
        />
      );
    },
    [inquiryState, isDisabled, onCorrectAnswerAmountChanged, subInquiries],
  );

  const answerContent = useMemo(
    () =>
      cleanupAndAddPlaceholderComponents(
        clozeLayoutType === 'CONTINUOUS_TEXT'
          ? inquiryText
          : subInquiries.map(s => s.subInquiryText).join('<p></p>'),
      ),
    [clozeLayoutType, inquiryText, subInquiries],
  );

  if (clozeLayoutType !== 'CONTINUOUS_TEXT' && clozeLayoutType !== 'SINGLE_SENTENCE') {
    return null;
  }

  return (
    <div className={className} data-cloze-layout-type={clozeLayoutType}>
      <ExerciseRichText
        content={answerContent}
        createComponent={createPulldown}
        isA="div"
        knowledges={knowledges}
      />
    </div>
  );
};

export const StyledPulldownAnswer = styled(PulldownAnswer)`
  &[data-cloze-layout-type='CONTINUOUS_TEXT'] p:not(:first-child) {
    display: inline;
  }

  position: relative;
  background-color: ${colors.BLUE_GREY_01};
  padding: 20px;

  /* @noflip */
  text-align: left;
  /* @noflip */
  direction: ltr;

  ${ClozePulldown} {
    margin: 0.1rem 0.25rem;
  }
`;
