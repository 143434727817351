import { USER } from '../constants/userUrl';

export const PS_PREFIX = USER.PS_URL_WITH_DOMAIN;
export const CERTIFICATE_URL = `${PS_PREFIX}protected/certificate/`;

export const getCertificate = id => {
  return fetch(`${CERTIFICATE_URL}${id}`, {
    headers: {
      'Content-Type': 'application/pdf',
    },
  }).then(response => (response.ok ? response : response.json().then(err => Promise.reject(err))));
};
