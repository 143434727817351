import { PageMetaData } from '../components/Page/PageMetaData.jsx';
import { GTM_NO_CONTENT_PAGES, PAGE_TYPES } from '../constants/pageTypes';
import { StyledVocabulary as Vocabulary } from '../components/Vocabulary/Vocabulary.jsx';
import { PageContainer } from '../components/Page/PageContainer.jsx';
import { GtmScriptWithDataLayer } from '../components/GoogleTagManager';

export const VocabularyPage = () => {
  return (
    <PageContainer>
      <PageMetaData pageType={PAGE_TYPES.VOCABULARY_OVERVIEW} />
      <GtmScriptWithDataLayer noContentPageName={GTM_NO_CONTENT_PAGES.VOCABULARY_OVERVIEW} />
      <Vocabulary />
    </PageContainer>
  );
};
