import { createContext, useRef, useState } from 'react';
import { LessonExerciseSoundFailureHiddenAudio } from '../components/Lesson/LessonExercise/LessonExerciseHiddenAudio/LessonExerciseSoundFailureHiddenAudio.jsx';
import { LessonExerciseSoundSuccessHiddenAudio } from '../components/Lesson/LessonExercise/LessonExerciseHiddenAudio/LessonExerciseSoundSuccessHiddenAudio.jsx';

/**
* @type {React.Context<[
*   React.MutableRefObject<HTMLAudioElement>[],
*   React.Dispatch<React.SetStateAction<React.MutableRefObject<HTMLAudioElement>[]>>,
*   React.MutableRefObject<HTMLAudioElement>,
*   React.MutableRefObject<HTMLAudioElement>,
]>}
*/
export const AudioContext = createContext([
  [],
  () => {},
  { current: { play: () => {} } },
  { current: { play: () => {} } },
]);

export const AudioProvider = props => {
  const [questionAudiosRefArray, setQuestionAudiosRefArray] = useState([]);
  const wrongAnswerAudioRef = useRef();
  const correctAnswerAudioRef = useRef();

  return (
    <AudioContext.Provider
      value={[
        questionAudiosRefArray,
        setQuestionAudiosRefArray,
        wrongAnswerAudioRef,
        correctAnswerAudioRef,
      ]}
    >
      <LessonExerciseSoundFailureHiddenAudio ref={wrongAnswerAudioRef} />
      <LessonExerciseSoundSuccessHiddenAudio ref={correctAnswerAudioRef} />
      {props.children}
    </AudioContext.Provider>
  );
};
