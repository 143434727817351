import styled from 'styled-components';
import { colors } from '../../utils/css';
import { PointerSvg } from '../BubbleButton/BubbleButton.jsx';
import { DetailIntroText } from '../Text/Text.jsx';

const UnstyledBubble = ({ children, ...restProps }) => {
  return (
    <DetailIntroText as="div" {...restProps}>
      {children}
      <PointerSvg />
    </DetailIntroText>
  );
};

export const Bubble = styled(UnstyledBubble)`
  background-color: ${colors.LG_WHITE};
  position: relative;
  text-align: start;
  padding: calc(1rem - 3px);
  border: solid 3px ${colors.DW_YELLOW};
  border-radius: 8px;

  > ${PointerSvg} {
    display: block;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%) scaleX(-1);

    /* @noflip */
    left: -23px;

    > .pointerBackground {
      fill: ${colors.LG_WHITE};
    }

    > .border {
      /* Double border width, because we clip the outer half in the svg */
      stroke-width: 6px;
      stroke: ${colors.DW_YELLOW};
    }
  }
`;
