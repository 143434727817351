import styled, { css } from 'styled-components';
import { useCallback } from 'react';
import { StyledRichText as RichText } from './RichText.jsx';
import { replaceMedia } from '../../utils/replacers/mediaReplacer.jsx';
import { replaceImageGallery } from '../../utils/replacers/imageGalleryReplacer.jsx';
import { colors, mediaMin } from '../../utils/css';
import { replaceWidgets } from '../../utils/replacers/widgetReplacer.jsx';
import { useGlobalsContext } from '../../context/GlobalsContext';
import { contentAreaPadding as contentSpacing } from '../Carousel/Indicator.jsx';

export const RichTextWithMedia = ({
  className,
  videos,
  audios,
  imageGalleries,
  additionalReplacements,
  ...richTextProps
}) => {
  const { document } = useGlobalsContext();

  const insertMedia = useCallback(
    node => {
      const mediaPortals =
        (videos && videos.length > 0) || (audios && audios.length > 0)
          ? replaceMedia({
              node,
              videos,
              audios,
            })
          : [];

      const imageGalleryPortals =
        imageGalleries && imageGalleries.length > 0
          ? replaceImageGallery({ node, imageGalleries })
          : [];

      const widgetPortals = replaceWidgets({
        selector: '.dw-widget',
        node,
        document,
      });

      const additionalPortals = additionalReplacements?.(node);

      return [
        ...mediaPortals,
        ...imageGalleryPortals,
        ...widgetPortals,
        ...(additionalPortals ?? []),
      ];
    },
    [additionalReplacements, audios, document, imageGalleries, videos],
  );

  return (
    <RichText
      className={className}
      additionalReplacements={insertMedia}
      isContainer={false}
      {...richTextProps}
    />
  );
};

export const mediaTagStyles = css`
  width: 0.9em;
  height: 0.9em;
  margin-bottom: 0.18em;
  margin-right: 7px;
  vertical-align: middle;

  path {
    fill: currentColor;
  }

  ${mediaMin.xl`
    margin-right: 10px;
  `}
`;

export const StyledRichTextWithMedia = styled(RichTextWithMedia)`
    margin: 1.5rem 0 3rem;
    
    h2:first-child:not(p),
    h3:first-child:not(p) {
        margin: 0 0 1rem;
    }
    
    a.external-link svg {
        margin: 0 0.35em;
    }
    
    .vjs-wrapper {
      display: flex;
      flex-direction: column-reverse;
      background: ${colors.LG_BLACK};
        margin: 0;
        padding: 20px;
        svg {
          ${mediaTagStyles}
        }
      }
      video:not(.vjs-tech),
      audio:not(.vjs-tech) {
        height: 56.25vw;
      }
  
      &.big {
        margin-bottom: 20px;
        width: auto;
  
        ${mediaMin.md`
          margin-bottom: 30px;
          .headline h2 {
              padding: 2rem ${contentSpacing.md}px 1rem;
          }
        `}
  
        ${mediaMin.lg`
          margin-bottom: 40px;
        `}
  
        ${mediaMin.xl`
          .headline h2 {
              padding: 2rem ${contentSpacing.lg}px 1rem;
          }
        `}
      }
    }
  
    .vjs-wrapper:not(.big) {
      width: 45%;
      margin-top: 0.5em;
      float: left;
      &.left {
        margin-right: 15px;
        margin-left: 30px;
      }
      &.right {
        float: right;
        margin-left: 15px;
        margin-right: 30px;
      }
      video:not(.vjs-tech),
      audio:not(.vjs-tech) {
        height: 24.5vw;
      }
    }
  
    ${mediaMin.md`
      .vjs-wrapper:not(.big) {
        width: 46%;
        &.left {
          margin-left: 24px;
        }
        &.right {
          margin-right: 24px;
        }
      }
    `}
  
    ${mediaMin.xl`
      .vjs-wrapper.big {
        video:not(.vjs-tech),
        audio:not(.vjs-tech) {
          height: 624.375px;
        }
      }
  
      .vjs-wrapper:not(.big) {
        width: 41%;
        &.left {
          margin-left: 0px;
        }
        &.right {
          margin-right: 0px;
        }
        video:not(.vjs-tech),
        audio:not(.vjs-tech) {
          height: 256px;
        }
      }
    `}
`;
