import styled from 'styled-components';
import classNames from 'classnames';
import { Bubble } from '../../../../Bubble/Bubble.jsx';

export const LessonExerciseQuestionText = ({ className, inquiryText, joinBottom }) => {
  if (inquiryText.length === 0) return null;

  return <Bubble className={classNames(className, { joinBottom })}>{inquiryText}</Bubble>;
};

export const StyledLessonExerciseQuestionText = styled(LessonExerciseQuestionText)`
  /* @noflip */
  text-align: left;
  /* @noflip */
  direction: ltr;

  width: 100%;

  &.joinBottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
