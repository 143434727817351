import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { StyledCheckbox } from '../../FormElements/Checkbox/StyledCheckbox.jsx';
import { I18nText } from '../../I18n/I18nText.jsx';
import { StyledStandardButton } from '../../StandardButton/StandardButton.jsx';
import { deleteUserAccount } from '../../../state/user/userDuck';
import { feedbackHelper } from '../../../utils/url/feedbackHelpers';
import { useTranslation } from '../../../hooks/useTranslation';
import { CloseModalButton, StyledModal } from '../StyledModal.jsx';
import { colors, mediaMin } from '../../../utils/css';
import { useLanguage } from '../../../context/LanguageContext';
import { useOnEsc } from '../../../hooks/useOnEsc';
import { NavigationSectionHeadline } from '../../Headline/Headline.jsx';
import { DetailIntroText } from '../../Text/Text.jsx';

export const DeleteAccountModal = ({ onClose, className }) => {
  const { langCode } = useLanguage();
  const history = useHistory();
  const dispatch = useDispatch();

  const formMethods = useForm({
    mode: 'onSubmit',
  });
  const { handleSubmit } = formMethods;

  const confirmDeleteLabel = useTranslation('profile.edit.modal.confirmDelete');

  const onSubmit = async () => {
    try {
      await dispatch(deleteUserAccount());
      history.push(feedbackHelper.forUserDelete(langCode));
    } catch (err) {
      history.push(feedbackHelper.getForErrorCode(err, langCode));
    }
  };

  useOnEsc(onClose);

  return (
    <CustomStyledModal fadeIn={true} className={className}>
      <FormProvider {...formMethods}>
        <form
          id="main-form"
          data-id="delete-user"
          className="validate"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="modal-header">
            <CloseModalButton onClick={onClose} className="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </CloseModalButton>
            <I18nText
              isA={NavigationSectionHeadline}
              as="h4"
              translation="profile.edit.modal.title"
              className="modal-title"
            />
          </div>
          <div className="modal-body">
            <I18nText
              isA={DetailIntroText}
              as="span"
              translation="profile.edit.modal.confirmQuestion"
            />
            <div className="modal-form">
              <StyledCheckbox
                name="confirmUserDelete"
                labelText={confirmDeleteLabel}
                registerOptions={{ required: true }}
                translationKeyForErrorInsertion="profile.edit.modal.confirmDelete"
              />
              <StyledStandardButton titleKey="profile.edit.modal.deleteAccount" />
              <StyledStandardButton titleKey="profile.edit.modal.cancel" onClick={onClose} />
            </div>
          </div>
        </form>
      </FormProvider>
    </CustomStyledModal>
  );
};

export const StyledDeleteAccountModal = styled(DeleteAccountModal)`
  .modal-header {
    display: block;
    padding: 15px 15px 10px;
    border-bottom: 1px solid ${colors.DW_GREY_02};
  }
  .modal-body {
    padding: 10px 15px 15px 15px;
    position: relative;
  }
  .modal-form {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  ${StyledStandardButton} {
    width: 100%;
    margin: 20px 0;
  }
`;

export const CustomStyledModal = styled(StyledModal)`
  ${mediaMin.md`
    .modal-dialogue {
      width: 300px;
    }
  `}
`;
