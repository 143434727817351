import styled from 'styled-components';
import { Link } from '../../Link/Link.jsx';
import { PageLinkInteraction } from '../../Interaction/Interaction.jsx';
import { TeaserText } from '../../Text/Text.jsx';

export const LinkTeaser = ({ className, contents, ...restProps }) => {
  const { name, description, url, target } = contents;
  const targetProps = target
    ? { to: target.namedUrl }
    : { native: true, to: url, target: '_blank' };

  return (
    <Link className={className} title={name} {...targetProps} {...restProps}>
      <PageLinkInteraction as="h3">{name}</PageLinkInteraction>
      {description && <TeaserText as="div">{description}</TeaserText>}
    </Link>
  );
};

export const StyledLinkTeaser = styled(LinkTeaser)`
  display: table-cell;

  text-decoration: none;
  margin-bottom: 1rem;

  :hover > ${PageLinkInteraction} {
    text-decoration: underline;
  }

  > ${PageLinkInteraction} {
    margin: 0;
  }

  > ${TeaserText} {
    margin-top: 0.5rem;
  }

  > div {
    padding-left: 0;
    flex-grow: 1;
    font-weight: normal; /* Don't inherit */
  }
`;
