import { TrackingUtils } from './TrackingUtils';

const convertSecondsToMilliSeconds = timeInSeconds => timeInSeconds * 1000;

const roundToFixedPrecision = number => Math.round(number * 100) / 100;

const extractMediaId = playerId => +playerId.replace(/(video|audio)-/gi, '');

const pushMediaEventToGoogleTagManager = ({ player, mediaEvent, ...extra }) => {
  TrackingUtils.pushToGoogleTagManager({
    datalayerObj: {
      event: 'onMediaEvent',
      eventData: {
        mediaEvent,
        mediaId: extractMediaId(player.id()),
        currentMediaTime: convertSecondsToMilliSeconds(player.currentTime()),
        ...extra,
      },
    },
  });
};

/** @param {videojs.VideoJsPlayer} player */
export const registerTrackingEvents = player => {
  player.on('play', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.play' });
  });

  player.on('firstplay', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.start' });
  });

  player.on('pause', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.pause' });
  });

  player.on('ended', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.stop' });
  });

  player.on('seeked', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.seeked' });
  });

  player.on('seeking', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.seek.start' });
  });

  if (!player.isAudio()) {
    player.qualityLevels().on('change', event => {
      const selectedQuality = player.qualityLevels()[event.selectedIndex]?.height;
      pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.quality', selectedQuality });
    });
  }

  player.on('ratechange', () => {
    const selectedPlaybackRate = player.playbackRate();
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.speed', selectedPlaybackRate });
  });

  player.on('error', () => {
    pushMediaEventToGoogleTagManager({
      player,
      mediaEvent: 'av.error',
      errorMessage: player.error()?.message,
    });
  });

  player.on('volumechange', () => {
    pushMediaEventToGoogleTagManager({
      player,
      mediaEvent: 'av.volume',
      volume: roundToFixedPrecision(player.volume()),
    });
  });

  player.on('fullscreenchange', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.fullscreen-change' });
  });

  player.on('enterpictureinpicture', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.enter-picture-in-picture' });
  });

  player.on('leavepictureinpicture', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.leave-picture-in-picture' });
  });

  player.on('playbackrateschange', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.playback-rate-change' });
  });

  player.on('loadeddata', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.loaded-data' });
  });

  player.on('loadedmetadata', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.loaded-metadata' });
  });

  player.on('loadstart', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.load-start' });
  });

  player.on('playing', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.playing' });
  });

  player.on('abort', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.abort' });
  });

  player.on('stalled', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.stalled' });
  });

  player.on('suspend', () => {
    pushMediaEventToGoogleTagManager({ player, mediaEvent: 'av.suspend' });
  });
};
