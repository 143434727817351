import gql from 'graphql-tag';
import { useSelector } from 'react-redux';
import { memoizeWith } from 'ramda';
import { toLang } from '../utils/mappers/langMapper';
import { PageContainer } from '../components/Page/PageContainer.jsx';
import { WithGraphQLQueryHandling } from '../components/WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { CourseOverview } from '../components/Course/CourseOverview.jsx';
import { scoreFragment } from '../state/progress/learnProgress';
import { userDataSelector, userIsLoggedIn } from '../state/user/userSelectors';
import { metaDataFragment, PageMetaData } from '../components/Page/PageMetaData.jsx';
import { PAGE_TYPES } from '../constants/pageTypes';
import {
  ImageOGMetaData,
  imageOGMetaDataFragment,
} from '../components/ImageOGMetaData/ImageOGMetaData.jsx';
import { GtmScriptWithDataLayer, gtmDataLayerFragment } from '../components/GoogleTagManager';
import { CourseDataContext } from '../context/CourseDataContext';
import { lessonDetails } from '../components/Course/CourseList/LessonItem/LessonItem.jsx';
import { heroImageFragment } from '../components/HeroImage/HeroImage.jsx';

const queryName = ({ addMetaInfo, handicap }) =>
  `CoursePage${handicap || ''}${addMetaInfo ? 'WithMetaInfo' : ''}`;
export const coursePageQuery = {
  name: queryName,
  query: memoizeWith(
    ({ addMetaInfo, handicap }) => `${addMetaInfo};${handicap}`,
    ({ addMetaInfo, handicap }) => gql`
      query ${queryName({ addMetaInfo, handicap })}($id: Int!, $lang: Language!) {
        content(id: $id, lang: $lang) {
          ... on Course {
            id
            name
            title
            dkLearningLevel
            namedUrl
            ...${heroImageFragment.name}
            contentLinks(targetTypes: LESSON) {
              id
              additionalInformation
              groupName
              target {
                ...${lessonDetails.name}
              }
            }
            ...${imageOGMetaDataFragment.name({ type: 'Course' })}
            ...${scoreFragment.name({ type: 'Course', addLearningMetaInfo: addMetaInfo, handicap })}
            ...${metaDataFragment.name({ type: 'Course' })}
            ${gtmDataLayerFragment.partial()}
          }
        }
      }
      ${scoreFragment.fragment({ type: 'Course', addLearningMetaInfo: addMetaInfo, handicap })}
      ${imageOGMetaDataFragment.fragment({ type: 'Course' })}
      ${metaDataFragment.fragment({ type: 'Course' })}
      ${lessonDetails.fragment()}
      ${heroImageFragment.fragment()}
    `,
  ),
};

export const CoursePage = ({ contentId, langCode }) => {
  const isLoggedIn = useSelector(userIsLoggedIn);
  const { handicap } = useSelector(userDataSelector) || {};

  const coursePageQueryVariables = {
    id: +contentId,
    lang: toLang(langCode),
  };

  return (
    <PageContainer>
      <WithGraphQLQueryHandling
        query={coursePageQuery.query({ addMetaInfo: isLoggedIn, handicap })}
        queryVariables={coursePageQueryVariables}
      >
        {({ data }) => {
          const {
            dkLearningLevel,
            name: courseName,
            title: courseTitle,
            __typename: type,
          } = data.content;
          return (
            <CourseDataContext.Provider
              value={{
                courseId: +contentId,
                courseName,
                courseTitle,
                dkLearningLevel,
                type,
              }}
            >
              <PageMetaData
                pageType={PAGE_TYPES.COURSE}
                content={data.content}
                useOnlyCmsKeywords={true}
                relativeCanonicalUrl={data.content.namedUrl}
              />
              <ImageOGMetaData image={data.content.mainContentImage} />
              <GtmScriptWithDataLayer content={data.content} />
              <CourseOverview {...{ data, contentId }} />
            </CourseDataContext.Provider>
          );
        }}
      </WithGraphQLQueryHandling>
    </PageContainer>
  );
};
