import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';
import stylisRTLPlugin from 'stylis-plugin-rtl';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { StyledDefault404Page } from '../pages/Default404Page.jsx';
import { CoursePage } from '../pages/CoursePage.jsx';
import { GrammarOverviewPage } from '../pages/GrammarOverviewPage.jsx';
import { pathPartials } from '../utils/url/pathAnalyser';
import { LessonPage } from '../pages/LessonPage.jsx';
import {
  PAGE_GRAMMAR,
  PAGE_OVERVIEW,
  PAGE_PASSWORD_CHANGE,
  PAGE_PASSWORD_RESET,
  PAGE_PLACEMENT_TEST,
  PAGE_PROFILE,
  PAGE_REGISTRATION,
  PAGE_VOCABULARY,
  PAGE_VOCABULARY_TRAINER,
  PAGE_VOCABULARY_TRAINER_START,
  STATUS_FEEDBACK,
} from '../utils/url/urlFactory';
import { RestrictedRoute } from './RestrictedRoute/RestrictedRoute.jsx';
import { ConfirmUserRegistrationContainer } from './user/ConfirmUserRegistrationContainer.jsx';
import { StyledRegistration as Registration } from './user/Registration.jsx';
import { StyledPasswordChange as PasswordChange } from './user/PasswordChange.jsx';
import { StyledPasswordReset as PasswordReset } from './user/PasswordReset.jsx';
import { StyledStatusFeedback as StatusFeedback } from './StatusFeedback/StatusFeedback.jsx';
import { PageContainer, PageContainerAndMetadata } from './Page/PageContainer.jsx';
import { StyledUserProfile as UserProfile } from './user/StyledUserProfile.jsx';
import { StyledSetNewPassword as SetNewPassword } from './user/SetNewPassword.jsx';
import { PlacementTestPage } from '../pages/PlacementTestPage.jsx';
import { VocabularyTrainer } from './VocabularyTrainer/VocabularyTrainer.jsx';
import { FinalTestResultPage } from '../pages/FinalTestResultPage.jsx';
import { PlacementTestResultPage } from '../pages/PlacementTestResultPage.jsx';
import { HelpPage } from '../pages/HelpPage.jsx';
import { VendorConsentPage } from '../pages/VendorConsentPage.jsx';
import { PAGE_TYPES } from '../constants/pageTypes';
import { NavigationPage } from '../pages/NavigationPage.jsx';
import { VocabularyPage } from '../pages/VocabularyPage.jsx';
import { ContentDetailPage } from '../pages/ContentDetailPage.jsx';
import { ConfirmEmailChangeContainer } from './user/ConfirmEmailChangeContainer.jsx';
import { LanguageContext } from '../context/LanguageContext';
import { isRtlByLangCode } from '../utils/mappers/rtlLanguages';
import { usePrev } from '../hooks/usePrev';
import { ErrorBoundary } from './ErrorBoundary/ErrorBoundary.jsx';
import { RedirectToLandingPage } from './Redirect/RedirectToLandingPage.jsx';

export const Pages = () => {
  const { path } = useRouteMatch();
  const { langCode } = useParams();

  return (
    <LanguageContext.Provider value={{ langCode, isRtl: isRtlByLangCode(langCode) }}>
      <ErrorBoundary>
        <Switch>
          <Route exact path={`${path}${PAGE_OVERVIEW}`}>
            <PageContainer>
              <RedirectToLandingPage />
            </PageContainer>
          </Route>
          <Route
            path={`${path}/:title(${pathPartials.titlePathRegex})?/l-:lessonId(${pathPartials.contentIdRegex})`}
          >
            <LessonPage />
          </Route>
          <Route
            path={`${path}/:title(${pathPartials.titlePathRegex})?/c-:contentId(${pathPartials.contentIdRegex})`}
            render={({ match: { params } }) => <CoursePage {...params} />}
          />
          <Route
            path={`${path}/:title(${pathPartials.titlePathRegex})?/gr-:contentId(${pathPartials.contentIdRegex})`}
            render={({ match: { params } }) => (
              <Redirect to={`/${langCode}/grammar#${params.contentId}`} />
            )}
          />
          <Route
            path={`${path}/:title(${pathPartials.titlePathRegex})?/placement-:contentId(${pathPartials.contentIdRegex})`}
            render={({ match: { params } }) => <PlacementTestResultPage {...params} />}
          />
          <Route
            path={`${path}/:title(${pathPartials.titlePathRegex})?/final-:contentId(${pathPartials.contentIdRegex})`}
            render={({ match: { params } }) => <FinalTestResultPage {...params} />}
          />
          <Route
            path={`${path}/:title(${pathPartials.titlePathRegex})?/s-:contentId(${pathPartials.contentIdRegex})`}
          >
            <NavigationPage />
          </Route>
          <Route
            path={`${path}/:title(${pathPartials.titlePathRegex})?/a-:contentId(${pathPartials.contentIdRegex})`}
            render={({ match: { params } }) => <ContentDetailPage {...params} type="Article" />}
          />
          <Route exact path={`${path}${PAGE_GRAMMAR}`}>
            <GrammarOverviewPage />
          </Route>
          <Route exact path={`${path}/help`}>
            <HelpPage />
          </Route>
          <Route
            exact
            path={`${path}/:title(${pathPartials.titlePathRegex})?/privacy-settings-:langCode(${pathPartials.langCodeRegex})`}
          >
            <VendorConsentPage />
          </Route>
          <Route exact path={`${path}${PAGE_VOCABULARY}`}>
            <VocabularyPage />
          </Route>
          <Route exact path={`${path}${PAGE_REGISTRATION}`}>
            <PageContainerAndMetadata pageType={PAGE_TYPES.REGISTER_USER} useDescriptionTranslation>
              <Registration />
            </PageContainerAndMetadata>
          </Route>
          <Route
            path={`${path}/user/password/set`}
            render={({ location }) => (
              <PageContainerAndMetadata
                pageType={PAGE_TYPES.PASSWORD_SET}
                addKeywordsAndDescription={false}
                noSearchEngine
              >
                <SetNewPassword
                  confirmationId={new URLSearchParams(location.search).get('confirmationId')}
                />
              </PageContainerAndMetadata>
            )}
          />
          <Route
            path={`${path}/user/register/confirm`}
            render={({ location }) => (
              <>
                <Helmet>
                  <meta name="robots" content="none" />
                </Helmet>
                <ConfirmUserRegistrationContainer
                  confirmationId={new URLSearchParams(location.search).get('confirmationId')}
                />
              </>
            )}
          />
          <Route
            path={`${path}/user/email/change`}
            render={({ location }) => (
              <>
                <Helmet>
                  <meta name="robots" content="none" />
                </Helmet>
                <ConfirmEmailChangeContainer
                  confirmationId={new URLSearchParams(location.search).get('confirmationId')}
                />
              </>
            )}
          />
          <RestrictedRoute exact path={`${path}${PAGE_PROFILE}`}>
            <PageContainerAndMetadata
              pageType={PAGE_TYPES.USER_PROFILE}
              addKeywordsAndDescription={false}
              noSearchEngine
            >
              <UserProfile />
            </PageContainerAndMetadata>
          </RestrictedRoute>
          <RestrictedRoute exact path={`${path}${PAGE_PASSWORD_CHANGE}`}>
            <PageContainerAndMetadata
              pageType={PAGE_TYPES.PASSWORD_CHANGE}
              addKeywordsAndDescription={false}
              noSearchEngine
            >
              <PasswordChange />
            </PageContainerAndMetadata>
          </RestrictedRoute>
          <RestrictedRoute
            exact
            path={[`${path}${PAGE_VOCABULARY_TRAINER}`, `${path}${PAGE_VOCABULARY_TRAINER_START}`]}
          >
            <PageContainerAndMetadata
              pageType={PAGE_TYPES.VOCABULARY_TRAINER}
              addKeywordsAndDescription={false}
            >
              <VocabularyTrainer />
            </PageContainerAndMetadata>
          </RestrictedRoute>
          <Route exact path={`${path}${PAGE_PASSWORD_RESET}`}>
            <PageContainerAndMetadata
              pageType={PAGE_TYPES.PASSWORD_RESET}
              addKeywordsAndDescription={false}
            >
              <PasswordReset />
            </PageContainerAndMetadata>
          </Route>
          <Route
            exact
            path={`${path}${STATUS_FEEDBACK}/:feedbackType(${pathPartials.feedbackTypes})`}
          >
            <PageContainerAndMetadata
              pageType={PAGE_TYPES.FEEDBACK_STATUS}
              addKeywordsAndDescription={false}
              noSearchEngine
            >
              <StatusFeedback />
            </PageContainerAndMetadata>
          </Route>
          <Route exact path={`${path}${PAGE_PLACEMENT_TEST}`}>
            <PlacementTestPage />
          </Route>
          <Route exact path={path}>
            <Redirect to={`/${langCode}/overview`} />
          </Route>
          <Route path={path}>
            <PageContainer>
              <StyledDefault404Page />
            </PageContainer>
          </Route>
        </Switch>
      </ErrorBoundary>
    </LanguageContext.Provider>
  );
};

const langCodeRegex = new RegExp(`^/(${pathPartials.langCodeRegex})`);

export const App = ({ ...restProps }) => {
  const { pathname } = useLocation();

  const isRtl = useMemo(() => isRtlByLangCode(langCodeRegex.exec(pathname)?.[1]), [pathname]);

  /**
   * Force a rerender on first isRtl change.
   * This is a workaround for stylesheets not updating after SSR.
   */
  const [hasRerendered, setHasRerendered] = useState(false);
  const prevRtl = usePrev(isRtl);

  useEffect(() => {
    if (hasRerendered || prevRtl === undefined || prevRtl === isRtl) {
      return;
    }

    setHasRerendered(true);
  }, [hasRerendered, isRtl, prevRtl]);

  return (
    <StyleSheetManager
      key={hasRerendered}
      stylisPlugins={isRtl ? [stylisRTLPlugin] : []}
      {...restProps}
    >
      <Switch>
        <Route exact path="/">
          <Redirect to="/en/overview" />
        </Route>
        <Route path={`/:langCode(${pathPartials.langCodeRegex})`}>
          <Pages />
        </Route>
        <Route>
          <PageContainer>
            <StyledDefault404Page />
          </PageContainer>
        </Route>
      </Switch>
    </StyleSheetManager>
  );
};
