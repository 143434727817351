import { useCallback, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useHistory, useLocation } from 'react-router-dom';
import { memoizeWith } from 'ramda';
import { StyledVocabularyTrainerStart as VocabularyTrainerStart } from './VocabularyTrainerStart.jsx';
import { StyledVocabularyTrainerExercise as VocabularyTrainerExercise } from './VocabularyTrainerExercise.jsx';
import { WithGraphQLQueryHandling } from '../WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { toLang } from '../../utils/mappers/langMapper';
import { shuffle } from '../../utils/commons';
import {
  getFeedbackUrl,
  getVocabularyTrainerPage,
  PAGE_VOCABULARY_TRAINER_START,
} from '../../utils/url/urlFactory';
import { FEEDBACK_TYPES } from '../../constants/feedback';
import { vocabularyFragment } from '../Vocabulary/VocabularyQuery.jsx';
import { useLanguage } from '../../context/LanguageContext';

const queryName = 'VocabularyTrainer';
export const vocabularyTrainerQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}($keys: [ContentKeyInput]!, $lang: Language!) {
        contents(keys: $keys, lang: $lang) {
          ... on Lesson {
            id
            vocabularies {
              ...${vocabularyFragment.name}
              audios {
                mp3Src
                mainContentImage {
                  id
                  name
                }
              }
            }
          }
        }
      }
      ${vocabularyFragment.fragment()}
    `,
  ),
};

export const makeQueryVariables = ({ ids = [], langCode }) => ({
  keys: ids.map(lessonId => ({
    id: lessonId,
    type: 'LESSON',
  })),
  lang: toLang(langCode),
});

export const VocabularyTrainer = () => {
  const [lastLessonsIds, setLastLessonsIds] = useState([]);
  const [numberOfVocabularies, setNumberOfVocabularies] = useState(0);
  const [isStarted, setStarted] = useState(false);
  const { langCode } = useLanguage();
  const { pathname } = useLocation();
  const history = useHistory();

  const handleExerciseStart = () => {
    setStarted(true);
  };

  const handleExerciseEnd = useCallback(() => {
    setStarted(false);
  }, []);

  useEffect(() => {
    if (pathname.includes(PAGE_VOCABULARY_TRAINER_START)) {
      setStarted(false);
      setLastLessonsIds([]);
      history.replace(getVocabularyTrainerPage(langCode));
    }
    if (isStarted && !lastLessonsIds?.length) {
      history.push(getFeedbackUrl(FEEDBACK_TYPES.VOCABULARY_TRAINER_NO_VOCABULARIES, langCode));
    }
  }, [history, langCode, lastLessonsIds, isStarted, pathname]);

  const getRandomVocabularies = useCallback(
    ({ contents }) => {
      const allVocabularies = new Set();
      contents.forEach(content => {
        if (content) {
          content.vocabularies.forEach(v => allVocabularies.add(v));
        }
      });
      return shuffle(Array.from(allVocabularies)).slice(0, numberOfVocabularies);
    },
    [numberOfVocabularies],
  );

  if (!isStarted) {
    return (
      <VocabularyTrainerStart
        {...{ setLastLessonsIds, setNumberOfVocabularies, handleExerciseStart }}
      />
    );
  }

  return (
    <WithGraphQLQueryHandling
      query={vocabularyTrainerQuery.query()}
      queryVariables={makeQueryVariables({ ids: lastLessonsIds, langCode })}
    >
      {({ data }) => (
        <VocabularyTrainerExercise
          vocabularies={getRandomVocabularies(data)}
          handleExerciseEnd={handleExerciseEnd}
        />
      )}
    </WithGraphQLQueryHandling>
  );
};
