import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import { ReactComponent as IconHintCircleOrange } from '../../assets/svg/icon-hint-circle-orange.svg';
import { StyledVocabularySearch as VocabularySearch } from './VocabularySearch/VocabularySearch.jsx';
import { I18nText } from '../I18n/I18nText.jsx';
import { combineVocabularyData, hasMoreResults } from '../../hooks/vocabularySearchHooks';
import { StyledVocabularyItem as VocabularyItem } from './VocabularyItem.jsx';
import { colors, columnSize } from '../../utils/css';
import { DateTimeInfo } from '../Info/Info.jsx';
import { getQueryVariables, vocabularySearchQuery } from './VocabularyQuery.jsx';
import { useLanguage } from '../../context/LanguageContext';
import { StyledLoadingMessage } from '../commons/LoadingMessage.jsx';
import { StyledStandardButton } from '../StandardButton/StandardButton.jsx';

export const Vocabulary = ({ className }) => {
  const { langCode } = useLanguage();
  const [searchText, setSearchText] = useState('');

  const { data, fetchMore, loading } = useQuery(vocabularySearchQuery.query(), {
    variables: getQueryVariables({ langCode, searchQuery: searchText }),
    notifyOnNetworkStatusChange: true,
  });

  const vocabularyData = useMemo(() => (data ? combineVocabularyData(data) : []), [data]);
  const moreResults = data ? hasMoreResults(data) : true;

  const findAdditionalData = () => {
    fetchMore({
      variables: {
        offset: (data?.original?.offset ?? 0) + 12,
      },
    });
  };

  return (
    <div className={className}>
      <VocabularySearch onItemSelected={setSearchText} />
      <div className="items">
        {vocabularyData.map(item => (
          <VocabularyItem key={item.id} item={item} />
        ))}
      </div>
      {loading && <StyledLoadingMessage />}
      <div className="vocabulary-footer">
        {!moreResults && (
          <>
            <IconHintCircleOrange />
            <I18nText isA={DateTimeInfo} translation="vocabularyOverview.noMoreVocabularies" />
          </>
        )}
        {moreResults && (
          <StyledStandardButton
            titleKey="vocabularyOverview.more"
            data-testid="vocabularyMoreButton"
            disabled={loading}
            onClick={findAdditionalData}
          />
        )}
      </div>
    </div>
  );
};

export const StyledVocabulary = styled(Vocabulary)`
  padding: 1.5rem ${columnSize.c2} 0;

  > div > ${VocabularyItem}:nth-child(odd) {
    background-color: ${colors.BLUE_GREY_01};
  }

  > div > ${VocabularyItem}:nth-child(even) {
    background-color: ${colors.LG_WHITE};
  }

  > .vocabulary-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
  }

  > ${StyledLoadingMessage} {
    height: auto;
  }
`;
