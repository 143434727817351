import styled from 'styled-components';
import { StyledPlacementTile as PlacementTile } from './PlacementTile.jsx';
import { colors, mediaMax } from '../../utils/css';
import { placementTestTileTextColors, tileColors } from '../../constants/tileColors';
import { StyledBlankPlacementTile as BlankPlacementTile } from './BlankPlacementTile.jsx';
import { StyledHeroImage as HeroImage } from '../HeroImage/HeroImage.jsx';

export const PlacementTest = ({ className, placementTestCourse }) => {
  const { mainContentImageLink, lessons } = placementTestCourse;

  return (
    <div className={className}>
      <HeroImage mainContentImageLink={mainContentImageLink} />
      <div className="tiles">
        {lessons.map((lesson, index) => (
          <PlacementTile
            key={lesson.id}
            color={placementTestTileTextColors[index % tileColors.length]}
            lesson={lesson}
          />
        ))}
        <BlankPlacementTile />
      </div>
    </div>
  );
};

export const StyledPlacementTest = styled(PlacementTest)`
  .tiles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-bottom: 1px solid ${colors.LG_WHITE};

    .firstStaticTile {
      grid-column-start: 1;
    }

    ${mediaMax.md`
      grid-template-columns: repeat(2, 1fr);

      .noTablet {
        display: none;
      }
    `}

    ${mediaMax.sm`
      grid-template-columns: 1fr;
    `}
  }
`;
