import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { Link } from '../Link/Link.jsx';
import { FooterLinkInteraction } from '../Interaction/Interaction.jsx';

const fragmentName = 'FooterVendorSettingsLink';
export const footerVendorSettingsLinkFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Footer {
        privacySettings {
          title
          namedUrl
        }
      }
    `,
  ),
};

export const FooterVendorSettingsLink = ({ privacySettings }) => {
  const { namedUrl, title } = privacySettings || {};
  return (
    <FooterLinkInteraction as={Link} to={namedUrl} title={title} target="_blank">
      {title}
    </FooterLinkInteraction>
  );
};
