import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import {
  StyledArticleDetail as ArticleDetail,
  articleDetailFragment,
} from '../ArticleDetail/ArticleDetail.jsx';
import { StyledErrorMessage as ErrorMessage } from '../commons/ErrorMessage.jsx';

const MainContentByType = {
  Article: {
    component: ArticleDetail,
    fragment: articleDetailFragment,
  },
  Unsupported: {
    component: ErrorMessage,
  },
};

const fragmentName = ({ type }) => `${type}MainContent`;
export const mainContentAreaFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    ({ type }) => type,
    ({ type }) => gql`
      fragment ${fragmentName({ type })} on ${type} {
        ...${MainContentByType[type].fragment.name({ type })}
      }
      ${MainContentByType[type].fragment.fragment({ type })}
    `,
  ),
};

export const MainContentArea = data => {
  const { __typename = {} } = data.content || {};
  // eslint-disable-next-line no-underscore-dangle
  const typeConfig = MainContentByType[__typename] || MainContentByType.Unsupported;
  const Tag = typeConfig.component;
  return <Tag data={data} />;
};
