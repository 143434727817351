import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from './useTranslation';

/**
 * @param {string} name
 * @param {string} [label]
 * @returns {{ hasError: boolean, errorMessage: string | null }} return
 */
export const useFormError = (name, label) => {
  const {
    formState: { errors },
  } = useFormContext();

  const err = errors[name];

  const errorMessageKey = useMemo(() => {
    if (!err) {
      return null;
    }

    if (err.type === 'required') {
      return 'client.validator.required';
    }

    return err.message;
  }, [err]);

  const errorMessage = useTranslation({ key: errorMessageKey, parameters: { first: label } });

  return { hasError: Boolean(err), errorMessage };
};
