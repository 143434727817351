import { useEffect, useState } from 'react';

// @used in ROAD
export const BrowserOnly = ({ children, fallback }) => {
  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return fallback || null;
  }

  return children;
};
