import styled from 'styled-components';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useId } from 'react';
import { StyledValidationMessage } from '../ValidationMessage.jsx';
import { colors } from '../../../utils/css';
import { useTranslation } from '../../../hooks/useTranslation';
import { useFormError } from '../../../hooks/useFormError';
import { inputBoldBorder, inputBorderStyles } from '../BaseInput.jsx';
import { StyledInteractionLabel as InteractionLabel } from '../InteractionLabel.jsx';
import { keyCodes } from '../../../constants/keyCodes';

export const Radio = ({ className, name, labelKey, options, registerOptions, defaultChecked }) => {
  const labelText = useTranslation(labelKey);
  const { register } = useFormContext();
  const { hasError, errorMessage } = useFormError(name, labelText);
  const inputId = useId();

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classnames(className, { error: hasError })}
      aria-live="assertive"
      onKeyDown={e => {
        e.key === keyCodes.ENTER && e.preventDefault();
      }}
    >
      <InteractionLabel htmlForName={inputId} labelKey={labelKey} {...{ registerOptions }} />
      <div>
        {options?.map(option => (
          <StyledRadioItem
            key={option.value}
            {...{ name, option, register, registerOptions, defaultChecked }}
          />
        ))}
      </div>
      {errorMessage && <StyledValidationMessage>{errorMessage}</StyledValidationMessage>}
    </div>
  );
};

const RadioItem = ({
  className,
  name,
  registerOptions,
  register,
  option: { key, name: optionName, value, ariaKey },
  defaultChecked,
}) => {
  const ariaLabel = useTranslation(ariaKey);
  const radioLabel = key ?? optionName;
  const inputId = useId();

  return (
    <span className={className}>
      <input
        id={inputId}
        type="radio"
        aria-label={ariaLabel}
        value={value}
        {...register(name, registerOptions)}
        defaultChecked={defaultChecked === value}
      />
      <InteractionLabel htmlForName={inputId} labelKey={radioLabel} />
    </span>
  );
};

const StyledRadioItem = styled(RadioItem)`
  display: grid;
  grid-template-columns: 20px auto;
  gap: 11px;

  input {
    display: grid;
    appearance: none;
    background-color: transparent;
    margin: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    place-content: center;
    ${inputBorderStyles}
    &:active,
    &:focus {
      ${inputBoldBorder}
    }
  }

  input::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 0;
    transform: scale(0);
    box-shadow: inset 10px 10px ${colors.DW_DARK_BLUE};
  }
  input:checked::before {
    transform: scale(1);
  }
  label {
    cursor: pointer;
  }
`;

export const StyledRadio = styled(Radio)`
  display: block;
  margin: 0px;
  padding: 0px;
  padding-bottom: 20px;
  box-sizing: border-box;

  > div {
    display: flex;
    gap: 17px;
    margin-top: 10px;
    /* overwrites basestyles.css */
    > span {
      input[type='radio'] {
        outline: none;
        outline-offset: initial;
      }
    }
  }
`;
