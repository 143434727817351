import { useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { evolve } from 'ramda';
import { StyledManyToManyAnswer as ManyToManyAnswer } from './ManyToManyAnswer/ManyToManyAnswer.jsx';
import { I18nText } from '../../../I18n/I18nText.jsx';
import {
  reducer,
  defaultState,
  CHECK_ANSWERS,
  SELECT_LEFT,
  SELECT_RIGHT,
  DESELECT,
} from './manyToManyQuestionRowReducer';
import { StyledLessonExerciseHint as LessonExerciseHint } from '../LessonExerciseHint/LessonExerciseHint.jsx';
import { StyledSolveCheckButton } from '../../../SolveCheckButton/SolveCheckButton.jsx';
import { StyledLessonExerciseQuestionText as LessonExerciseQuestionText } from '../LessonExerciseQuestion/QuestionTypes/LessonExerciseQuestionText.jsx';
import { useInquiryHandling } from '../workflow/useInquiryHandling';
import { useAudio } from '../../../../hooks/useAudio';
import { safeShuffle } from '../../../../utils/commons';
import { isNormalizedTextEqual } from '../utils/exerciseUtils';
import { INQUIRY_STATE } from '../workflow/inquiryState';
import { colors } from '../../../../utils/css';
import { DateTimeInfo } from '../../../Info/Info.jsx';

const pairCorrect = (leftElement, rightElement) => {
  if (leftElement.id === rightElement.id) {
    return true;
  }
  const expectedAnswerText = leftElement.alternatives[0]?.alternativeText;
  const selectedAnswerText = rightElement.alternatives[0]?.alternativeText;
  return isNormalizedTextEqual(selectedAnswerText, expectedAnswerText);
};

export const ManyToManyExercise = ({ className, inquiry }) => {
  const { exerciseId } = useParams();
  const {
    id: inquiryId,
    inquiryText,
    inquiryDescription,
    subInquiries,
    mediaType,
    learningMetaInfo: { achievableScore },
  } = inquiry;
  const allAnswers = subInquiries;

  const [
    {
      unselectedLeft,
      preselectionLeft,
      unselectedRight,
      preselectionRight,
      selection,
      checkedTuples,
    },
    dispatch,
  ] = useReducer(
    reducer,
    {
      ...defaultState,
      unselectedLeft: allAnswers,
      unselectedRight: allAnswers,
    },
    evolve({ unselectedLeft: safeShuffle, unselectedRight: safeShuffle }),
  );

  const correctness = useMemo(() => selection.map(([l, r]) => pairCorrect(l, r)), [selection]);

  const checkedness = useMemo(
    () => selection.map(s => checkedTuples.includes(s)),
    [selection, checkedTuples],
  );

  const {
    isSolveButtonDisabled,
    isCheckButtonDisabled,
    handleCorrectAnswer,
    handleWrongAnswer,
    handleSolved,
    inquiryState,
    isDisabled,
  } = useInquiryHandling({
    inquiryId,
    achievableScore,
  });
  const { playWrongDefaultAudioFeedBack, playCorrectDefaultAudioFeedBack } = useAudio();

  const onCheckClicked = () => {
    if (correctness.length === allAnswers.length && correctness.every(Boolean)) {
      handleCorrectAnswer();
      playCorrectDefaultAudioFeedBack();
    } else {
      handleWrongAnswer();
      playWrongDefaultAudioFeedBack();
    }
    dispatch({
      type: CHECK_ANSWERS,
    });
  };

  const onSolveClicked = () => {
    handleSolved();
  };

  return (
    <div className={className} data-type="allocationpairs" data-exercise-id={exerciseId}>
      <LessonExerciseQuestionText joinBottom inquiryText={inquiryText} />
      <div className="many-to-many-container">
        <div className="choose-answer-area" data-testid="choose-answer">
          {inquiryState !== INQUIRY_STATE.SOLVED &&
            unselectedLeft.map(answer => (
              <ManyToManyAnswer
                key={answer.id}
                answer={answer}
                mediaType={mediaType}
                isLeftAnswer
                selected={preselectionLeft === answer.id}
                disabled={
                  isDisabled || (preselectionLeft !== null && preselectionLeft !== answer.id)
                }
                onClick={() => dispatch({ type: SELECT_LEFT, id: answer.id })}
              />
            ))}
          {inquiryState !== INQUIRY_STATE.SOLVED &&
            unselectedRight.map(answer => (
              <ManyToManyAnswer
                key={answer.id}
                answer={answer}
                mediaType={mediaType}
                selected={preselectionRight === answer.id}
                disabled={
                  isDisabled || (preselectionRight !== null && preselectionRight !== answer.id)
                }
                onClick={() => dispatch({ type: SELECT_RIGHT, id: answer.id })}
              />
            ))}
        </div>
        <I18nText
          isA={DateTimeInfo}
          as="p"
          className="matched-pairs"
          translation="exercise.matchedPairs"
        />
        <div className="matched-pairs-area" data-testid="matched-pairs">
          {inquiryState === INQUIRY_STATE.SOLVED
            ? subInquiries.map(answer => (
                <ManyToManyAnswer
                  key={answer.id}
                  answer={answer}
                  mediaType={mediaType}
                  isLeftAnswer
                  selected
                  disabled
                />
              ))
            : selection.map(([leftAnswer], index) => (
                <ManyToManyAnswer
                  key={leftAnswer.id}
                  answer={leftAnswer}
                  mediaType={mediaType}
                  isLeftAnswer
                  correct={checkedness[index] && correctness[index]}
                  incorrect={checkedness[index] && !correctness[index]}
                  disabled={checkedness[index] && correctness[index]}
                  onClick={() => dispatch({ type: DESELECT, index })}
                />
              ))}
          {inquiryState === INQUIRY_STATE.SOLVED
            ? subInquiries.map(answer => (
                <ManyToManyAnswer
                  key={answer.id}
                  answer={answer}
                  mediaType={mediaType}
                  selected
                  disabled
                />
              ))
            : selection.map(([, rightAnswer], index) => (
                <ManyToManyAnswer
                  key={rightAnswer.id}
                  answer={rightAnswer}
                  mediaType={mediaType}
                  correct={checkedness[index] && correctness[index]}
                  incorrect={checkedness[index] && !correctness[index]}
                  disabled={checkedness[index] && correctness[index]}
                  onClick={() => dispatch({ type: DESELECT, index })}
                />
              ))}
        </div>
      </div>
      <LessonExerciseHint description={inquiryDescription} />
      <StyledSolveCheckButton
        isSolveButtonDisabled={isSolveButtonDisabled}
        isCheckButtonDisabled={isCheckButtonDisabled}
        handleSolveClicked={onSolveClicked}
        handleCheckClicked={onCheckClicked}
      />
    </div>
  );
};

export const StyledManyToManyExercise = styled(ManyToManyExercise)`
  /* @noflip */
   {
    text-align: left;
    direction: ltr;
  }

  .many-to-many-container {
    position: relative;
    background-color: ${colors.BLUE_GREY_01};
    padding: 1rem;
    margin-bottom: 50px;

    .choose-answer-area,
    .matched-pairs-area {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-flow: dense;
      gap: 1rem;

      div {
        /* @noflip */
        text-align: left;
      }
      div.rightAnswer {
        /* @noflip */
        text-align: right;
      }
    }

    .matched-pairs-area {
      div {
        /* @noflip */
        text-align: right;
      }
      div.rightAnswer {
        /* @noflip */
        text-align: left;
      }
    }
  }

  .matched-pairs {
    text-align: center;
  }
`;
