import { createContext, useContext } from 'react';

export const PageEmbeddingContext = createContext({ isInRichText: false });

export const usePageEmbeddingContext = () => useContext(PageEmbeddingContext);

const richTextTrue = { isInRichText: true };
export const InRichTextProvider = ({ children }) => (
  <PageEmbeddingContext.Provider value={richTextTrue}>{children}</PageEmbeddingContext.Provider>
);
