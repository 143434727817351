import styled from 'styled-components';
import { INQUIRY_MEDIA_TYPE } from '../../../../constants/inquiryMediaTypes';
import { replaceInPattern } from '../../../../utils/i18n';
import { Choicechip } from '../../../Choicechip/Choicechip.jsx';

export const SortingAnswerButton = ({ className, type, answer, onClick, ...restProps }) => {
  const { alternativeText, images } = answer;
  const isInquiryMediaTypeText = type === INQUIRY_MEDIA_TYPE.TEXT.toLowerCase();
  const answerContent = isInquiryMediaTypeText ? (
    alternativeText
  ) : (
    <img src={replaceInPattern(images[0].staticUrl, { formatId: '601' })} alt={alternativeText} />
  );

  return (
    <Choicechip
      onClick={() => onClick(answer)}
      className={className}
      aria-selected={answer.isSelected}
      data-exercise-answer-id={answer.id}
      selected={answer.isSelected}
      {...restProps}
    >
      {answerContent}
    </Choicechip>
  );
};

export const StyledSortingAnswerButton = styled(SortingAnswerButton)`
  :has(> img):disabled {
    opacity: 0.65;
  }

  > img {
    height: 56px;
  }
`;
