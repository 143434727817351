import { Route, useParams, useRouteMatch } from 'react-router-dom';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { LessonMenu } from './LessonMenu/LessonMenu.jsx';
import { LessonInformation } from './LessonInformation/LessonInformation.jsx';
import { pathPartials } from '../../utils/url/pathAnalyser';
import { ExerciseProgressProvider } from './LessonExercise/workflow/ExerciseProgress.jsx';
import { LessonExerciseNavigationPage } from '../../pages/LessonExerciseNavigationPage.jsx';
import { LessonKnowledgeNavigation } from './LessonKnowledgeNavigation/LessonKnowledgeNavigation.jsx';
import {
  LESSON_EXERCISE_RESULT_SUFFIX,
  LESSON_EXTRAS_SUFFIX,
  LESSON_MANUSCRIPT_SUFFIX,
  LESSON_SUMMARY_SUFFIX,
} from '../../utils/url/urlFactory';
import { ManuscriptPage } from '../../pages/ManuscriptPage.jsx';
import { LessonExtrasPage } from '../../pages/LessonExtrasPage.jsx';
import { StyledLessonExerciseResult as LessonExerciseResult } from './LessonExercise/LessonExerciseResult/LessonExerciseResult.jsx';
import { LessonSummaryPage } from '../../pages/LessonSummaryPage.jsx';
import {
  addPhonemicChartOverviewPart,
  filterLessonExerciseOverviewItems,
  filterLessonKnowledgeOverviewItems,
  hasLessonExtras,
  isFinalTestLesson,
} from '../../utils/lessonUtils';
import { hasCorrectImageFormat } from '../ImageOGMetaData/ImageOGMetaData.jsx';
import { gtmDataLayerFragment } from '../GoogleTagManager';
import { StructuralLessonContextProvider } from '../../context/StructuralLessonDataContext.jsx';

const fragmentName = ({ scope, handicap, contextId }) =>
  `${scope}OverviewParts${handicap || ''}For${contextId}`;
export const lessonOverviewPartsFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    params => fragmentName(params),
    ({ scope, handicap, contextId }) => {
      const handicapArg = handicap ? `(handicap: ${handicap})` : '';
      return gql`
        fragment ${fragmentName({ scope, handicap, contextId })} on Lesson {
          dkLearningLevel
          overviewParts${handicapArg} {
            targetId
            lessonPart
            target {
              ... on Exercise {
                name
                namedUrl(contextId: ${contextId})
                ${gtmDataLayerFragment.partial()}
              }
              ... on Knowledge {
                name
                namedUrl(contextId: ${contextId})
              }
            }
          }
        }
      `;
    },
  ),
};

export const Lesson = ({ data }) => {
  const {
    id: courseId,
    name: courseName,
    contentLinks: lessonContentLinksFromCourse,
    mainContentImage: courseMainImage,
    isAlphabeticalCourse,
  } = data.contentsByType[0] || {};

  const { content } = data;
  const {
    name: lessonName,
    language,
    overviewParts,
    learningMetaInfo: { achievableScore },
    manuscript,
    knowledges,
    mainContentImage: lessonMainImage,
    keywordStrings: lessonKeywordStrings,
    navigations,
    namedUrl: lessonUrl,
    dkLearningLevel,
    lastPublicationDate,
    firstPublicationDate,
  } = content;

  // remove all search params from webapi because we use it as base path for other lesson topics
  const lessonPath = lessonUrl.split('?')[0];
  const lessonOverviewParts = isAlphabeticalCourse
    ? addPhonemicChartOverviewPart(lessonName, overviewParts)
    : overviewParts;
  const lessonKnowledgeOverviewItems = filterLessonKnowledgeOverviewItems(lessonOverviewParts);
  const lessonExerciseOverviewItems = filterLessonExerciseOverviewItems(lessonOverviewParts);
  const manuscriptContent = { manuscript, knowledges };
  const { path } = useRouteMatch();
  const { lessonId: lessonIdString } = useParams();

  const lessonId = +lessonIdString;
  const isFinalTest = isFinalTestLesson({
    lessonContentLinksFromCourse,
    currentLessonId: lessonId,
  });

  const structuralData = {
    dkLearningLevel,
    ...(data.contentsByType[0] || navigations[0]),
    lessonId,
    lessonName,
    lessonPath,
    lessonExerciseOverviewItems,
    isFinalTest,
  };

  const ogImage =
    lessonMainImage && hasCorrectImageFormat(lessonMainImage.imageFormats)
      ? lessonMainImage
      : courseMainImage;

  const hasExtras = hasLessonExtras(content);

  return (
    <StructuralLessonContextProvider {...{ structuralData }}>
      <section id="lesson">
        <LessonMenu
          {...{
            lessonId: lessonIdString,
            lessonName,
            lessonOverviewParts,
            hasExtras,
          }}
        />
        <Route
          exact
          path={path}
          render={() => (
            <LessonInformation lessonLang={language} courseName={courseName} ogImage={ogImage} />
          )}
        />
        <Route
          exact
          path={`${path}/e-:exerciseId(${pathPartials.contentIdRegex})`}
          render={() => (
            <ExerciseProgressProvider>
              <LessonExerciseNavigationPage
                lessonLang={language}
                lessonName={lessonName}
                lessonAchievableScore={achievableScore}
                courseId={courseId}
                manuscriptContent={manuscriptContent}
                lessonPath={lessonPath}
              />
            </ExerciseProgressProvider>
          )}
        />
        <Route
          exact
          path={[
            `${path}/:knowledgeType(${pathPartials.knowledgeTypeRegex})`,
            `${path}/:knowledgeType(${pathPartials.knowledgeWithContentIdTypeRegex})-:contentId(${pathPartials.contentIdRegex})`,
          ]}
          render={() => (
            <LessonKnowledgeNavigation
              {...{ lessonName, lessonKeywordStrings, lessonKnowledgeOverviewItems, lessonPath }}
            />
          )}
        />
        <Route
          exact
          path={`${path}/${LESSON_MANUSCRIPT_SUFFIX}`}
          render={() => <ManuscriptPage language={language} contentId={lessonId} />}
        />
        <Route
          exact
          path={`${path}/${LESSON_EXTRAS_SUFFIX}`}
          render={() => <LessonExtrasPage language={language} contentId={lessonId} />}
        />
        <Route
          exact
          path={`${path}/${LESSON_EXERCISE_RESULT_SUFFIX}`}
          render={() => (
            <LessonExerciseResult
              {...{
                lessonOverviewParts,
                lessonId,
                lessonName,
                lessonKeywordStrings,
                data,
                lessonPath,
                lastPublicationDate,
                firstPublicationDate,
              }}
            />
          )}
        />
        <Route
          exact
          path={`${path}/${LESSON_SUMMARY_SUFFIX}`}
          render={() => (
            <LessonSummaryPage
              {...{
                courseId,
                lessonId,
                language,
                lessonName,
                lessonKeywordStrings,
                lastPublicationDate,
                firstPublicationDate,
              }}
            />
          )}
        />
      </section>
    </StructuralLessonContextProvider>
  );
};
