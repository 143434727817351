import styled from 'styled-components';
import { colors, mediaDevice, mediaMin } from '../../utils/css';
import { StyledToggleableArrow } from '../ToggleableArrow/ToggleableArrow.jsx';

// @used in ROAD
export const MoreActionsButton = ({ className, onClick, title, isOpen }) => (
  <button className={className} onClick={onClick} title={title} aria-expanded={isOpen}>
    <StyledToggleableArrow isUp={isOpen} />
  </button>
);

export const StyledMoreActionsButton = styled(MoreActionsButton)`
  padding: 5px;
  cursor: pointer;
  outline: none;
  background: transparent;
  border: none;

  > ${StyledToggleableArrow} {
    position: unset;
    width: unset;
    height: unset;

    @media (prefers-reduced-motion: no-preference) {
      transition: transform 170ms;
    }
  }

  svg {
    padding: 5px;

    ${mediaMin.md`
      padding: 0;
    `}
  }

  ${mediaDevice.mouse`
    &:hover svg, &:focus svg {
      fill: ${colors.LG_BLUE_2};
    }
  `}
`;
