import styled from 'styled-components';
import { useMemo } from 'react';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { wrapTablesWithDiv } from '../../utils/replacers/htmlReplacer';
import { StyledRichTextWithKnowledge } from '../RichText/RichTextWithKnowledge.jsx';
import { columnSize } from '../../utils/css';
import { knowledgesFragment } from '../Lesson/LessonKnowledge/LessonKnowledge.jsx';
import { I18nText } from '../I18n/I18nText.jsx';
import { RichTextHeadline } from '../Headline/Headline.jsx';

const fragmentName = 'GrammarItemFragment';
export const grammarItemFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Knowledge {
        name
        text
        ...${knowledgesFragment.name({ type: 'Knowledge' })}
      }
      ${knowledgesFragment.fragment({ type: 'Knowledge' })}
    `,
  ),
};

export const GrammarItem = ({ content, className }) => {
  const { name, text, knowledges } = content;
  const markup = useMemo(() => wrapTablesWithDiv(text), [text]);

  return (
    <div className={className}>
      <I18nText isA={RichTextHeadline} translation={name} />
      <StyledRichTextWithKnowledge knowledges={knowledges} content={markup} />
    </div>
  );
};

export const StyledGrammarItem = styled(GrammarItem)`
  padding: 1.5rem ${columnSize.c4} 3rem;

  > ${RichTextHeadline} {
    margin: 0 0 2rem;
  }
`;
