/* @used in ROAD */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useFrontendConfig } from '../../context/FrontendConfigContext';
import { useGlobalsContext } from '../../context/GlobalsContext';

let scriptPromise = null;
let loadingForConsentID = null;

const loadCmpScript = async ({ consentManagerId, footer }) => {
  // need to import with inline option because we deal with shadow dom that makes things harder ;)
  // we should not inject css into the page, but only into the correct shadow root
  // eslint-disable-next-line import/no-unresolved
  const { default: customCmpStyles } = await import('./cmp-style-overwrite.css?inline');
  const { initCmpScript } = await import('../../utils/consentScript');
  initCmpScript({ consentManagerId, customCmpStyles, footer });
};

const loadCmpScriptOnlyOnce = async ({ consentManagerId, footer }) => {
  if (!scriptPromise || consentManagerId !== loadingForConsentID) {
    scriptPromise = loadCmpScript({ consentManagerId, footer });
    loadingForConsentID = consentManagerId;
  }
  return loadCmpScript;
};

export const ConsentManagement = ({ data: { footer } }) => {
  const { consentManagerId } = useFrontendConfig();
  const { document } = useGlobalsContext();
  const { pathname } = useLocation();

  useEffect(() => {
    if (consentManagerId) {
      loadCmpScriptOnlyOnce({ consentManagerId, footer });
    }
  }, [consentManagerId, footer]);

  useEffect(
    () => () => {
      [...document.querySelectorAll('script[data-cmp-haspreview]')].forEach(elem => {
        ['data-cmp-preview-connect', 'data-cmp-preview-id', 'data-cmp-haspreview'].forEach(attr =>
          elem.removeAttribute(attr),
        );
      });
    },
    [pathname, document],
  );

  return null;
};
