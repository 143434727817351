import { createContext, useCallback, useContext, useReducer } from 'react';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

export const NavigationContext = createContext({});

const TRADITIONAL_CHIN_LOCAL_STORAGE_KEY = 'isTraditionalChin';

export const NAV_MENU_ACTION_TYPES = Object.freeze({
  TOGGLE_NAV_MENU: 'TOGGLE_NAV_MENU',
  TOGGLE_LANG_MENU: 'TOGGLE_LANG_MENU',
  CLOSE_MENU: 'CLOSE_MENU',
});

const initialState = {
  isNavMenuOpen: false,
  isLanguageSubMenuOpen: false,
};

const navMenuReducer = (state, action) => {
  switch (action.type) {
    case NAV_MENU_ACTION_TYPES.TOGGLE_NAV_MENU:
      return {
        ...state,
        isNavMenuOpen: !state.isNavMenuOpen,
        isLanguageSubMenuOpen: !state.isNavMenuOpen === false ? false : state.isLanguageSubMenuOpen,
      };
    case NAV_MENU_ACTION_TYPES.TOGGLE_LANG_MENU:
      return {
        ...state,
        isLanguageSubMenuOpen: !state.isLanguageSubMenuOpen,
      };
    case NAV_MENU_ACTION_TYPES.CLOSE_MENU:
      return initialState;
    default:
      throw new Error('unexpected action type');
  }
};

export const NavigationContextProvider = ({ children }) => {
  const [isTraditionalChin, setIsTraditionalChin] = useLocalStorage(
    TRADITIONAL_CHIN_LOCAL_STORAGE_KEY,
  );
  const [menuState, dispatch] = useReducer(navMenuReducer, initialState);

  return (
    <NavigationContext.Provider
      value={{
        isTraditionalChin,
        setIsTraditionalChin,
        menuState,
        dispatch,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

// usage hook
export const useNavigationContext = () => {
  const { isTraditionalChin, setIsTraditionalChin, menuState, dispatch } =
    useContext(NavigationContext);

  const toggleNavMenu = useCallback(() => {
    dispatch({ type: NAV_MENU_ACTION_TYPES.TOGGLE_NAV_MENU });
  }, [dispatch]);

  const toggleLanguageSubNavMenu = useCallback(() => {
    dispatch({ type: NAV_MENU_ACTION_TYPES.TOGGLE_LANG_MENU });
  }, [dispatch]);

  const closeMenu = useCallback(() => {
    dispatch({ type: NAV_MENU_ACTION_TYPES.CLOSE_MENU });
  }, [dispatch]);

  return {
    isTraditionalChin,
    setIsTraditionalChin,
    ...menuState,
    toggleNavMenu,
    toggleLanguageSubNavMenu,
    closeMenu,
  };
};
