import { useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import {
  StyledSmileyNegative as SmileyNegative,
  StyledSmileyPositive as SmileyPositive,
} from '../../assets/icons/Smiley';
import { colors } from '../../utils/css';

export const VocabularyTrainerSmileys = ({ handleCorrectAnswer, handleWrongAnswer, className }) => {
  const ANSWER_STATE = Object.freeze({
    INITIAL: 'initial',
    WRONG: 'wrong',
    CORRECT: 'correct',
  });
  const [answerState, setAnswerState] = useState(ANSWER_STATE.INITIAL);

  const onPositiveSmileyClicked = e => {
    e.preventDefault();
    setAnswerState(ANSWER_STATE.CORRECT);
    handleCorrectAnswer();
  };

  const onNegativeSmileyClicked = e => {
    e.preventDefault();
    setAnswerState(ANSWER_STATE.WRONG);
    handleWrongAnswer();
  };

  return (
    <div className={className}>
      <div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={onPositiveSmileyClicked}>
          <SmileyPositive
            className={classnames('smiley', { fine: answerState === ANSWER_STATE.CORRECT })}
          />
        </a>
      </div>
      <div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={onNegativeSmileyClicked}>
          <SmileyNegative
            className={classnames('smiley', { notsogood: answerState === ANSWER_STATE.WRONG })}
          />
        </a>
      </div>
    </div>
  );
};

export const StyledVocabularyTrainerSmileys = styled(VocabularyTrainerSmileys)`
  display: flex;
  justify-content: center;
  /* @noflip */
  direction: ltr;
  margin-top: 8px;
  margin-bottom: 30px;

  div {
    margin: 20px;
  }

  .smiley {
    width: 60px;

    :hover circle.dot {
      fill: ${colors.DW_LIGHT_BLUE_NEW} !important;
    }

    &.fine circle.dot {
      fill: ${colors.LG_GREEN_LIGHT} !important;
    }

    &.notsogood circle.dot {
      fill: ${colors.LG_ORANGE_DARK}!important;
    }
  }
`;
