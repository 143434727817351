import styled from 'styled-components';
import { colors, mediaMax } from '../../utils/css';

export const StyledBlankPlacementTile = styled.div`
  background-color: ${colors.BLUE_GREY_01};
  border-color: ${colors.LG_WHITE};
  border-style: solid;
  border-width: 1px 1px 0 0;

  ${mediaMax.sm`
    display: none;
  `}
`;
