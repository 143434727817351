const TABLE_WITH_ATTRIBUTES = '<table(.*?)>';
const OPEN_TABLE_TAG = '<table>';
const CLOSE_TABLE_TAG = '</table>';
const TABLE_WRAPPER_DIV = '<div class="tab-wrap">';
const DIV_CLOSING = '</div>';

export const wrapTablesWithDiv = htmlString => {
  const textReplacedOpenings = htmlString.replace(
    new RegExp(TABLE_WITH_ATTRIBUTES, 'g'),
    `${TABLE_WRAPPER_DIV}${OPEN_TABLE_TAG}`,
  );
  const textReplacedClosings = textReplacedOpenings.replace(
    new RegExp(CLOSE_TABLE_TAG, 'g'),
    `${CLOSE_TABLE_TAG}${DIV_CLOSING}`,
  );
  return textReplacedClosings;
};
