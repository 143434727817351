import { StyledResponsiveDwPicture } from '../../components/ResponsiveDwPicture/ResponsiveDwPicture.jsx';
import { replaceWithComponent } from './replaceCommons';

const getFormatGroupName = rawFormat => {
  if (rawFormat.includes('LEGACY')) {
    return 'LEGACY';
  }
  if (rawFormat === 'FREE_IMAGE') {
    return 'FREE';
  }
  return '90X';
};

export const mapImgPlaceholderToPicComponent = matchedElem => {
  const aspectRatioFromCss = matchedElem.style['padding-bottom'];
  const { id, format: rawImageFormat, url } = matchedElem.dataset;

  const alt = matchedElem.getAttribute('alt');
  const { className } = matchedElem;

  return (
    <StyledResponsiveDwPicture
      image={{ id: +id, staticUrl: url }}
      alt={alt}
      className={className}
      formatGroupName={getFormatGroupName(rawImageFormat)}
      aspectRatio={+aspectRatioFromCss.slice(0, -1)}
      isA="div"
    />
  );
};

/**
 * temporary solution:
 *   removes copyright and image descriptions if empty, otherwise removes copyright only
 */
export const removeFigCaptionIfEmpty = node => {
  const selector = '.placeholder-image figcaption';
  [...node.querySelectorAll(selector)].forEach(matchedElem => {
    if (matchedElem.childNodes[0].tagName === 'SMALL') {
      matchedElem.remove();
    } else {
      matchedElem.querySelector('small')?.remove();
    }
  });
  return node;
};

export const replacePlaceholderImagesWithResponsivePictures = node => {
  return replaceWithComponent({
    /**
     * assumption:
     *   the placeholder has data-format (mapImgPlacholderToPicComponent needs it),
     *   the injected element doesn't
     */
    selector: '.placeholder-image img[data-format]',
    node: removeFigCaptionIfEmpty(node),
    componentFn: mapImgPlaceholderToPicComponent,
    elementType: 'div',
  }).portals;
};
