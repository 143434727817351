import { memoizeWith } from 'ramda';
import gql from 'graphql-tag';
import { articleFragment } from './ArticleTeaser.jsx';
import { lessonFragment } from './LessonTeaser.jsx';

const fragmentName = 'seriesFragment';
export const seriesFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on UnifiedProgram {
        id
        modelType
        moreContentsFromUnifiedProgram(amount: 6) {
          ...${articleFragment.name}
          ...${lessonFragment.name}
        }
      }
      ${articleFragment.fragment()}
      ${lessonFragment.fragment()}
    `,
  ),
};

export const selectLatestSeriesTeasers = (contents, isAllowedTeaser) => {
  return contents
    .filter(isSeriesTeaser)
    .map(seriesContents => seriesContents.moreContentsFromUnifiedProgram)
    .flat()
    .filter(isAllowedTeaser)
    .sort(byYoungestDate)
    .slice(0, 6);
};

function isSeriesTeaser(teaser) {
  return teaser.modelType === 'SERIES';
}

function byYoungestDate(teaserA, teaserB) {
  const dateA = getDateForComparison(teaserA);
  const dateB = getDateForComparison(teaserB);
  return dateA < dateB ? 1 : -1;
}

const getDateForComparison = teaserContent => {
  const { contentDateMode, lastPublicationDate, freeDate } = teaserContent;
  return contentDateMode === 'FREE_DATE' ? freeDate : lastPublicationDate;
};
