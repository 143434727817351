import { useEffect, useRef } from 'react';
import { TrackingUtils } from '../TrackingUtils';

const eventName = 'onSlideChange';
// @used in ROAD
export const useOnSlideChangeTracking = ({ maxIndex, currentIndex, contentId }) => {
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    TrackingUtils.pushToGoogleTagManager({
      datalayerObj: {
        event: eventName,
        eventData: {
          maxIndex,
          currentIndex,
          content: { contentId },
        },
      },
    });
  }, [contentId, currentIndex, maxIndex]);
};
