import gql from 'graphql-tag';
import { useSelector } from 'react-redux';
import { memoizeWith } from 'ramda';
import { hasLessonExtrasFragment } from '../utils/lessonUtils';
import { PAGE_TYPES } from '../constants/pageTypes';
import { StyledPlacementTestResult as PlacementTestResult } from '../components/TestResult/PlacementTestResult.jsx';
import { PageContainer } from '../components/Page/PageContainer.jsx';
import { WithGraphQLQueryHandling } from '../components/WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { toLang } from '../utils/mappers/langMapper';
import { lessonOverviewPartsFragment } from '../components/Lesson/Lesson.jsx';
import { userDataSelector } from '../state/user/userSelectors';
import { metaDataFragment, PageMetaData } from '../components/Page/PageMetaData.jsx';
import { gtmDataLayerFragment, GtmScriptWithDataLayer } from '../components/GoogleTagManager';
import { createLinkToResultPage } from '../utils/url/urlFactory';
import { useLanguage } from '../context/LanguageContext';

const queryName = ({ handicap, contextId }) =>
  `PlacementResultPage${handicap || ''}For${contextId}`;
export const placementResultPageQuery = {
  name: queryName,
  query: memoizeWith(
    params => queryName(params),
    ({ handicap, contextId }) => gql`
      query ${queryName({
        handicap,
        contextId,
      })}($id: Int!, $lessonIdString: String!, $lang: Language!) {
        lesson: content(id: $id, lang: $lang) {
          ... on Lesson {
            id
            name
            namedUrl
            dkLearningLevel
            dwLinks: contentLinks(targetTypes: [NAVIGATION]) {
              id: targetId
              description
              name
            }
          }
          ...${lessonOverviewPartsFragment.name({ scope: 'Placement', handicap, contextId })}
          ...${metaDataFragment.name({ type: 'Lesson' })}
          ${gtmDataLayerFragment.partial()}
          ...${hasLessonExtrasFragment.name}
        }

        currentCourse: contentsByType(
          lang: $lang
          type: COURSE
          amount: 1
          filters: { field: "contentLinks.targetId", value: [$lessonIdString] }
        ) {
          ... on Course {
            id
            name
            title
            language
            dkLearningLevel
            isPlacementTestCourse
            contentLinks(targetTypes: LESSON) {
              targetId
              additionalInformation
            }
          }
        }
      }
      ${lessonOverviewPartsFragment.fragment({ scope: 'Placement', handicap, contextId })}
      ${metaDataFragment.fragment({ type: 'Lesson' })}
      ${hasLessonExtrasFragment.fragment()}
    `,
  ),
};

export const PlacementTestResultPage = ({ contentId }) => {
  const { langCode } = useLanguage();
  const { handicap } = useSelector(userDataSelector) || {};

  const placementResultPageQueryVariables = {
    id: +contentId,
    lessonIdString: contentId,
    lang: toLang(langCode),
  };

  return (
    <PageContainer>
      <WithGraphQLQueryHandling
        query={placementResultPageQuery.query({ handicap, contextId: +contentId })}
        queryVariables={placementResultPageQueryVariables}
      >
        {({ data: { currentCourse, lesson } }) => {
          const course = currentCourse[0];
          const linkToThisPage = createLinkToResultPage({
            lessonPath: lesson.namedUrl,
            isFinalTest: false,
            isFromPlacementCourse: course.isPlacementTestCourse,
          });

          return (
            <>
              <PageMetaDataComponent course={course} relativeCanonicalUrl={linkToThisPage} />
              <GtmScriptWithDataLayer content={lesson} />
              <PlacementTestResult lesson={lesson} course={course} />
            </>
          );
        }}
      </WithGraphQLQueryHandling>
    </PageContainer>
  );
};

const PageMetaDataComponent = ({ course, relativeCanonicalUrl }) => {
  if (course.isPlacementTestCourse) {
    return (
      <PageMetaData
        pageType={PAGE_TYPES.PLACEMENT_TEST}
        relativeCanonicalUrl={relativeCanonicalUrl}
        noSearchEngine
      />
    );
  }
  return <PageMetaData relativeCanonicalUrl={relativeCanonicalUrl} noSearchEngine />;
};
