import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { useGlobalsContext } from '../../context/GlobalsContext';
import { useTranslation } from '../../hooks/useTranslation';
import { INTERSECTION_OBSERVER_SETTINGS } from '../DwPicture/LazyLoadDwPicture.jsx';

export const WidgetIFrame = ({ widgetId, langCode, className, ...restProps }) => {
  const src = `/webapi/iframes/widget/${langCode}/${widgetId}`;

  const [inViewRef, inView] = useInView(INTERSECTION_OBSERVER_SETTINGS);
  const [height, setHeight] = useState(300);

  const iframeRef = useRef();
  const { window } = useGlobalsContext();

  useEffect(() => {
    const updateHeight = event => {
      if (event.source === iframeRef.current?.contentWindow && event.data.type === 'embed-size') {
        setHeight(event.data.height + 1);
      }
    };

    window.addEventListener('message', updateHeight);
    return () => window.removeEventListener('message', updateHeight);
  }, [window]);

  return (
    <div ref={inViewRef} className={className}>
      <iframe
        ref={iframeRef}
        title={useTranslation('content_block.widget.headline')}
        src={inView ? src : ''}
        style={{
          height,
        }}
        {...restProps}
      ></iframe>
    </div>
  );
};

export const StyledWidgetIFrame = styled(WidgetIFrame)`
  iframe {
    border: none;
    width: 100%;
    min-height: 300px;
  }
`;
