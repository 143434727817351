import { useRef } from 'react';
import styled from 'styled-components';
import { ReactComponent as AudioIcon } from '../../assets/svg/audio-speaker.svg';
import { BubbleButton } from '../BubbleButton/BubbleButton.jsx';

export const VocabularyAnswer = ({
  className,
  isSolved,
  mp3Src,
  name,
  subTitle,
  onSolveClicked,
  onSolved,
}) => {
  const audioRef = useRef();

  const onAnswerClicked = event => {
    event.preventDefault();
    audioRef.current.play();
  };

  const onAudioEnded = () => {
    onSolved();
  };

  const onSolve = event => {
    event.preventDefault();
    onSolveClicked();
    if (!mp3Src) {
      onSolved();
    }
  };

  return (
    <BubbleButton
      className={className}
      aria-label="Audio - "
      aria-selected={isSolved}
      selected={isSolved}
      onClick={isSolved ? onAnswerClicked : onSolve}
    >
      {isSolved ? (
        <div className="answer-content">
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio autoPlay onEnded={onAudioEnded} ref={audioRef} tabIndex="0">
            <source src={mp3Src} type="audio/mpeg" />
          </audio>
          <AudioIcon />
          <span>{name}</span>
          {subTitle && <span className="answer-hint">{subTitle}</span>}
        </div>
      ) : (
        <span>...</span>
      )}
    </BubbleButton>
  );
};

export const StyledVocabularyAnswer = styled(VocabularyAnswer)`
  position: relative;
  width: 100%;

  .answer-content {
    /* @noflip */
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 6fr;
    /* @noflip */
    padding: 10px 0;
    gap: 7px;

    .answer-hint {
      grid-row-start: 2;
      grid-column-start: 2;
    }
  }
`;
