import styled from 'styled-components';
import { colors, columnSize, mediaDevice, mediaMin } from '../../../../utils/css';
import navCrossBlackLg from '../../../../assets/svg/nav-cross-black-lg.svg';
import navCrossLg from '../../../../assets/svg/nav-cross-lg.svg';
import { StyledIconBar as IconBar } from '../../../IconBar/IconBar.jsx';
import { I18nText } from '../../../I18n/I18nText.jsx';

export const LessonBurgerButton = ({ isMenuCollapsed, setIsMenuCollapsed }) => (
  <StyledLessonBurgerButton>
    <button
      onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
      type="button"
      className={isMenuCollapsed ? 'icon-toggle collapsed' : 'icon-toggle collapse'}
      data-toggle={isMenuCollapsed ? 'collapsed' : 'collapse'}
      data-target="#lecture-nav-user-menu"
      aria-expanded={!isMenuCollapsed}
      aria-controls="navbar"
    >
      <I18nText className="title" translation="lesson.menu.inLesson" />
      <div className="burger-menu-toggle-container">
        <I18nText className="sr-only" translation="lesson.menu.toggleNavigation" />
        {!isMenuCollapsed && <span className="close-menu" />}

        {isMenuCollapsed && (
          <>
            <IconBar />
            <IconBar />
          </>
        )}
      </div>
    </button>
  </StyledLessonBurgerButton>
);

const buttonStyle = `
  background-color: transparent;
  background-image: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
`;

const defaultHoverStyles = `
  &:hover ${IconBar} {
    background-color: ${colors.DARK_BLUE_GREY_02};
  }
  &:hover .close-menu {
    background: url(${navCrossBlackLg});
    background-size: cover;
  }  
`;

const buttonPadding = '10px';

export const StyledLessonBurgerButton = styled('div')`
  position: absolute;
  /*! @noflip */
  right: calc(${columnSize.c1} - ${buttonPadding});
  top: calc(50% - 20px);

  ${mediaMin.xl`
    /*! @noflip */
    right: calc(${columnSize.c1} - ${buttonPadding});
  `}

  .title {
    display: none;
  }

  ${IconBar} {
    background-color: ${colors.LG_WHITE};
    padding: 0;
  }

  .close-menu {
    background: url(${navCrossLg});
    background-size: cover;
    display: block;
    height: 15px;
    margin-top: 6px;
    /*! @noflip */
    right: ${columnSize.c1};
    width: 15px;

    ${mediaMin.md`
      height: 21px;
      margin-top: 2px;
      width: 22px;
    `}
  }

  .collapse {
    display: block;
    ${buttonStyle}
  }

  .collapsed {
    ${buttonStyle}
  }

  .burger-menu-toggle-container {
    margin: 0;
    position: relative;
  }

  .icon-toggle {
    padding: 13.5px ${buttonPadding};

    &.collapse {
      padding: 9px ${buttonPadding};
    }

    ${mediaDevice.mouse`
      ${defaultHoverStyles}
    `}
    ${mediaDevice.touch`
      ${defaultHoverStyles}
    `}

    &:active ${IconBar}, 
    &:focus ${IconBar} {
      background-color: ${colors.DARK_BLUE_GREY_02};
    }

    &:focus .close-menu,
    &:active .close-menu {
      background: url(${navCrossBlackLg});
      background-size: cover;
    }
  }
`;
