import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getTranslationCodeForLessonPart } from '../../../../utils/mappers/lessonPartToTranslationCode';
import { LESSON_PARTS } from '../../../../constants/lessonParts';
import { useI18nContext } from '../../../../context/I18nContext.jsx';
import { I18nText } from '../../../I18n/I18nText.jsx';
import { StyledNavigationRightArrow, StyledNavigationLeftArrow } from './NavigationArrow.jsx';
import { colors, mediaMin } from '../../../../utils/css';
import { KickerInfo } from '../../../Info/Info.jsx';

const getArrowAriaLabel = (item, t) => {
  if (item && item.lessonPart === LESSON_PARTS.GRAMMAR) {
    return item.target.name;
  }
  if (item && item.lessonPart === LESSON_PARTS.VOCABULARY_GLOSSARY) {
    return t('lesson.aria.vocabulary');
  }
  return '';
};

export const NavigationRow = ({ className, currentItem, previousItem, nextItem, moveTo }) => {
  const { i18n } = useI18nContext();

  const createLinkComponent = (itemToNavigate, isPrevItem) =>
    itemToNavigate ? (
      <Link
        className="navigation"
        to={moveTo(itemToNavigate)}
        aria-label={getArrowAriaLabel(itemToNavigate, i18n.t)}
      >
        {isPrevItem ? <StyledNavigationLeftArrow /> : <StyledNavigationRightArrow />}
      </Link>
    ) : (
      <div className="navigation" />
    );

  return (
    <div className={className}>
      {createLinkComponent(previousItem, true)}
      <I18nText
        isA={KickerInfo}
        translation={getTranslationCodeForLessonPart(currentItem?.lessonPart)}
      />
      {createLinkComponent(nextItem, false)}
    </div>
  );
};

export const StyledNavigationRow = styled(NavigationRow)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  /* @noflip */
  direction: ltr;

  ${mediaMin.md`
    padding: 27px 0;
  `};

  ${mediaMin.xl`
    padding: 50px 0;
  `};

  > div,
  > a {
    min-width: 30px;
    height: 43px;
  }

  > a:is(:hover, :focus) g {
    fill: ${colors.LG_BLUE_1};
  }
`;
