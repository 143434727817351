import styled from 'styled-components';
import classnames from 'classnames';
import { StyledToggleableArrowIcon as ToggleableArrowIcon } from '../../assets/icons/ToggleableArrowIcon.jsx';

const ToggleableArrow = ({ className, isUp, ...restProps }) => (
  <ToggleableArrowIcon
    className={classnames(className, {
      up: isUp,
    })}
    {...restProps}
  />
);
export const StyledToggleableArrow = styled(ToggleableArrow)`
  position: absolute;
  width: 28px;
  height: 24px;

  &.up {
    transform: rotate(-180deg);
  }
`;
