import { Redirect } from 'react-router';
import { StyledDefault404Page } from '../../pages/Default404Page.jsx';
import { useLandingPage } from '../../context/NavigationRootContext';

export const RedirectToLandingPage = () => {
  const landingPage = useLandingPage();

  if (landingPage?.namedUrl) {
    return <Redirect to={landingPage.namedUrl} />;
  }

  return <StyledDefault404Page />;
};
