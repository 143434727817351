/* eslint-disable jsx-a11y/aria-role */
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { I18nText } from '../I18n/I18nText.jsx';
import { getHelpUrl } from '../../utils/url/urlFactory';
import { StyledStandardButton } from '../StandardButton/StandardButton.jsx';
import { getLastEditedLessonsIds } from '../../services/learnProgressService';
import { mediaMin } from '../../utils/css';
import { StyledSelect as Select } from '../FormElements/Select/StyledSelect.jsx';
import { Link } from '../Link/Link.jsx';
import { useLanguage } from '../../context/LanguageContext';
import { NavigationSectionHeadline } from '../Headline/Headline.jsx';
import { NavigationLinkInteraction } from '../Interaction/Interaction.jsx';

const SCOPE = Object.freeze({
  LAST_LESSON: 1,
  LAST_THREE_LESSONS: 3,
  LAST_TEN_LESSONS: 10,
  ALL_LESSONS: 0,
});

const vocabularyTrainerScopeKey = 'vocabularyTrainer';
const vocabularyTrainerScopeOptions = Object.freeze([
  { value: SCOPE.LAST_LESSON, key: `${vocabularyTrainerScopeKey}.last` },
  { value: SCOPE.LAST_THREE_LESSONS, key: `${vocabularyTrainerScopeKey}.last3` },
  { value: SCOPE.LAST_TEN_LESSONS, key: `${vocabularyTrainerScopeKey}.last10` },
  { value: SCOPE.ALL_LESSONS, key: `${vocabularyTrainerScopeKey}.all` },
]);

const findVocabularyScopeTranslationKey = value =>
  vocabularyTrainerScopeOptions.find(option => option.value === +value);

export const VocabularyTrainerStart = ({
  className,
  setLastLessonsIds,
  setNumberOfVocabularies,
  handleExerciseStart,
}) => {
  const [numberOfLessons, setNumberOfLessons] = useState(SCOPE.LAST_LESSON);
  const { langCode } = useLanguage();
  const formMethods = useForm();

  const handleChangeScope = async value => {
    const lastEditedLessonIds = await getLastEditedLessonsIds(value, langCode);
    setNumberOfLessons(value);
    setLastLessonsIds && setLastLessonsIds(lastEditedLessonIds);
  };

  const onSubmit = () => {
    handleExerciseStart && handleExerciseStart();
  };

  useEffect(() => {
    handleChangeScope(SCOPE.LAST_LESSON);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={className}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} className="vocabulary-form">
          <I18nText
            isA={NavigationSectionHeadline}
            translation={findVocabularyScopeTranslationKey(numberOfLessons)}
          />

          <div>
            <StyledStandardButton
              titleKey="vocabularyTrainer.10words"
              className="vocabulary-amount-button"
              onClick={() => setNumberOfVocabularies(10)}
            />
            <StyledStandardButton
              titleKey="vocabularyTrainer.30words"
              className="vocabulary-amount-button"
              onClick={() => setNumberOfVocabularies(30)}
            />
            <StyledStandardButton
              titleKey="vocabularyTrainer.50words"
              className="vocabulary-amount-button"
              onClick={() => setNumberOfVocabularies(50)}
            />
          </div>

          <div>
            <div className="headline">
              <I18nText isA={NavigationSectionHeadline} translation="vocabularyTrainer.others" />
            </div>

            <div>
              <Select
                name="vocabularyScope"
                options={vocabularyTrainerScopeOptions}
                onChange={e => handleChangeScope(e.target.value)}
              />
            </div>
          </div>

          <div className="need-help">
            <I18nText
              isA={NavigationLinkInteraction}
              as={Link}
              to={getHelpUrl(langCode)}
              translation="vocabularyTrainer.needhelp"
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export const StyledVocabularyTrainerStart = styled(VocabularyTrainerStart)`
  .vocabulary-form {
    display: flex;
    flex-direction: column;
    margin-left: 3%;
    width: 94%;

    ${mediaMin.md`
      margin-left: 17%;
      width: 66%;
    `}

    ${mediaMin.xl`
      margin-left: 25%;
      width: 50%;
    `}
  }

  .headline {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .vocabulary-amount-button {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }

  .need-help {
    margin-top: 30px;
    margin-bottom: 10px;
  }
`;
