import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { I18nText } from '../I18n/I18nText.jsx';
import { BirthDateInput } from '../FormElements/Input/BirthDateInput/BirthDateInput.jsx';
import { StyledInput as Input } from '../FormElements/Input/StyledInput.jsx';
import { StyledSelect as Select } from '../FormElements/Select/StyledSelect.jsx';
import { StyledCheckbox as Checkbox } from '../FormElements/Checkbox/StyledCheckbox.jsx';
import { StyledStandardButton as StandardButton } from '../StandardButton/StandardButton.jsx';
import { getDashboardUrl } from '../../utils/url/urlFactory';
import { registerUser } from '../../services/userService';
import { useI18nContext } from '../../context/I18nContext.jsx';
import { countries } from '../../constants/countries';
import { sexOptions } from '../../constants/userInputOptions';
import { feedbackHelper } from '../../utils/url/feedbackHelpers';
import {
  emailPattern,
  namePattern,
  passwordDiffersFromEmail,
  passwordRetypeValid,
  passwordValid,
} from '../../utils/validation/validators';
import { mediaMin, columnSize } from '../../utils/css';
import { useLanguage } from '../../context/LanguageContext';
import { StyledRadio as Radio } from '../FormElements/Radio/StyledRadio.jsx';
import { StyledReCaptcha as ReCaptcha } from '../ReCaptcha';
import { DetailPageHeadline } from '../Headline/Headline.jsx';
import { DetailIntroText } from '../Text/Text.jsx';

export const Registration = ({ className }) => {
  const { i18n } = useI18nContext();

  const [executeCaptcha, updateExecuteCaptcha] = useState();
  const setExecuteCaptcha = useCallback(
    newExecuteCallback => updateExecuteCaptcha(() => newExecuteCallback),
    [updateExecuteCaptcha],
  );

  const { langCode } = useLanguage();
  const history = useHistory();

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { handleSubmit, getValues } = formMethods;

  const createLink = (href, text) => `<a
      href="${href}"
      target="_blank"
      rel='noopener noreferrer'
    >
      ${text}
    </a>`;

  const fullGdpr = useMemo(() => {
    const dataPrivacyPolicy = createLink(
      'https://p.dw.com/p/1Edck',
      i18n.t('profile.register.privacyPolicy'),
    );
    const conditionsOfParticipation = createLink(
      'https://p.dw.com/p/16hIr',
      i18n.t('profile.register.agb'),
    );

    const label = i18n.t('profile.register.agbAndPrivacyPolicyFormat', {
      dataPrivacyPolicy,
      conditionsOfParticipation,
    });

    const agree = i18n.t('common.gdpr.overlay.agree');
    const dissent = i18n.t('common.gdpr.overlay.dissent');

    return agree.concat(dissent).concat(label);
  }, [i18n]);

  const onSubmit = async userData => {
    try {
      const captchaToken = await executeCaptcha();
      if (captchaToken === null) {
        return;
      }

      const response = await registerUser({
        userData,
        captchaToken,
        langCode,
      });
      if (response.ok) {
        history.push(feedbackHelper.forRegistered(langCode));
      } else {
        const responseJson = await response.json();
        history.push(feedbackHelper.getForErrorCode(responseJson, langCode));
      }
    } catch (err) {
      history.push(feedbackHelper.getForErrorCode(err, langCode));
    }
  };

  const cancelRegistration = e => {
    e.preventDefault();
    history.push(getDashboardUrl(langCode));
  };

  return (
    <section className={className}>
      <I18nText isA={DetailPageHeadline} translation="profile.register.title" />
      <I18nText isA={DetailIntroText} translation="profile.register.subtitle" />
      <FormProvider {...formMethods}>
        <form id="main-form" data-id="register-user" onSubmit={handleSubmit(onSubmit)}>
          <ShortInput
            labelKey="profile.register.firstName"
            name="firstName"
            registerOptions={{
              required: true,
              pattern: namePattern,
            }}
          />
          <ShortInput
            labelKey="profile.register.lastName"
            name="lastName"
            registerOptions={{
              required: true,
              pattern: namePattern,
            }}
          />
          <Radio
            name="sex"
            registerOptions={{ required: true }}
            labelKey="profile.register.sex.title"
            options={sexOptions}
          />
          <Input
            labelKey="profile.register.email"
            name="email"
            registerOptions={{ required: true, pattern: emailPattern }}
          />
          <ShortInput
            labelKey="profile.register.password"
            name="password"
            type="password"
            registerOptions={{
              required: true,
              validate: {
                pattern: v => passwordValid(v, getValues().email),
                differsFromEmail: v => passwordDiffersFromEmail(v, getValues().email),
              },
            }}
          />
          <ShortInput
            labelKey="profile.register.passwordRetype"
            name="passwordRetype"
            type="password"
            registerOptions={{
              required: true,
              validate: {
                passwordRetype: v => passwordRetypeValid(v, getValues().password),
              },
            }}
          />
          <Select
            name="country"
            registerOptions={{ required: true }}
            defaultOptionKey="profile.register.country"
            options={countries}
          />
          <BirthDateInput labelKey="profile.register.dateOfBirth" />
          <Checkbox
            name="gdpr"
            labelText={fullGdpr}
            translationKeyForErrorInsertion="client.acceptTermsConditions"
            registerOptions={{ required: true }}
          />
          <Checkbox name="teacher" labelText="profile.register.teacher" />
          <Checkbox name="newsletter" labelText="profile.register.newsletter" />
          <StandardButton titleKey="profile.register.save" data-testid="registerUserButton" />
          <StandardButton
            titleKey="profile.register.cancel"
            variant="secondary"
            data-testid="cancelRegisterUserButton"
            onClick={e => cancelRegistration(e)}
          />
          <ReCaptcha languageCode={langCode} getExecuteAsync={setExecuteCaptcha} />
        </form>
      </FormProvider>
    </section>
  );
};

const ShortInput = styled(Input)`
  ${mediaMin.sm`
    display: inline-block;
    width: 50%;
    padding-right: 10px;
    vertical-align:top;
  `}
`;

export const StyledRegistration = styled(Registration)`
  width: 100%;
  margin: 0 auto;
  padding-right: ${columnSize.c1};
  padding-left: ${columnSize.c1};

  ${DetailPageHeadline} {
    margin-bottom: 10px;
  }

  form {
    ${mediaMin.sm`
      ${ShortInput} + ${ShortInput} {
        padding-left: ${columnSize.c1};
        padding-right: 0;
      }
    `}
    > ${StandardButton} {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
    }
  }
  ${mediaMin.xl`
    width: ${columnSize.c16};
    padding-left: ${columnSize.c1};
    padding-right: ${columnSize.c1};

    & > ${DetailPageHeadline} {
      margin-top: 40px;
    }
  `}
`;
