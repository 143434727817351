import styled from 'styled-components';
import { I18nText } from '../I18n/I18nText.jsx';
import { colors } from '../../utils/css';
import { LinkButton } from '../LinkButton/LinkButton.jsx';
import { DateTimeInfo } from '../Info/Info.jsx';
import { DetailPageHeadline } from '../Headline/Headline.jsx';

export const NextCourseRecommendation = ({ className, nextLevel, nextCourseUrl }) => (
  <div className={className}>
    <div className="recommendation">
      <I18nText isA={DateTimeInfo} as="p" translation="placementTest.recommendation" />
      <DetailPageHeadline>{nextLevel}</DetailPageHeadline>
    </div>
    <div className="buttonArea">
      <LinkButton to={nextCourseUrl} titleKey="common.next" variant="primary" />
    </div>
  </div>
);

export const StyledNextCourseRecommendation = styled(NextCourseRecommendation)`
  > .recommendation {
    background-color: ${colors.BLUE_GREY_01};
    padding: 60px 30px;
    text-align: center;

    > h1 {
      margin: 0;
    }
  }

  > .buttonArea {
    margin: 50px 20px;
    text-align: center;
  }
`;
