import classnames from 'classnames';
import styled from 'styled-components';
import { useState } from 'react';
import { INQUIRY_STATE } from '../workflow/inquiryState';
import { colors } from '../../../../utils/css';
import { ButtonInteraction } from '../../../Interaction/Interaction.jsx';

export const ClozePulldown = ({
  alternatives,
  selectionRef,
  pulldownId,
  inquiryState,
  onAnswerSelected,
  isDisabled,
  className,
}) => {
  const [, setRerender] = useState(false);

  const selectedAlternative =
    inquiryState === INQUIRY_STATE.SOLVED
      ? alternatives.find(a => a.isCorrect)
      : alternatives.find(a => a.id === selectionRef.current[pulldownId]);

  return (
    <ButtonInteraction
      as="select"
      className={classnames(
        className,
        inquiryState === INQUIRY_STATE.SOLVED && 'solvedAnswer',
        inquiryState !== INQUIRY_STATE.INITIAL &&
          inquiryState !== INQUIRY_STATE.SOLVED &&
          (selectedAlternative?.isCorrect ? 'correctAnswer' : 'wrongAnswer'),
      )}
      onChange={evt => {
        const newId = evt.target.value;
        onAnswerSelected(newId ? parseInt(newId, 10) : undefined);
        setRerender(o => !o);
      }}
      value={selectedAlternative ? selectedAlternative.id : ''}
      disabled={isDisabled}
    >
      <option value="">...</option>
      {alternatives.map((alternative, altIndex) => (
        <option
          key={alternative.id}
          data-answer-select={alternative.id}
          data-exercise-answer-id={alternative.id}
          data-index={altIndex}
          value={alternative.id}
        >
          {alternative.alternativeText}
        </option>
      ))}
    </ButtonInteraction>
  );
};

export const StyledClozePulldown = styled(ClozePulldown)`
  outline: none;
  cursor: pointer;
  height: 2rem;
  border-color: ${colors.BLUE_GREY_04};

  :hover:enabled {
    border-color: ${colors.DW_DARK_BLUE_NEW};
  }

  &.solvedAnswer {
    background-color: ${colors.DW_DARK_BLUE_NEW};
    color: ${colors.LG_WHITE};
  }

  &.wrongAnswer {
    background-color: ${colors.ACCENT_RED};
    color: ${colors.DARK_BLUE_GREY_02};
  }

  &.correctAnswer {
    background-color: ${colors.ACCENT_GREEN};
    color: ${colors.DARK_BLUE_GREY_02};
  }

  :disabled {
    /* Override user agent default */
    opacity: 1;
    cursor: default;
    pointer-events: none;

    &:not(.solvedAnswer, .wrongAnswer, .correctAnswer) {
      background-color: ${colors.BLUE_GREY_02};
      color: ${colors.BLUE_GREY_03};
    }
  }
`;
