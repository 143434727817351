import styled from 'styled-components';
import gql from 'graphql-tag';
import classnames from 'classnames';
import { memoizeWith } from 'ramda';
import { mediaMin } from '../../utils/css';
import {
  socialMediaIconLinkFragment,
  StyledSocialMediaIconLink as SocialMediaIconLink,
} from '../SocialMediaIconLink/SocialMediaIconLink.jsx';
import { useToggle } from '../../hooks/useToggle';
import { useTranslation } from '../../hooks/useTranslation';
import { StyledMoreActionsButton as MoreActionsButton } from './MoreActionsButton.jsx';
import { useGlobalsContext } from '../../context/GlobalsContext';

const defaultFragmenType = 'SocialMediaAspect';
const fragmentName = ({ type = defaultFragmenType } = {}) => `ContentDetailActions${type}`;
export const contentDetailActionsFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    fragmentName,
    ({ type = defaultFragmenType } = {}) => gql`
      fragment ${fragmentName({ type })} on ${type} {
        socialMediaConfig {
          id
          ...${socialMediaIconLinkFragment.name}
        }
      }
      ${socialMediaIconLinkFragment.fragment()}
    `,
  ),
};

// @used in ROAD
export const ContentDetailActions = ({ content = {}, className, innerRef }) => {
  const contentUrl = useGlobalsContext().window.location.href;
  const [isOpen, { toggleOnClick: toggleIsOpen }] = useToggle();

  const buttonTitle = useTranslation(
    `common.socialMedia.sharing.toggle_button.${isOpen ? 'close' : 'open'}`,
  );

  const [firstSM, secondSM, ...moreSocialMedia] = content.socialMediaConfig || [];
  const toggableClassName = isOpen ? 'open' : 'closed';
  const tabIndexForToggableButtons = isOpen ? 0 : -1;

  return (
    <section ref={innerRef} className={classnames(className, toggableClassName)}>
      <div className="always-visible">
        <SocialMediaIconLink socialMediaEntry={firstSM} contentUrl={contentUrl} />
        <SocialMediaIconLink socialMediaEntry={secondSM} contentUrl={contentUrl} />
        <MoreActionsButton
          className="more-icon icon"
          onClick={toggleIsOpen}
          title={buttonTitle}
          isOpen={isOpen}
        />
      </div>
      <div className={classnames('more', toggableClassName)}>
        {isOpen &&
          moreSocialMedia.map(socialMediaEntry => (
            <SocialMediaIconLink
              socialMediaEntry={socialMediaEntry}
              key={socialMediaEntry.id}
              contentUrl={contentUrl}
              tabIndex={tabIndexForToggableButtons}
            />
          ))}
      </div>
    </section>
  );
};

export const StyledContentDetailActions = styled(ContentDetailActions)`
  display: flex;
  flex-direction: column;
  min-height: inherit;
  max-width: 300px;
  margin: 0 auto;

  ${mediaMin.sm`
    max-width: 340px;
  `}

  ${mediaMin.md`
    max-width: 100%;
    align-items: flex-start;
  `}

  .always-visible {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: inherit;
    justify-content: center;
    align-items: center;

    ${mediaMin.md`
      width: 100%;
      justify-content: flex-start;
    `}
  }

  a,
  .icon {
    width: 55px;
    height: 55px;
    margin: 0 5px 0 0;
    flex-shrink: 1;

    ${mediaMin.sm`
      width: 60px;
      height: 60px;
      margin-right: 8px;
    `}

    ${mediaMin.md`
      width: 40px;
      height: 40px;
    `}

    &:focus {
      outline: dotted thin;
      outline: -webkit-focus-ring-color auto 5px;
      outline-offset: -2px;
    }
  }

  .more {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    width: 100%;

    &.open {
      margin-top: 3px;
      max-height: 500px;
      transition: max-height 170ms cubic-bezier(0, 0, 0.09, 1) 33ms,
        opacity 170ms cubic-bezier(0.43, 0, 0.09, 1) 33ms;

      a,
      .icon {
        margin: 3px 5px 2.5px 0;

        ${mediaMin.sm`
          margin: 4px 8px 4px 0;
        `}
      }
    }

    &.closed {
      max-height: 0;
      opacity: 0;
      transition: max-height 150ms cubic-bezier(0, 0, 0.09, 1) 50ms,
        opacity 100ms cubic-bezier(0.43, 0, 0.09, 1) 50ms;
    }
  }
`;
