import styled from 'styled-components';
import { useRef } from 'react';
import { StyledToggleableArrow } from '../ToggleableArrow/ToggleableArrow.jsx';
import { useDetailsAnimation } from './useDetailsAnimation';
import { TeaserHeadline } from '../Headline/Headline.jsx';
import { mediaMin } from '../../utils/css';

export const AccordionContainer = ({
  /* StyledToggleableArrow instances inside will flip on open */
  summaryContent,
  summaryProps,
  summaryTag: Tag = TeaserHeadline,
  children,
  ...restProps
}) => {
  const detailsRef = useRef();
  const summaryRef = useRef();
  const contentRef = useRef();
  useDetailsAnimation({ detailsRef, summaryRef, contentRef });

  return (
    <details ref={detailsRef} {...restProps}>
      <Tag as="summary" {...summaryProps} ref={summaryRef}>
        {summaryContent}
      </Tag>
      <div ref={contentRef}>{children}</div>
    </details>
  );
};

export const StyledAccordionContainer = styled(AccordionContainer)`
  overflow: hidden;

  > summary {
    cursor: pointer;
    display: flex;
  }

  > summary::-webkit-details-marker {
    display: none;
  }

  > summary ${StyledToggleableArrow} {
    position: relative;
    width: 20px;
    height: 20px;
    transform: rotate(0deg);

    @media (prefers-reduced-motion: no-preference) {
      transition: transform 0.7s;
    }

    ${mediaMin.md`
      width: 30px;
      height: 30px;
    `}
  }

  &[open] > summary ${StyledToggleableArrow} {
    transform: rotate(-180deg);
  }

  &[open][data-isclosing='true'] > summary ${StyledToggleableArrow} {
    /** Without this, the animation won't start until closing is finished */
    transform: rotate(0deg);
  }
`;
