import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { memoizeWith } from 'ramda';
import gql from 'graphql-tag';
import { useHistory, useLocation } from 'react-router';
import { colors, columnSize } from '../../utils/css';
import { StyledToggleableArrow } from '../ToggleableArrow/ToggleableArrow.jsx';
import { StyledGrammarItem, grammarItemFragment } from '../GrammarItem/GrammarItem.jsx';
import { StyledAccordionContainer } from '../ContentContainer/AccordionContainer.jsx';
import { StyledSlidingQueryHandling } from '../WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { toLang } from '../../utils/mappers/langMapper';
import { useLanguage } from '../../context/LanguageContext';
import { useGlobalsContext } from '../../context/GlobalsContext';
import { NavigationLinkInteraction } from '../Interaction/Interaction.jsx';
import { StyledLoadingMessage } from '../commons/LoadingMessage.jsx';

const queryName = 'GrammarOverviewItem';
export const grammarOverviewItemQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}($id: Int!, $lang: Language!) {
        content(id: $id, lang: $lang) {
          ...${grammarItemFragment.name}
        }
      }
      ${grammarItemFragment.fragment()}
    `,
  ),
};

const closeOpenSiblings = evt => {
  if (!evt.target.open) {
    return;
  }

  const openSummaries = evt.target.parentNode.querySelectorAll(
    ':scope > details[open]:not([data-isclosing="true"]) > summary',
  );

  openSummaries.forEach(s => {
    if (s.parentNode !== evt.target) {
      s.click();
    }
  });
};

export const GrammarOverviewItem = ({ title, contents, className }) => {
  const { document } = useGlobalsContext();
  const { hash } = useLocation();

  useEffect(() => {
    const givenId = hash.substring(1);
    if (!givenId) {
      return;
    }

    const detailsEl = document.getElementById(givenId);
    if (!detailsEl) {
      return;
    }

    let detailsAncestor = detailsEl.parentElement;
    while (detailsAncestor && detailsAncestor.tagName !== 'DETAILS') {
      detailsAncestor = detailsAncestor.parentElement;
    }

    if (detailsAncestor?.tagName === 'DETAILS') {
      detailsEl.open = true;
      detailsAncestor.open = true;
    }
    // only open associated details on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledAccordionContainer
      className={className}
      onToggle={closeOpenSiblings}
      summaryContent={
        <>
          {title}
          <StyledToggleableArrow aria-hidden />
        </>
      }
    >
      {contents.map(({ id, shortTitle }) => (
        <Content key={id} id={id} shortTitle={shortTitle} />
      ))}
    </StyledAccordionContainer>
  );
};

/**
 * @param {HTMLElement} elem
 * @returns {Promise<void>}
 */
const allAnimationsDone = async elem => {
  const animations = elem.getAnimations();
  if (animations.length === 0) {
    return Promise.resolve();
  }

  try {
    await Promise.all(animations.map(a => a.finished));
  } catch {
    // don't care about cancels
  }

  return allAnimationsDone(elem);
};

const Content = ({ id, shortTitle }) => {
  const { langCode } = useLanguage();
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [isOpening, setIsOpening] = useState(false);
  const { replace } = useHistory();

  return (
    <StyledAccordionContainer
      id={id}
      onToggle={evt => {
        if (evt.target.open) {
          closeOpenSiblings(evt);
          setReadyToLoad(true);
          replace({ hash: String(id) });

          // ?. so we don't crash on older browsers
          if (evt.target.getAnimations?.().length > 0) {
            setIsOpening(true);
            // rapid clicks create consecutive animations but not call onToggle multiple times
            allAnimationsDone(evt.target).then(() => setIsOpening(false));
          }
        }
      }}
      summaryTag={NavigationLinkInteraction}
      summaryContent={
        <>
          {shortTitle}
          <StyledToggleableArrow aria-hidden />
        </>
      }
    >
      <StyledSlidingQueryHandling
        query={grammarOverviewItemQuery.query()}
        variables={{ id: +id, lang: toLang(langCode) }}
        skip={!readyToLoad}
        delayTransition={isOpening}
      >
        {({ data: { content } }) => <StyledGrammarItem content={content} />}
      </StyledSlidingQueryHandling>
    </StyledAccordionContainer>
  );
};

export const StyledGrammarOverviewItem = styled(GrammarOverviewItem)`
  :nth-child(odd),
  :nth-child(odd) ${StyledSlidingQueryHandling} > ${StyledLoadingMessage} {
    background-color: ${colors.BLUE_GREY_01};
  }

  :first-of-type {
    border-top: 1px solid ${colors.BLUE_GREY_03};
  }

  > summary,
  ${StyledAccordionContainer} > summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem ${columnSize.c1};
    cursor: pointer;
    border-bottom: 1px solid ${colors.BLUE_GREY_03};
  }

  ${StyledGrammarItem}, ${StyledLoadingMessage} {
    border-bottom: 1px solid ${colors.BLUE_GREY_03};
  }

  ${StyledAccordionContainer} > summary {
    padding-inline-start: ${columnSize.c2};
  }

  ${StyledAccordionContainer} > ${NavigationLinkInteraction}:hover {
    text-decoration: none;
  }
`;
