import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useAudio } from '../../../../hooks/useAudio';
import audioIcon from '../../../../assets/svg/audio-speaker.svg';
import { ButtonInteraction } from '../../../Interaction/Interaction.jsx';

export const VocabularyAudio = ({ mp3Src, children, className }) => {
  const audioRef = useRef();
  const { addRef, playAudio } = useAudio();

  useEffect(() => addRef(audioRef), [addRef]);

  return (
    <ButtonInteraction
      as="a"
      className={className}
      href="/#"
      onClick={e => playAudio(e, audioRef)}
      role="button"
    >
      {children}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio tabIndex="0" width="100%" ref={audioRef}>
        <source src={mp3Src} type="audio/MP3" />
      </audio>
    </ButtonInteraction>
  );
};

export const StyledVocabularyAudio = styled(VocabularyAudio)`
  &:before {
    content: ' ';
    display: inline-block;
    /* Using mask allows us to change icon color on hover */
    mask: url(${audioIcon});
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: currentcolor;
    width: 16px;
    height: 15px;
    /* @noflip */
    margin-right: 8px;
    margin-bottom: -2px;
  }

  text-decoration: none;
  &:focus {
    text-decoration: none;
  }
`;
