import { USER } from '../constants/userUrl';
import { BASIC_JSON_CONTENT_TYPE_HEADER } from '../constants/services';
import { formatPasswordResetUserData } from '../utils/mappers/passwordReset';

const PS_PREFIX = USER.PS_URL_WITH_DOMAIN;
const PASSWORD_RESOURCE_PUBLIC_RESET = `${PS_PREFIX}public/user/password/reset`;
export const PASSWORD_RESOURCE_PUBLIC_RESET_REQUEST = `${PS_PREFIX}public/user/password/reset-request`;
const PASSWORD_RESOURCE_PROTECTED = `${PS_PREFIX}protected/user/password`;

export const changePassword = (oldUserPassword, userPassword) =>
  fetch(`${PASSWORD_RESOURCE_PROTECTED}/change`, {
    method: 'POST',
    body: JSON.stringify({
      oldPassword: oldUserPassword,
      newPassword: userPassword,
    }),
    ...BASIC_JSON_CONTENT_TYPE_HEADER,
  });

export const requestResetPassword = ({ userData, captchaToken }) => {
  const formattedData = formatPasswordResetUserData({ userData, captchaToken });
  return fetch(PASSWORD_RESOURCE_PUBLIC_RESET_REQUEST, {
    method: 'POST',
    body: JSON.stringify(formattedData),
    ...BASIC_JSON_CONTENT_TYPE_HEADER,
  });
};

export const setPasswordByToken = (newPassword, token) =>
  fetch(PASSWORD_RESOURCE_PUBLIC_RESET, {
    method: 'POST',
    body: JSON.stringify({
      newPassword,
      passwordResetToken: token,
    }),
    ...BASIC_JSON_CONTENT_TYPE_HEADER,
  }).then(response => (response.ok ? Promise.resolve(response) : Promise.reject(response)));

export const getUserByConfirmationId = confirmationId =>
  fetch(`${PS_PREFIX}public/user/mail?token=${confirmationId}`)
    .then(res => res.text())
    .catch(e => console.log(e));
