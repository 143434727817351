import { useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { getFormatIdForWidth, replaceFormatInUrl } from '../../../utils/imgUtils';
import { INTERSECTION_OBSERVER_SETTINGS } from '../../DwPicture/LazyLoadDwPicture.jsx';
import { useFrontendConfig } from '../../../context/FrontendConfigContext';
import { landscapeFormatGroupName } from '../../ResponsiveDwPicture/pictureConfigs';

const DW_POSTER_IMAGE_SUFFIX_REGEX = /_605.(jpg|png)$/;

const getPosterImageUrl = ({
  posterImageUrl,
  fallbackImageStaticUrl,
  inView,
  playerWidth,
  staticBaseHost,
}) => {
  const isPosterImageAvailable = !!(posterImageUrl && posterImageUrl !== '');
  const isNoImageAvailable = !!(!isPosterImageAvailable && !fallbackImageStaticUrl);

  if (!inView || isNoImageAvailable) {
    return '';
  }

  // host checked via startsWith as we do not want to compile the regex for each case
  if (isPosterImageAvailable && !posterImageUrl.startsWith(staticBaseHost)) {
    return posterImageUrl;
  }

  const imgFormatId = getFormatIdForWidth({
    imgWidth: playerWidth,
    formatGroupName: landscapeFormatGroupName,
  });

  if (isPosterImageAvailable) {
    return posterImageUrl.replace(DW_POSTER_IMAGE_SUFFIX_REGEX, `_${imgFormatId}.$1`);
  }
  return replaceFormatInUrl(fallbackImageStaticUrl, imgFormatId);
};

export const useLazyPosterImage = ({ posterImageUrl, fallbackImageStaticUrl }) => {
  const sizeRef = useRef();
  const playerWidth = sizeRef?.current?.clientWidth;
  const [inViewRef, inView] = useInView(INTERSECTION_OBSERVER_SETTINGS);
  const { staticBaseHost } = useFrontendConfig();

  return {
    sizeRef,
    inViewRef,
    backgroundImageUrl: getPosterImageUrl({
      posterImageUrl,
      fallbackImageStaticUrl,
      inView,
      playerWidth,
      staticBaseHost,
    }),
  };
};
