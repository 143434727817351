import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { StyledFormatBlock as FormatBlock } from './ContentBlocks/FormatBlock.jsx';
import { StyledInfoBlock as InfoBlock } from './ContentBlocks/InfoBlock.jsx';
import { StyledLinkListBlock as LinkListBlock } from './ContentBlocks/LinkListBlock.jsx';
import { articleFragment } from './Teaser/ArticleTeaser.jsx';
import { lessonFragment } from './Teaser/LessonTeaser.jsx';
import { referenceFragment } from './Teaser/ReferenceTeaser.jsx';
import { courseFragment } from './Teaser/CourseTeaser.jsx';
import { StyledCarouselBlock as CarouselBlock } from './ContentBlocks/CarouselBlock.jsx';
import { seriesFragment } from './Teaser/seriesTeaserUtils';
import {
  StyledSubHeaderBlock as SubHeaderBlock,
  subHeaderFragment,
} from './ContentBlocks/SubHeaderBlock.jsx';
import { StyledHeroImage as HeroImage } from '../HeroImage/HeroImage.jsx';

const fragmentName = 'compositionComponent';
export const compositionComponentFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on CompositionComponent {
        id
        type
        modelType
        contents {
          ...${articleFragment.name}
          ...${lessonFragment.name}
          ...${referenceFragment.name}
          ...${courseFragment.name}
          ...${seriesFragment.name}
        }
        configuration {
          ...${subHeaderFragment.name}
        }
      }
      ${articleFragment.fragment()}
      ${lessonFragment.fragment()}
      ${referenceFragment.fragment()}
      ${courseFragment.fragment()}
      ${seriesFragment.fragment()}
      ${subHeaderFragment.fragment()}
    `,
  ),
};

export const NavigationContentComposition = ({
  className,
  mainContentImageLink,
  compositionComponents,
}) => {
  const getBlockTagByType = type => {
    switch (type) {
      case 'LG_FORMAT':
        return FormatBlock;
      case 'LG_CAROUSEL':
        return CarouselBlock;
      case 'LG_LINK_LIST':
        return LinkListBlock;
      case 'LG_SUB_HEADER':
        return SubHeaderBlock;
      default:
        return InfoBlock;
    }
  };
  const getContentBlockByType = component => {
    if (!component) {
      return null;
    }
    const ComponentTag = getBlockTagByType(component.type);
    return (
      ComponentTag && (
        <ComponentTag
          key={component.id}
          contents={component.contents}
          configuration={component.configuration}
        />
      )
    );
  };

  return (
    <div className={className}>
      <HeroImage mainContentImageLink={mainContentImageLink} />
      {compositionComponents.map(component => getContentBlockByType(component))}
    </div>
  );
};
