import styled from 'styled-components';

import { socialMediaToIcon } from '../../utils/socialBookmarks';
import { mediaMax, columnSize, mediaDevice } from '../../utils/css';
import { socialMediaHoverColors } from '../SocialMediaIconLink/SocialMediaIconLink.jsx';
import { ExternalLink } from './FooterLink.jsx';
import { useTranslation } from '../../hooks/useTranslation';
import { NavigationLinkInteraction } from '../Interaction/Interaction.jsx';

// @used in ROAD
export const SocialMediaAccountLink = ({ socialMediaAccount, className, ...restProps }) => {
  const { service, url } = socialMediaAccount;
  const externalLinkPrefix = useTranslation('common.link.external_prefix');
  const socialMediaIconDef = socialMediaToIcon[service];
  if (!socialMediaIconDef) {
    return null;
  }
  const SocialMediaIconTag = socialMediaIconDef.Tag;
  return (
    <NavigationLinkInteraction
      as={ExternalLink}
      className={`${className} ${service}`}
      to={url}
      title={`${externalLinkPrefix} — ${service}`}
      {...restProps}
    >
      <SocialMediaIconTag />
    </NavigationLinkInteraction>
  );
};

export const StyledSocialMediaAccountLink = styled(SocialMediaAccountLink)`
  width: ${columnSize.c1};
  ${mediaMax.md`
    width: 40px;
  `}
  > svg {
    fill: currentcolor;
  }

  ${mediaDevice.mouse`
    ${socialMediaHoverColors}
  `}
`;
