import styled from 'styled-components';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { StyledRichText } from '../RichText/RichText.jsx';
import { colors } from '../../utils/css';
import { DetailPageHeadline } from '../Headline/Headline.jsx';
import { DarkThemeProvider } from '../../context/ThemeContext.jsx';

const fragmentName = 'Help';
export const helpFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Article {
        id
        shortTitle
        text
      }
    `,
  ),
};

export const Help = ({ className, help }) => {
  const { shortTitle, text } = help;

  return (
    <div className={className}>
      <DarkThemeProvider>
        <DetailPageHeadline>{shortTitle}</DetailPageHeadline>
      </DarkThemeProvider>
      <StyledRichText content={text} />
    </div>
  );
};

export const StyledHelp = styled(Help)`
  vertical-align: middle;

  > ${DetailPageHeadline} {
    background-color: ${colors.LG_ORANGE};
    text-align: center;
    padding: 0.5em 0;
  }

  ${StyledRichText} {
    margin: 10px;
  }
`;
