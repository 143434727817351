import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import classnames from 'classnames';
import { memoizeWith } from 'ramda';
import { Partials } from './partials';
import { slideResponsiveImageFragment } from './partials/SlideResponsiveImage.jsx';
import { slideTimeOutAutoPlay, useSliderLogic } from '../../hooks/useSliderLogic';
import { colors, aspectRatioAware } from '../../utils/css';
import { keyCodes } from '../../constants/keyCodes';
import { usePrev } from '../../hooks/usePrev';
import { StyledWithDraggable as WithDraggable } from '../commons/WithDraggable.jsx';
import { getSliderPositionStyles, transition } from '../../utils/draggableUtils';
import { useToggle } from '../../hooks/useToggle';
import globals from '../../utils/globals';
import { STATUS } from './partials/ControlBar.jsx';
import { usePageEmbeddingContext } from '../../context/PageEmbeddingContext.jsx';
import { useOnSlideChangeTracking } from '../GoogleTagManager/hooks/useOnSlideChangeTracking';
import { useMediaTracking } from '../GoogleTagManager/hooks/useMediaTracking';
import { useLanguage } from '../../context/LanguageContext';
import { darkButtonStyles } from '../StandardButton/IconButton.jsx';
import { DarkThemeProvider } from '../../context/ThemeContext.jsx';

const fragmentName = 'Slideshow';
export const slideshowFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on ImageGallery {
        id
        name
        namedUrl
        teaser
        extendedGalleryImages {
          ...${slideResponsiveImageFragment.name}
        }
      }
      ${slideResponsiveImageFragment.fragment()}
    `,
  ),
};

// @used in ROAD
export const Slideshow = ({ imageGallery = {}, className }) => {
  const {
    extendedGalleryImages: galleryImages,
    teaser,
    name: galleryName,
    id: galleryId,
  } = imageGallery;
  const { isInRichText } = usePageEmbeddingContext();
  const { isRtl } = useLanguage();

  const minIndex = 0;
  const maxIndex = galleryImages.length - 1;
  const [sliderState, { prev, next, goTo, play, pause, progress, isFirstSlide, isLastSlide }] =
    useSliderLogic(minIndex, maxIndex);

  const sliderTrackStyles = getSliderPositionStyles({
    childrenLength: galleryImages.length,
    currentIndex: sliderState.currentIndex,
    isRtl,
  });
  const [isTextOverlayVisible, { toggleOnClick: toggleTextVisibility }] = useToggle({
    initialState: true,
  });
  const wasInPlayingState = usePrev(sliderState.isPlaying);
  const [galleryHasFocus, setGalleryHasFocus] = useState(false);
  const galleryEl = useRef(null);

  useMediaTracking(imageGallery);

  useOnSlideChangeTracking({
    maxIndex,
    currentIndex: sliderState.currentIndex,
    contentId: galleryId,
  });

  useEffect(() => {
    globals.window.addEventListener('blur', pause, false);
    return () => globals.window.removeEventListener('blur', pause, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (sliderState.isPlaying) {
        if (wasInPlayingState !== sliderState.isPlaying) {
          progress();
          return undefined;
        }
        const timeout = globals.window.setTimeout(() => {
          progress();
          isLastSlide() && pause();
        }, slideTimeOutAutoPlay);
        return () => globals.window.clearTimeout(timeout);
      }
      return undefined;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sliderState.isPlaying, sliderState.currentIndex],
  );

  useEffect(
    () => {
      if (sliderState.isPlaying && isLastSlide()) {
        goTo(1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sliderState.isPlaying],
  );

  const handleKeyDown = e => {
    switch (e.key) {
      case keyCodes.SPACEBAR:
        e.stopPropagation();
        e.preventDefault();
        sliderState.isPlaying ? pause() : play();
        break;
      case keyCodes.ARROW_LEFT:
        e.stopPropagation();
        sliderState.isPlaying && pause();
        isRtl ? next() : prev();
        break;
      case keyCodes.ARROW_RIGHT:
        e.stopPropagation();
        sliderState.isPlaying && pause();
        isRtl ? prev() : next();
        break;
      default:
    }
  };

  useEffect(() => {
    galleryHasFocus && globals.window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      globals.window.removeEventListener('keydown', handleKeyDown, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderState.isPlaying, galleryHasFocus]);

  useEffect(() => {
    const handleFocusChange = e => {
      const isGalleryClicked = e.composedPath().includes(galleryEl.current);
      setGalleryHasFocus(isGalleryClicked);
    };

    globals.window.addEventListener('click', handleFocusChange);

    return () => {
      globals.window.removeEventListener('click', handleFocusChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFocus = () => {
    setGalleryHasFocus(true);
  };

  const handleBlur = () => {
    setGalleryHasFocus(false);
  };

  return (
    galleryImages.length > 0 && (
      <DarkThemeProvider>
        <div
          ref={galleryEl}
          onFocusCapture={handleFocus}
          onBlurCapture={handleBlur}
          className={classnames(className, 'big')}
          data-tracking-name="slide-show"
        >
          <WithDraggable className="slider-container" next={next} prev={prev}>
            {draggableRef => (
              <section
                ref={draggableRef}
                className={classnames('slider', { 'is-hero': !isInRichText })}
                style={sliderTrackStyles}
              >
                {galleryImages.map((galleryImage, index) => {
                  const imagesClassName = 'slide-img';
                  const isCurrentIndex = index === sliderState.currentIndex;
                  const styleForFixedWidth = {
                    width: `${100 / galleryImages.length}%`,
                  };
                  return index === 0 ? (
                    <Partials.CoverImage
                      galleryImage={galleryImage}
                      title={galleryName}
                      teaser={teaser}
                      className={imagesClassName}
                      key={index}
                      style={styleForFixedWidth}
                      aria-hidden={!isCurrentIndex}
                    />
                  ) : (
                    <Partials.SlideImage
                      galleryImage={galleryImage}
                      onTextOverlayToggle={() => {
                        toggleTextVisibility();
                        pause();
                      }}
                      isTextOverlayOpened={isTextOverlayVisible}
                      className={imagesClassName}
                      key={index}
                      isCurrentIndex={isCurrentIndex}
                      style={styleForFixedWidth}
                      aria-hidden={!isCurrentIndex}
                    />
                  );
                })}
              </section>
            )}
          </WithDraggable>
          <Partials.Indicator
            slideNumber={sliderState.currentIndex}
            slidesCount={maxIndex}
            className={classnames('indicator', { onFirstSlide: !isFirstSlide() })}
            isAutoplay={sliderState.isPlaying}
          />
          <Partials.SlideArrows
            disablePrevArrow={isFirstSlide()}
            disableNextArrow={isLastSlide()}
            onPrev={() => {
              sliderState.isPlaying && pause();
              prev();
            }}
            onNext={() => {
              sliderState.isPlaying && pause();
              next();
            }}
          />
          <Partials.ControlBar
            slideNumber={sliderState.currentIndex}
            slidesCount={maxIndex}
            playingState={
              sliderState.isPlaying ? STATUS.play : `${isFirstSlide() ? STATUS.init : STATUS.pause}`
            }
            onPlayPause={sliderState.isPlaying ? pause : play}
          />
        </div>
      </DarkThemeProvider>
    )
  );
};

export const StyledSlideshow = styled(Slideshow)`
  ${darkButtonStyles};
  position: relative;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  .slider-container {
    margin: 0 auto;
    overflow: hidden;
    background-color: ${colors.LG_BLACK};
  }

  .slider {
    transition: ${transition};
    display: flex;
  }

  .slide-img {
    position: relative;
    overflow: hidden;
  }

  .text-overlay {
    & * {
      color: ${colors.LG_WHITE};
      overflow-wrap: break-word;
    }

    width: 100%;
    position: absolute;
    bottom: -1px;
    z-index: 1;
    padding: 0 0 10px;
    display: grid;
    max-height: 80%;
    overflow: hidden;

    :nth-last-child(3) {
      padding: 0;
      padding-bottom: 15px;
    }
  }

  .indicator {
    opacity: 0;
    margin-top: -40px;
    transition: all 400ms cubic-bezier(0.91, 0, 1, 1);

    &.onFirstSlide {
      margin-top: 0;
      opacity: 1;
      transition: all 400ms cubic-bezier(0, 0, 0.09, 1) 400ms;
    }
  }

  ${aspectRatioAware.xl.ultraWide`
    .is-hero .slide-img {
      height: calc(100vh - 130px);
      overflow: hidden;
      max-height: 700px;
      
      figure {
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        padding-bottom: 50%;
    
        img {
          width: auto;
          height: 100%;
        }
      }
    }
  `}
`;
