import styled from 'styled-components';
import classnames from 'classnames';
import { colors, mediaMax } from '../../../../utils/css';
import { StyledLessonItem as LessonItem } from '../LessonItem/LessonItem.jsx';

export const LessonList = ({ className, groupedLessons, onClickReset, isOpen = true }) => (
  // eslint-disable-next-line jsx-a11y/role-supports-aria-props
  <ul className={classnames(className, { collapsed: !isOpen })} aria-expanded={isOpen}>
    {groupedLessons.map(lesson => (
      <li key={lesson.id}>
        <LessonItem lesson={lesson.target} onClickReset={onClickReset} />
      </li>
    ))}
  </ul>
);

export const StyledLessonList = styled(LessonList)`
  transition: max-height 0.3s linear;
  overflow-y: hidden;
  visibility: visible;
  max-height: ${props => props.groupedLessons.length * 127}px;
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    margin: 0;
  }

  ${mediaMax.md`
    background-color: ${colors.BLUE_GREY_01};

    &:last-child {
      border-bottom: 0;
    }

    &.collapsed {
      visibility: hidden;
      max-height: 0;
    }
  `}
`;
