export const emailPattern = {
  value:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  message: 'client.validator.email',
};
export const transcriptionPattern = {
  value: /^[\sa-zA-ZäöüÄÖÜß-]*$/,
  message: 'client.validator.transcription',
};
export const namePattern = {
  value: /^[^\u0027\u0022;{}<>&:\\]+$/,
  message: 'client.validator.safeMongoCharactersOnly',
};

const passwordInvalidMessage = 'client.validator.password';
export const passwordValid = password => {
  if (!password) {
    return passwordInvalidMessage;
  }

  const minLength = 8;
  // eslint-disable-next-line
  let numberOfFulfilledConditions = 0;
  const minNumberOfFulfilledConditions = 3;
  const regexPatterns = [/[0-9]/, /[^A-Za-z_0-9]/, /[A-Z]/, /[a-z]/];

  regexPatterns.forEach(pattern => {
    if (password.match(pattern) !== null) {
      // eslint-disable-next-line
      numberOfFulfilledConditions += 1;
    }
  });

  return (
    (password.length >= minLength &&
      numberOfFulfilledConditions >= minNumberOfFulfilledConditions) ||
    passwordInvalidMessage
  );
};

const unequalMessage = 'client.validator.equalTo';
export const passwordRetypeValid = (passwordRetype, password) =>
  !!(password && password === passwordRetype) || unequalMessage;

const equalToEmailMessage = 'client.validator.differFromEmail';
export const passwordDiffersFromEmail = (password, email) => {
  const repeatedCharsLimit = 4;
  const template = email;
  const { length } = template;
  // eslint-disable-next-line
  let subString;

  // eslint-disable-next-line
  for (let i = 0; i < length - repeatedCharsLimit; i++) {
    // eslint-disable-next-line
    subString = template.substring(i, i + repeatedCharsLimit);
    if (password.indexOf(subString) !== -1) {
      return equalToEmailMessage;
    }
  }
  return true;
};
