import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MARKS } from '../../../../constants/marks';
import { I18nText } from '../../../I18n/I18nText.jsx';
import { calculatePercentage, generateRandomNumber } from '../../../../utils/commons';
import { findLessonLearnProgressSelector } from '../../../../state/progress/learnProgressSelectors';
import { colors, columnSize } from '../../../../utils/css';
import { useTranslation } from '../../../../hooks/useTranslation';
import { DetailIntroText } from '../../../Text/Text.jsx';
import { DetailPageHeadline } from '../../../Headline/Headline.jsx';
import { DarkThemeProvider } from '../../../../context/ThemeContext.jsx';

export const Summary = ({ className, lessonId, showFeedback = false, showTitle = true }) => {
  const lesson = useSelector(findLessonLearnProgressSelector(lessonId));

  const {
    allExerciseCount = 0,
    doneExerciseCount = 0,
    maxPointsForAllExercises = 0,
    resultPoints = 0,
  } = lesson || {};

  const percentile = calculatePercentage(resultPoints, maxPointsForAllExercises);

  const feedbackText = useMemo(() => {
    const randomNumber = generateRandomNumber(1, 3);

    if (percentile >= MARKS.GOOD) return `good${randomNumber}`;
    if (percentile >= MARKS.MEDIUM) return `mid${randomNumber}`;

    return `bad${randomNumber}`;
  }, [percentile]);

  const resultsTranslation = useTranslation('lesson.exercises.result.title');

  return (
    <div className={className}>
      {showTitle && (
        <I18nText isA={DetailPageHeadline} as="h2" translation="lesson.exercises.result.title" />
      )}
      {showFeedback && (
        <I18nText isA={DetailIntroText} translation={`course.result.${feedbackText}`} />
      )}
      <DarkThemeProvider>
        <div>
          <DetailPageHeadline dir="ltr">{percentile}%</DetailPageHeadline>
          <DetailIntroText>{`${resultsTranslation}: ${doneExerciseCount}/${allExerciseCount}`}</DetailIntroText>
        </div>
      </DarkThemeProvider>
    </div>
  );
};

export const StyledSummary = styled(Summary)`
  > ${DetailIntroText} {
    margin-bottom: 50px;
  }

  > div {
    margin: 0 calc(-1 * ${columnSize.c1});
    background-color: ${colors.DW_GREY_11};
    padding: ${props => (props.big ? `120px ${columnSize.c2}` : `60px ${columnSize.c1}`)};
    text-align: center;

    > * {
      margin: 0;
    }
  }
`;
