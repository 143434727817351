import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { memoizeWith } from 'ramda';
import { StyledVocabularyRow as VocabularyRow } from './VocabularyRow/VocabularyRow.jsx';
import { toLang } from '../../../utils/mappers/langMapper';
import { WithGraphQLQueryHandling } from '../../WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { metaDataFragment, PageMetaData } from '../../Page/PageMetaData.jsx';
import { GTM_LESSON_PARTS, PAGE_TYPES } from '../../../constants/pageTypes';
import { gtmDataLayerFragment, GtmScriptWithDataLayer } from '../../GoogleTagManager';
import { vocabularyFragment } from '../../Vocabulary/VocabularyQuery.jsx';
import { useStructuralLessonData } from '../../../context/StructuralLessonDataContext.jsx';
import { LESSON_VOCABULARY_URL_SUFFIX } from '../../../utils/url/urlFactory';

const queryName = 'LessonVocabulary';
export const lessonVocabularyQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}($lessonId: Int!, $lessonLang: Language!) {
        content(id: $lessonId, lang: $lessonLang) {
          ... on Lesson {
            vocabularies {
              ...${vocabularyFragment.name}
            }
            glossaries {
              ...${vocabularyFragment.name}
            }
            ...${metaDataFragment.name({ type: 'Lesson' })}
            ${gtmDataLayerFragment.partial()}
          }
        }
      }
      ${metaDataFragment.fragment({ type: 'Lesson' })}
      ${vocabularyFragment.fragment()}
    `,
  ),
};

export const LessonVocabulary = () => {
  const { lessonId } = useParams();
  const { langCode } = useParams();
  const { lessonPath } = useStructuralLessonData().structuralData;

  const lessonVocabularyQueryVariables = {
    lessonId: +lessonId,
    lessonLang: toLang(langCode),
  };

  return (
    <WithGraphQLQueryHandling
      query={lessonVocabularyQuery.query()}
      queryVariables={lessonVocabularyQueryVariables}
    >
      {({ data }) => {
        const { content } = data;
        const { vocabularies, glossaries } = content;

        return (
          <>
            <PageMetaData
              pageType={PAGE_TYPES.VOCABULARY}
              content={content}
              translationFirst={true}
              useDescriptionTranslation={true}
              relativeCanonicalUrl={`${lessonPath}/${LESSON_VOCABULARY_URL_SUFFIX}`}
            />
            <GtmScriptWithDataLayer
              content={content}
              lessonItem={GTM_LESSON_PARTS[PAGE_TYPES.VOCABULARY]}
            />
            {[...vocabularies, ...glossaries].map(vocabularyItem => (
              <VocabularyRow key={vocabularyItem.name} data={vocabularyItem} />
            ))}
          </>
        );
      }}
    </WithGraphQLQueryHandling>
  );
};
