import createEngine from 'redux-storage-engine-localstorage';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import * as storage from './redux-storage';
import rootReducer from './rootReducers';
import globals from '../utils/globals';
import { isServer } from '../utils/ssr';

export const reducer = storage.reducer(rootReducer);
const engine = createEngine('learngerman');
const storageMiddleware = storage.createMiddleware(engine);

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = globals.window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const createStoreWithMiddleware = composeEnhancers(
  applyMiddleware(thunk, storageMiddleware),
)(createStore);

const store = createStoreWithMiddleware(reducer);

const load = storage.createLoader(engine);
if (!isServer()) {
  load(store);
}

export default store;
