import { suspendedComponentFactory } from '../SuspendedComponent/suspendedComponentFactory.jsx';

export { StyledSmFacebook } from './components/Facebook.jsx';
export { StyledSmInstagram } from './components/Instagram.jsx';
export { StyledSmTwitter } from './components/Twitter.jsx';
export { StyledSmYoutube } from './components/Youtube.jsx';
export { StyledSmTiktok } from './components/Tiktok.jsx';

export const { StyledSmBalatarin } = suspendedComponentFactory(() =>
  import('./components/Balatarin.jsx'),
);
export const { StyledSmDouban } = suspendedComponentFactory(() =>
  import('./components/Douban.jsx'),
);
export const { StyledSmEmail } = suspendedComponentFactory(() => import('./components/Email.jsx'));
export const { StyledSmFacebookMessenger } = suspendedComponentFactory(() =>
  import('./components/FacebookMessenger.jsx'),
);
export const { StyledSmFlipboard } = suspendedComponentFactory(() =>
  import('./components/Flipboard.jsx'),
);
export const { StyledSmLine } = suspendedComponentFactory(() => import('./components/Line.jsx'));
export const { StyledSmLinkedIn } = suspendedComponentFactory(() =>
  import('./components/LinkedIn.jsx'),
);
export const { StyledSmMeneame } = suspendedComponentFactory(() =>
  import('./components/Meneame.jsx'),
);
export const { StyledSmMoikrug } = suspendedComponentFactory(() =>
  import('./components/Moikrug.jsx'),
);
export const { StyledSmOdnoklassniki } = suspendedComponentFactory(() =>
  import('./components/Odnoklassniki.jsx'),
);
export const { StyledSmQzone } = suspendedComponentFactory(() => import('./components/Qzone.jsx'));
export const { StyledSmReddit } = suspendedComponentFactory(() =>
  import('./components/Reddit.jsx'),
);
export const { StyledSmRenren } = suspendedComponentFactory(() =>
  import('./components/Renren.jsx'),
);
export const { StyledSmSms } = suspendedComponentFactory(() => import('./components/Sms.jsx'));
export const { StyledSmTelegram } = suspendedComponentFactory(() =>
  import('./components/Telegram.jsx'),
);
export const { StyledSmTwoo } = suspendedComponentFactory(() => import('./components/Twoo.jsx'));
export const { StyledSmViber } = suspendedComponentFactory(() => import('./components/Viber.jsx'));
export const { StyledSmVkontakte } = suspendedComponentFactory(() =>
  import('./components/Vkontakte.jsx'),
);
export const { StyledSmWechat } = suspendedComponentFactory(() =>
  import('./components/Wechat.jsx'),
);
export const { StyledSmWeibo } = suspendedComponentFactory(() => import('./components/Weibo.jsx'));
export const { StyledSmWhatsapp } = suspendedComponentFactory(() =>
  import('./components/Whatsapp.jsx'),
);
export const { StyledSmSharechat } = suspendedComponentFactory(() =>
  import('./components/Sharechat.jsx'),
);
export const { StyledSmMoj } = suspendedComponentFactory(() => import('./components/Moj.jsx'));

export const { StyledComments } = suspendedComponentFactory(() =>
  import('./components/Comments.jsx'),
);
export const { StyledPrint } = suspendedComponentFactory(() => import('./components/Print.jsx'));
export const { StyledTextSize } = suspendedComponentFactory(() =>
  import('./components/TextSize.jsx'),
);
