import { useMemo } from 'react';
import { gql } from '@apollo/client';
import { memoizeWith } from 'ramda';
import { toLang } from '../../../utils/mappers/langMapper';
import { GTM_LESSON_PARTS, PAGE_TYPES } from '../../../constants/pageTypes';
import { gtmDataLayerFragment, GtmScriptWithDataLayer } from '../../GoogleTagManager';
import { WithGraphQLQueryHandling } from '../../WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { metaDataFragment, PageMetaData } from '../../Page/PageMetaData.jsx';
import { LessonPhonemicItems } from './LessonPhonemicItems.jsx';
import { useStructuralLessonData } from '../../../context/StructuralLessonDataContext.jsx';
import { ALPHABETICAL_ORDER_URL_SUFFIX } from '../../../utils/url/urlFactory';
import { responsiveDwPictureFragment } from '../../ResponsiveDwPicture/ResponsiveDwPicture.jsx';

const queryName = 'LessonPhonemicChart';
export const lessonPhonemicChartQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}($lessonId: Int!, $courseId: Int!, $lessonLang: Language!) {
        content(id: $lessonId, lang: $lessonLang) {
          ...${metaDataFragment.name({ type: 'Lesson' })}
          ${gtmDataLayerFragment.partial()}
        }

        course: content(id: $courseId, lang: $lessonLang) {
          ... on Course {
            lessons {
              exercises {
                knowledges {
                  id
                  name
                  knowledgeType
                  mainContentImage {
                    name
                    ...${responsiveDwPictureFragment.name}
                  }
                  audios {
                    mp3Src
                    mainContentImage {
                      name
                      ...${responsiveDwPictureFragment.name}
                    }
                  }
                  text
                }
              }
            }
          }
        }
      }
      ${metaDataFragment.fragment({ type: 'Lesson' })}
      ${responsiveDwPictureFragment.fragment()}
    `,
  ),
};

/**
 * @param {{ lessonId: string, langCode: string }} props
 */
export const LessonPhonemicChart = ({ lessonId, langCode }) => {
  const { structuralData } = useStructuralLessonData();
  const { lessonParentId, lessonNumber: currentLessonNumber, lessonPath } = structuralData;
  const queryVariables = useMemo(
    () => ({
      lessonId: +lessonId,
      courseId: lessonParentId,
      lessonLang: toLang(langCode),
    }),
    [langCode, lessonId, lessonParentId],
  );

  return (
    <WithGraphQLQueryHandling
      query={lessonPhonemicChartQuery.query()}
      queryVariables={queryVariables}
    >
      {({ data: { content, course } }) => (
        <>
          <PageMetaData
            pageType={PAGE_TYPES.ALPHABETICAL_ORDER}
            content={content}
            translationFirst={true}
            useDescriptionTranslation={true}
            relativeCanonicalUrl={`${lessonPath}/${ALPHABETICAL_ORDER_URL_SUFFIX}`}
          />
          <GtmScriptWithDataLayer
            content={content}
            lessonItem={GTM_LESSON_PARTS[PAGE_TYPES.ALPHABETICAL_ORDER]}
          />
          <LessonPhonemicItems course={course} currentLessonNumber={currentLessonNumber} />
        </>
      )}
    </WithGraphQLQueryHandling>
  );
};
