import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { StyledBigImageTeaser as BigImageTeaser } from './BigImageTeaser.jsx';
import {
  publicationDateDeliveryFragment,
  publicationDateModelFragment,
} from '../../PublicationDate/PublicationDate.jsx';

const fragmentName = 'articleFragment';
export const articleFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Article {
        id
        modelType
        isRtl
        name
        text
        shortTitle
        shortTeaser
        namedUrl
        mainContentImage {
          id
          name
          staticUrl
        }
        dkLearningLevel
        ...${publicationDateDeliveryFragment.name()}
        ...${publicationDateModelFragment.name()}
      }
      ${publicationDateDeliveryFragment.fragment()}
      ${publicationDateModelFragment.fragment()}
    `,
  ),
};

export const ArticleTeaser = ({ className, contents, ...restProps }) => {
  const { mainContentImage, shortTitle, shortTeaser, namedUrl, dkLearningLevel, isRtl } = contents;

  return (
    <BigImageTeaser
      className={className}
      image={mainContentImage}
      title={shortTitle}
      teaser={shortTeaser}
      to={namedUrl}
      publicationDateConfig={contents}
      dkLearningLevel={dkLearningLevel}
      isRtl={isRtl}
      {...restProps}
    />
  );
};

export const StyledArticleTeaser = ArticleTeaser;
