import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { StyledLinkTeaser as LinkTeaser } from '../../CoCo/Teaser/LinkTeaser.jsx';

const fragmentName = 'DownloadTeaser';
export const downloadTeaserFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on ContentLink {
        name
        target {
          ... on Download {
            id
            staticUrl
          }
        }
      }
    `,
  ),
};

export const DownloadTeaser = ({ className, link }) => {
  const {
    target: { staticUrl: targetLink },
    name: title,
  } = link;

  const contents = { name: title, url: targetLink };

  return <LinkTeaser className={className} contents={contents} />;
};
