import styled from 'styled-components';
import { useState } from 'react';
import { useInquiryHandling } from '../workflow/useInquiryHandling';
import { useTranslation } from '../../../../hooks/useTranslation';
import { ANSWER_STATE } from '../LessonExerciseAnswer/answerState';
import { LessonExerciseQuestion } from '../LessonExerciseQuestion/LessonExerciseQuestion.jsx';
import { StyledLessonExerciseHint as LessonExerciseHint } from '../LessonExerciseHint/LessonExerciseHint.jsx';
import { LessonExerciseTextAnswer } from '../LessonExerciseAnswer/LessonExerciseTextAnswer/LessonExerciseTextAnswer.jsx';
import { StyledSelfAssessmentQuestion as SelfAssessmentQuestion } from './SelfAssessment/SelfAssessmentQuestion.jsx';
import { exerciseBasicGrid } from '../utils/exerciseCustomCSS';
import { colors, mediaMax, mediaMin } from '../../../../utils/css';
import { ButtonInteraction } from '../../../Interaction/Interaction.jsx';

export const RepeatExercise = ({ className, inquiry }) => {
  const {
    audios,
    mainContentImage,
    learningMetaInfo: { achievableScore },
    id: inquiryId,
  } = inquiry;
  const fixedAnswers = [
    {
      alternativeText: `${useTranslation('exercise.02')}`,
      index: 1,
      answerState: ANSWER_STATE.INITIAL,
    },
    {
      alternativeText: `${useTranslation('exercise.01')}`,
      index: 0,
      answerState: ANSWER_STATE.INITIAL,
    },
  ];

  const { inquiryDescription, subInquiries } = inquiry;
  const { subInquiryDescription } = subInquiries[0];

  const { handleCorrectAnswer, handleWrongAnswer } = useInquiryHandling({
    inquiryId,
    achievableScore,
  });

  const [answersFrozen, setAnswersFrozen] = useState(false);
  const [answers, setSelected] = useState(fixedAnswers);

  const updateSelected = selectedIndex => {
    const updatedAnswers = answers.map((answer, index) => {
      if (index !== selectedIndex) {
        return answer;
      }

      return {
        ...answer,
        answerState: index !== 0 ? ANSWER_STATE.WRONG : ANSWER_STATE.CORRECT,
      };
    });

    setSelected(updatedAnswers);
  };

  const evaluateAnswer = selectedIndex => {
    if (selectedIndex === 0) {
      handleCorrectAnswer();
    } else {
      handleWrongAnswer();
    }
  };

  const onAnswerClicked = index => {
    evaluateAnswer(index);
    updateSelected(index);
    setAnswersFrozen(true);
  };

  return (
    <div
      className={`${className} ${answersFrozen ? 'finished' : ''}`}
      data-type="allocationaudiopronunce"
      data-exercise-id={inquiry.id}
    >
      <div className="question">
        <LessonExerciseQuestion {...{ inquiry, useSubInquiryTextAsLabel: true }} />
      </div>
      {subInquiryDescription && (
        <ButtonInteraction as="div" className="subInquiry">
          {subInquiryDescription}
          <BubblePointer />
        </ButtonInteraction>
      )}
      <LessonExerciseHint className="hint" description={inquiryDescription} />
      <SelfAssessmentQuestion className="selfAssessment" {...{ audios, mainContentImage }} />
      <div className="answer-input" aria-multiselectable="false">
        {answers.map((answer, index) => (
          <LessonExerciseTextAnswer
            key={`${inquiryId}-${index}`}
            answer={answer}
            handleAnswerClicked={() => onAnswerClicked(index)}
            disabled={answersFrozen}
          />
        ))}
      </div>
    </div>
  );
};

const BubblePointer = styled('span')`
  /* @noflip */
   {
    position: absolute;
    z-index: 2;
    background-color: ${colors.BLUE_GREY_02};
    border-top: 20px solid ${colors.LG_WHITE};
    border-left: 20px solid ${colors.BLUE_GREY_02};

    right: -20px;
    top: calc(50%);
    transform: translateY(-50%);
  }
`;

export const StyledRepeatExercise = styled(RepeatExercise)`
  ${exerciseBasicGrid}

  > .subInquiry {
    position: relative;
    background-color: ${colors.BLUE_GREY_02};
    /* Has a slightly different look, to distinguish from interactive answers */
    color: ${colors.DARK_BLUE_GREY_02};
    padding: 1rem 1.2rem;
    border-radius: 8px;
  }

  > .selfAssessment {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }

  > .question ~ .hint {
    margin-top: 80px;
  }

  > .question,
  > .selfAssessment {
    margin-bottom: 1rem;
  }

  > .answer-input {
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
  }

  ${mediaMin.md`
    margin-bottom: 80px;
    
    > .answer-input {
      margin-top: 100px;
      margin-bottom: 60px;
      grid-area: 3 / 2 / span 1 / span 1;
    }
    
    > .question {
      display: flex;
      flex-flow: column;
      height: min-content;
      margin-bottom: 0;
    }    
    
    > .hint ~ .answer-input {
      margin-top: unset;
    }

    > .selfAssessment {
      margin-bottom: auto;
    }
  `}

  ${mediaMax.sm`
    > .answer-input {
      margin-top: 30px;
    }
    > .subInquiry ~ .answer-input {
      margin-top: 0;
    }
  `}
`;
