import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  groupBy,
  isFinalTestPredicate,
  removeContentLinksWithEmptyTarget,
} from '../../utils/commons';
import {
  deleteCourseProgress,
  deleteLessonProgress,
  initCourse,
  readCourseProgress,
  readLessonProgresses,
} from '../../state/progress/learnProgressDuck';
import { StyledCourseList as CourseList } from './CourseList/CourseList.jsx';
import { ModalIntegration } from '../Modal/ModalIntegration.jsx';
import { StyledResetModal as ResetModal } from '../Modal/StyledResetModal.jsx';
import { StyledCourseProgress as CourseProgress } from './Progress/CourseProgress.jsx';
import { StyledHeroImage as HeroImage } from '../HeroImage/HeroImage.jsx';

export const CourseOverview = ({ data, contentId }) => {
  const {
    contentLinks: lessonContentLinks,
    mainContentImageLink,
    learningMetaInfo: { achievableScore, exerciseCount } = {},
  } = data.content;
  const filteredContentLinks = removeContentLinksWithEmptyTarget(lessonContentLinks);
  const finalLessonId = filteredContentLinks
    .filter(contentLink => isFinalTestPredicate(contentLink))
    .map(contentLink => contentLink.target.id);
  const allLessonIds = filteredContentLinks.map(contentLink => contentLink.target.id);
  const groupedLessons = groupBy('groupName', filteredContentLinks);
  const [isResetModalOpen, setResetModalOpen] = useState(false);
  const [lessonIdToDelete, setLessonIdToDelete] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      initCourse({
        allExerciseCount: exerciseCount,
        maxPointsForAllExercises: achievableScore,
        cosCourseId: contentId,
      }),
    );
    dispatch(readLessonProgresses(allLessonIds));
    dispatch(readCourseProgress(contentId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickResetCourse = () => {
    setResetModalOpen(true);
  };

  const onClickResetLesson = (e, lessonId) => {
    e.preventDefault();
    setLessonIdToDelete(lessonId);
    setResetModalOpen(true);
  };

  const onConfirmResetProgress = () => {
    if (lessonIdToDelete) {
      dispatch(deleteLessonProgress(lessonIdToDelete));
      setLessonIdToDelete(null);
    } else {
      dispatch(deleteCourseProgress(contentId));
    }
    setResetModalOpen(false);
  };

  const onCancelResetModal = () => {
    setLessonIdToDelete(null);
    setResetModalOpen(false);
  };

  return (
    <main data-course-id={contentId} id="courses">
      <HeroImage mainContentImageLink={mainContentImageLink} />
      <CourseProgress
        courseId={+contentId}
        finalLessonId={finalLessonId}
        onClickResetCourse={onClickResetCourse}
      />
      <CourseList groupedLessons={groupedLessons} onClickResetLesson={onClickResetLesson} />
      <ModalIntegration isOpen={isResetModalOpen}>
        <ResetModal
          fadeIn={true}
          onConfirmClicked={onConfirmResetProgress}
          onCancelClicked={onCancelResetModal}
        />
      </ModalIntegration>
    </main>
  );
};
