import styled from 'styled-components';
import { colors } from '../../utils/css';
import { StyledExerciseButton } from '../BubbleButton/ExerciseButton.jsx';

const UnstyledChoicechip = ({ className, children, ...restProps }) => {
  return (
    <StyledExerciseButton className={className} {...restProps}>
      {children}
    </StyledExerciseButton>
  );
};

export const ChoicechipPlaceholder = styled.span`
  background-color: ${colors.LG_WHITE};
  border: solid 2px ${colors.BLUE_GREY_02};
  border-radius: 10px;

  display: inline-block;
  /* Should have same height as filled chip (font-size plus padding) */
  height: calc(2rem);
  /* Arbitrary content width plus padding */
  width: calc(6ch + 1rem);
`;

export const Choicechip = styled(UnstyledChoicechip)`
  padding: calc(0.5rem - var(--border-width));
  border-radius: 10px;
  width: unset;
`;
