import { css } from 'styled-components';
import { resolutions, mediaMin } from '../../utils/css';
import { StyledSummary as Summary } from '../Lesson/LessonExercise/LessonExerciseResult/Summary.jsx';
import { ContentSection } from '../ContentContainer/ContentSection/ContentSection.jsx';
import { gridSpacingStyles } from '../Lesson/LessonExercise/utils/exerciseCustomCSS';

export const TestResultStyles = css`
  ${gridSpacingStyles({ spacing: 0 })}

  @media (min-width: ${resolutions.min.md}px) and (max-width: ${resolutions.max.md}px) {
    --grid-spacing: 0;
    --grid-width: 100%;
  }

  ${Summary} {
    margin-bottom: 50px;
  }

  .result-text,
  ${ContentSection} {
    margin-bottom: 60px;
    ${gridSpacingStyles({ spacing: 20 })}

    ${mediaMin.xl`
      margin-left: 0%;
      width: 100%;
    `}
  }
`;
