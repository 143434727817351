import styled from 'styled-components';
import { StyledDesktopBanner as DesktopBanner } from './DesktopBanner.jsx';
import { StyledMobileBanner as MobileBanner } from './MobileBanner.jsx';
import { StyledNavigationContainer as NavigationContainer } from './NavigationContainer/NavigationContainer.jsx';
import { colors, mediaMin } from '../../utils/css';
import { getDashboardUrl } from '../../utils/url/urlFactory';
import { useLanguage } from '../../context/LanguageContext';

export const Header = ({ className }) => {
  const { langCode } = useLanguage();
  const homeURL = getDashboardUrl(langCode);
  const headerTitleKey = 'header.bar';

  return (
    <header className={className}>
      <MobileBanner title={headerTitleKey} homeURL={homeURL} />
      <DesktopBanner homeURL={homeURL} />
      <NavigationContainer title={headerTitleKey} />
    </header>
  );
};

export const StyledHeader = styled(Header)`
  background: ${colors.LG_WHITE};
  background-image: none;
  height: auto;
  min-height: 60px;

  ${mediaMin.md`
    border-radius: 4px;
    margin-bottom: 0px;
  `}

  ${mediaMin.xl`
    border: none;
    height: 200px;
    margin: 0;
    margin-bottom: 20px;
  `}
`;
