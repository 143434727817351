import styled from 'styled-components';
import { useExerciseProgress } from '../workflow/ExerciseProgress.jsx';
import { useLanguage } from '../../../../context/LanguageContext';
import { useTranslation } from '../../../../hooks/useTranslation';
import { TeaserText } from '../../../Text/Text.jsx';

export const LessonExerciseStats = ({ className, amountOfInquiries }) => {
  const { exerciseProgress } = useExerciseProgress();
  const { isRtl } = useLanguage();
  const outOfText = useTranslation('exercise.outOf');
  const numberOfPointsEarnedText = useTranslation('exercise.pointsEarned');
  const numberOfExercisesSolvedText = useTranslation('exercise.exercisesResolved');

  const ltrStats = `${exerciseProgress.numberOfDoneInquiries}\u00A0${outOfText}\u00A0${amountOfInquiries} ${numberOfExercisesSolvedText}. ${exerciseProgress.currentExerciseScore}\u00A0${numberOfPointsEarnedText}.`;

  const rtlStats = `${exerciseProgress.numberOfDoneInquiries}\u00A0${outOfText} ${numberOfExercisesSolvedText}\u00A0${amountOfInquiries} ${numberOfPointsEarnedText}\u00A0${exerciseProgress.currentExerciseScore}`;

  return (
    <section className={className}>
      <TeaserText as="p">{isRtl ? rtlStats : ltrStats}</TeaserText>
    </section>
  );
};

export const StyledLessonExerciseStats = styled(LessonExerciseStats)`
  text-align: center;
`;
