import styled from 'styled-components';
import { mediaMax, colors } from '../../../utils/css';
import { CourseAccordion } from './CourseAccordion/CourseAccordion.jsx';
import { StyledLessonList } from './LessonList/LessonList.jsx';

export const CourseList = ({ className, groupedLessons, onClickResetLesson }) => (
  <section className={className}>
    {Object.values(groupedLessons).map(group => (
      // group lessons by their group names
      <section key={group[0].id}>
        <CourseAccordion
          groupNameTranslationKey={group[0].groupName}
          groupedLessons={group}
          onClickReset={onClickResetLesson}
        />
      </section>
    ))}
  </section>
);

export const StyledCourseList = styled(CourseList)`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;

  > section {
    display: inline-block;
    background: ${colors.LG_WHITE};

    &:nth-child(odd) ${StyledLessonList} {
      background-color: ${colors.BLUE_GREY_01};
    }
  }

  ${mediaMax.md`
    grid-template-columns: 100%;
    padding: 0;
  `}
`;
