import { useEffect, useRef } from 'react';
import { getOptions, importVideoJsScripts } from '../../video';
import { registerTrackingEvents } from '../GoogleTagManager/mediaTrackingUtils';

export const useVideoJsCommons = ({
  elementRef,
  componentNameRef,
  updatePlayer,
  isVideo,
  noTracking,
}) => {
  /** @type {React.MutableRefObject<videojs.VideoJsPlayer | null>} */
  const playerRef = useRef(null);

  useEffect(() => {
    let didCancel = false;
    const playerNode = elementRef.current;

    if (playerRef.current) {
      updatePlayer(playerRef.current);
    } else {
      importVideoJsScripts({ audioPlugins: !isVideo, videoPlugins: isVideo })
        .then(({ videojs, childcomponentNames }) => {
          if (didCancel || !playerNode) {
            return;
          }

          const player = videojs(playerNode, getOptions(playerNode));

          // eslint-disable-next-line no-param-reassign
          componentNameRef.current = childcomponentNames;
          playerRef.current = player;

          updatePlayer(player);
          if (!noTracking) {
            registerTrackingEvents(player);
          }
        })
        .catch(err => console.error('Error in useVideoJs', err));
    }

    return () => {
      didCancel = true;
    };
  }, [updatePlayer, elementRef, isVideo, componentNameRef, noTracking]);

  useEffect(
    () => () => {
      playerRef.current?.dispose();
      /**
       * Resetting playerRef avoids crashing after hot code swapping.
       * HCS calls this unmount callback without actually unmounting.
       * It still does not work for video players, but at least it won't crash.
       */
      playerRef.current = null;
    },
    [],
  );
};
