import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { FrontendConfigContext } from '../../context/FrontendConfigContext';
import { toLang } from '../../utils/mappers/langMapper';
import { WithGraphQLQueryHandling } from '../WithGraphQLQueryHandling/WithGraphQLQueryHandling.jsx';
import { footerFragment } from '../Footer/Footer.jsx';
import { MetadataContext } from '../../context/MetadataContext';
import { useLanguage } from '../../context/LanguageContext';
import { I18nStringProvider } from '../../context/I18nContext.jsx';
import { NavigationRootContext, navigationRootFragment } from '../../context/NavigationRootContext';
import { FooterContext } from '../../context/FooterContext';

const queryName = 'translationsAndConfig';
export const i18nConfigQuery = {
  name: queryName,
  query: memoizeWith(
    () => '',
    () => gql`
      query ${queryName}($lang: Language!) {
        i18n: i18nByLang(lang: $lang) {
          all
        }
        config {
          imageBasePath
          staticBaseHost
          consentManagerId
          gtmId
          gtmLevel1Id
          gtmEnvironment(lang: $lang)
          reCaptchaSiteKey
        }
        iso639ByLang(lang: $lang)
        ...${footerFragment.name}
        lgNavigationRoot {
          ...${navigationRootFragment.name}
        }
      }
      ${footerFragment.fragment()}
      ${navigationRootFragment.fragment()}
    `,
  ),
};

export const I18nConfigLoader = ({ children }) => {
  const { langCode } = useLanguage();

  const i18nQueryVariables = { lang: toLang(langCode || 'en') };

  return (
    <WithGraphQLQueryHandling query={i18nConfigQuery.query()} queryVariables={i18nQueryVariables}>
      {({ data }) => (
        <I18nStringProvider translationsString={data.i18n?.all}>
          <MetadataContext.Provider value={data.iso639ByLang}>
            <FrontendConfigContext.Provider value={data.config}>
              <NavigationRootContext.Provider value={data.lgNavigationRoot}>
                <FooterContext.Provider value={data.footer}>
                  {children(data)}
                </FooterContext.Provider>
              </NavigationRootContext.Provider>
            </FrontendConfigContext.Provider>
          </MetadataContext.Provider>
        </I18nStringProvider>
      )}
    </WithGraphQLQueryHandling>
  );
};
