import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { Helmet } from 'react-helmet-async';
import { useFrontendConfig } from '../../context/FrontendConfigContext';
import { replaceInPattern } from '../../utils/i18n';

const fragmentName = ({ type }) => `${type}ImageOGMetaData`;
export const imageOGMetaDataFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    ({ type }) => type,
    ({ type }) => gql`
      fragment ${fragmentName({ type })} on ${type} {
        mainContentImage {
          id
          imageFormats
          staticUrl
        }
      }
    `,
  ),
};

export const hasCorrectImageFormat = imageFormats =>
  imageFormats.find(format => format.includes('MASTER_LANDSCAPE'));

const createImageSharingData = ({ imageFormats, staticUrl }) => {
  if (hasCorrectImageFormat(imageFormats)) {
    return {
      url: replaceInPattern(staticUrl, { formatId: '401' }),
      width: 700,
      height: 394,
    };
  }
  return undefined;
};

export const ImageOGMetaData = ({ image }) => {
  const { imageBasePath } = useFrontendConfig();

  if (!image) {
    return null;
  }

  const { url, width, height } = createImageSharingData({ ...image, imageBasePath }) || {};

  if (!url) {
    return null;
  }

  return (
    <Helmet>
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image" content={url} />
      <meta property="og:image:width" content={width} />
      <meta property="og:image:height" content={height} />
    </Helmet>
  );
};
