import { useState, useMemo } from 'react';
import styled from 'styled-components';
import { update } from 'ramda';
import { cleanupAndAddPlaceholderComponents } from '../clozeExerciseUtils';
import { StyledExerciseRichText as ExerciseRichText } from '../ExerciseRichText.jsx';
import { INQUIRY_STATE } from '../../workflow/inquiryState';
import { shuffle } from '../../../../../utils/commons';
import { colors } from '../../../../../utils/css';
import { isNormalizedTextEqual } from '../../utils/exerciseUtils';
import { Choicechip } from '../../../../Choicechip/Choicechip.jsx';

export const ChooseAnswer = ({
  inquiry,
  onCorrectAnswerAmountChanged,
  inquiryState,
  isDisabled,
  knowledges,
  className,
}) => {
  const { subInquiries } = inquiry;
  const isContinuousText = inquiry.clozeLayoutType === 'CONTINUOUS_TEXT';

  const answers = useMemo(
    () => [...subInquiries].sort((a, b) => a.order - b.order).map(s => s.alternatives[0]),
    [subInquiries],
  );

  const [{ slots, options }, setState] = useState(() =>
    /**
     * A slot can be empty or hold an answerId.
     * @type {{ slots: (number | null)[], options: number[] }}
     */
    {
      const slotAmount = isContinuousText
        ? inquiry.text.match(/#p[sml]?#/g)?.length ?? 0
        : answers.length;
      return {
        slots: Array(slotAmount).fill(null),
        options: shuffle(answers.map(a => a.id)),
      };
    },
  );

  const isCorrectAnswer = (selectedAnswerId, index) => {
    if (!selectedAnswerId) {
      return false;
    }
    const { id: expectedAnswerId, alternativeText: expectedAnswerText } = answers[index];
    if (expectedAnswerId === selectedAnswerId) {
      return true;
    }
    const { alternativeText: selectedAnswerText } = answers.find(a => a.id === selectedAnswerId);
    return isNormalizedTextEqual(expectedAnswerText, selectedAnswerText);
  };

  const selectAnswer = answerId => {
    const newState = {
      slots: update(
        slots.findIndex(e => e === null),
        answerId,
        slots,
      ),
      options: options.filter(opt => opt !== answerId),
    };

    setState(newState);
    onCorrectAnswerAmountChanged(newState.slots.filter(isCorrectAnswer).length);
  };

  const removeAnswer = answerId => {
    const newState = {
      slots: slots.map(entry => (entry === answerId ? null : entry)),
      options: [answerId, ...options],
    };

    setState(newState);
    onCorrectAnswerAmountChanged(newState.slots.filter(isCorrectAnswer).length);
  };

  const createPlaceholder = index => {
    const selectedAnswer =
      inquiryState === INQUIRY_STATE.SOLVED
        ? answers[index]
        : answers.find(a => a.id === slots[index]);

    if (!selectedAnswer) {
      return <EmptyPlaceholder />;
    }

    const isCorrect = isCorrectAnswer(selectedAnswer.id, index);

    return (
      <AnswerButton
        onClick={() => removeAnswer(selectedAnswer.id)}
        correct={
          (inquiryState === INQUIRY_STATE.CORRECT || inquiryState === INQUIRY_STATE.WRONG) &&
          isCorrect
        }
        incorrect={inquiryState === INQUIRY_STATE.WRONG && !isCorrect}
        disabled={isDisabled}
        aria-selected
        selected
      >
        {selectedAnswer.alternativeText}
      </AnswerButton>
    );
  };

  const answerContent = useMemo(
    () =>
      cleanupAndAddPlaceholderComponents(
        isContinuousText ? inquiry.text : subInquiries.map(s => s.subInquiryText).join('<br>'),
      ),
    [isContinuousText, inquiry.text, subInquiries],
  );

  const allSlotsFilled = slots.every(s => s !== null);

  return (
    <div className={className}>
      <StyledContainerTextContent>
        <ExerciseRichText
          content={answerContent}
          createComponent={createPlaceholder}
          knowledges={knowledges}
        />
      </StyledContainerTextContent>
      <div className="answerCollection" aria-multiselectable="true">
        {inquiryState !== INQUIRY_STATE.SOLVED &&
          options.map(answerId => {
            const { id, alternativeText } = answers.find(a => a.id === answerId);
            return (
              <AnswerButton
                key={id}
                onClick={() => selectAnswer(id)}
                disabled={allSlotsFilled}
                aria-selected={false}
              >
                {alternativeText}
              </AnswerButton>
            );
          })}
      </div>
    </div>
  );
};

const StyledContainerTextContent = styled('div')`
  br {
    content: '';
    display: block;
    height: 10px;
  }
`;

const EmptyPlaceholder = styled('div')`
  display: inline-block;
  background-color: ${colors.LG_WHITE};
  border: 1px solid ${colors.BLUE_GREY_04};
  height: 2rem;
  width: 80px;
  vertical-align: middle;
  margin: 0.1rem;
`;

const AnswerButton = styled(Choicechip)`
  margin: 0.1rem 0.25rem;
`;

export const StyledChooseAnswer = styled(ChooseAnswer)`
  /* @noflip */
  text-align: left;
  /* @noflip */
  direction: ltr;

  > ${StyledContainerTextContent} {
    background-color: ${colors.BLUE_GREY_01};
    padding: 1rem;
  }

  > .answerCollection {
    margin-top: 1rem;
    /* @noflip */
    text-align: right;
  }
`;
