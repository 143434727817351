import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from '../../hooks/useTranslation';
import { TeaserText } from '../Text/Text.jsx';

export const InteractionLabel = ({ className, htmlForName, labelKey, registerOptions }) => {
  const translatedText = useTranslation(labelKey);
  const required = registerOptions?.required || false;

  if (!translatedText) return null;

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <TeaserText
      as="label"
      className={classnames(className, { required })}
      htmlFor={htmlForName}
      dangerouslySetInnerHTML={{
        __html: translatedText,
      }}
    />
  );
};

export const StyledInteractionLabel = styled(InteractionLabel)`
  padding-bottom: 5px;

  &.required::after {
    content: '*';
  }
`;
