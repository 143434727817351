import styled from 'styled-components';
import { useOnEsc } from '../../hooks/useOnEsc';
import { StyledModal as Modal } from './StyledModal.jsx';
import { I18nText } from '../I18n/I18nText.jsx';
import { colors, mediaMax } from '../../utils/css';
import { StyledStandardButton } from '../StandardButton/StandardButton.jsx';
import { DetailIntroText } from '../Text/Text.jsx';

export const ResetModal = ({ onConfirmClicked, onCancelClicked, ...modalProps }) => {
  useOnEsc(onCancelClicked);

  return (
    <Modal {...modalProps}>
      <div className="modal-body">
        <I18nText isA={DetailIntroText} translation="lesson.modal.confirm" />
      </div>
      <div className="modal-footer">
        <StyledStandardButton
          variant="primary"
          titleKey="lesson.modal.reset"
          onClick={onConfirmClicked}
        />
        <StyledStandardButton
          variant="secondary"
          titleKey="lesson.modal.cancel"
          onClick={onCancelClicked}
        />
      </div>
    </Modal>
  );
};

export const StyledResetModal = styled(ResetModal)`
  .modal-body {
    position: relative;
    padding: 15px;

    p {
      margin: 0 0 10px;
    }
  }

  .modal-footer {
    padding: 35px 10px;
    text-align: right;
    border-top: 1px solid ${colors.DW_GREY_03};
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;

    ${mediaMax.md`
      flex-flow: column;
    `}

    button {
      position: relative;
      margin: 0 7px;
      width: 100%;

      ${mediaMax.md`
        margin: 15px 0;
      `}
    }
  }
`;
