import { css } from 'styled-components';

export const AnswersGridDefinition = css`
  .answers {
    display: grid;

    &.text {
      grid-template-columns: 1fr;
      grid-row-gap: 0.5rem;
    }

    &.image {
      grid-template-columns: 1fr 1fr;
    }
  }

  .question {
    margin-bottom: 1rem;
  }
`;
