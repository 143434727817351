import styled from 'styled-components';
import { ANSWER_STATE } from '../answerState';
import { BubbleButton } from '../../../../BubbleButton/BubbleButton.jsx';

const assignAnswerAriaLabel = answerState => {
  switch (answerState) {
    case ANSWER_STATE.INITIAL:
      return 'Not selected';
    case ANSWER_STATE.SOLVED:
    case ANSWER_STATE.SELECTED:
      return 'Selected';
    case ANSWER_STATE.CORRECT:
      return 'Correct';
    case ANSWER_STATE.WRONG:
      return 'Wrong';
    default:
      throw new Error('invalid answerState');
  }
};

const UnstyledLessonExerciseTextAnswer = ({ answer, handleAnswerClicked, ...restProps }) => {
  const { id, answerState, alternativeText } = answer;

  return (
    <BubbleButton
      aria-label={`${alternativeText} - ${assignAnswerAriaLabel(answerState)}`}
      aria-selected={answerState !== ANSWER_STATE.INITIAL}
      selected={answerState !== ANSWER_STATE.INITIAL}
      correct={answerState === ANSWER_STATE.CORRECT}
      incorrect={answerState === ANSWER_STATE.WRONG}
      data-exercise-answer-id={id}
      onClick={handleAnswerClicked}
      {...restProps}
    >
      {alternativeText}
    </BubbleButton>
  );
};

export const LessonExerciseTextAnswer = styled(UnstyledLessonExerciseTextAnswer)``;
