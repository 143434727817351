import styled from 'styled-components';
import StarGrey from '../../assets/svg/star-gray.svg';
import { useTranslation } from '../../hooks/useTranslation';

export const ProgressIconStar = ({ className }) => {
  const title = useTranslation('common.progress.good');

  return <img alt={title} className={className} src={StarGrey} title={title} />;
};

export const StyledProgressIconStar = styled(ProgressIconStar)`
  width: 16px;
  height: 16px;
`;
